var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// packages/remirror__extension-history/src/history-extension.ts
import {
  command,
  environment,
  extension,
  helper,
  isFunction,
  keyBinding,
  NamedShortcut,
  nonChainable,
  PlainExtension
} from "@remirror/core";
import { ExtensionHistoryMessages as Messages } from "@remirror/messages";
import { history, redo, redoDepth, undo, undoDepth } from "@remirror/pm/history";
var HistoryExtension = class extends PlainExtension {
  constructor() {
    super(...arguments);
    /**
     * Wraps the history method to inject state from the getState or getDispatch
     * method.
     *
     * @param method - the method to wrap
     */
    this.wrapMethod = (method, callback) => {
      return ({ state, dispatch, view }) => {
        const { getState, getDispatch } = this.options;
        const wrappedState = isFunction(getState) ? getState() : state;
        const wrappedDispatch = isFunction(getDispatch) && dispatch ? getDispatch() : dispatch;
        const success = method(wrappedState, wrappedDispatch, view);
        callback == null ? void 0 : callback(success);
        return success;
      };
    };
  }
  get name() {
    return "history";
  }
  /**
   * Adds the default key mappings for undo and redo.
   */
  createKeymap() {
    return {
      "Mod-y": !environment.isMac ? this.wrapMethod(redo, this.options.onRedo) : () => false,
      "Mod-z": this.wrapMethod(undo, this.options.onUndo),
      "Shift-Mod-z": this.wrapMethod(redo, this.options.onRedo)
    };
  }
  undoShortcut(props) {
    return this.wrapMethod(undo, this.options.onUndo)(props);
  }
  redoShortcut(props) {
    return this.wrapMethod(redo, this.options.onRedo)(props);
  }
  /**
   * Bring the `prosemirror-history` plugin with options set on this extension.
   */
  createExternalPlugins() {
    const { depth, newGroupDelay } = this.options;
    return [history({ depth, newGroupDelay })];
  }
  undo() {
    return nonChainable(this.wrapMethod(undo, this.options.onUndo));
  }
  redo() {
    return nonChainable(this.wrapMethod(redo, this.options.onRedo));
  }
  undoDepth(state = this.store.getState()) {
    return undoDepth(state);
  }
  redoDepth(state = this.store.getState()) {
    return redoDepth(state);
  }
};
__decorateClass([
  keyBinding({ shortcut: NamedShortcut.Undo, command: "undo" })
], HistoryExtension.prototype, "undoShortcut", 1);
__decorateClass([
  keyBinding({ shortcut: NamedShortcut.Redo, command: "redo" })
], HistoryExtension.prototype, "redoShortcut", 1);
__decorateClass([
  command({
    disableChaining: true,
    description: ({ t }) => t(Messages.UNDO_DESCRIPTION),
    label: ({ t }) => t(Messages.UNDO_LABEL),
    icon: "arrowGoBackFill"
  })
], HistoryExtension.prototype, "undo", 1);
__decorateClass([
  command({
    disableChaining: true,
    description: ({ t }) => t(Messages.REDO_DESCRIPTION),
    label: ({ t }) => t(Messages.REDO_LABEL),
    icon: "arrowGoForwardFill"
  })
], HistoryExtension.prototype, "redo", 1);
__decorateClass([
  helper()
], HistoryExtension.prototype, "undoDepth", 1);
__decorateClass([
  helper()
], HistoryExtension.prototype, "redoDepth", 1);
HistoryExtension = __decorateClass([
  extension({
    defaultOptions: {
      depth: 100,
      newGroupDelay: 500,
      getDispatch: void 0,
      getState: void 0
    },
    staticKeys: ["depth", "newGroupDelay"],
    handlerKeys: ["onUndo", "onRedo"]
  })
], HistoryExtension);
export {
  HistoryExtension
};
