import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { CONTACT_FRAGMENT } from '@cotiss/contact'
import { CONTRACT_SHELL_FRAGMENT } from '@cotiss/contract'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation'
import { PERFORMANCE_SCORECARD_FRAGMENT } from '@cotiss/performance'
import {
  GqlCreatePerformanceScorecardInput,
  GqlCreatePerformanceScorecardMutation,
  GqlCreatePerformanceScorecardMutationVariables,
  GqlPerformanceScorecardFieldsFragment,
} from '@gql'

export const mutateCreatePerformanceScorecard = async (input: GqlCreatePerformanceScorecardInput) => {
  const { data } = await apolloService.mutate<GqlCreatePerformanceScorecardMutation, GqlCreatePerformanceScorecardMutationVariables>({
    mutation: gql`
      ${CONTACT_FRAGMENT}
      ${CONTRACT_SHELL_FRAGMENT}
      ${ORGANISATION_FRAGMENT}
      ${PERFORMANCE_SCORECARD_FRAGMENT}

      mutation CreatePerformanceScorecard($input: CreatePerformanceScorecardInput!) {
        createPerformanceScorecard(input: $input) {
          ...PerformanceScorecardFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createPerformanceScorecard as GqlPerformanceScorecardFieldsFragment
}
