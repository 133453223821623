import { includes, map } from 'lodash'
import React, { memo, useMemo, useState } from 'react'
import { GqlPagination } from '@gql'
import { userService } from '@cotiss/user'
import { usePerformanceScorecardUser, usePerformanceScorecardMetricUser } from '@cotiss/performance'
import {
  useToast,
  useCallout,
  useAsyncEffect,
  sentryService,
  Text,
  Hr,
  TableHeader,
  Table,
  TableColumn,
  Button,
  Icon,
  TableRowCta,
  ConfirmModal,
} from '@cotiss/common'

type Props = {
  performanceScorecardId: string
  performanceScorecardUserId: string
}

export const PerformanceScorecardEvaluatorViewMetricsStep = memo(({ performanceScorecardId, performanceScorecardUserId }: Props) => {
  const { openToast } = useToast()
  const { openModal, closeDrawer } = useCallout()
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState<GqlPagination>()
  const { performanceScorecardUser, performanceScorecardUserInSession } = usePerformanceScorecardUser()
  const { performanceScorecardMetricUsers, queryPerformanceScorecardMetricUserList, mutateDeletePerformanceScorecardMetricUser } =
    usePerformanceScorecardMetricUser()

  useAsyncEffect(async () => {
    try {
      setIsLoading(true)
      const [{ pagination }] = await Promise.all([
        queryPerformanceScorecardMetricUserList({
          filter: { performanceScorecardId, performanceScorecardUserId },
          pagination: { page: currentPage, pageSize: 20 },
        }),
      ])
      setPagination(pagination)
      setIsLoading(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      closeDrawer()
    }
  }, [currentPage])

  const { columns } = useMemo(() => {
    const columns: TableColumn[] = [
      {
        heading: 'Metric',
        rows: map(performanceScorecardMetricUsers, ({ id: performanceScorecardMetricUserId, performanceScorecardMetric }) => ({
          content: () => (
            <Text className="truncate" font="jakarta" title={performanceScorecardMetric.performanceMetric.name}>
              {performanceScorecardMetric.performanceMetric.name}
            </Text>
          ),
          cta: performanceScorecardUser && includes(performanceScorecardUserInSession?.roles, 'owner') && (
            <TableRowCta
              actions={[
                {
                  label: 'Delete',
                  onClick: () =>
                    openModal(
                      <ConfirmModal
                        heading="Delete metric"
                        description="Are you sure you want to delete this metric from this user?"
                        onSubmit={() => mutateDeletePerformanceScorecardMetricUser({ performanceScorecardMetricUserId })}
                      />
                    ),
                },
              ]}
            />
          ),
        })),
      },
      {
        heading: 'Group',
        rows: map(performanceScorecardMetricUsers, ({ performanceScorecardMetric }) => ({
          content: () => <Text>{performanceScorecardMetric.performanceMetric.group}</Text>,
        })),
      },
      {
        heading: 'Target',
        rows: map(performanceScorecardMetricUsers, ({ performanceScorecardMetric }) => ({
          content: () => <Text>{performanceScorecardMetric.target}</Text>,
        })),
      },
    ]
    return { columns }
  }, [performanceScorecardMetricUsers])

  return (
    <>
      {performanceScorecardUser && (
        <Text className="font-medium" size="lg">
          {userService.getFullName(performanceScorecardUser.user)}
        </Text>
      )}
      {performanceScorecardUser && (
        <Text className="mt-2" size="sm" variant="light">
          {performanceScorecardUser.user.email}
        </Text>
      )}
      <Hr className="my-4" />
      <TableHeader className="flex items-center justify-between">
        <div className="mr-4">
          <Text className="font-medium" size="lg">
            Metrics
          </Text>
          <Text variant="light">Below are all the metrics assigned to this user.</Text>
        </div>
        <Button className="ml-2" type="submit" variant="secondary-dark" state="translucent" size="sm">
          <Icon icon="plus" /> Associate metric
        </Button>
      </TableHeader>
      <Table
        columns={columns}
        isLoading={isLoading}
        pagination={pagination}
        onPageChange={setCurrentPage}
        emptyCta={
          <Button size="sm" state="text" variant="secondary" type="submit" isDisabled={isLoading}>
            + Associate metric
          </Button>
        }
      />
    </>
  )
})
