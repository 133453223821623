import { gql } from '@apollo/client'
import { CONTACT_FRAGMENT } from '@cotiss/contact'
import { CONTRACT_SHELL_FRAGMENT } from '@cotiss/contract'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation'
import { PERFORMANCE_SCORECARD_FRAGMENT } from '@cotiss/performance'
import { apolloService, PAGINATION_FRAGMENT } from '@cotiss/common'
import { GqlPerformanceScorecardListInput, GqlPerformanceScorecardListQuery } from '@gql'

export const queryPerformanceScorecardList = async (input: GqlPerformanceScorecardListInput) => {
  const { data } = await apolloService.query<GqlPerformanceScorecardListQuery>({
    query: gql`
      ${CONTACT_FRAGMENT}
      ${PAGINATION_FRAGMENT}
      ${ORGANISATION_FRAGMENT}
      ${CONTRACT_SHELL_FRAGMENT}
      ${PERFORMANCE_SCORECARD_FRAGMENT}

      query PerformanceScorecardList($input: PerformanceScorecardListInput!) {
        performanceScorecardList(input: $input) {
          items {
            ...PerformanceScorecardFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.performanceScorecardList
}
