var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// packages/remirror__extension-embed/src/iframe-extension.ts
import { parse, stringify } from "querystringify";
import {
  command,
  cx,
  extension,
  ExtensionTag,
  findSelectedNodeOfType,
  NodeExtension,
  object,
  omitExtraAttributes
} from "@remirror/core";

// packages/remirror__extension-embed/src/resizable-iframe-view.ts
import { ResizableNodeView, ResizableRatioType } from "prosemirror-resizable-view";
var ResizableIframeView = class extends ResizableNodeView {
  constructor(node, view, getPos, iframeOptions) {
    const { width, height } = node.attrs;
    const initialSize = width && height ? { width, height } : void 0;
    super({
      node,
      view,
      getPos,
      aspectRatio: ResizableRatioType.Flexible,
      options: iframeOptions,
      initialSize
    });
    this.options = iframeOptions;
  }
  createElement({ node, options }) {
    const { src, type, allowFullScreen, frameBorder } = node.attrs;
    const iframe = document.createElement("iframe");
    iframe.setAttribute("src", src);
    iframe.dataset.embedType = type;
    iframe.setAttribute("allowfullscreen", allowFullScreen ? "true" : "false");
    iframe.setAttribute("frameBorder", frameBorder == null ? void 0 : frameBorder.toString());
    if (options == null ? void 0 : options.class) {
      iframe.classList.add(options.class);
      iframe.classList.add(`${options.class}-${type}`);
    }
    iframe.style.width = "100%";
    iframe.style.height = "100%";
    return iframe;
  }
};

// packages/remirror__extension-embed/src/iframe-extension.ts
var IframeExtension = class extends NodeExtension {
  get name() {
    return "iframe";
  }
  createTags() {
    return [ExtensionTag.Block];
  }
  createNodeViews() {
    const iframeOptions = {
      ...this.options
    };
    if (this.options.enableResizing) {
      return (node, view, getPos) => {
        return new ResizableIframeView(node, view, getPos, iframeOptions);
      };
    }
    return {};
  }
  createNodeSpec(extra, override) {
    const { defaultSource } = this.options;
    return {
      selectable: false,
      ...override,
      attrs: {
        ...extra.defaults(),
        src: defaultSource ? { default: defaultSource } : {},
        allowFullScreen: { default: true },
        frameBorder: { default: 0 },
        type: { default: "custom" },
        width: { default: null },
        height: { default: null }
      },
      parseDOM: [
        {
          tag: "iframe",
          getAttrs: (dom) => {
            const frameBorder = dom.getAttribute("frameborder");
            const width = dom.getAttribute("width");
            const height = dom.getAttribute("height");
            return {
              ...extra.parse(dom),
              type: dom.getAttribute("data-embed-type") ?? void 0,
              height: height && Number.parseInt(height, 10) || void 0,
              width: width && Number.parseInt(width, 10) || void 0,
              allowFullScreen: dom.getAttribute("allowfullscreen") === "false" ? false : true,
              frameBorder: frameBorder ? Number.parseInt(frameBorder, 10) : 0,
              src: dom.getAttribute("src") ?? ""
            };
          }
        },
        ...override.parseDOM ?? []
      ],
      toDOM: (node) => {
        const { frameBorder, allowFullScreen, src, type, ...rest } = omitExtraAttributes(
          node.attrs,
          extra
        );
        const { class: className } = this.options;
        return [
          "iframe",
          {
            ...extra.dom(node),
            ...rest,
            class: cx(className, `${className}-${type}`),
            src,
            "data-embed-type": type,
            allowfullscreen: allowFullScreen ? "true" : "false",
            frameBorder: frameBorder == null ? void 0 : frameBorder.toString()
          }
        ];
      }
    };
  }
  addIframe(attributes) {
    return ({ tr, dispatch }) => {
      dispatch == null ? void 0 : dispatch(tr.replaceSelectionWith(this.type.create(attributes)));
      return true;
    };
  }
  addYouTubeVideo(props) {
    return this.addIframe({
      src: createYouTubeUrl(props),
      frameBorder: 0,
      type: "youtube",
      allowFullScreen: "true"
    });
  }
  updateIframeSource(src) {
    return ({ tr, dispatch }) => {
      const iframeNode = findSelectedNodeOfType({ selection: tr.selection, types: this.type });
      if (!iframeNode) {
        return false;
      }
      dispatch == null ? void 0 : dispatch(tr.setNodeMarkup(iframeNode.pos, void 0, { ...iframeNode.node.attrs, src }));
      return true;
    };
  }
  updateYouTubeVideo(props) {
    return this.updateIframeSource(createYouTubeUrl(props));
  }
};
__decorateClass([
  command()
], IframeExtension.prototype, "addIframe", 1);
__decorateClass([
  command()
], IframeExtension.prototype, "addYouTubeVideo", 1);
__decorateClass([
  command()
], IframeExtension.prototype, "updateIframeSource", 1);
__decorateClass([
  command()
], IframeExtension.prototype, "updateYouTubeVideo", 1);
IframeExtension = __decorateClass([
  extension({
    defaultOptions: {
      defaultSource: "",
      class: "remirror-iframe",
      enableResizing: false
    },
    staticKeys: ["defaultSource", "class"]
  })
], IframeExtension);
function parseUrl(url) {
  const parser = document.createElement("a");
  parser.href = url;
  const searchObject = parse(parser.search);
  return {
    protocol: parser.protocol,
    host: parser.host,
    hostname: parser.hostname,
    port: parser.port,
    pathname: parser.pathname,
    search: parser.search,
    searchObject,
    hash: parser.hash
  };
}
function createYouTubeUrl(props) {
  var _a, _b;
  const { video, enhancedPrivacy = true, showControls = true, startAt = 0 } = props;
  const id = ((_b = (_a = parseUrl(video)) == null ? void 0 : _a.searchObject) == null ? void 0 : _b.v) ?? video;
  const urlStart = enhancedPrivacy ? "https://www.youtube-nocookie.com" : "https://www.youtube.com";
  const searchObject = object();
  if (!showControls) {
    searchObject.controls = "0";
  }
  if (startAt) {
    searchObject["amp;start"] = startAt;
  }
  return `${urlStart}/embed/${id}?${stringify(searchObject)}`;
}
export {
  IframeExtension
};
