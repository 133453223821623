var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// packages/remirror__extension-emoji/src/emoji-extension.ts
import EMOJI_REGEX from "emojibase-regex/emoji.js";
import EMOTICON_REGEX from "emojibase-regex/emoticon.js";
import SHORTCODE_REGEX from "emojibase-regex/shortcode.js";
import escapeStringRegex from "escape-string-regexp";
import {
  command,
  extension,
  ExtensionTag,
  getMatchString,
  getTextSelection,
  isElementDomNode,
  isString,
  keyBinding,
  LEAF_NODE_REPLACING_CHARACTER,
  NodeExtension,
  nodeInputRule,
  omitExtraAttributes,
  plainInputRule
} from "@remirror/core";
import { DEFAULT_SUGGESTER } from "@remirror/pm/suggest";
import { ExtensionEmojiTheme } from "@remirror/theme";

// packages/remirror__extension-emoji/src/emoji-utils.ts
import * as _svgmoji from "@ocavue/svgmoji-cjs";
import { defaultImport } from "@remirror/core";
var svgmoji = defaultImport(_svgmoji);
var { Blobmoji, Notomoji, Openmoji, Twemoji } = svgmoji;
var DefaultMoji = {
  /**
   * The google emoji library.
   */
  noto: Notomoji,
  /**
   * The openmoji library.
   */
  openmoji: Openmoji,
  /**
   * The Twitter emoji library,
   */
  twemoji: Twemoji,
  /**
   * The blob emoji library previously developed by google. Now a community project.
   */
  blob: Blobmoji
};
var EMOJI_DATA_ATTRIBUTE = "data-remirror-emoji";

// packages/remirror__extension-emoji/src/emoji-extension.ts
var EmojiExtension = class extends NodeExtension {
  /**
   * The name is dynamically generated based on the passed in type.
   */
  get name() {
    return "emoji";
  }
  get moji() {
    return this._moji ?? (this._moji = isString(this.options.moji) ? new DefaultMoji[this.options.moji]({
      data: this.options.data,
      type: "all",
      fallback: this.options.fallback
    }) : this.options.moji);
  }
  createTags() {
    return [ExtensionTag.InlineNode];
  }
  createNodeSpec(extra, override) {
    return {
      selectable: true,
      draggable: false,
      ...override,
      inline: true,
      atom: true,
      attrs: { ...extra.defaults(), code: {} },
      parseDOM: [
        {
          tag: `span[${EMOJI_DATA_ATTRIBUTE}`,
          getAttrs: (node) => {
            if (!isElementDomNode(node)) {
              return null;
            }
            const code = node.getAttribute(EMOJI_DATA_ATTRIBUTE);
            return { ...extra.parse(node), code };
          }
        },
        ...override.parseDOM ?? []
      ],
      toDOM: (node) => {
        const { code } = omitExtraAttributes(node.attrs, extra);
        const emoji = this.moji.find(code) ?? this.moji.fallback;
        return [
          "span",
          {
            class: ExtensionEmojiTheme.EMOJI_WRAPPER,
            [EMOJI_DATA_ATTRIBUTE]: emoji[this.options.identifier]
          },
          [
            "img",
            {
              role: "presentation",
              class: ExtensionEmojiTheme.EMOJI_IMAGE,
              "aria-label": emoji.annotation,
              alt: emoji.annotation,
              // TODO use the emoji rather than the code once `svgmoji` supports it.
              src: this.moji.url(code)
            }
          ]
          // ['span', { style: 'display: inline-block; text-indent: -99999px' }, emoji.emoji],
        ];
      }
    };
  }
  /**
   * Manage input rules for emoticons.
   */
  createInputRules() {
    if (this.options.plainText) {
      return [
        // Replace emoticons
        plainInputRule({
          regexp: new RegExp(`(${EMOTICON_REGEX.source})[\\s]$`),
          transformMatch: ([full, partial]) => {
            if (!full || !partial) {
              return null;
            }
            const emoji = this.moji.find(partial);
            return emoji ? full.replace(partial, emoji.emoji) : null;
          }
        }),
        // Replace matching shortcodes
        plainInputRule({
          regexp: new RegExp(`(${SHORTCODE_REGEX.source})$`),
          transformMatch: ([, match]) => {
            if (!match) {
              return null;
            }
            const emoji = this.moji.find(match);
            return emoji ? emoji.emoji : null;
          }
        })
      ];
    }
    const shouldSkip = ({ captureGroup }) => {
      return !captureGroup || !this.moji.find(captureGroup);
    };
    const getAttributes = ([, match]) => {
      if (!match) {
        return;
      }
      const emoji = this.moji.find(match);
      return emoji ? { code: emoji[this.options.identifier] } : void 0;
    };
    const type = this.type;
    return [
      // Replace emoticons
      nodeInputRule({
        type,
        shouldSkip,
        getAttributes,
        regexp: new RegExp(`(${EMOTICON_REGEX.source})[\\s]$`),
        beforeDispatch: ({ tr }) => {
          tr.insertText(" ");
        }
      }),
      // Replace matching shortcodes
      nodeInputRule({
        type,
        shouldSkip,
        getAttributes,
        regexp: new RegExp(`(${SHORTCODE_REGEX.source})$`)
      }),
      // Replace matching shortcodes
      nodeInputRule({
        type,
        shouldSkip,
        getAttributes,
        regexp: new RegExp(`(${EMOJI_REGEX.source})`)
      })
    ];
  }
  addEmoji(identifier, options = {}) {
    return (props) => {
      const { dispatch, tr } = props;
      const emoji = this.moji.find(identifier);
      if (!emoji) {
        return false;
      }
      if (!this.options.plainText) {
        return this.store.commands.replaceText.original({
          type: this.type,
          attrs: { code: emoji[this.options.identifier] },
          selection: options.selection
        })(props);
      }
      const { from, to } = getTextSelection(options.selection ?? tr.selection, tr.doc);
      dispatch == null ? void 0 : dispatch(tr.insertText(emoji.emoji, from, to));
      return true;
    };
  }
  suggestEmoji(selection) {
    return ({ tr, dispatch }) => {
      const { from, to } = getTextSelection(selection ?? tr.selection, tr.doc);
      const text = this.store.helpers.getTextBetween(from - 1, to, tr.doc);
      if (text.includes(this.options.suggestionCharacter)) {
        return false;
      }
      dispatch == null ? void 0 : dispatch(tr.insertText(this.options.suggestionCharacter, from, to));
      return true;
    };
  }
  handleEnterKey({ tr, next }) {
    const { $from, empty } = tr.selection;
    if (!empty) {
      return next();
    }
    const textBeforeCursor = $from.parent.textBetween(
      Math.max(0, $from.parentOffset - 5),
      $from.parentOffset,
      void 0,
      LEAF_NODE_REPLACING_CHARACTER
    );
    const match = textBeforeCursor.match(EMOTICON_REGEX);
    if (match) {
      const emoticon = getMatchString(match);
      const selection = {
        from: $from.pos - emoticon.length,
        to: $from.pos
      };
      this.store.chain(tr).addEmoji(emoticon, { selection }).tr();
    }
    return next();
  }
  /**
   * Emojis can be selected via `:` the colon key (by default). This sets the
   * configuration using `prosemirror-suggest`
   */
  createSuggesters() {
    return {
      disableDecorations: true,
      invalidPrefixCharacters: `${escapeStringRegex(this.options.suggestionCharacter)}|\\w`,
      supportedCharacters: this.options.supportedCharacters,
      char: this.options.suggestionCharacter,
      name: this.name,
      suggestTag: "span",
      onChange: (props) => {
        this.options.suggestEmoji({
          moji: this.moji,
          query: props.query.full,
          text: props.text.full,
          range: props.range,
          exit: !!props.exitReason,
          change: !!props.changeReason,
          apply: (code) => {
            this.store.commands.addEmoji(code, { selection: props.range });
          }
        });
      }
    };
  }
};
__decorateClass([
  command()
], EmojiExtension.prototype, "addEmoji", 1);
__decorateClass([
  command()
], EmojiExtension.prototype, "suggestEmoji", 1);
__decorateClass([
  keyBinding({ shortcut: "Enter" })
], EmojiExtension.prototype, "handleEnterKey", 1);
EmojiExtension = __decorateClass([
  extension({
    defaultOptions: {
      plainText: false,
      data: [],
      identifier: "emoji",
      fallback: ":red_question_mark:",
      moji: "noto",
      suggestionCharacter: ":",
      supportedCharacters: DEFAULT_SUGGESTER.supportedCharacters
    },
    staticKeys: ["plainText"],
    handlerKeys: ["suggestEmoji"]
  })
], EmojiExtension);
export {
  EmojiExtension
};
