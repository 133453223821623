import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation'
import { apolloService, PAGINATION_FRAGMENT } from '@cotiss/common'
import {
  PERFORMANCE_METRIC_FRAGMENT,
  PERFORMANCE_SCORECARD_METRIC_FRAGMENT,
  PERFORMANCE_SCORECARD_METRIC_USER_FRAGMENT,
  PERFORMANCE_SCORECARD_USER_FRAGMENT,
} from '@cotiss/performance'
import {
  GqlCreatePerformanceScorecardMetricUserInput,
  GqlCreatePerformanceScorecardMetricUserMutation,
  GqlCreatePerformanceScorecardMetricUserMutationVariables,
  GqlPerformanceScorecardMetricUserFieldsFragment,
} from '@gql'

export const mutateCreatePerformanceScorecardMetricUser = async (input: GqlCreatePerformanceScorecardMetricUserInput) => {
  const { data } = await apolloService.mutate<
    GqlCreatePerformanceScorecardMetricUserMutation,
    GqlCreatePerformanceScorecardMetricUserMutationVariables
  >({
    mutation: gql`
      ${USER_FRAGMENT}
      ${PAGINATION_FRAGMENT}
      ${ORGANISATION_FRAGMENT}
      ${PERFORMANCE_METRIC_FRAGMENT}
      ${PERFORMANCE_SCORECARD_USER_FRAGMENT}
      ${PERFORMANCE_SCORECARD_METRIC_FRAGMENT}
      ${PERFORMANCE_SCORECARD_METRIC_USER_FRAGMENT}

      mutation CreatePerformanceScorecardMetricUser($input: CreatePerformanceScorecardMetricUserInput!) {
        createPerformanceScorecardMetricUser(input: $input) {
          ...PerformanceScorecardMetricUserFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createPerformanceScorecardMetricUser as GqlPerformanceScorecardMetricUserFieldsFragment
}
