var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// packages/remirror__extension-drop-cursor/src/drop-cursor-extension.ts
import { extension, PlainExtension } from "@remirror/core";
import { dropCursor } from "@remirror/pm/dropcursor";
var DropCursorExtension = class extends PlainExtension {
  get name() {
    return "dropCursor";
  }
  /**
   * Use the dropCursor plugin with provided options.
   */
  createExternalPlugins() {
    const { color, width } = this.options;
    return [dropCursor({ color, width })];
  }
};
DropCursorExtension = __decorateClass([
  extension({
    defaultOptions: {
      color: "black",
      width: 1
    }
  })
], DropCursorExtension);
export {
  DropCursorExtension
};
