var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// packages/remirror__preset-react/src/react-extension.ts
import { extension, ExtensionPriority, PlainExtension } from "@remirror/core";
import { PlaceholderExtension } from "@remirror/extension-placeholder";
import {
  ReactComponentExtension
} from "@remirror/extension-react-component";
var DEFAULT_OPTIONS = {
  ...PlaceholderExtension.defaultOptions,
  ...ReactComponentExtension.defaultOptions
};
var STATIC_KEYS = [...PlaceholderExtension.staticKeys, ...ReactComponentExtension.staticKeys];
var ReactExtension = class extends PlainExtension {
  get name() {
    return "react";
  }
  onSetOptions(props) {
    const { pickChanged } = props;
    this.getExtension(PlaceholderExtension).setOptions(pickChanged(["placeholder"]));
  }
  createExtensions() {
    const {
      emptyNodeClass,
      placeholder,
      defaultBlockNode,
      defaultContentNode,
      defaultEnvironment,
      defaultInlineNode,
      nodeViewComponents
    } = this.options;
    return [
      new PlaceholderExtension({
        emptyNodeClass,
        placeholder,
        priority: ExtensionPriority.Low
      }),
      new ReactComponentExtension({
        defaultBlockNode,
        defaultContentNode,
        defaultEnvironment,
        defaultInlineNode,
        nodeViewComponents
      })
    ];
  }
};
ReactExtension = __decorateClass([
  extension({
    defaultOptions: DEFAULT_OPTIONS,
    staticKeys: STATIC_KEYS
  })
], ReactExtension);

// packages/remirror__preset-react/src/index.ts
export * from "@remirror/extension-placeholder";
export {
  ReactExtension
};
