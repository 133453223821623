// packages/remirror__react-renderer/src/handlers/callout.tsx
import React from "react";
var Callout = (props) => {
  const content = props.node.content;
  if (!content) {
    return null;
  }
  const children = content.map((node, ii) => {
    return /* @__PURE__ */ React.createElement(RemirrorRenderer, { json: node, key: ii, ...props });
  });
  const { type, emoji } = props.node.attrs ?? {};
  const dataAttrs = { "data-callout-type": type };
  if (emoji) {
    dataAttrs["data-callout-emoji"] = emoji;
  }
  return /* @__PURE__ */ React.createElement("div", { ...dataAttrs }, emoji ? /* @__PURE__ */ React.createElement("div", { className: "remirror-callout-emoji-wrapper" }, /* @__PURE__ */ React.createElement("span", null, emoji)) : null, /* @__PURE__ */ React.createElement("div", null, children));
};

// packages/remirror__react-renderer/src/handlers/code-block.tsx
import React3 from "react";

// packages/remirror__react-renderer/src/handlers/text.tsx
import React2, { Fragment } from "react";
import { isString } from "@remirror/core";
var normalizeMark = (mark) => isString(mark) ? { type: mark, attrs: {} } : { attrs: {}, ...mark };
var TextHandler = ({ node, ...props }) => {
  if (!node.text) {
    return null;
  }
  let textElement = /* @__PURE__ */ React2.createElement(Fragment, null, node.text);
  if (!node.marks) {
    return textElement;
  }
  for (const mark of node.marks) {
    const normalized = normalizeMark(mark);
    const MarkHandler = props.markMap[normalized.type];
    if (!MarkHandler) {
      if (!props.skipUnknownMarks) {
        throw new Error(`No handler for mark type \`${normalized.type}\` registered`);
      }
      continue;
    }
    textElement = /* @__PURE__ */ React2.createElement(MarkHandler, { ...normalized.attrs }, textElement);
  }
  return textElement;
};

// packages/remirror__react-renderer/src/handlers/code-block.tsx
var CodeBlock = (props) => {
  const content = props.node.content;
  if (!content) {
    return null;
  }
  const children = content.map((node, ii) => {
    return /* @__PURE__ */ React3.createElement(TextHandler, { key: ii, ...{ ...props, node } });
  });
  return /* @__PURE__ */ React3.createElement("pre", null, /* @__PURE__ */ React3.createElement("code", null, children));
};

// packages/remirror__react-renderer/src/handlers/heading.tsx
import React4, { createElement } from "react";
var Heading = (props) => {
  var _a;
  const content = props.node.content;
  if (!content) {
    return null;
  }
  const children = content.map((node, ii) => {
    return /* @__PURE__ */ React4.createElement(TextHandler, { key: ii, ...{ ...props, node } });
  });
  const level = ((_a = props.node.attrs) == null ? void 0 : _a.level) ?? 1;
  return createElement(`h${level}`, null, children);
};

// packages/remirror__react-renderer/src/handlers/iframe.tsx
import React5 from "react";
var createIFrameHandler = (overwriteAttrs) => {
  const iframeHandler = (props) => {
    const { allowFullScreen, type, ...attrs } = props.node.attrs ?? {};
    const normalizedAttrs = {
      ...attrs,
      // Prevent React error that allowFullScreen must be boolean (but is string in remirror)
      allowFullScreen: allowFullScreen !== "false",
      ...overwriteAttrs
    };
    return /* @__PURE__ */ React5.createElement("iframe", { ...normalizedAttrs });
  };
  return iframeHandler;
};

// packages/remirror__react-renderer/src/handlers/link.tsx
import React6 from "react";
var createLinkHandler = (overwriteAttrs) => {
  const linkHandler = ({ href, target, children }) => {
    const normalizedAttrs = {
      href,
      target: target ?? void 0,
      ...overwriteAttrs
    };
    return /* @__PURE__ */ React6.createElement("a", { ...normalizedAttrs }, children);
  };
  return linkHandler;
};

// packages/remirror__react-renderer/src/renderer.tsx
import React7, { Fragment as Fragment2 } from "react";
import { isEmptyArray, isString as isString2, object } from "@remirror/core";
var Doc = ({ node, ...props }) => {
  const content = node.content;
  if (!content || isEmptyArray(content)) {
    return null;
  }
  const children = content.map((child, ii) => {
    return /* @__PURE__ */ React7.createElement(RemirrorRenderer, { json: child, ...props, key: ii });
  });
  return /* @__PURE__ */ React7.createElement("div", { ...node.attrs ?? object() }, children);
};
var defaultTypeMap = {
  blockquote: "blockquote",
  bulletList: "ul",
  callout: Callout,
  doc: Doc,
  heading: Heading,
  paragraph: "p",
  horizontalRule: "hr",
  iframe: createIFrameHandler(),
  image: "img",
  hardBreak: "br",
  codeBlock: CodeBlock,
  orderedList: "ol",
  text: TextHandler
};
var defaultMarkMap = {
  italic: "em",
  bold: "strong",
  code: "code",
  link: createLinkHandler(),
  underline: "u"
};
var RemirrorRenderer = (props) => {
  const {
    json,
    markMap = defaultMarkMap,
    skipUnknownMarks = false,
    skipUnknownTypes = false,
    typeMap = defaultTypeMap
  } = props;
  if (json.type === "text" && json.text && (!json.marks || isEmptyArray(json.marks))) {
    return /* @__PURE__ */ React7.createElement(Fragment2, null, json.text);
  }
  const rest = { markMap, typeMap, skipUnknownMarks, skipUnknownTypes };
  const TypeHandler = typeMap[json.type];
  if (!TypeHandler) {
    if (!skipUnknownTypes) {
      throw new Error(`No handler for node type \`${json.type}\` registered`);
    }
    return null;
  }
  const childProps = isString2(TypeHandler) ? json.attrs ?? object() : { ...rest, node: json };
  const { content } = json;
  if (!content || isEmptyArray(content)) {
    return /* @__PURE__ */ React7.createElement(TypeHandler, { ...childProps });
  }
  const children = content.map((child, ii) => {
    return /* @__PURE__ */ React7.createElement(RemirrorRenderer, { key: ii, json: child, ...rest });
  });
  return /* @__PURE__ */ React7.createElement(TypeHandler, { ...childProps }, children);
};
export {
  Callout,
  CodeBlock,
  Doc,
  Heading,
  RemirrorRenderer,
  TextHandler,
  createIFrameHandler,
  createLinkHandler
};
