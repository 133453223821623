import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation'
import { apolloService, PAGINATION_FRAGMENT } from '@cotiss/common'
import { GqlPerformanceScorecardMetricUserListInput, GqlPerformanceScorecardMetricUserListQuery } from '@gql'
import {
  PERFORMANCE_METRIC_FRAGMENT,
  PERFORMANCE_SCORECARD_METRIC_FRAGMENT,
  PERFORMANCE_SCORECARD_METRIC_USER_FRAGMENT,
  PERFORMANCE_SCORECARD_USER_FRAGMENT,
} from '@cotiss/performance'

export const queryPerformanceScorecardMetricUserList = async (input: GqlPerformanceScorecardMetricUserListInput) => {
  const { data } = await apolloService.query<GqlPerformanceScorecardMetricUserListQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${PAGINATION_FRAGMENT}
      ${ORGANISATION_FRAGMENT}
      ${PERFORMANCE_METRIC_FRAGMENT}
      ${PERFORMANCE_SCORECARD_USER_FRAGMENT}
      ${PERFORMANCE_SCORECARD_METRIC_FRAGMENT}
      ${PERFORMANCE_SCORECARD_METRIC_USER_FRAGMENT}

      query PerformanceScorecardMetricUserList($input: PerformanceScorecardMetricUserListInput!) {
        performanceScorecardMetricUserList(input: $input) {
          items {
            ...PerformanceScorecardMetricUserFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.performanceScorecardMetricUserList
}
