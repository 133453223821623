import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { CONTACT_FRAGMENT } from '@cotiss/contact'
import { CONTRACT_SHELL_FRAGMENT } from '@cotiss/contract'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation'
import { PERFORMANCE_SCORECARD_FRAGMENT } from '@cotiss/performance'
import { GqlPerformanceScorecardViewInput, GqlPerformanceScorecardViewQuery } from '@gql'

export const queryPerformanceScorecardView = async (input: GqlPerformanceScorecardViewInput) => {
  const { data } = await apolloService.query<GqlPerformanceScorecardViewQuery>({
    query: gql`
      ${CONTACT_FRAGMENT}
      ${CONTRACT_SHELL_FRAGMENT}
      ${ORGANISATION_FRAGMENT}
      ${PERFORMANCE_SCORECARD_FRAGMENT}

      query PerformanceScorecardView($input: PerformanceScorecardViewInput!) {
        performanceScorecardView(input: $input) {
          ...PerformanceScorecardFields
        }
      }
    `,
    variables: { input },
  })

  return data.performanceScorecardView
}
