import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService } from '@cotiss/common'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation'
import { GqlPerformanceScorecardMetricViewInput, GqlPerformanceScorecardMetricViewQuery } from '@gql'
import { PERFORMANCE_METRIC_FRAGMENT, PERFORMANCE_SCORECARD_METRIC_FRAGMENT } from '@cotiss/performance'

export const queryPerformanceScorecardMetricView = async (input: GqlPerformanceScorecardMetricViewInput) => {
  const { data } = await apolloService.query<GqlPerformanceScorecardMetricViewQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${ORGANISATION_FRAGMENT}
      ${PERFORMANCE_METRIC_FRAGMENT}
      ${PERFORMANCE_SCORECARD_METRIC_FRAGMENT}

      query PerformanceScorecardMetricView($input: PerformanceScorecardMetricViewInput!) {
        performanceScorecardMetricView(input: $input) {
          ...PerformanceScorecardMetricFields
        }
      }
    `,
    variables: { input },
  })

  return data.performanceScorecardMetricView
}
