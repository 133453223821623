// packages/remirror__icons/src/core-icons.ts
var ab = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M5 15v2c0 1.054.95 2 2 2h3v2H7a4 4 0 0 1-4-4v-2h2zm13-5l4.4 11h-2.155l-1.201-3h-4.09l-1.199 3h-2.154L16 10h2zm-1 2.885L15.753 16h2.492L17 12.885zM3 3h6a3 3 0 0 1 2.235 5A3 3 0 0 1 9 13H3V3zm6 6H5v2h4a1 1 0 0 0 0-2zm8-6a4 4 0 0 1 4 4v2h-2V7a2 2 0 0 0-2-2h-3V3h3zM9 5H5v2h4a1 1 0 1 0 0-2z"
    }
  }
];
var addFill = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  { tag: "path", attr: { d: "M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" } }
];
var addLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  { tag: "path", attr: { d: "M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" } }
];
var alertLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      fillRule: "nonzero",
      d: "M12.866 3l9.526 16.5a1 1 0 0 1-.866 1.5H2.474a1 1 0 0 1-.866-1.5L11.134 3a1 1 0 0 1 1.732 0zm-8.66 16h15.588L12 5.5 4.206 19zM11 16h2v2h-2v-2zm0-7h2v5h-2V9z"
    }
  }
];
var alignBottom = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: { d: "M3 19h18v2H3v-2zm5-6h3l-4 4-4-4h3V3h2v10zm10 0h3l-4 4-4-4h3V3h2v10z" }
  }
];
var alignCenter = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  { tag: "path", attr: { d: "M3 4h18v2H3V4zm2 15h14v2H5v-2zm-2-5h18v2H3v-2zm2-5h14v2H5V9z" } }
];
var alignJustify = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  { tag: "path", attr: { d: "M3 4h18v2H3V4zm0 15h18v2H3v-2zm0-5h18v2H3v-2zm0-5h18v2H3V9z" } }
];
var alignLeft = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  { tag: "path", attr: { d: "M3 4h18v2H3V4zm0 15h14v2H3v-2zm0-5h18v2H3v-2zm0-5h14v2H3V9z" } }
];
var alignRight = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  { tag: "path", attr: { d: "M3 4h18v2H3V4zm4 15h14v2H7v-2zm-4-5h18v2H3v-2zm4-5h14v2H7V9z" } }
];
var alignTop = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: { d: "M3 3h18v2H3V3zm5 8v10H6V11H3l4-4 4 4H8zm10 0v10h-2V11h-3l4-4 4 4h-3z" }
  }
];
var alignVertically = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M3 11h18v2H3v-2zm15 7v3h-2v-3h-3l4-4 4 4h-3zM8 18v3H6v-3H3l4-4 4 4H8zM18 6h3l-4 4-4-4h3V3h2v3zM8 6h3l-4 4-4-4h3V3h2v3z"
    }
  }
];
var appsLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M6.75 2.5A4.25 4.25 0 0 1 11 6.75V11H6.75a4.25 4.25 0 1 1 0-8.5zM9 9V6.75A2.25 2.25 0 1 0 6.75 9H9zm-2.25 4H11v4.25A4.25 4.25 0 1 1 6.75 13zm0 2A2.25 2.25 0 1 0 9 17.25V15H6.75zm10.5-12.5a4.25 4.25 0 1 1 0 8.5H13V6.75a4.25 4.25 0 0 1 4.25-4.25zm0 6.5A2.25 2.25 0 1 0 15 6.75V9h2.25zM13 13h4.25A4.25 4.25 0 1 1 13 17.25V13zm2 2v2.25A2.25 2.25 0 1 0 17.25 15H15z"
    }
  }
];
var arrowDownSFill = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  { tag: "path", attr: { d: "M12 16l-6-6h12z" } }
];
var arrowGoBackFill = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  { tag: "path", attr: { d: "M8 7v4L2 6l6-5v4h5a8 8 0 1 1 0 16H4v-2h9a6 6 0 1 0 0-12H8z" } }
];
var arrowGoForwardFill = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  { tag: "path", attr: { d: "M16 7h-5a6 6 0 1 0 0 12h9v2h-9a8 8 0 1 1 0-16h5V1l6 5-6 5V7z" } }
];
var arrowLeftSFill = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  { tag: "path", attr: { d: "M8 12l6-6v12z" } }
];
var arrowRightSFill = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  { tag: "path", attr: { d: "M16 12l-6 6V6z" } }
];
var arrowUpSFill = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  { tag: "path", attr: { d: "M12 8l6 6H6z" } }
];
var asterisk = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M13 3v7.267l6.294-3.633 1 1.732-6.293 3.633 6.293 3.635-1 1.732L13 13.732V21h-2v-7.268l-6.294 3.634-1-1.732L9.999 12 3.706 8.366l1-1.732L11 10.267V3z"
    }
  }
];
var attachment2 = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M14.828 7.757l-5.656 5.657a1 1 0 1 0 1.414 1.414l5.657-5.656A3 3 0 1 0 12 4.929l-5.657 5.657a5 5 0 1 0 7.071 7.07L19.071 12l1.414 1.414-5.657 5.657a7 7 0 1 1-9.9-9.9l5.658-5.656a5 5 0 0 1 7.07 7.07L12 16.244A3 3 0 1 1 7.757 12l5.657-5.657 1.414 1.414z"
    }
  }
];
var bold = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M8 11h4.5a2.5 2.5 0 1 0 0-5H8v5zm10 4.5a4.5 4.5 0 0 1-4.5 4.5H6V4h6.5a4.5 4.5 0 0 1 3.256 7.606A4.498 4.498 0 0 1 18 15.5zM8 13v5h5.5a2.5 2.5 0 1 0 0-5H8z"
    }
  }
];
var bracesLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M4 18v-3.7a1.5 1.5 0 0 0-1.5-1.5H2v-1.6h.5A1.5 1.5 0 0 0 4 9.7V6a3 3 0 0 1 3-3h1v2H7a1 1 0 0 0-1 1v4.1A2 2 0 0 1 4.626 12 2 2 0 0 1 6 13.9V18a1 1 0 0 0 1 1h1v2H7a3 3 0 0 1-3-3zm16-3.7V18a3 3 0 0 1-3 3h-1v-2h1a1 1 0 0 0 1-1v-4.1a2 2 0 0 1 1.374-1.9A2 2 0 0 1 18 10.1V6a1 1 0 0 0-1-1h-1V3h1a3 3 0 0 1 3 3v3.7a1.5 1.5 0 0 0 1.5 1.5h.5v1.6h-.5a1.5 1.5 0 0 0-1.5 1.5z"
    }
  }
];
var bringForward = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M14 3c.552 0 1 .448 1 1v5h5c.552 0 1 .448 1 1v10c0 .552-.448 1-1 1H10c-.552 0-1-.448-1-1v-5H4c-.552 0-1-.448-1-1V4c0-.552.448-1 1-1h10zm-1 2H5v8h8V5z"
    }
  }
];
var bringToFront = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M11 3c.552 0 1 .448 1 1v2h5c.552 0 1 .448 1 1v5h2c.552 0 1 .448 1 1v7c0 .552-.448 1-1 1h-7c-.552 0-1-.448-1-1v-2H7c-.552 0-1-.448-1-1v-5H4c-.552 0-1-.448-1-1V4c0-.552.448-1 1-1h7zm5 5H8v8h8V8z"
    }
  }
];
var chatNewLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M14 3v2H4v13.385L5.763 17H20v-7h2v8a1 1 0 0 1-1 1H6.455L2 22.5V4a1 1 0 0 1 1-1h11zm5 0V0h2v3h3v2h-3v3h-2V5h-3V3h3z"
    }
  }
];
var checkboxCircleLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z"
    }
  }
];
var checkboxMultipleLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      fillRule: "nonzero",
      d: "M7 7V3a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1h-4v3.993c0 .556-.449 1.007-1.007 1.007H3.007A1.006 1.006 0 0 1 2 20.993l.003-12.986C2.003 7.451 2.452 7 3.01 7H7zm2 0h6.993C16.549 7 17 7.449 17 8.007V15h3V4H9v3zm6 2H4.003L4 20h11V9zm-6.497 9l-3.536-3.536 1.414-1.414 2.122 2.122 4.242-4.243 1.414 1.414L8.503 18z"
    }
  }
];
var clipboardFill = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M6 4v4h12V4h2.007c.548 0 .993.445.993.993v16.014a.994.994 0 0 1-.993.993H3.993A.994.994 0 0 1 3 21.007V4.993C3 4.445 3.445 4 3.993 4H6zm2-2h8v4H8V2z"
    }
  }
];
var clipboardLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M7 4V2h10v2h3.007c.548 0 .993.445.993.993v16.014a.994.994 0 0 1-.993.993H3.993A.994.994 0 0 1 3 21.007V4.993C3 4.445 3.445 4 3.993 4H7zm0 2H5v14h14V6h-2v2H7V6zm2-2v2h6V4H9z"
    }
  }
];
var closeCircleLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
    }
  }
];
var closeFill = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
    }
  }
];
var closeLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
    }
  }
];
var codeLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M23 12l-7.071 7.071-1.414-1.414L20.172 12l-5.657-5.657 1.414-1.414L23 12zM3.828 12l5.657 5.657-1.414 1.414L1 12l7.071-7.071 1.414 1.414L3.828 12z"
    }
  }
];
var codeView = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M16.95 8.464l1.414-1.414 4.95 4.95-4.95 4.95-1.414-1.414L20.485 12 16.95 8.464zm-9.9 0L3.515 12l3.535 3.536-1.414 1.414L.686 12l4.95-4.95L7.05 8.464z"
    }
  }
];
var deleteBinFill = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm-8 5v6h2v-6H9zm4 0v6h2v-6h-2zM9 4v2h6V4H9z"
    }
  }
];
var deleteBinLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z"
    }
  }
];
var deleteColumn = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M12 3c.552 0 1 .448 1 1v8c.835-.628 1.874-1 3-1 2.761 0 5 2.239 5 5s-2.239 5-5 5c-1.032 0-1.99-.313-2.787-.848L13 20c0 .552-.448 1-1 1H6c-.552 0-1-.448-1-1V4c0-.552.448-1 1-1h6zm-1 2H7v14h4V5zm8 10h-6v2h6v-2z"
    }
  }
];
var deleteRow = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M20 5c.552 0 1 .448 1 1v6c0 .552-.448 1-1 1 .628.835 1 1.874 1 3 0 2.761-2.239 5-5 5s-5-2.239-5-5c0-1.126.372-2.165 1-3H4c-.552 0-1-.448-1-1V6c0-.552.448-1 1-1h16zm-7 10v2h6v-2h-6zm6-8H5v4h14V7z"
    }
  }
];
var doubleQuotesL = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z"
    }
  }
];
var doubleQuotesR = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M19.417 6.679C20.447 7.773 21 9 21 10.989c0 3.5-2.457 6.637-6.03 8.188l-.893-1.378c3.335-1.804 3.987-4.145 4.247-5.621-.537.278-1.24.375-1.929.311-1.804-.167-3.226-1.648-3.226-3.489a3.5 3.5 0 0 1 3.5-3.5c1.073 0 2.099.49 2.748 1.179zm-10 0C10.447 7.773 11 9 11 10.989c0 3.5-2.457 6.637-6.03 8.188l-.893-1.378c3.335-1.804 3.987-4.145 4.247-5.621-.537.278-1.24.375-1.929.311C4.591 12.322 3.17 10.841 3.17 9a3.5 3.5 0 0 1 3.5-3.5c1.073 0 2.099.49 2.748 1.179z"
    }
  }
];
var download2Fill = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: { d: "M4 19h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7zM14 9h5l-7 7-7-7h5V3h4v6z" }
  }
];
var dragDropLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      fillRule: "nonzero",
      d: "M16 13l6.964 4.062-2.973.85 2.125 3.681-1.732 1-2.125-3.68-2.223 2.15L16 13zm-2-7h2v2h5a1 1 0 0 1 1 1v4h-2v-3H10v10h4v2H9a1 1 0 0 1-1-1v-5H6v-2h2V9a1 1 0 0 1 1-1h5V6zM4 14v2H2v-2h2zm0-4v2H2v-2h2zm0-4v2H2V6h2zm0-4v2H2V2h2zm4 0v2H6V2h2zm4 0v2h-2V2h2zm4 0v2h-2V2h2z"
    }
  }
];
var emphasisCn = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M12 19a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm-5.5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm11 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zM13 2v2h6v2h-1.968a18.222 18.222 0 0 1-3.621 6.302 14.685 14.685 0 0 0 5.327 3.042l-.536 1.93A16.685 16.685 0 0 1 12 13.726a16.696 16.696 0 0 1-6.202 3.547l-.536-1.929a14.7 14.7 0 0 0 5.327-3.042 18.077 18.077 0 0 1-2.822-4.3h2.24A16.031 16.031 0 0 0 12 10.876a16.168 16.168 0 0 0 2.91-4.876L5 6V4h6V2h2z"
    }
  }
];
var emphasis = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M12 19a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm-5.5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm11 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zM18 3v2H8v4h9v2H8v4h10v2H6V3h12z"
    }
  }
];
var englishInput = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M14 10h2v.757a4.5 4.5 0 0 1 7 3.743V20h-2v-5.5c0-1.43-1.175-2.5-2.5-2.5S16 13.07 16 14.5V20h-2V10zm-2-6v2H4v5h8v2H4v5h8v2H2V4h10z"
    }
  }
];
var errorWarningLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm0-8h2v6h-2V7z"
    }
  }
];
var externalLinkFill = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M10 6v2H5v11h11v-5h2v6a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h6zm11-3v9l-3.794-3.793-5.999 6-1.414-1.414 5.999-6L12 3h9z"
    }
  }
];
var fileCopyLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.007-1H7zM5.003 8L5 20h10V8H5.003zM9 6h8v10h2V4H9v2z"
    }
  }
];
var flowChart = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M6 21.5c-1.933 0-3.5-1.567-3.5-3.5s1.567-3.5 3.5-3.5c1.585 0 2.924 1.054 3.355 2.5H15v-2h2V9.242L14.757 7H9V9H3V3h6v2h5.757L18 1.756 22.243 6 19 9.241V15L21 15v6h-6v-2H9.355c-.43 1.446-1.77 2.5-3.355 2.5zm0-5c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5 1.5-.672 1.5-1.5-.672-1.5-1.5-1.5zm13 .5h-2v2h2v-2zM18 4.586L16.586 6 18 7.414 19.414 6 18 4.586zM7 5H5v2h2V5z"
    }
  }
];
var fontColor = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M15.246 14H8.754l-1.6 4H5l6-15h2l6 15h-2.154l-1.6-4zm-.8-2L12 5.885 9.554 12h4.892zM3 20h18v2H3v-2z"
    }
  }
];
var fontSize2 = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  { tag: "path", attr: { d: "M10 6v15H8V6H2V4h14v2h-6zm8 8v7h-2v-7h-3v-2h8v2h-3z" } }
];
var fontSize = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M11.246 15H4.754l-2 5H.6L7 4h2l6.4 16h-2.154l-2-5zm-.8-2L8 6.885 5.554 13h4.892zM21 12.535V12h2v8h-2v-.535a4 4 0 1 1 0-6.93zM19 18a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
    }
  }
];
var formatClear = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M12.651 14.065L11.605 20H9.574l1.35-7.661-7.41-7.41L4.93 3.515 20.485 19.07l-1.414 1.414-6.42-6.42zm-.878-6.535l.27-1.53h-1.8l-2-2H20v2h-5.927L13.5 9.257 11.773 7.53z"
    }
  }
];
var fullscreenExitLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: { d: "M18 7h4v2h-6V3h2v4zM8 9H2V7h4V3h2v6zm10 8v4h-2v-6h6v2h-4zM8 15v6H6v-4H2v-2h6z" }
  }
];
var fullscreenLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M20 3h2v6h-2V5h-4V3h4zM4 3h4v2H4v4H2V3h2zm16 16v-4h2v6h-6v-2h4zM4 19h4v2H2v-6h2v4z"
    }
  }
];
var functions = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  { tag: "path", attr: { d: "M5 18l7.68-6L5 6V4h14v2H8.263L16 12l-7.737 6H19v2H5v-2z" } }
];
var galleryUploadLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M8 1v4H4v14h16V3h1.008c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3H6V1h2zm4 7l4 4h-3v4h-2v-4H8l4-4zm6-7v4h-8V3h6V1h2z"
    }
  }
];
var h1 = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: { d: "M13 20h-2v-7H4v7H2V4h2v7h7V4h2v16zm8-12v12h-2v-9.796l-2 .536V8.67L19.5 8H21z" }
  }
];
var h2 = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M4 4v7h7V4h2v16h-2v-7H4v7H2V4h2zm14.5 4c2.071 0 3.75 1.679 3.75 3.75 0 .857-.288 1.648-.772 2.28l-.148.18L18.034 18H22v2h-7v-1.556l4.82-5.546c.268-.307.43-.709.43-1.148 0-.966-.784-1.75-1.75-1.75-.918 0-1.671.707-1.744 1.606l-.006.144h-2C14.75 9.679 16.429 8 18.5 8z"
    }
  }
];
var h3 = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M22 8l-.002 2-2.505 2.883c1.59.435 2.757 1.89 2.757 3.617 0 2.071-1.679 3.75-3.75 3.75-1.826 0-3.347-1.305-3.682-3.033l1.964-.382c.156.806.866 1.415 1.718 1.415.966 0 1.75-.784 1.75-1.75s-.784-1.75-1.75-1.75c-.286 0-.556.069-.794.19l-1.307-1.547L19.35 10H15V8h7zM4 4v7h7V4h2v16h-2v-7H4v7H2V4h2z"
    }
  }
];
var h4 = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M13 20h-2v-7H4v7H2V4h2v7h7V4h2v16zm9-12v8h1.5v2H22v2h-2v-2h-5.5v-1.34l5-8.66H22zm-2 3.133L17.19 16H20v-4.867z"
    }
  }
];
var h5 = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M22 8v2h-4.323l-.464 2.636c.33-.089.678-.136 1.037-.136 2.21 0 4 1.79 4 4s-1.79 4-4 4c-1.827 0-3.367-1.224-3.846-2.897l1.923-.551c.24.836 1.01 1.448 1.923 1.448 1.105 0 2-.895 2-2s-.895-2-2-2c-.63 0-1.193.292-1.56.748l-1.81-.904L16 8h6zM4 4v7h7V4h2v16h-2v-7H4v7H2V4h2z"
    }
  }
];
var h6 = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M21.097 8l-2.598 4.5c2.21 0 4.001 1.79 4.001 4s-1.79 4-4 4-4-1.79-4-4c0-.736.199-1.426.546-2.019L18.788 8h2.309zM4 4v7h7V4h2v16h-2v-7H4v7H2V4h2zm14.5 10.5c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2z"
    }
  }
];
var hashtag = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M7.784 14l.42-4H4V8h4.415l.525-5h2.011l-.525 5h3.989l.525-5h2.011l-.525 5H20v2h-3.784l-.42 4H20v2h-4.415l-.525 5h-2.011l.525-5H9.585l-.525 5H7.049l.525-5H4v-2h3.784zm2.011 0h3.99l.42-4h-3.99l-.42 4z"
    }
  }
];
var heading = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  { tag: "path", attr: { d: "M17 11V4h2v17h-2v-8H7v8H5V4h2v7z" } }
];
var imageAddLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M21 15v3h3v2h-3v3h-2v-3h-3v-2h3v-3h2zm.008-12c.548 0 .992.445.992.993V13h-2V5H4v13.999L14 9l3 3v2.829l-3-3L6.827 19H14v2H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016zM8 7a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
    }
  }
];
var imageEditLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M20 3c.552 0 1 .448 1 1v1.757l-2 2V5H5v8.1l4-4 4.328 4.329-1.415 1.413L9 11.93l-4 3.999V19h10.533l.708.001 1.329-1.33L18.9 19h.1v-2.758l2-2V20c0 .552-.448 1-1 1H4c-.55 0-1-.45-1-1V4c0-.552.448-1 1-1h16zm1.778 4.808l1.414 1.414L15.414 17l-1.416-.002.002-1.412 7.778-7.778zM15.5 7c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5S14 9.328 14 8.5 14.672 7 15.5 7z"
    }
  }
];
var imageLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M4.828 21l-.02.02-.021-.02H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H4.828zM20 15V5H4v14L14 9l6 6zm0 2.828l-6-6L6.828 19H20v-1.172zM8 11a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
    }
  }
];
var indentDecrease = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M3 4h18v2H3V4zm0 15h18v2H3v-2zm8-5h10v2H11v-2zm0-5h10v2H11V9zm-8 3.5L7 9v7l-4-3.5z"
    }
  }
];
var indentIncrease = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M3 4h18v2H3V4zm0 15h18v2H3v-2zm8-5h10v2H11v-2zm0-5h10v2H11V9zm-4 3.5L3 16V9l4 3.5z"
    }
  }
];
var informationLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z"
    }
  }
];
var inputCursorMove = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M8 21v-2h3V5H8V3h8v2h-3v14h3v2H8zM18.05 7.05L23 12l-4.95 4.95-1.414-1.414L20.172 12l-3.536-3.536L18.05 7.05zm-12.1 0l1.414 1.414L3.828 12l3.536 3.536L5.95 16.95 1 12l4.95-4.95z"
    }
  }
];
var insertColumnLeft = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M20 3c.552 0 1 .448 1 1v16c0 .552-.448 1-1 1h-6c-.552 0-1-.448-1-1V4c0-.552.448-1 1-1h6zm-1 2h-4v14h4V5zM6 7c2.761 0 5 2.239 5 5s-2.239 5-5 5-5-2.239-5-5 2.239-5 5-5zm1 2H5v1.999L3 11v2l2-.001V15h2v-2.001L9 13v-2l-2-.001V9z"
    }
  }
];
var insertColumnRight = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M10 3c.552 0 1 .448 1 1v16c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1V4c0-.552.448-1 1-1h6zM9 5H5v14h4V5zm9 2c2.761 0 5 2.239 5 5s-2.239 5-5 5-5-2.239-5-5 2.239-5 5-5zm1 2h-2v1.999L15 11v2l2-.001V15h2v-2.001L21 13v-2l-2-.001V9z"
    }
  }
];
var insertRowBottom = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M12 13c2.761 0 5 2.239 5 5s-2.239 5-5 5-5-2.239-5-5 2.239-5 5-5zm1 2h-2v1.999L9 17v2l2-.001V21h2v-2.001L15 19v-2l-2-.001V15zm7-12c.552 0 1 .448 1 1v6c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1V4c0-.552.448-1 1-1h16zM5 5v4h14V5H5z"
    }
  }
];
var insertRowTop = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M20 13c.552 0 1 .448 1 1v6c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1v-6c0-.552.448-1 1-1h16zm-1 2H5v4h14v-4zM12 1c2.761 0 5 2.239 5 5s-2.239 5-5 5-5-2.239-5-5 2.239-5 5-5zm1 2h-2v1.999L9 5v2l2-.001V9h2V6.999L15 7V5l-2-.001V3z"
    }
  }
];
var italic = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  { tag: "path", attr: { d: "M15 20H7v-2h2.927l2.116-12H9V4h8v2h-2.927l-2.116 12H15z" } }
];
var layoutColumnLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      fillRule: "nonzero",
      d: "M11 5H5v14h6V5zm2 0v14h6V5h-6zM4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1z"
    }
  }
];
var lineHeight = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M11 4h10v2H11V4zM6 7v4H4V7H1l4-4 4 4H6zm0 10h3l-4 4-4-4h3v-4h2v4zm5 1h10v2H11v-2zm-2-7h12v2H9v-2z"
    }
  }
];
var linkM = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M17.657 14.828l-1.414-1.414L17.657 12A4 4 0 1 0 12 6.343l-1.414 1.414-1.414-1.414 1.414-1.414a6 6 0 0 1 8.485 8.485l-1.414 1.414zm-2.829 2.829l-1.414 1.414a6 6 0 1 1-8.485-8.485l1.414-1.414 1.414 1.414L6.343 12A4 4 0 1 0 12 17.657l1.414-1.414 1.414 1.414zm0-9.9l1.415 1.415-7.071 7.07-1.415-1.414 7.071-7.07z"
    }
  }
];
var linkUnlinkM = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M17.657 14.828l-1.414-1.414L17.657 12A4 4 0 1 0 12 6.343l-1.414 1.414-1.414-1.414 1.414-1.414a6 6 0 0 1 8.485 8.485l-1.414 1.414zm-2.829 2.829l-1.414 1.414a6 6 0 1 1-8.485-8.485l1.414-1.414 1.414 1.414L6.343 12A4 4 0 1 0 12 17.657l1.414-1.414 1.414 1.414zm0-9.9l1.415 1.415-7.071 7.07-1.415-1.414 7.071-7.07zM5.775 2.293l1.932-.518L8.742 5.64l-1.931.518-1.036-3.864zm9.483 16.068l1.931-.518 1.036 3.864-1.932.518-1.035-3.864zM2.293 5.775l3.864 1.036-.518 1.931-3.864-1.035.518-1.932zm16.068 9.483l3.864 1.035-.518 1.932-3.864-1.036.518-1.931z"
    }
  }
];
var linkUnlink = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M17 17h5v2h-3v3h-2v-5zM7 7H2V5h3V2h2v5zm11.364 8.536L16.95 14.12l1.414-1.414a5 5 0 1 0-7.071-7.071L9.879 7.05 8.464 5.636 9.88 4.222a7 7 0 0 1 9.9 9.9l-1.415 1.414zm-2.828 2.828l-1.415 1.414a7 7 0 0 1-9.9-9.9l1.415-1.414L7.05 9.88l-1.414 1.414a5 5 0 1 0 7.071 7.071l1.414-1.414 1.415 1.414zm-.708-10.607l1.415 1.415-7.071 7.07-1.415-1.414 7.071-7.07z"
    }
  }
];
var link = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M18.364 15.536L16.95 14.12l1.414-1.414a5 5 0 1 0-7.071-7.071L9.879 7.05 8.464 5.636 9.88 4.222a7 7 0 0 1 9.9 9.9l-1.415 1.414zm-2.828 2.828l-1.415 1.414a7 7 0 0 1-9.9-9.9l1.415-1.414L7.05 9.88l-1.414 1.414a5 5 0 1 0 7.071 7.071l1.414-1.414 1.415 1.414zm-.708-10.607l1.415 1.415-7.071 7.07-1.415-1.414 7.071-7.07z"
    }
  }
];
var listCheck2 = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M11 4h10v2H11V4zm0 4h6v2h-6V8zm0 6h10v2H11v-2zm0 4h6v2h-6v-2zM3 4h6v6H3V4zm2 2v2h2V6H5zm-2 8h6v6H3v-6zm2 2v2h2v-2H5z"
    }
  }
];
var listCheck = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M8 4h13v2H8V4zm-5-.5h3v3H3v-3zm0 7h3v3H3v-3zm0 7h3v3H3v-3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z"
    }
  }
];
var listOrdered = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M8 4h13v2H8V4zM5 3v3h1v1H3V6h1V4H3V3h2zM3 14v-2.5h2V11H3v-1h3v2.5H4v.5h2v1H3zm2 5.5H3v-1h2V18H3v-1h3v4H3v-1h2v-.5zM8 11h13v2H8v-2zm0 7h13v2H8v-2z"
    }
  }
];
var listUnordered = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M8 4h13v2H8V4zM4.5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 7a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 6.9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z"
    }
  }
];
var markPenLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M15.243 4.515l-6.738 6.737-.707 2.121-1.04 1.041 2.828 2.829 1.04-1.041 2.122-.707 6.737-6.738-4.242-4.242zm6.364 3.535a1 1 0 0 1 0 1.414l-7.779 7.779-2.12.707-1.415 1.414a1 1 0 0 1-1.414 0l-4.243-4.243a1 1 0 0 1 0-1.414l1.414-1.414.707-2.121 7.779-7.779a1 1 0 0 1 1.414 0l5.657 5.657zm-6.364-.707l1.414 1.414-4.95 4.95-1.414-1.414 4.95-4.95zM4.283 16.89l2.828 2.829-1.414 1.414-4.243-1.414 2.828-2.829z"
    }
  }
];
var markdownFill = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm4 12.5v-4l2 2 2-2v4h2v-7h-2l-2 2-2-2H5v7h2zm11-3v-4h-2v4h-2l3 3 3-3h-2z"
    }
  }
];
var markdownLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      fillRule: "nonzero",
      d: "M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm1 2v14h16V5H4zm3 10.5H5v-7h2l2 2 2-2h2v7h-2v-4l-2 2-2-2v4zm11-3h2l-3 3-3-3h2v-4h2v4z"
    }
  }
];
var mergeCellsHorizontal = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M20 3c.552 0 1 .448 1 1v16c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1V4c0-.552.448-1 1-1h16zm-9 2H5v5.999h2V9l3 3-3 3v-2H5v6h6v-2h2v2h6v-6h-2v2l-3-3 3-3v1.999h2V5h-6v2h-2V5zm2 8v2h-2v-2h2zm0-4v2h-2V9h2z"
    }
  }
];
var mergeCellsVertical = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M21 20c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1V4c0-.552.448-1 1-1h16c.552 0 1 .448 1 1v16zm-2-9V5h-5.999v2H15l-3 3-3-3h2V5H5v6h2v2H5v6h6v-2H9l3-3 3 3h-1.999v2H19v-6h-2v-2h2zm-8 2H9v-2h2v2zm4 0h-2v-2h2v2z"
    }
  }
];
var mindMap = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M18 3c1.657 0 3 1.343 3 3s-1.343 3-3 3h-3c-1.306 0-2.417-.834-2.829-2H11c-1.1 0-2 .9-2 2v.171c1.166.412 2 1.523 2 2.829 0 1.306-.834 2.417-2 2.829V15c0 1.1.9 2 2 2h1.17c.412-1.165 1.524-2 2.83-2h3c1.657 0 3 1.343 3 3s-1.343 3-3 3h-3c-1.306 0-2.417-.834-2.829-2H11c-2.21 0-4-1.79-4-4H5c-1.657 0-3-1.343-3-3s1.343-3 3-3h2c0-2.21 1.79-4 4-4h1.17c.412-1.165 1.524-2 2.83-2h3zm0 14h-3c-.552 0-1 .448-1 1s.448 1 1 1h3c.552 0 1-.448 1-1s-.448-1-1-1zM8 11H5c-.552 0-1 .448-1 1s.448 1 1 1h3c.552 0 1-.448 1-1s-.448-1-1-1zm10-6h-3c-.552 0-1 .448-1 1s.448 1 1 1h3c.552 0 1-.448 1-1s-.448-1-1-1z"
    }
  }
];
var moreFill = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M5 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm14 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-7 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
    }
  }
];
var nodeTree = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M10 2c.552 0 1 .448 1 1v4c0 .552-.448 1-1 1H8v2h5V9c0-.552.448-1 1-1h6c.552 0 1 .448 1 1v4c0 .552-.448 1-1 1h-6c-.552 0-1-.448-1-1v-1H8v6h5v-1c0-.552.448-1 1-1h6c.552 0 1 .448 1 1v4c0 .552-.448 1-1 1h-6c-.552 0-1-.448-1-1v-1H7c-.552 0-1-.448-1-1V8H4c-.552 0-1-.448-1-1V3c0-.552.448-1 1-1h6zm9 16h-4v2h4v-2zm0-8h-4v2h4v-2zM9 4H5v2h4V4z"
    }
  }
];
var number0 = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M12 1.5c1.321 0 2.484.348 3.447.994.963.645 1.726 1.588 2.249 2.778.522 1.19.804 2.625.804 4.257v4.942c0 1.632-.282 3.068-.804 4.257-.523 1.19-1.286 2.133-2.25 2.778-.962.646-2.125.994-3.446.994-1.321 0-2.484-.348-3.447-.994-.963-.645-1.726-1.588-2.249-2.778-.522-1.19-.804-2.625-.804-4.257V9.529c0-1.632.282-3.068.804-4.257.523-1.19 1.286-2.133 2.25-2.778C9.515 1.848 10.678 1.5 12 1.5zm0 2c-.916 0-1.694.226-2.333.655-.637.427-1.158 1.07-1.532 1.92-.412.94-.635 2.108-.635 3.454v4.942c0 1.346.223 2.514.635 3.453.374.851.895 1.494 1.532 1.921.639.429 1.417.655 2.333.655.916 0 1.694-.226 2.333-.655.637-.427 1.158-1.07 1.532-1.92.412-.94.635-2.108.635-3.454V9.529c0-1.346-.223-2.514-.635-3.453-.374-.851-.895-1.494-1.532-1.921C13.694 3.726 12.916 3.5 12 3.5z"
    }
  }
];
var number1 = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  { tag: "path", attr: { d: "M14 1.5V22h-2V3.704L7.5 4.91V2.839l5-1.339z" } }
];
var number2 = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M16 7.5a4 4 0 1 0-8 0H6a6 6 0 1 1 10.663 3.776l-7.32 8.723L18 20v2H6v-1.127l9.064-10.802A3.982 3.982 0 0 0 16 7.5z"
    }
  }
];
var number3 = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M18 2v1.362L12.809 9.55a6.501 6.501 0 1 1-7.116 8.028l1.94-.486A4.502 4.502 0 0 0 16.5 16a4.5 4.5 0 0 0-6.505-4.03l-.228.122-.69-1.207L14.855 4 6.5 4V2H18z"
    }
  }
];
var number4 = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: { d: "M16 1.5V16h3v2h-3v4h-2v-4H4v-1.102L14 1.5h2zM14 16V5.171L6.968 16H14z" }
  }
];
var number5 = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M18 2v2H9.3l-.677 6.445a6.5 6.5 0 1 1-2.93 7.133l1.94-.486A4.502 4.502 0 0 0 16.5 16a4.5 4.5 0 0 0-4.5-4.5c-2.022 0-3.278.639-3.96 1.53l-1.575-1.182L7.5 2H18z"
    }
  }
];
var number6 = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M14.886 2l-4.438 7.686A6.5 6.5 0 1 1 6.4 12.7L12.576 2h2.31zM12 11.5a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9z"
    }
  }
];
var number7 = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  { tag: "path", attr: { d: "M19 2v1.5L10.763 22H8.574l8.013-18H6V2z" } }
];
var number8 = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M12 1.5a5.5 5.5 0 0 1 3.352 9.86C17.24 12.41 18.5 14.32 18.5 16.5c0 3.314-2.91 6-6.5 6s-6.5-2.686-6.5-6c0-2.181 1.261-4.09 3.147-5.141A5.5 5.5 0 0 1 12 1.5zm0 11c-2.52 0-4.5 1.828-4.5 4 0 2.172 1.98 4 4.5 4s4.5-1.828 4.5-4c0-2.172-1.98-4-4.5-4zm0-9a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7z"
    }
  }
];
var number9 = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M12 1.5a6.5 6.5 0 0 1 5.619 9.77l-6.196 10.729H9.114l4.439-7.686A6.5 6.5 0 1 1 12 1.5zm0 2a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9z"
    }
  }
];
var omega = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      fillRule: "nonzero",
      d: "M14 20v-2.157c1.863-1.192 3.5-3.875 3.5-6.959 0-3.073-2-6.029-5.5-6.029s-5.5 2.956-5.5 6.03c0 3.083 1.637 5.766 3.5 6.958V20H3v-2h4.76C5.666 16.505 4 13.989 4 10.884 4 6.247 7.5 3 12 3s8 3.247 8 7.884c0 3.105-1.666 5.621-3.76 7.116H21v2h-7z"
    }
  }
];
var organizationChart = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M15 3c.552 0 1 .448 1 1v4c0 .552-.448 1-1 1h-2v2h4c.552 0 1 .448 1 1v3h2c.552 0 1 .448 1 1v4c0 .552-.448 1-1 1h-6c-.552 0-1-.448-1-1v-4c0-.552.448-1 1-1h2v-2H8v2h2c.552 0 1 .448 1 1v4c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1v-4c0-.552.448-1 1-1h2v-3c0-.552.448-1 1-1h4V9H9c-.552 0-1-.448-1-1V4c0-.552.448-1 1-1h6zM9 17H5v2h4v-2zm10 0h-4v2h4v-2zM14 5h-4v2h4V5z"
    }
  }
];
var pageSeparator = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M17 21v-4H7v4H5v-5a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v5h-2zM7 3v4h10V3h2v5a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V3h2zM2 9l4 3-4 3V9zm20 0v6l-4-3 4-3z"
    }
  }
];
var paragraph = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: { d: "M12 6v15h-2v-5a6 6 0 1 1 0-12h10v2h-3v15h-2V6h-3zm-2 0a4 4 0 1 0 0 8V6z" }
  }
];
var pencilFill = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M12.9 6.858l4.242 4.243L7.242 21H3v-4.243l9.9-9.9zm1.414-1.414l2.121-2.122a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414l-2.122 2.121-4.242-4.242z"
    }
  }
];
var pencilLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M15.728 9.686l-1.414-1.414L5 17.586V19h1.414l9.314-9.314zm1.414-1.414l1.414-1.414-1.414-1.414-1.414 1.414 1.414 1.414zM7.242 21H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 21z"
    }
  }
];
var pinyinInput = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M17.934 3.036l1.732 1L18.531 6H21v2h-2v4h2v2h-2v7h-2v-7h-3.084c-.325 2.862-1.564 5.394-3.37 7.193l-1.562-1.27c1.52-1.438 2.596-3.522 2.917-5.922L10 14v-2l2-.001V8h-2V6h2.467l-1.133-1.964 1.732-1L14.777 6h1.444l1.713-2.964zM5 13.803l-2 .536v-2.071l2-.536V8H3V6h2V3h2v3h2v2H7v3.197l2-.536v2.07l-2 .536V18.5A2.5 2.5 0 0 1 4.5 21H3v-2h1.5a.5.5 0 0 0 .492-.41L5 18.5v-4.697zM17 8h-3v4h3V8z"
    }
  }
];
var questionMark = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M12 19c.828 0 1.5.672 1.5 1.5S12.828 22 12 22s-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm0-17c3.314 0 6 2.686 6 6 0 2.165-.753 3.29-2.674 4.923C13.399 14.56 13 15.297 13 17h-2c0-2.474.787-3.695 3.031-5.601C15.548 10.11 16 9.434 16 8c0-2.21-1.79-4-4-4S8 5.79 8 8v1H6V8c0-3.314 2.686-6 6-6z"
    }
  }
];
var roundedCorner = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M21 19v2h-2v-2h2zm-4 0v2h-2v-2h2zm-4 0v2h-2v-2h2zm-4 0v2H7v-2h2zm-4 0v2H3v-2h2zm16-4v2h-2v-2h2zM5 15v2H3v-2h2zm0-4v2H3v-2h2zm11-8c2.687 0 4.882 2.124 4.995 4.783L21 8v5h-2V8c0-1.591-1.255-2.903-2.824-2.995L16 5h-5V3h5zM5 7v2H3V7h2zm0-4v2H3V3h2zm4 0v2H7V3h2z"
    }
  }
];
var scissorsFill = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M9.683 7.562L12 9.88l6.374-6.375a2 2 0 0 1 2.829 0l.707.707L9.683 16.438a4 4 0 1 1-2.121-2.121L9.88 12 7.562 9.683a4 4 0 1 1 2.121-2.121zM6 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 12a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm9.535-6.587l6.375 6.376-.707.707a2 2 0 0 1-2.829 0l-4.96-4.961 2.12-2.122z"
    }
  }
];
var sendBackward = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M14 3c.552 0 1 .448 1 1v5h5c.552 0 1 .448 1 1v10c0 .552-.448 1-1 1H10c-.552 0-1-.448-1-1v-5H4c-.552 0-1-.448-1-1V4c0-.552.448-1 1-1h10zm-1 2H5v8h4v-3c0-.552.448-1 1-1h3V5z"
    }
  }
];
var sendToBack = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M11 3c.552 0 1 .448 1 1v2h5c.552 0 1 .448 1 1v5h2c.552 0 1 .448 1 1v7c0 .552-.448 1-1 1h-7c-.552 0-1-.448-1-1v-2H7c-.552 0-1-.448-1-1v-5H4c-.552 0-1-.448-1-1V4c0-.552.448-1 1-1h7zm5 5h-4v3c0 .552-.448 1-1 1H8v4h4v-3c0-.552.448-1 1-1h3V8z"
    }
  }
];
var separator = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  { tag: "path", attr: { d: "M2 11h2v2H2v-2zm4 0h12v2H6v-2zm14 0h2v2h-2v-2z" } }
];
var singleQuotesL = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M9.583 17.321C8.553 16.227 8 15 8 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z"
    }
  }
];
var singleQuotesR = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M14.417 6.679C15.447 7.773 16 9 16 10.989c0 3.5-2.457 6.637-6.03 8.188l-.893-1.378c3.335-1.804 3.987-4.145 4.247-5.621-.537.278-1.24.375-1.929.311C9.591 12.322 8.17 10.841 8.17 9a3.5 3.5 0 0 1 3.5-3.5c1.073 0 2.099.49 2.748 1.179z"
    }
  }
];
var sortAsc = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: { d: "M19 3l4 5h-3v12h-2V8h-3l4-5zm-5 15v2H3v-2h11zm0-7v2H3v-2h11zm-2-7v2H3V4h9z" }
  }
];
var sortDesc = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: { d: "M20 4v12h3l-4 5-4-5h3V4h2zm-8 14v2H3v-2h9zm2-7v2H3v-2h11zm0-7v2H3V4h11z" }
  }
];
var space = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  { tag: "path", attr: { d: "M4 9v4h16V9h2v5a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V9h2z" } }
];
var spamLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      fillRule: "nonzero",
      d: "M17.5 2.5L23 12l-5.5 9.5h-11L1 12l5.5-9.5h11zm-1.153 2H7.653L3.311 12l4.342 7.5h8.694l4.342-7.5-4.342-7.5zM11 15h2v2h-2v-2zm0-8h2v6h-2V7z"
    }
  }
];
var splitCellsHorizontal = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M20 3c.552 0 1 .448 1 1v16c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1V4c0-.552.448-1 1-1h16zm-9 2H5v14h6v-4h2v4h6V5h-6v4h-2V5zm4 4l3 3-3 3v-2H9v2l-3-3 3-3v2h6V9z"
    }
  }
];
var splitCellsVertical = [
  { tag: "path", attr: { fill: "none", d: "M0 0H24V24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M20 3c.552 0 1 .448 1 1v16c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1V4c0-.552.448-1 1-1h16zm-1 2H5v5.999L9 11v2H5v6h14v-6h-4v-2l4-.001V5zm-7 1l3 3h-2v6h2l-3 3-3-3h2V9H9l3-3z"
    }
  }
];
var strikethrough2 = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  { tag: "path", attr: { d: "M13 9h-2V6H5V4h14v2h-6v3zm0 6v5h-2v-5h2zM3 11h18v2H3v-2z" } }
];
var strikethrough = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M17.154 14c.23.516.346 1.09.346 1.72 0 1.342-.524 2.392-1.571 3.147C14.88 19.622 13.433 20 11.586 20c-1.64 0-3.263-.381-4.87-1.144V16.6c1.52.877 3.075 1.316 4.666 1.316 2.551 0 3.83-.732 3.839-2.197a2.21 2.21 0 0 0-.648-1.603l-.12-.117H3v-2h18v2h-3.846zm-4.078-3H7.629a4.086 4.086 0 0 1-.481-.522C6.716 9.92 6.5 9.246 6.5 8.452c0-1.236.466-2.287 1.397-3.153C8.83 4.433 10.271 4 12.222 4c1.471 0 2.879.328 4.222.984v2.152c-1.2-.687-2.515-1.03-3.946-1.03-2.48 0-3.719.782-3.719 2.346 0 .42.218.786.654 1.099.436.313.974.562 1.613.75.62.18 1.297.414 2.03.699z"
    }
  }
];
var subscript2 = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M11 6v13H9V6H3V4h14v2h-6zm8.55 10.58a.8.8 0 1 0-1.32-.36l-1.154.33A2.001 2.001 0 0 1 19 14a2 2 0 0 1 1.373 3.454L18.744 19H21v1h-4v-1l2.55-2.42z"
    }
  }
];
var subscript = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M5.596 4L10.5 9.928 15.404 4H18l-6.202 7.497L18 18.994V19h-2.59l-4.91-5.934L5.59 19H3v-.006l6.202-7.497L3 4h2.596zM21.55 16.58a.8.8 0 1 0-1.32-.36l-1.155.33A2.001 2.001 0 0 1 21 14a2 2 0 0 1 1.373 3.454L20.744 19H23v1h-4v-1l2.55-2.42z"
    }
  }
];
var subtractLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  { tag: "path", attr: { d: "M5 11h14v2H5z" } }
];
var superscript2 = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M11 7v13H9V7H3V5h12v2h-4zm8.55-.42a.8.8 0 1 0-1.32-.36l-1.154.33A2.001 2.001 0 0 1 19 4a2 2 0 0 1 1.373 3.454L18.744 9H21v1h-4V9l2.55-2.42z"
    }
  }
];
var superscript = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M5.596 5l4.904 5.928L15.404 5H18l-6.202 7.497L18 19.994V20h-2.59l-4.91-5.934L5.59 20H3v-.006l6.202-7.497L3 5h2.596zM21.55 6.58a.8.8 0 1 0-1.32-.36l-1.155.33A2.001 2.001 0 0 1 21 4a2 2 0 0 1 1.373 3.454L20.744 9H23v1h-4V9l2.55-2.42z"
    }
  }
];
var table2 = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      fillRule: "nonzero",
      d: "M13 10v4h6v-4h-6zm-2 0H5v4h6v-4zm2 9h6v-3h-6v3zm-2 0v-3H5v3h6zm2-14v3h6V5h-6zm-2 0H5v3h6V5zM4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1z"
    }
  }
];
var tableLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M4 8h16V5H4v3zm10 11v-9h-4v9h4zm2 0h4v-9h-4v9zm-8 0v-9H4v9h4zM3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1z"
    }
  }
];
var textDirectionL = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M11 5v10H9v-4a4 4 0 1 1 0-8h8v2h-2v10h-2V5h-2zM9 5a2 2 0 1 0 0 4V5zm8 12v-2.5l4 3.5-4 3.5V19H5v-2h12z"
    }
  }
];
var textDirectionR = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M11 5v10H9v-4a4 4 0 1 1 0-8h8v2h-2v10h-2V5h-2zM9 5a2 2 0 1 0 0 4V5zM7 17h12v2H7v2.5L3 18l4-3.5V17z"
    }
  }
];
var textSpacing = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M7 17h10v-2.5l3.5 3.5-3.5 3.5V19H7v2.5L3.5 18 7 14.5V17zm6-11v9h-2V6H5V4h14v2h-6z"
    }
  }
];
var textWrap = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M15 18h1.5a2.5 2.5 0 1 0 0-5H3v-2h13.5a4.5 4.5 0 1 1 0 9H15v2l-4-3 4-3v2zM3 4h18v2H3V4zm6 14v2H3v-2h6z"
    }
  }
];
var text = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  { tag: "path", attr: { d: "M13 6v15h-2V6H5V4h14v2z" } }
];
var translate2 = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M18.5 10l4.4 11h-2.155l-1.201-3h-4.09l-1.199 3h-2.154L16.5 10h2zM10 2v2h6v2h-1.968a18.222 18.222 0 0 1-3.62 6.301 14.864 14.864 0 0 0 2.336 1.707l-.751 1.878A17.015 17.015 0 0 1 9 13.725a16.676 16.676 0 0 1-6.201 3.548l-.536-1.929a14.7 14.7 0 0 0 5.327-3.042A18.078 18.078 0 0 1 4.767 8h2.24A16.032 16.032 0 0 0 9 10.877a16.165 16.165 0 0 0 2.91-4.876L2 6V4h6V2h2zm7.5 10.885L16.253 16h2.492L17.5 12.885z"
    }
  }
];
var translate = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M5 15v2a2 2 0 0 0 1.85 1.995L7 19h3v2H7a4 4 0 0 1-4-4v-2h2zm13-5l4.4 11h-2.155l-1.201-3h-4.09l-1.199 3h-2.154L16 10h2zm-1 2.885L15.753 16h2.492L17 12.885zM8 2v2h4v7H8v3H6v-3H2V4h4V2h2zm9 1a4 4 0 0 1 4 4v2h-2V7a2 2 0 0 0-2-2h-3V3h3zM6 6H4v3h2V6zm4 0H8v3h2V6z"
    }
  }
];
var underline = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  { tag: "path", attr: { d: "M8 3v9a4 4 0 1 0 8 0V3h2v9a6 6 0 1 1-12 0V3h2zM4 20h16v2H4v-2z" } }
];
var upload2Fill = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: { d: "M4 19h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7zM14 9v6h-4V9H5l7-7 7 7h-5z" }
  }
];
var videoLine = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M3 3.993C3 3.445 3.445 3 3.993 3h16.014c.548 0 .993.445.993.993v16.014a.994.994 0 0 1-.993.993H3.993A.994.994 0 0 1 3 20.007V3.993zM5 5v14h14V5H5zm5.622 3.415l4.879 3.252a.4.4 0 0 1 0 .666l-4.88 3.252a.4.4 0 0 1-.621-.332V8.747a.4.4 0 0 1 .622-.332z"
    }
  }
];
var wubiInput = [
  { tag: "path", attr: { fill: "none", d: "M0 0h24v24H0z" } },
  {
    tag: "path",
    attr: {
      d: "M3 21v-2h3.662l1.234-7H5v-2h3.249l.881-5H4V3h16v2h-8.839l-.882 5H18v9h3v2H3zm13-9H9.927l-1.235 7H16v-7z"
    }
  }
];
export {
  ab,
  addFill,
  addLine,
  alertLine,
  alignBottom,
  alignCenter,
  alignJustify,
  alignLeft,
  alignRight,
  alignTop,
  alignVertically,
  appsLine,
  arrowDownSFill,
  arrowGoBackFill,
  arrowGoForwardFill,
  arrowLeftSFill,
  arrowRightSFill,
  arrowUpSFill,
  asterisk,
  attachment2,
  bold,
  bracesLine,
  bringForward,
  bringToFront,
  chatNewLine,
  checkboxCircleLine,
  checkboxMultipleLine,
  clipboardFill,
  clipboardLine,
  closeCircleLine,
  closeFill,
  closeLine,
  codeLine,
  codeView,
  deleteBinFill,
  deleteBinLine,
  deleteColumn,
  deleteRow,
  doubleQuotesL,
  doubleQuotesR,
  download2Fill,
  dragDropLine,
  emphasis,
  emphasisCn,
  englishInput,
  errorWarningLine,
  externalLinkFill,
  fileCopyLine,
  flowChart,
  fontColor,
  fontSize,
  fontSize2,
  formatClear,
  fullscreenExitLine,
  fullscreenLine,
  functions,
  galleryUploadLine,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hashtag,
  heading,
  imageAddLine,
  imageEditLine,
  imageLine,
  indentDecrease,
  indentIncrease,
  informationLine,
  inputCursorMove,
  insertColumnLeft,
  insertColumnRight,
  insertRowBottom,
  insertRowTop,
  italic,
  layoutColumnLine,
  lineHeight,
  link,
  linkM,
  linkUnlink,
  linkUnlinkM,
  listCheck,
  listCheck2,
  listOrdered,
  listUnordered,
  markPenLine,
  markdownFill,
  markdownLine,
  mergeCellsHorizontal,
  mergeCellsVertical,
  mindMap,
  moreFill,
  nodeTree,
  number0,
  number1,
  number2,
  number3,
  number4,
  number5,
  number6,
  number7,
  number8,
  number9,
  omega,
  organizationChart,
  pageSeparator,
  paragraph,
  pencilFill,
  pencilLine,
  pinyinInput,
  questionMark,
  roundedCorner,
  scissorsFill,
  sendBackward,
  sendToBack,
  separator,
  singleQuotesL,
  singleQuotesR,
  sortAsc,
  sortDesc,
  space,
  spamLine,
  splitCellsHorizontal,
  splitCellsVertical,
  strikethrough,
  strikethrough2,
  subscript,
  subscript2,
  subtractLine,
  superscript,
  superscript2,
  table2,
  tableLine,
  text,
  textDirectionL,
  textDirectionR,
  textSpacing,
  textWrap,
  translate,
  translate2,
  underline,
  upload2Fill,
  videoLine,
  wubiInput
};
