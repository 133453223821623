var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// packages/remirror__extension-sub/src/sub-extension.ts
import {
  command,
  extension,
  ExtensionTag,
  keyBinding,
  MarkExtension,
  NamedShortcut,
  toggleMark
} from "@remirror/core";
import { ExtensionSubMessages as Messages } from "@remirror/messages";
var toggleSubscriptOptions = {
  icon: "subscript",
  label: ({ t }) => t(Messages.LABEL)
};
var SubExtension = class extends MarkExtension {
  get name() {
    return "sub";
  }
  createTags() {
    return [ExtensionTag.FormattingMark, ExtensionTag.FontStyle];
  }
  createMarkSpec(extra, override) {
    return {
      ...override,
      attrs: extra.defaults(),
      parseDOM: [
        {
          tag: "sub",
          getAttrs: extra.parse
        },
        ...override.parseDOM ?? []
      ],
      toDOM: (mark) => ["sub", extra.dom(mark), 0]
    };
  }
  toggleSubscript() {
    return toggleMark({ type: this.type });
  }
  shortcut(props) {
    return this.toggleSubscript()(props);
  }
};
__decorateClass([
  command(toggleSubscriptOptions)
], SubExtension.prototype, "toggleSubscript", 1);
__decorateClass([
  keyBinding({ shortcut: NamedShortcut.Subscript, command: "toggleSubscript" })
], SubExtension.prototype, "shortcut", 1);
SubExtension = __decorateClass([
  extension({})
], SubExtension);
export {
  SubExtension
};
