var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// packages/remirror__extension-text-case/src/text-case-extension.ts
import {
  command,
  extension,
  ExtensionPriority,
  ExtensionTag,
  isElementDomNode,
  joinStyles,
  MarkExtension,
  omitExtraAttributes
} from "@remirror/core";

// packages/remirror__extension-text-case/src/text-case-utils.ts
import { includes } from "@remirror/core";
import { ExtensionTextCaseMessages as Messages } from "@remirror/messages";
var toggleTextCaseOptions = {
  icon: "fontSize2",
  label: ({ t }) => t(Messages.TOGGLE_LABEL)
};
var TEXT_CASE_ATTRIBUTE = "data-text-case";
var VALID_CASING = ["uppercase", "lowercase", "capitalize", "small-caps"];
function isValidCasing(value) {
  return includes(VALID_CASING, value);
}

// packages/remirror__extension-text-case/src/text-case-extension.ts
var TextCaseExtension = class extends MarkExtension {
  get name() {
    return "textCase";
  }
  createTags() {
    return [ExtensionTag.FontStyle, ExtensionTag.FormattingMark];
  }
  createMarkSpec(extra, override) {
    return {
      ...override,
      attrs: { ...extra.defaults(), casing: { default: this.options.defaultCasing } },
      parseDOM: [
        {
          tag: `span[${TEXT_CASE_ATTRIBUTE}]`,
          getAttrs: (dom) => {
            if (!isElementDomNode(dom)) {
              return false;
            }
            const casing = dom.getAttribute(TEXT_CASE_ATTRIBUTE);
            if (!casing || casing === "none") {
              return false;
            }
            return { ...extra.parse(dom), casing };
          }
        },
        {
          // Get the color from the css style property. This is useful for pasted content.
          style: "text-transform",
          priority: ExtensionPriority.Low,
          getAttrs: (casing) => {
            if (!isValidCasing(casing)) {
              return false;
            }
            return { casing };
          }
        },
        {
          // Get the color from the css style property. This is useful for pasted content.
          style: "font-variant",
          priority: ExtensionPriority.Low,
          getAttrs: (casing) => {
            if (!isValidCasing(casing)) {
              return false;
            }
            return { casing };
          }
        },
        ...override.parseDOM ?? []
      ],
      toDOM: (mark) => {
        let { casing } = omitExtraAttributes(mark.attrs, extra);
        const extraAttrs = extra.dom(mark);
        let style = extraAttrs.style;
        if (!isValidCasing(casing)) {
          casing = "none";
        }
        const property = casing === "small-caps" ? "fontVariant" : "textTransform";
        style = joinStyles({ [property]: casing }, style);
        return ["span", { ...extraAttrs, style, [TEXT_CASE_ATTRIBUTE]: casing }, 0];
      }
    };
  }
  toggleTextCase() {
    return this.store.commands.toggleMark.original({
      type: this.type,
      attrs: { casing: this.options.defaultCasing }
    });
  }
  setTextCase(casing, selection) {
    const attrs = typeof casing === "string" ? { casing } : casing;
    return this.store.commands.applyMark.original(this.type, attrs, selection);
  }
};
__decorateClass([
  command(toggleTextCaseOptions)
], TextCaseExtension.prototype, "toggleTextCase", 1);
__decorateClass([
  command()
], TextCaseExtension.prototype, "setTextCase", 1);
TextCaseExtension = __decorateClass([
  extension({
    defaultOptions: {
      defaultCasing: "none"
    },
    staticKeys: ["defaultCasing"]
  })
], TextCaseExtension);
export {
  TextCaseExtension
};
