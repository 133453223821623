var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// packages/remirror__extension-paragraph/src/paragraph-extension.ts
import {
  command,
  extension,
  ExtensionPriority,
  ExtensionTag,
  keyBinding,
  NamedShortcut,
  NodeExtension
} from "@remirror/core";
import { ExtensionParagraphMessages } from "@remirror/messages";
var insertParagraphOptions = {
  icon: "paragraph",
  label: ({ t }) => t(ExtensionParagraphMessages.INSERT_LABEL),
  description: ({ t }) => t(ExtensionParagraphMessages.INSERT_DESCRIPTION)
};
var convertParagraphOptions = {
  icon: "paragraph",
  label: ({ t }) => t(ExtensionParagraphMessages.CONVERT_LABEL),
  description: ({ t }) => t(ExtensionParagraphMessages.CONVERT_DESCRIPTION)
};
var ParagraphExtension = class extends NodeExtension {
  get name() {
    return "paragraph";
  }
  createTags() {
    return [
      ExtensionTag.LastNodeCompatible,
      ExtensionTag.TextBlock,
      ExtensionTag.Block,
      ExtensionTag.FormattingNode
    ];
  }
  createNodeSpec(extra, override) {
    return {
      content: "inline*",
      draggable: false,
      ...override,
      attrs: {
        ...extra.defaults()
      },
      parseDOM: [
        {
          tag: "p",
          getAttrs: (node) => ({
            ...extra.parse(node)
          })
        },
        ...override.parseDOM ?? []
      ],
      toDOM: (node) => {
        return ["p", extra.dom(node), 0];
      }
    };
  }
  convertParagraph(options = {}) {
    const { attrs, selection, preserveAttrs } = options;
    return this.store.commands.setBlockNodeType.original(
      this.type,
      attrs,
      selection,
      preserveAttrs
    );
  }
  insertParagraph(content, options = {}) {
    const { selection, attrs } = options;
    return this.store.commands.insertNode.original(this.type, { content, selection, attrs });
  }
  shortcut(props) {
    return this.convertParagraph()(props);
  }
};
__decorateClass([
  command(convertParagraphOptions)
], ParagraphExtension.prototype, "convertParagraph", 1);
__decorateClass([
  command(insertParagraphOptions)
], ParagraphExtension.prototype, "insertParagraph", 1);
__decorateClass([
  keyBinding({ shortcut: NamedShortcut.Paragraph, command: "convertParagraph" })
], ParagraphExtension.prototype, "shortcut", 1);
ParagraphExtension = __decorateClass([
  extension({
    defaultPriority: ExtensionPriority.Medium
  })
], ParagraphExtension);
export {
  ParagraphExtension
};
