var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// packages/remirror__extension-text-highlight/src/text-highlight-extension.ts
import {
  command,
  extension,
  ExtensionPriority,
  ExtensionTag,
  isElementDomNode,
  isString,
  joinStyles,
  MarkExtension,
  omitExtraAttributes
} from "@remirror/core";
import { palette } from "@remirror/extension-text-color";
import { ExtensionTextHighlightMessages as Messages } from "@remirror/messages";
var setTextHighlightOptions = {
  icon: "markPenLine",
  description: ({ t }) => t(Messages.DESCRIPTION),
  label: ({ t }) => t(Messages.LABEL)
};
var TEXT_HIGHLIGHT_ATTRIBUTE = "data-text-highlight-mark";
var TextHighlightExtension = class extends MarkExtension {
  get name() {
    return "textHighlight";
  }
  createTags() {
    return [ExtensionTag.FormattingMark, ExtensionTag.FontStyle];
  }
  createMarkSpec(extra, override) {
    return {
      ...override,
      attrs: {
        ...extra.defaults(),
        highlight: { default: this.options.defaultHighlight }
      },
      parseDOM: [
        {
          tag: `span[${TEXT_HIGHLIGHT_ATTRIBUTE}]`,
          getAttrs: (dom) => {
            if (!isElementDomNode(dom)) {
              return null;
            }
            const highlight = dom.getAttribute(TEXT_HIGHLIGHT_ATTRIBUTE);
            if (!highlight) {
              return null;
            }
            return { ...extra.parse(dom), highlight };
          }
        },
        {
          tag: `span[${TEXT_HIGHLIGHT_ATTRIBUTE}]`,
          getAttrs: (dom) => {
            if (!isElementDomNode(dom)) {
              return null;
            }
            const highlight = dom.getAttribute(TEXT_HIGHLIGHT_ATTRIBUTE);
            if (!highlight) {
              return null;
            }
            return { ...extra.parse(dom), highlight };
          }
        },
        {
          // Get the color from the css style property. This is useful for pasted content.
          style: "background-color",
          priority: ExtensionPriority.Low,
          getAttrs: (highlight) => {
            if (!isString(highlight)) {
              return null;
            }
            return { highlight };
          }
        },
        ...override.parseDOM ?? []
      ],
      toDOM: (mark) => {
        const { highlight, ...other } = omitExtraAttributes(
          mark.attrs,
          extra
        );
        const extraAttrs = extra.dom(mark);
        let style = extraAttrs.style;
        if (highlight) {
          style = joinStyles({ backgroundColor: highlight }, style);
        }
        return [
          "mark",
          { ...other, ...extraAttrs, style, [TEXT_HIGHLIGHT_ATTRIBUTE]: highlight },
          0
        ];
      }
    };
  }
  setTextHighlight(highlight, options) {
    return this.store.commands.applyMark.original(this.type, { highlight }, options == null ? void 0 : options.selection);
  }
  removeTextHighlight(options) {
    return this.store.commands.removeMark.original({ type: this.type, ...options, expand: true });
  }
};
__decorateClass([
  command(setTextHighlightOptions)
], TextHighlightExtension.prototype, "setTextHighlight", 1);
__decorateClass([
  command()
], TextHighlightExtension.prototype, "removeTextHighlight", 1);
TextHighlightExtension = __decorateClass([
  extension({
    defaultOptions: {
      defaultHighlight: "",
      palette
    },
    staticKeys: ["defaultHighlight"]
  })
], TextHighlightExtension);
export {
  TextHighlightExtension
};
