import { gql } from '@apollo/client'

export const PERFORMANCE_SCORECARD_FRAGMENT = gql`
  fragment PerformanceScorecardFields on PerformanceScorecard {
    id
    name
    performanceMetricIds
    organisationId
    organisation {
      ...OrganisationFields
    }
    contactId
    contact {
      ...ContactFields
    }
    contractShellId
    contractShell {
      ...ContractShellFields
    }
    status
    isArchived
    createdAt
    updatedAt
    deletedAt
  }
`
