import { includes, map } from 'lodash'
import { useParams } from 'react-router-dom'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { GqlPagination } from '@gql'
import { documentService, useGetDocument } from '@cotiss/document'
import {
  PerformanceScorecardDocumentUploadModal,
  usePerformanceScorecard,
  usePerformanceScorecardAnalytics,
  usePerformanceScorecardDocument,
  usePerformanceScorecardUser,
} from '@cotiss/performance'
import {
  Button,
  ConfirmModal,
  ErrorPanel,
  ScrollableTable,
  ScrollableTableColumn,
  TableHeader,
  TableRowCta,
  Text,
  datetimeService,
  sentryService,
  useAsyncEffect,
  useCallout,
} from '@cotiss/common'

export const PerformanceScorecardViewResourcesTab = memo(() => {
  const { openModal } = useCallout()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const { track } = usePerformanceScorecardAnalytics()
  const { performanceScorecard } = usePerformanceScorecard()
  const [pagination, setPagination] = useState<GqlPagination>()
  const [documentIdToDownload, setDocumentIdToDownload] = useState('')
  const { performanceScorecardUserInSession } = usePerformanceScorecardUser()
  const { document: documentToDownload } = useGetDocument(documentIdToDownload)
  const { performanceScorecardId } = useParams<{ performanceScorecardId: string }>()
  const canCreateDocuments = !performanceScorecard?.isArchived && includes(performanceScorecardUserInSession?.roles, 'owner')
  const { performanceScorecardDocuments, queryPerformanceScorecardDocumentList, mutateDeletePerformanceScorecardDocument } =
    usePerformanceScorecardDocument()

  useEffect(() => {
    track('performance_scorecard_view_resources_tab_view')
  }, [])

  useAsyncEffect(async () => {
    try {
      const { pagination } = await queryPerformanceScorecardDocumentList({
        filter: { performanceScorecardId },
        pagination: { page: currentPage, pageSize: 100 },
      })

      setPagination(pagination)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }, [currentPage])

  useEffect(() => {
    if (documentToDownload?.downloadUrl) {
      window.open(documentToDownload.downloadUrl)
      setDocumentIdToDownload('')
    }
  }, [documentToDownload])

  const { fixedColumns, columns } = useMemo(() => {
    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Resource name',
        rows: map(performanceScorecardDocuments, ({ id: performanceScorecardDocumentId, fileName, documentId }) => ({
          content: () => (
            <Button
              className="text-sm underline cursor-pointer truncate inline-block align-middle"
              onClick={() => setDocumentIdToDownload(documentId)}
              state="raw"
            >
              <Text>{fileName}</Text>
            </Button>
          ),
          cta: canCreateDocuments && (
            <TableRowCta
              actions={[
                {
                  label: 'Delete',
                  onClick: () =>
                    openModal(
                      <ConfirmModal
                        heading="Delete document"
                        description="Are you sure you want to delete this document?"
                        onSubmit={() => {
                          track('performance_scorecard_view_resources_tab_delete_submit')
                          mutateDeletePerformanceScorecardDocument({ performanceScorecardDocumentId })
                        }}
                      />
                    ),
                },
              ]}
            />
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Date created',
        rows: map(performanceScorecardDocuments, ({ createdAt }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {datetimeService.format(createdAt, 'do MMM yyyy')}
            </Text>
          ),
        })),
      },
      {
        heading: 'Size',
        rows: map(performanceScorecardDocuments, ({ fileSize }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {documentService.formatSize(fileSize)}
            </Text>
          ),
        })),
      },
    ]

    return { fixedColumns, columns }
  }, [performanceScorecardDocuments])

  if (!isLoading && isError) {
    return <ErrorPanel />
  }

  return (
    <>
      <TableHeader className="flex justify-between items-center" variant="white">
        <Text className="font-medium" variant="heading" font="jakarta" size="lg">
          Resources
        </Text>
        {canCreateDocuments && (
          <Button onClick={() => openModal(<PerformanceScorecardDocumentUploadModal />)} state="translucent" variant="secondary-dark" size="xs">
            + Add resource
          </Button>
        )}
      </TableHeader>
      <ScrollableTable
        fixedColumns={fixedColumns}
        columns={columns}
        pagination={pagination}
        onPageChange={setCurrentPage}
        emptyCta={
          canCreateDocuments && (
            <Button
              size="sm"
              state="text"
              variant="secondary"
              onClick={() => openModal(<PerformanceScorecardDocumentUploadModal />)}
              isDisabled={isLoading}
            >
              + Add resource
            </Button>
          )
        }
        isLoading={isLoading}
      />
    </>
  )
})
