var __accessCheck = (obj, member, msg) => {
  if (!member.has(obj))
    throw TypeError("Cannot " + msg);
};
var __privateGet = (obj, member, getter) => {
  __accessCheck(obj, member, "read from private field");
  return getter ? getter.call(obj) : member.get(obj);
};
var __privateAdd = (obj, member, value) => {
  if (member.has(obj))
    throw TypeError("Cannot add the same private member more than once");
  member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
};
var __privateSet = (obj, member, value, setter) => {
  __accessCheck(obj, member, "write to private field");
  setter ? setter.call(obj, value) : member.set(obj, value);
  return value;
};

// packages/prosemirror-resizable-view/src/prosemirror-resizable-view.ts
import { throttle } from "@remirror/core-helpers";
import { setStyle as setStyle2 } from "@remirror/core-utils";

// packages/prosemirror-resizable-view/src/resizable-view-handle.ts
import { setStyle } from "@remirror/core-utils";

// packages/prosemirror-resizable-view/src/corner-handle.ts
var leftCorner = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="22" height="22" fill="rgba(0, 0, 0, 0.65)" stroke="rgba(255, 255, 255, 0.5)" transform="rotate(90)" xmlns:v="https://vecta.io/nano"><path fill-rule="evenodd" d="M14 0a2 2 0 0 0-2 2v10H2a2 2 0 1 0 0 4h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/></svg>`;
var rightCorner = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="22" height="22" fill="rgba(0, 0, 0, 0.65)" stroke="rgba(255, 255, 255, 0.5)" transform="matrix(0 1 1 0 0 0)" xmlns:v="https://vecta.io/nano"><path fill-rule="evenodd" d="M14 0a2 2 0 0 0-2 2v10H2a2 2 0 1 0 0 4h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/></svg>`;
var leftCornerHandle = encodeURIComponent(leftCorner);
var rightCornerHandle = encodeURIComponent(rightCorner);

// packages/prosemirror-resizable-view/src/resizable-view-handle.ts
var _handle;
var ResizableHandle = class {
  constructor(type) {
    __privateAdd(this, _handle, void 0);
    const wrapper = document.createElement("div");
    const handle = document.createElement("div");
    this.dom = wrapper;
    __privateSet(this, _handle, handle);
    this.type = type;
    this.createHandle(type);
  }
  createHandle(type) {
    setStyle(this.dom, {
      position: "absolute",
      pointerEvents: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: "100"
    });
    setStyle(__privateGet(this, _handle), {
      opacity: "0",
      transition: "opacity 300ms ease-in 0s"
    });
    __privateGet(this, _handle).dataset.dragging = "";
    switch (type) {
      case 0 /* Right */:
        setStyle(this.dom, {
          right: "0px",
          top: "0px",
          height: "100%",
          width: "15px",
          cursor: "col-resize"
        });
        setStyle(__privateGet(this, _handle), {
          width: " 4px",
          height: "36px",
          maxHeight: "50%",
          boxSizing: "content-box",
          background: "rgba(0, 0, 0, 0.65)",
          border: "1px solid rgba(255, 255, 255, 0.5)",
          borderRadius: "6px"
        });
        break;
      case 1 /* Left */:
        setStyle(this.dom, {
          left: "0px",
          top: "0px",
          height: "100%",
          width: "15px",
          cursor: "col-resize"
        });
        setStyle(__privateGet(this, _handle), {
          width: " 4px",
          height: "36px",
          maxHeight: "50%",
          boxSizing: "content-box",
          background: "rgba(0, 0, 0, 0.65)",
          border: "1px solid rgba(255, 255, 255, 0.5)",
          borderRadius: "6px"
        });
        break;
      case 2 /* Bottom */:
        setStyle(this.dom, {
          bottom: "0px",
          width: "100%",
          height: "14px",
          cursor: "row-resize"
        });
        setStyle(__privateGet(this, _handle), {
          width: " 42px",
          height: "4px",
          boxSizing: "content-box",
          maxWidth: "50%",
          background: "rgba(0, 0, 0, 0.65)",
          border: "1px solid rgba(255, 255, 255, 0.5)",
          borderRadius: "6px"
        });
        break;
      case 3 /* BottomRight */:
        setStyle(this.dom, {
          right: "-1px",
          bottom: "-2px",
          width: "30px",
          height: "30px",
          cursor: "nwse-resize",
          zIndex: "101"
        });
        setStyle(__privateGet(this, _handle), {
          height: "22px",
          width: "22px",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url("data:image/svg+xml,${rightCornerHandle}") `
        });
        break;
      case 4 /* BottomLeft */:
        setStyle(this.dom, {
          left: "-1px",
          bottom: "-2px",
          width: "30px",
          height: "30px",
          cursor: "nesw-resize",
          zIndex: "101"
        });
        setStyle(__privateGet(this, _handle), {
          height: "22px",
          width: "22px",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url("data:image/svg+xml,${leftCornerHandle}") `
        });
        break;
    }
    this.dom.append(__privateGet(this, _handle));
  }
  setHandleVisibility(visible) {
    const isVisible = visible || !!__privateGet(this, _handle).dataset.dragging;
    __privateGet(this, _handle).style.opacity = isVisible ? "1" : "0";
  }
  dataSetDragging(isDraging) {
    __privateGet(this, _handle).dataset.dragging = isDraging ? "true" : "";
  }
};
_handle = new WeakMap();

// packages/prosemirror-resizable-view/src/prosemirror-resizable-view.ts
var MIN_WIDTH = 50;
var ResizableRatioType = /* @__PURE__ */ ((ResizableRatioType2) => {
  ResizableRatioType2[ResizableRatioType2["Fixed"] = 0] = "Fixed";
  ResizableRatioType2[ResizableRatioType2["Flexible"] = 1] = "Flexible";
  return ResizableRatioType2;
})(ResizableRatioType || {});
var _element, _node, _destroyList, _width, _height;
var ResizableNodeView = class {
  constructor({
    node,
    view,
    getPos,
    aspectRatio = 0 /* Fixed */,
    options,
    initialSize
  }) {
    __privateAdd(this, _element, void 0);
    __privateAdd(this, _node, void 0);
    __privateAdd(this, _destroyList, []);
    // cache the current element's size so that we can compare with new node's
    // size when `update` method is called.
    __privateAdd(this, _width, void 0);
    __privateAdd(this, _height, void 0);
    const outer = this.createWrapper(node, initialSize);
    const element = this.createElement({ node, view, getPos, options });
    const types = aspectRatio === 1 /* Flexible */ ? [
      0 /* Right */,
      1 /* Left */,
      2 /* Bottom */,
      3 /* BottomRight */,
      4 /* BottomLeft */
    ] : [0 /* Right */, 1 /* Left */];
    const handles = types.map((type) => new ResizableHandle(type));
    for (const handle of handles) {
      const onMouseDown = (e) => {
        this.startResizing(e, view, getPos, handle);
      };
      handle.dom.addEventListener("mousedown", onMouseDown);
      __privateGet(this, _destroyList).push(() => handle.dom.removeEventListener("mousedown", onMouseDown));
      outer.append(handle.dom);
    }
    const setHandleVisibe = () => {
      handles.forEach((handle) => handle.setHandleVisibility(true));
    };
    const setHandleInvisible = () => {
      handles.forEach((handle) => handle.setHandleVisibility(false));
    };
    outer.addEventListener("mouseover", setHandleVisibe);
    outer.addEventListener("mouseout", setHandleInvisible);
    __privateGet(this, _destroyList).push(
      () => outer.removeEventListener("mouseover", setHandleVisibe),
      () => outer.removeEventListener("mouseout", setHandleInvisible)
    );
    outer.append(element);
    this.dom = outer;
    __privateSet(this, _node, node);
    __privateSet(this, _element, element);
    this.aspectRatio = aspectRatio;
  }
  createWrapper(node, initialSize) {
    const outer = document.createElement("div");
    outer.classList.add("remirror-resizable-view");
    outer.style.position = "relative";
    if (initialSize) {
      setStyle2(outer, {
        width: normalizeSize(initialSize.width),
        aspectRatio: `${initialSize.width} / ${initialSize.height}`
      });
    } else {
      setStyle2(outer, {
        width: normalizeSize(node.attrs.width),
        aspectRatio: `${node.attrs.width} / ${node.attrs.height}`
      });
    }
    setStyle2(outer, {
      maxWidth: "100%",
      minWidth: `${MIN_WIDTH}px`,
      // By default, inline-block has "vertical-align: baseline", which makes
      // the outer wrapper slightly taller than the resizable view, which will
      // causes layout shift. So we need to set `vertical-align` to avoid this.
      verticalAlign: "bottom",
      display: "inline-block",
      // necessary so the bottom right handle is aligned nicely
      lineHeight: "0",
      // make sure transition time is larger then mousemove event's throttle time
      transition: "width 0.15s ease-out, height 0.15s ease-out"
    });
    return outer;
  }
  startResizing(e, view, getPos, handle) {
    var _a, _b;
    e.preventDefault();
    handle.dataSetDragging(true);
    __privateGet(this, _element).style.pointerEvents = "none";
    const startX = e.pageX;
    const startY = e.pageY;
    const startWidth = ((_a = __privateGet(this, _element)) == null ? void 0 : _a.getBoundingClientRect().width) || 0;
    const startHeight = ((_b = __privateGet(this, _element)) == null ? void 0 : _b.getBoundingClientRect().height) || 0;
    const onMouseMove = throttle(100, false, (e2) => {
      const currentX = e2.pageX;
      const currentY = e2.pageY;
      const diffX = currentX - startX;
      const diffY = currentY - startY;
      let newWidth = null;
      let newHeight = null;
      if (this.aspectRatio === 0 /* Fixed */ && startWidth && startHeight) {
        switch (handle.type) {
          case 0 /* Right */:
          case 3 /* BottomRight */:
            newWidth = startWidth + diffX;
            newHeight = startHeight / startWidth * newWidth;
            break;
          case 1 /* Left */:
          case 4 /* BottomLeft */:
            newWidth = startWidth - diffX;
            newHeight = startHeight / startWidth * newWidth;
            break;
          case 2 /* Bottom */:
            newHeight = startHeight + diffY;
            newWidth = startWidth / startHeight * newHeight;
            break;
        }
      } else if (this.aspectRatio === 1 /* Flexible */) {
        switch (handle.type) {
          case 0 /* Right */:
            newWidth = startWidth + diffX;
            break;
          case 1 /* Left */:
            newWidth = startWidth - diffX;
            break;
          case 2 /* Bottom */:
            newHeight = startHeight + diffY;
            break;
          case 3 /* BottomRight */:
            newWidth = startWidth + diffX;
            newHeight = startHeight + diffY;
            break;
          case 4 /* BottomLeft */:
            newWidth = startWidth - diffX;
            newHeight = startHeight + diffY;
            break;
        }
      }
      if (typeof newWidth === "number" && newWidth < MIN_WIDTH) {
        if (this.aspectRatio === 0 /* Fixed */ && startWidth && startHeight) {
          newWidth = MIN_WIDTH;
          newHeight = startHeight / startWidth * newWidth;
        } else if (this.aspectRatio === 1 /* Flexible */) {
          newWidth = MIN_WIDTH;
        }
      }
      if (newWidth) {
        __privateSet(this, _width, Math.round(newWidth));
        this.dom.style.width = `${__privateGet(this, _width)}px`;
      }
      if (newHeight) {
        __privateSet(this, _height, Math.round(newHeight));
      }
      if (newWidth || newHeight) {
        this.dom.style.aspectRatio = `${__privateGet(this, _width)} / ${__privateGet(this, _height)}`;
      }
    });
    const onMouseUp = (e2) => {
      e2.preventDefault();
      handle.dataSetDragging(false);
      handle.setHandleVisibility(false);
      __privateGet(this, _element).style.pointerEvents = "auto";
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
      const pos = getPos();
      const tr = view.state.tr.setNodeMarkup(pos, void 0, {
        ...__privateGet(this, _node).attrs,
        width: __privateGet(this, _width),
        height: __privateGet(this, _height)
      });
      view.dispatch(tr);
    };
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
    __privateGet(this, _destroyList).push(() => document.removeEventListener("mousemove", onMouseMove));
    __privateGet(this, _destroyList).push(() => document.removeEventListener("mouseup", onMouseUp));
  }
  /**
   * `update` will be called by Prosemirror, when the view is updating itself.
   */
  update(node) {
    if (node.type !== __privateGet(this, _node).type) {
      return false;
    }
    if (this.aspectRatio === 0 /* Fixed */ && node.attrs.width && node.attrs.width !== __privateGet(this, _width)) {
      return false;
    }
    if (this.aspectRatio === 1 /* Flexible */ && node.attrs.width && node.attrs.height && node.attrs.width !== __privateGet(this, _width) && node.attrs.height !== __privateGet(this, _height)) {
      return false;
    }
    if (!isEqualWithoutAttrs(__privateGet(this, _node), node, ["width", "height"])) {
      return false;
    }
    __privateSet(this, _node, node);
    __privateSet(this, _width, node.attrs.width);
    __privateSet(this, _height, node.attrs.height);
    return true;
  }
  destroy() {
    __privateGet(this, _destroyList).forEach((removeEventListener) => removeEventListener());
  }
};
_element = new WeakMap();
_node = new WeakMap();
_destroyList = new WeakMap();
_width = new WeakMap();
_height = new WeakMap();
function isEqualWithoutAttrs(node1, node2, ignoreAttrs) {
  return node1 === node2 || sameMarkup(node1, node2, ignoreAttrs) && node1.content.eq(node2.content);
}
function sameMarkup(node1, node2, ignoreAttrs) {
  const node1Attrs = node1.attrs;
  const node2Attrs = node2.attrs;
  const deltaAttrs = {};
  for (const attr of ignoreAttrs) {
    deltaAttrs[attr] = null;
  }
  node1.attrs = { ...node1Attrs, ...deltaAttrs };
  node2.attrs = { ...node2Attrs, ...deltaAttrs };
  const same = node1.sameMarkup(node2);
  node1.attrs = node1Attrs;
  node2.attrs = node2Attrs;
  return same;
}
function normalizeSize(size) {
  if (typeof size === "number") {
    return `${size}px`;
  } else if (size) {
    return size;
  } else {
    return void 0;
  }
}
export {
  ResizableNodeView,
  ResizableRatioType
};
