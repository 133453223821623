import { filter, uniqBy } from 'lodash'
import { makeVar, useReactiveVar } from '@apollo/client'
import {
  mutateCreatePerformanceScorecardMetricUser,
  mutateCreatePerformanceScorecardMetricUsers,
  mutateDeletePerformanceScorecardMetricUser,
  queryPerformanceScorecardMetricUserList,
} from '@cotiss/performance'
import {
  GqlCreatePerformanceScorecardMetricUserInput,
  GqlCreatePerformanceScorecardMetricUsersInput,
  GqlDeletePerformanceScorecardMetricUserInput,
  GqlPerformanceScorecardMetricUserFieldsFragment,
  GqlPerformanceScorecardMetricUserListInput,
} from '@gql'

const performanceScorecardMetricUsersVar = makeVar<GqlPerformanceScorecardMetricUserFieldsFragment[]>([])

export const usePerformanceScorecardMetricUser = () => {
  const performanceScorecardMetricUsers = useReactiveVar(performanceScorecardMetricUsersVar)

  return {
    performanceScorecardMetricUsers,
    setPerformanceScorecardMetricUsers: performanceScorecardMetricUsersVar,
    queryPerformanceScorecardMetricUserList: async (input: GqlPerformanceScorecardMetricUserListInput) => {
      const { items, pagination } = await queryPerformanceScorecardMetricUserList(input)

      performanceScorecardMetricUsersVar(items)

      return { items, pagination }
    },
    mutateCreatePerformanceScorecardMetricUser: async (input: GqlCreatePerformanceScorecardMetricUserInput) => {
      const createdPerformanceScorecardMetricUser = await mutateCreatePerformanceScorecardMetricUser(input)

      performanceScorecardMetricUsersVar(uniqBy([createdPerformanceScorecardMetricUser, ...performanceScorecardMetricUsers], 'id'))

      return createdPerformanceScorecardMetricUser
    },

    mutateCreatePerformanceScorecardMetricUsers: async (input: GqlCreatePerformanceScorecardMetricUsersInput) => {
      const createdPerformanceScorecardMetricUsers = await mutateCreatePerformanceScorecardMetricUsers(input)

      performanceScorecardMetricUsersVar(uniqBy([...createdPerformanceScorecardMetricUsers, ...performanceScorecardMetricUsers], 'id'))

      return createdPerformanceScorecardMetricUsers
    },

    mutateDeletePerformanceScorecardMetricUser: async (input: GqlDeletePerformanceScorecardMetricUserInput) => {
      await mutateDeletePerformanceScorecardMetricUser(input)

      performanceScorecardMetricUsersVar(filter(performanceScorecardMetricUsers, ({ id }) => id !== input.performanceScorecardMetricUserId))
    },
  }
}

export const clearReactivePerformanceScorecardMetricUser = async () => {
  performanceScorecardMetricUsersVar([])
}
