var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// packages/remirror__extension-hard-break/src/hard-break-extension.ts
import {
  chainCommands,
  command,
  convertCommand,
  extension,
  ExtensionPriority,
  ExtensionTag,
  NodeExtension
} from "@remirror/core";
import { exitCode } from "@remirror/pm/commands";
var HardBreakExtension = class extends NodeExtension {
  get name() {
    return "hardBreak";
  }
  createTags() {
    return [ExtensionTag.InlineNode];
  }
  createNodeSpec(extra, override) {
    return {
      inline: true,
      selectable: false,
      atom: true,
      leafText: () => {
        return "\n";
      },
      ...override,
      attrs: extra.defaults(),
      parseDOM: [{ tag: "br", getAttrs: extra.parse }, ...override.parseDOM ?? []],
      toDOM: (node) => ["br", extra.dom(node)]
    };
  }
  createKeymap() {
    const command2 = chainCommands(convertCommand(exitCode), () => {
      this.store.commands.insertHardBreak();
      return true;
    });
    return {
      "Mod-Enter": command2,
      "Shift-Enter": command2
    };
  }
  insertHardBreak() {
    return (props) => {
      const { tr, dispatch } = props;
      dispatch == null ? void 0 : dispatch(tr.replaceSelectionWith(this.type.create()).scrollIntoView());
      return true;
    };
  }
};
__decorateClass([
  command()
], HardBreakExtension.prototype, "insertHardBreak", 1);
HardBreakExtension = __decorateClass([
  extension({
    defaultPriority: ExtensionPriority.Low
  })
], HardBreakExtension);
export {
  HardBreakExtension
};
