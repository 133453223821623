var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// packages/remirror__extension-italic/src/italic-extension.ts
import {
  command,
  extension,
  ExtensionTag,
  keyBinding,
  MarkExtension,
  markInputRule,
  NamedShortcut,
  toggleMark
} from "@remirror/core";

// packages/remirror__extension-italic/src/italic-utils.ts
import { ExtensionItalicMessages as Messages } from "@remirror/messages";
var toggleItalicOptions = {
  icon: "italic",
  label: ({ t }) => t(Messages.LABEL),
  description: ({ t }) => t(Messages.DESCRIPTION)
};

// packages/remirror__extension-italic/src/italic-extension.ts
var ItalicExtension = class extends MarkExtension {
  get name() {
    return "italic";
  }
  createTags() {
    return [ExtensionTag.FontStyle, ExtensionTag.FormattingMark];
  }
  createMarkSpec(extra, override) {
    return {
      ...override,
      attrs: extra.defaults(),
      parseDOM: [
        { tag: "i", getAttrs: extra.parse },
        { tag: "em", getAttrs: extra.parse },
        { style: "font-style=italic" },
        ...override.parseDOM ?? []
      ],
      toDOM: (mark) => ["em", extra.dom(mark), 0]
    };
  }
  createKeymap() {
    return {
      "Mod-i": toggleMark({ type: this.type })
    };
  }
  createInputRules() {
    return [
      markInputRule({
        regexp: /(?:^|[^*])\*([^*]+)\*$/,
        type: this.type,
        ignoreWhitespace: true,
        updateCaptured: ({ fullMatch, start }) => !fullMatch.startsWith("*") ? { fullMatch: fullMatch.slice(1), start: start + 1 } : {}
      }),
      markInputRule({
        regexp: /(?:^|\W)_([^_]+)_$/,
        type: this.type,
        ignoreWhitespace: true,
        updateCaptured: ({ fullMatch, start }) => {
          return !fullMatch.startsWith("_") ? { fullMatch: fullMatch.slice(1), start: start + 1 } : {};
        }
      })
    ];
  }
  createPasteRules() {
    return [
      { type: "mark", markType: this.type, regexp: /(?:^|\W)_([^_]+)_/g },
      { type: "mark", markType: this.type, regexp: /\*([^*]+)\*/g }
    ];
  }
  toggleItalic(selection) {
    return toggleMark({ type: this.type, selection });
  }
  shortcut(props) {
    return this.toggleItalic()(props);
  }
};
__decorateClass([
  command(toggleItalicOptions)
], ItalicExtension.prototype, "toggleItalic", 1);
__decorateClass([
  keyBinding({ shortcut: NamedShortcut.Italic, command: "toggleItalic" })
], ItalicExtension.prototype, "shortcut", 1);
ItalicExtension = __decorateClass([
  extension({})
], ItalicExtension);
export {
  ItalicExtension
};
