import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '@cotiss/user'
import { apolloService } from '@cotiss/common'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation'
import { PERFORMANCE_METRIC_FRAGMENT, PERFORMANCE_SCORECARD_METRIC_FRAGMENT } from '@cotiss/performance'
import {
  GqlCreatePerformanceScorecardMetricInput,
  GqlCreatePerformanceScorecardMetricMutation,
  GqlCreatePerformanceScorecardMetricMutationVariables,
  GqlPerformanceScorecardMetricFieldsFragment,
} from '@gql'

export const mutateCreatePerformanceScorecardMetric = async (input: GqlCreatePerformanceScorecardMetricInput) => {
  const { data } = await apolloService.mutate<GqlCreatePerformanceScorecardMetricMutation, GqlCreatePerformanceScorecardMetricMutationVariables>({
    mutation: gql`
      ${USER_FRAGMENT}
      ${ORGANISATION_FRAGMENT}
      ${PERFORMANCE_METRIC_FRAGMENT}
      ${PERFORMANCE_SCORECARD_METRIC_FRAGMENT}

      mutation CreatePerformanceScorecardMetric($input: CreatePerformanceScorecardMetricInput!) {
        createPerformanceScorecardMetric(input: $input) {
          ...PerformanceScorecardMetricFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createPerformanceScorecardMetric as GqlPerformanceScorecardMetricFieldsFragment
}
