import { gql } from '@apollo/client'

export const PERFORMANCE_SCORECARD_METRIC_USER_FRAGMENT = gql`
  fragment PerformanceScorecardMetricUserFields on PerformanceScorecardMetricUser {
    id
    performanceScorecardMetricId
    performanceScorecardMetric {
      ...PerformanceScorecardMetricFields
    }
    performanceScorecardUserId
    performanceScorecardUser {
      ...PerformanceScorecardUserFields
    }
    createdAt
    updatedAt
    deletedAt
  }
`
