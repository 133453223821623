var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// packages/remirror__extension-bidi/src/bidi-extension.ts
import direction from "direction";
import {
  command,
  extension,
  ExtensionTag,
  findParentNode,
  getTextSelection,
  hasTransactionChanged,
  isString,
  PlainExtension
} from "@remirror/core";
import { ExtensionBidiMessages } from "@remirror/messages";
var setTextDirectionOptions = {
  icon: ({ attrs }) => (attrs == null ? void 0 : attrs.dir) === "ltr" ? "textDirectionL" : "textDirectionR",
  description: ({ t, attrs }) => t(ExtensionBidiMessages.DESCRIPTION, { dir: attrs == null ? void 0 : attrs.dir }),
  label: ({ t, attrs }) => t(ExtensionBidiMessages.LABEL, { dir: attrs == null ? void 0 : attrs.dir })
};
var BidiExtension = class extends PlainExtension {
  constructor() {
    super(...arguments);
    /**
     * Whether to ignore next updated.
     */
    this._ignoreNextUpdate = false;
  }
  get name() {
    return "bidi";
  }
  /**
   * Add the bidi property to the top level editor attributes `doc`.
   */
  createAttributes() {
    if (this.options.defaultDirection) {
      return {
        dir: this.options.defaultDirection
      };
    }
    return {};
  }
  /**
   * Add the `dir` to all the inner node types.
   */
  createSchemaAttributes() {
    const IGNORE_BIDI_AUTO_UPDATE = "data-ignore-bidi-auto";
    return [
      {
        identifiers: {
          type: "node",
          tags: [ExtensionTag.BlockNode],
          excludeNames: this.options.excludeNodes
        },
        attributes: {
          dir: this.dir(),
          ignoreBidiAutoUpdate: {
            default: null,
            parseDOM: IGNORE_BIDI_AUTO_UPDATE,
            toDOM: (attrs) => attrs.ignoreBidiAutoUpdate ? [IGNORE_BIDI_AUTO_UPDATE, "true"] : void 0
          }
        }
      }
    ];
  }
  /**
   * Create the plugin that ensures the node has the correct `dir` value on each
   * state update.
   */
  createPlugin() {
    return {
      state: {
        init: () => false,
        apply: (tr) => {
          if (this._ignoreNextUpdate) {
            this._ignoreNextUpdate = false;
            return false;
          }
          return hasTransactionChanged(tr);
        }
      },
      appendTransaction: (_, __, state) => {
        const shouldUpdate = this.getPluginState();
        const { autoUpdate, excludeNodes } = this.options;
        const tr = state.tr;
        if (!shouldUpdate || !autoUpdate) {
          return;
        }
        const parent = findParent(state.selection, excludeNodes);
        if (!parent) {
          return;
        }
        const { node, pos } = parent;
        const currentDirection = node.attrs.dir;
        const dir = this.getDirection(node.textContent);
        if (currentDirection === dir) {
          return;
        }
        if (node.attrs.ignoreBidiAutoUpdate) {
          return;
        }
        this._ignoreNextUpdate = true;
        return this.store.chain(tr).updateNodeAttributes(pos, { ...node.attrs, dir }).tr();
      }
    };
  }
  onSetOptions(props) {
    const { changes } = props;
    if (changes.defaultDirection.changed) {
      this.store.updateAttributes();
    }
  }
  /**
   * Create the `SchemaAttributesObject`
   */
  dir() {
    return {
      default: this.options.defaultDirection ?? null,
      parseDOM: (element) => element.getAttribute("dir") ?? this.getDirection(element.textContent),
      toDOM: (_, { node }) => {
        if (!node) {
          return;
        }
        if (!node.textContent) {
          return;
        }
        return this.getDirection(node.textContent) ?? this.options.defaultDirection;
      }
    };
  }
  /**
   * Get the direction of the text.
   */
  getDirection(text) {
    if (!isString(text)) {
      return;
    }
    const dir = direction(text);
    if (dir === "neutral") {
      return this.options.defaultDirection;
    }
    return dir;
  }
  setTextDirection(dir, options = {}) {
    return (props) => {
      const { tr } = props;
      const { selection } = options;
      const cmd = this.store.commands.updateNodeAttributes.original;
      const parent = findParent(
        getTextSelection(selection ?? tr.selection, tr.doc),
        this.options.excludeNodes
      );
      if (!parent || parent.node.attrs.dir == dir) {
        return false;
      }
      return cmd(parent.pos, { dir, ignoreBidiAutoUpdate: dir ? true : dir })(props);
    };
  }
};
__decorateClass([
  command(setTextDirectionOptions)
], BidiExtension.prototype, "setTextDirection", 1);
BidiExtension = __decorateClass([
  extension({
    defaultOptions: {
      defaultDirection: void 0,
      autoUpdate: false,
      excludeNodes: []
    },
    staticKeys: ["excludeNodes"]
  })
], BidiExtension);
function findParent(selection, excludeNodes) {
  return findParentNode({
    predicate: (node) => !!(node.isTextblock && node.textContent && !excludeNodes.includes(node.type.name)),
    selection
  });
}
export {
  BidiExtension
};
