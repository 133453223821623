var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// packages/remirror__extension-strike/src/strike-extension.ts
import {
  command,
  extension,
  ExtensionTag,
  keyBinding,
  MarkExtension,
  markInputRule,
  NamedShortcut,
  toggleMark
} from "@remirror/core";

// packages/remirror__extension-strike/src/strike-utils.ts
import { ExtensionStrikeMessages as Messages } from "@remirror/messages";
var toggleStrikeOptions = {
  icon: "strikethrough",
  label: ({ t }) => t(Messages.LABEL),
  description: ({ t }) => t(Messages.DESCRIPTION)
};

// packages/remirror__extension-strike/src/strike-extension.ts
var StrikeExtension = class extends MarkExtension {
  get name() {
    return "strike";
  }
  createTags() {
    return [ExtensionTag.FontStyle, ExtensionTag.FormattingMark];
  }
  createMarkSpec(extra, override) {
    return {
      ...override,
      attrs: extra.defaults(),
      parseDOM: [
        {
          tag: "s",
          getAttrs: extra.parse
        },
        {
          tag: "del",
          getAttrs: extra.parse
        },
        {
          tag: "strike",
          getAttrs: extra.parse
        },
        {
          style: "text-decoration",
          getAttrs: (node) => node === "line-through" ? {} : false
        },
        ...override.parseDOM ?? []
      ],
      toDOM: (mark) => ["s", extra.dom(mark), 0]
    };
  }
  toggleStrike() {
    return toggleMark({ type: this.type });
  }
  shortcut(props) {
    return this.toggleStrike()(props);
  }
  createInputRules() {
    return [markInputRule({ regexp: /~([^~]+)~$/, type: this.type, ignoreWhitespace: true })];
  }
  createPasteRules() {
    return [{ regexp: /~([^~]+)~/g, type: "mark", markType: this.type }];
  }
};
__decorateClass([
  command(toggleStrikeOptions)
], StrikeExtension.prototype, "toggleStrike", 1);
__decorateClass([
  keyBinding({ shortcut: NamedShortcut.Strike, command: "toggleStrike" })
], StrikeExtension.prototype, "shortcut", 1);
StrikeExtension = __decorateClass([
  extension({})
], StrikeExtension);
export {
  StrikeExtension
};
