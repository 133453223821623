var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __accessCheck = (obj, member, msg) => {
  if (!member.has(obj))
    throw TypeError("Cannot " + msg);
};
var __privateGet = (obj, member, getter) => {
  __accessCheck(obj, member, "read from private field");
  return getter ? getter.call(obj) : member.get(obj);
};
var __privateAdd = (obj, member, value) => {
  if (member.has(obj))
    throw TypeError("Cannot add the same private member more than once");
  member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
};
var __privateSet = (obj, member, value, setter) => {
  __accessCheck(obj, member, "write to private field");
  setter ? setter.call(obj, value) : member.set(obj, value);
  return value;
};

// packages/remirror__extension-react-component/src/portals/portal-container.ts
import { createNanoEvents } from "nanoevents";
import { uniqueId } from "@remirror/core";
var _events;
var PortalContainer = class {
  constructor() {
    /**
     * A map of all the active portals which have a one to one relation between
     * the container and the component.
     */
    this.portals = /* @__PURE__ */ new Map();
    /**
     * The event listener which allows consumers to subscribe to when a new portal
     * is added / deleted via the updated event.
     */
    __privateAdd(this, _events, createNanoEvents());
    /**
     * Event handler for subscribing to update events from the portalContainer.
     */
    this.on = (callback) => {
      return __privateGet(this, _events).on("update", callback);
    };
    /**
     * Subscribe to one event before automatically unbinding.
     */
    this.once = (callback) => {
      const unbind = __privateGet(this, _events).on("update", (portals) => {
        unbind();
        callback(portals);
      });
      return unbind;
    };
  }
  /**
   * Trigger an update in all subscribers.
   */
  update() {
    __privateGet(this, _events).emit("update", this.portals);
  }
  /**
   * Responsible for registering a new portal by rendering the react element
   * into the provided container.
   */
  render({ Component, container }) {
    const portal = this.portals.get(container);
    this.portals.set(container, { Component, key: (portal == null ? void 0 : portal.key) ?? uniqueId() });
    this.update();
  }
  /**
   * Force an update in all the portals by setting new keys for every portal.
   *
   * Delete all orphaned containers (deleted from the DOM). This is useful for
   * Decoration where there is no destroy method.
   */
  forceUpdate() {
    for (const [container, { Component }] of this.portals) {
      this.portals.set(container, { Component, key: uniqueId() });
    }
  }
  /**
   * Deletes the portal within the container.
   */
  remove(container) {
    this.portals.delete(container);
    this.update();
  }
};
_events = new WeakMap();

// packages/remirror__extension-react-component/src/portals/react-portals.tsx
import React, { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
var RemirrorPortals = (props) => {
  const { portals } = props;
  return /* @__PURE__ */ React.createElement(React.Fragment, null, portals.map(
    ([container, { Component, key }]) => createPortal(/* @__PURE__ */ React.createElement(Component, null), container, key)
  ));
};
function usePortals(portalContainer) {
  const [portals, setPortals] = useState(() => Array.from(portalContainer.portals.entries()));
  useEffect(() => {
    return portalContainer.on((portalMap) => {
      setPortals(Array.from(portalMap.entries()));
    });
  }, [portalContainer]);
  return useMemo(() => portals, [portals]);
}

// packages/remirror__extension-react-component/src/react-component-extension.ts
import {
  entries as entries2,
  extension,
  isNodeExtension,
  object,
  PlainExtension
} from "@remirror/core";

// packages/remirror__extension-react-component/src/react-node-view.tsx
import React2 from "react";
import {
  entries,
  ErrorConstant,
  invariant,
  isDomNode,
  isElementDomNode,
  isFunction,
  isNodeOfType,
  isPlainObject,
  isString,
  kebabCase,
  pascalCase,
  SELECTED_NODE_CLASS_NAME
} from "@remirror/core";
import { DOMSerializer } from "@remirror/pm/model";
import { NodeSelection } from "@remirror/pm/state";
var _node, _decorations, _view, _portalContainer, _Component, _getPosition, _options, _selected, _contentDOM, _contentDOMWrapper, _dom, _forwardRef;
var _ReactNodeView = class {
  /**
   * Create the node view for a react component and render it into the dom.
   */
  constructor({
    getPosition,
    node,
    portalContainer,
    view,
    ReactComponent,
    options
  }) {
    /**
     * The `ProsemirrorNode` that this nodeView is responsible for rendering.
     */
    __privateAdd(this, _node, void 0);
    /**
     * The decorations in the most recent update.
     */
    __privateAdd(this, _decorations, []);
    /**
     * The editor this nodeView belongs to.
     */
    __privateAdd(this, _view, void 0);
    /**
     * A container and event dispatcher which keeps track of all dom elements that
     * hold node views
     */
    __privateAdd(this, _portalContainer, void 0);
    /**
     * The extension responsible for creating this NodeView.
     */
    __privateAdd(this, _Component, void 0);
    /**
     * Method for retrieving the position of the current nodeView
     */
    __privateAdd(this, _getPosition, void 0);
    /**
     * The options passed through to the `ReactComponent`.
     */
    __privateAdd(this, _options, void 0);
    __privateAdd(this, _selected, false);
    __privateAdd(this, _contentDOM, void 0);
    /**
     * The wrapper for the content dom. Created from the node spec `toDOM` method.
     */
    __privateAdd(this, _contentDOMWrapper, void 0);
    __privateAdd(this, _dom, void 0);
    /**
     * Adds a ref to the component that has been provided and can be used to set
     * it as the content container. However it is advisable to either not use
     * ReactNodeViews for nodes with content or to take control of rendering the
     * content within the component..
     */
    __privateAdd(this, _forwardRef, (node) => {
      if (!node) {
        return;
      }
      invariant(__privateGet(this, _contentDOMWrapper), {
        code: ErrorConstant.REACT_NODE_VIEW,
        message: `You have applied a ref to a node view provided for '${__privateGet(this, _node).type.name}' which doesn't support content.`
      });
      node.append(__privateGet(this, _contentDOMWrapper));
    });
    /**
     * Render the provided component.
     *
     * This method is passed into the HTML element.
     */
    this.Component = () => {
      const ReactComponent = __privateGet(this, _Component);
      invariant(ReactComponent, {
        code: ErrorConstant.REACT_NODE_VIEW,
        message: `The custom react node view provided for ${__privateGet(this, _node).type.name} doesn't have a valid ReactComponent`
      });
      return /* @__PURE__ */ React2.createElement(
        ReactComponent,
        {
          updateAttributes: this.updateAttributes,
          selected: this.selected,
          view: __privateGet(this, _view),
          getPosition: __privateGet(this, _getPosition),
          node: __privateGet(this, _node),
          forwardRef: __privateGet(this, _forwardRef),
          decorations: __privateGet(this, _decorations)
        }
      );
    };
    /**
     * Passed to the Component to enable updating the attributes from within the component.
     */
    this.updateAttributes = (attrs) => {
      if (!__privateGet(this, _view).editable) {
        return;
      }
      const pos = __privateGet(this, _getPosition).call(this);
      if (pos == null) {
        return;
      }
      const tr = __privateGet(this, _view).state.tr.setNodeMarkup(pos, void 0, {
        ...__privateGet(this, _node).attrs,
        ...attrs
      });
      __privateGet(this, _view).dispatch(tr);
    };
    invariant(isFunction(getPosition), {
      message: "You are attempting to use a node view for a mark type. This is not supported yet. Please check your configuration."
    });
    __privateSet(this, _node, node);
    __privateSet(this, _view, view);
    __privateSet(this, _portalContainer, portalContainer);
    __privateSet(this, _Component, ReactComponent);
    __privateSet(this, _getPosition, getPosition);
    __privateSet(this, _options, options);
    __privateSet(this, _dom, this.createDom());
    const { contentDOM, wrapper } = this.createContentDom() ?? {};
    __privateSet(this, _contentDOM, contentDOM ?? void 0);
    __privateSet(this, _contentDOMWrapper, wrapper);
    if (__privateGet(this, _contentDOMWrapper)) {
      __privateGet(this, _dom).append(__privateGet(this, _contentDOMWrapper));
    }
    this.setDomAttributes(__privateGet(this, _node), __privateGet(this, _dom));
    this.Component.displayName = pascalCase(`${__privateGet(this, _node).type.name}NodeView`);
    this.renderComponent();
  }
  /**
   * A shorthand method for creating the `ReactNodeView`.
   */
  static create(props) {
    const { portalContainer, ReactComponent, options } = props;
    return (node, view, getPosition) => new _ReactNodeView({
      options,
      node,
      view,
      getPosition,
      portalContainer,
      ReactComponent
    });
  }
  /**
   * Whether or not the node is currently selected.
   */
  get selected() {
    return __privateGet(this, _selected);
  }
  /**
   * The DOM node that should hold the node's content.
   *
   * This is only meaningful if the NodeView is not a leaf type and it can
   * accept content. When these criteria are met, `ProseMirror` will take care of
   * rendering the node's children into it.
   *
   * In order to make use of this in a
   */
  get contentDOM() {
    return __privateGet(this, _contentDOM);
  }
  /**
   * Provides readonly access to the dom element. The dom is automatically for
   * react components.
   */
  get dom() {
    return __privateGet(this, _dom);
  }
  /**
   * Render the react component into the dom.
   */
  renderComponent() {
    __privateGet(this, _portalContainer).render({
      Component: this.Component,
      container: __privateGet(this, _dom)
    });
  }
  /**
   * Create the dom element which will hold the react component.
   */
  createDom() {
    const { defaultBlockNode, defaultInlineNode } = __privateGet(this, _options);
    const element = __privateGet(this, _node).isInline ? document.createElement(defaultInlineNode) : document.createElement(defaultBlockNode);
    element.classList.add(`${kebabCase(__privateGet(this, _node).type.name)}-node-view-wrapper`);
    return element;
  }
  /**
   * The element that will contain the content for this element.
   */
  createContentDom() {
    var _a, _b;
    if (__privateGet(this, _node).isLeaf) {
      return;
    }
    const domSpec = (_b = (_a = __privateGet(this, _node).type.spec).toDOM) == null ? void 0 : _b.call(_a, __privateGet(this, _node));
    if (!domSpec) {
      return;
    }
    const { contentDOM, dom } = DOMSerializer.renderSpec(document, domSpec);
    let wrapper;
    if (!isElementDomNode(dom)) {
      return;
    }
    wrapper = dom;
    if (dom === contentDOM) {
      wrapper = document.createElement("span");
      wrapper.classList.add(`${kebabCase(__privateGet(this, _node).type.name)}-node-view-content-wrapper`);
      wrapper.append(contentDOM);
    }
    if (isElementDomNode(contentDOM)) {
    }
    return { wrapper, contentDOM };
  }
  /**
   * This is called whenever the node is called.
   */
  update(node, decorations) {
    if (!isNodeOfType({ types: __privateGet(this, _node).type, node })) {
      return false;
    }
    if (__privateGet(this, _node) === node && __privateGet(this, _decorations) === decorations) {
      return true;
    }
    if (!__privateGet(this, _node).sameMarkup(node)) {
      this.setDomAttributes(node, __privateGet(this, _dom));
    }
    __privateSet(this, _node, node);
    __privateSet(this, _decorations, decorations);
    this.renderComponent();
    return true;
  }
  /**
   * Copies the attributes from a ProseMirror Node to the parent DOM node.
   *
   * @param node The Prosemirror Node from which to source the attributes
   */
  setDomAttributes(node, element) {
    const { toDOM } = __privateGet(this, _node).type.spec;
    let attributes = node.attrs;
    if (toDOM) {
      const domSpec = toDOM(node);
      if (isString(domSpec) || isDomNodeOutputSpec(domSpec)) {
        return;
      }
      if (isPlainObject(domSpec[1])) {
        attributes = domSpec[1];
      }
    }
    for (const [attribute, value] of entries(attributes)) {
      element.setAttribute(attribute, value);
    }
  }
  /**
   * Marks the node as being selected.
   */
  selectNode() {
    __privateSet(this, _selected, true);
    if (__privateGet(this, _dom)) {
      __privateGet(this, _dom).classList.add(SELECTED_NODE_CLASS_NAME);
    }
    this.renderComponent();
  }
  /**
   * Remove the selected node markings from this component.
   */
  deselectNode() {
    __privateSet(this, _selected, false);
    if (__privateGet(this, _dom)) {
      __privateGet(this, _dom).classList.remove(SELECTED_NODE_CLASS_NAME);
    }
    this.renderComponent();
  }
  /**
   * This is called whenever the node is being destroyed.
   */
  destroy() {
    __privateGet(this, _portalContainer).remove(__privateGet(this, _dom));
  }
  /**
   * The handler which decides when mutations should be ignored.
   */
  ignoreMutation(mutation) {
    if (mutation.type === "selection") {
      return !__privateGet(this, _node).type.spec.selectable;
    }
    if (!__privateGet(this, _contentDOMWrapper)) {
      return true;
    }
    return !__privateGet(this, _contentDOMWrapper).contains(mutation.target);
  }
  stopEvent(event) {
    var _a;
    if (!__privateGet(this, _dom)) {
      return false;
    }
    if (isFunction(__privateGet(this, _options).stopEvent)) {
      return __privateGet(this, _options).stopEvent({ event });
    }
    const target = event.target;
    const isInElement = __privateGet(this, _dom).contains(target) && !((_a = this.contentDOM) == null ? void 0 : _a.contains(target));
    if (!isInElement) {
      return false;
    }
    const isDropEvent = event.type === "drop";
    const isInput = ["INPUT", "BUTTON", "SELECT", "TEXTAREA"].includes(target.tagName) || target.isContentEditable;
    if (isInput && !isDropEvent) {
      return true;
    }
    const isDraggable = !!__privateGet(this, _node).type.spec.draggable;
    const isSelectable = NodeSelection.isSelectable(__privateGet(this, _node));
    const isCopyEvent = event.type === "copy";
    const isPasteEvent = event.type === "paste";
    const isCutEvent = event.type === "cut";
    const isClickEvent = event.type === "mousedown";
    const isDragEvent = event.type.startsWith("drag");
    if (!isDraggable && isSelectable && isDragEvent) {
      event.preventDefault();
    }
    if (isDragEvent || isDropEvent || isCopyEvent || isPasteEvent || isCutEvent || isClickEvent && isSelectable) {
      return false;
    }
    return true;
  }
};
var ReactNodeView = _ReactNodeView;
_node = new WeakMap();
_decorations = new WeakMap();
_view = new WeakMap();
_portalContainer = new WeakMap();
_Component = new WeakMap();
_getPosition = new WeakMap();
_options = new WeakMap();
_selected = new WeakMap();
_contentDOM = new WeakMap();
_contentDOMWrapper = new WeakMap();
_dom = new WeakMap();
_forwardRef = new WeakMap();
function isDomNodeOutputSpec(value) {
  return isDomNode(value) || isPlainObject(value) && isDomNode(value.dom);
}

// packages/remirror__extension-react-component/src/react-component-extension.ts
var ReactComponentExtension = class extends PlainExtension {
  constructor() {
    super(...arguments);
    /**
     * The portal container which keeps track of all the React Portals containing
     * custom prosemirror NodeViews.
     */
    this.portalContainer = new PortalContainer();
  }
  get name() {
    return "reactComponent";
  }
  /**
   * Add the portal container to the manager store. This can be used by the
   * `<Remirror />` component to manage portals for node content.
   */
  onCreate() {
    this.store.setStoreKey("portalContainer", this.portalContainer);
  }
  /**
   * Create the node views from the custom components provided.
   */
  createNodeViews() {
    const nodeViews = object();
    const managerComponents = this.store.managerSettings.nodeViewComponents ?? {};
    for (const extension2 of this.store.extensions) {
      if (!extension2.ReactComponent || !isNodeExtension(extension2) || extension2.reactComponentEnvironment === "ssr") {
        continue;
      }
      nodeViews[extension2.name] = ReactNodeView.create({
        options: this.options,
        ReactComponent: extension2.ReactComponent,
        portalContainer: this.portalContainer
      });
    }
    const namedComponents = entries2({ ...this.options.nodeViewComponents, ...managerComponents });
    for (const [name, ReactComponent] of namedComponents) {
      nodeViews[name] = ReactNodeView.create({
        options: this.options,
        ReactComponent,
        portalContainer: this.portalContainer
      });
    }
    return nodeViews;
  }
};
ReactComponentExtension = __decorateClass([
  extension({
    defaultOptions: {
      defaultBlockNode: "div",
      defaultInlineNode: "span",
      defaultContentNode: "span",
      defaultEnvironment: "both",
      nodeViewComponents: {},
      stopEvent: null
    },
    staticKeys: ["defaultBlockNode", "defaultInlineNode", "defaultContentNode", "defaultEnvironment"]
  })
], ReactComponentExtension);
export {
  PortalContainer,
  ReactComponentExtension,
  RemirrorPortals,
  usePortals
};
