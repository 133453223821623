var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// packages/remirror__extension-font-family/src/font-family-extension.ts
import {
  command,
  extension,
  ExtensionPriority,
  ExtensionTag,
  isElementDomNode,
  isString,
  joinStyles,
  MarkExtension,
  omitExtraAttributes
} from "@remirror/core";
var FONT_FAMILY_ATTRIBUTE = "data-font-family";
var FontFamilyExtension = class extends MarkExtension {
  get name() {
    return "fontFamily";
  }
  createTags() {
    return [ExtensionTag.FontStyle, ExtensionTag.FormattingMark];
  }
  createMarkSpec(extra, override) {
    return {
      ...override,
      attrs: { ...extra.defaults(), fontFamily: { default: null } },
      parseDOM: [
        {
          tag: `span[${FONT_FAMILY_ATTRIBUTE}]`,
          getAttrs: (dom) => {
            if (!isElementDomNode(dom)) {
              return false;
            }
            const fontFamily = dom.getAttribute(FONT_FAMILY_ATTRIBUTE);
            if (!fontFamily) {
              return false;
            }
            return { ...extra.parse(dom), fontFamily };
          }
        },
        {
          // Get the color from the css style property. This is useful for pasted content.
          style: "font-family",
          priority: ExtensionPriority.Low,
          getAttrs: (fontFamily) => {
            if (!isString(fontFamily)) {
              return false;
            }
            return {
              fontFamily: fontFamily ? fontFamily.replace(/["']/g, "") : ""
            };
          }
        },
        ...override.parseDOM ?? []
      ],
      toDOM: (mark) => {
        const { fontFamily } = omitExtraAttributes(mark.attrs, extra);
        const extraAttrs = extra.dom(mark);
        let style = extraAttrs.style;
        style = joinStyles({ fontFamily }, style);
        return ["span", { ...extraAttrs, style, [FONT_FAMILY_ATTRIBUTE]: fontFamily }, 0];
      }
    };
  }
  setFontFamily(fontFamily) {
    return this.store.commands.applyMark.original(this.type, { fontFamily });
  }
  toggleFontFamily(fontFamily) {
    return this.store.commands.toggleMark.original({ type: this.type, attrs: { fontFamily } });
  }
};
__decorateClass([
  command()
], FontFamilyExtension.prototype, "setFontFamily", 1);
__decorateClass([
  command()
], FontFamilyExtension.prototype, "toggleFontFamily", 1);
FontFamilyExtension = __decorateClass([
  extension({})
], FontFamilyExtension);
export {
  FontFamilyExtension
};
