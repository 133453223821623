var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// packages/remirror__extension-underline/src/underline-extension.ts
import {
  command,
  extension,
  ExtensionTag,
  keyBinding,
  MarkExtension,
  NamedShortcut,
  toggleMark
} from "@remirror/core";

// packages/remirror__extension-underline/src/underline-utils.ts
import { ExtensionUnderlineMessages as Messages } from "@remirror/messages";
var toggleUnderlineOptions = {
  icon: "underline",
  label: ({ t }) => t(Messages.LABEL),
  description: ({ t }) => t(Messages.DESCRIPTION)
};

// packages/remirror__extension-underline/src/underline-extension.ts
var UnderlineExtension = class extends MarkExtension {
  get name() {
    return "underline";
  }
  createTags() {
    return [ExtensionTag.FontStyle, ExtensionTag.FormattingMark];
  }
  createMarkSpec(extra, override) {
    return {
      ...override,
      attrs: extra.defaults(),
      parseDOM: [
        {
          tag: "u",
          getAttrs: extra.parse
        },
        {
          style: "text-decoration",
          getAttrs: (node) => node === "underline" ? {} : false
        },
        ...override.parseDOM ?? []
      ],
      toDOM: (mark) => ["u", extra.dom(mark), 0]
    };
  }
  toggleUnderline(selection) {
    return toggleMark({ type: this.type, selection });
  }
  shortcut(props) {
    return this.toggleUnderline()(props);
  }
};
__decorateClass([
  command(toggleUnderlineOptions)
], UnderlineExtension.prototype, "toggleUnderline", 1);
__decorateClass([
  keyBinding({ shortcut: NamedShortcut.Underline, command: "toggleUnderline" })
], UnderlineExtension.prototype, "shortcut", 1);
UnderlineExtension = __decorateClass([
  extension({})
], UnderlineExtension);
export {
  UnderlineExtension
};
