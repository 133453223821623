var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// packages/remirror__extension-horizontal-rule/src/horizontal-rule-extension.ts
import {
  command,
  ErrorConstant,
  extension,
  ExtensionTag,
  invariant,
  isEmptyBlockNode,
  isNodeSelection,
  NodeExtension,
  nodeInputRule
} from "@remirror/core";
import { TextSelection } from "@remirror/pm/state";

// packages/remirror__extension-horizontal-rule/src/horizontal-rule-utils.ts
import { ExtensionHorizontalRuleMessages as Messages } from "@remirror/messages";
var insertHorizontalRuleOptions = {
  icon: "separator",
  label: ({ t }) => t(Messages.LABEL),
  description: ({ t }) => t(Messages.DESCRIPTION)
};

// packages/remirror__extension-horizontal-rule/src/horizontal-rule-extension.ts
var HorizontalRuleExtension = class extends NodeExtension {
  get name() {
    return "horizontalRule";
  }
  createTags() {
    return [ExtensionTag.Block];
  }
  createNodeSpec(extra, override) {
    return {
      ...override,
      attrs: extra.defaults(),
      parseDOM: [{ tag: "hr", getAttrs: extra.parse }, ...override.parseDOM ?? []],
      toDOM: (node) => ["hr", extra.dom(node)]
    };
  }
  insertHorizontalRule() {
    return (props) => {
      const { tr, dispatch } = props;
      const $pos = tr.selection.$anchor;
      const initialParent = $pos.parent;
      if (initialParent.type.name === "doc" || initialParent.isAtom || initialParent.isLeaf) {
        return false;
      }
      if (!dispatch) {
        return true;
      }
      const shouldDuplicateEmptyNode = tr.selection.empty && isEmptyBlockNode(initialParent);
      if (shouldDuplicateEmptyNode) {
        tr.insert($pos.pos + 1, initialParent);
      }
      tr.replaceSelectionWith(this.type.create());
      this.updateFromNodeSelection(tr);
      dispatch(tr.scrollIntoView());
      return true;
    };
  }
  createInputRules() {
    return [
      nodeInputRule({
        // Allow dash + hyphen to cater for ShortcutsExtension, which replaces first
        // two hyphens with a dash, i.e. "---" becomes "<dash>-"
        regexp: /^(?:---|—-|___\s|\*\*\*\s)$/,
        type: this.type,
        beforeDispatch: ({ tr }) => {
          this.updateFromNodeSelection(tr);
        }
      })
    ];
  }
  /**
   * Updates the transaction after a `horizontalRule` has been inserted to make
   * sure the currently selected node isn't a Horizontal Rule.
   *
   * This should only be called for empty selections.
   */
  updateFromNodeSelection(tr) {
    if (!isNodeSelection(tr.selection) || tr.selection.node.type.name !== this.name) {
      return;
    }
    const pos = tr.selection.$from.pos + 1;
    const { insertionNode } = this.options;
    if (!insertionNode) {
      return;
    }
    const type = this.store.schema.nodes[insertionNode];
    invariant(type, {
      code: ErrorConstant.EXTENSION,
      message: `'${insertionNode}' node provided as the insertionNode to the '${this.constructorName}' does not exist.`
    });
    const node = type.create();
    tr.insert(pos, node);
    tr.setSelection(TextSelection.near(tr.doc.resolve(pos + 1)));
  }
};
__decorateClass([
  command(insertHorizontalRuleOptions)
], HorizontalRuleExtension.prototype, "insertHorizontalRule", 1);
HorizontalRuleExtension = __decorateClass([
  extension({
    defaultOptions: { insertionNode: "paragraph" }
  })
], HorizontalRuleExtension);
export {
  HorizontalRuleExtension
};
