var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// packages/remirror__extension-gap-cursor/src/gap-cursor-extension.ts
import { extension, isInstanceOf, PlainExtension } from "@remirror/core";
import { GapCursor, gapCursor } from "@remirror/pm/gapcursor";
var GapCursorExtension = class extends PlainExtension {
  get name() {
    return "gapCursor";
  }
  createExternalPlugins() {
    return [gapCursor()];
  }
};
GapCursorExtension = __decorateClass([
  extension({})
], GapCursorExtension);
var isGapCursorSelection = isInstanceOf(GapCursor);
export {
  GapCursorExtension,
  isGapCursorSelection
};
