import { animations } from '../../motion/features/animations.mjs';
import { gestureAnimations } from '../../motion/features/gestures.mjs';
import { createDomVisualElement } from './create-visual-element.mjs';

/**
 * @public
 */
const domAnimation = {
    renderer: createDomVisualElement,
    ...animations,
    ...gestureAnimations,
};

export { domAnimation };
