import { gql } from '@apollo/client'

export const PERFORMANCE_SCORECARD_METRIC_CYCLE_FRAGMENT = gql`
  fragment PerformanceScorecardMetricCycleFields on PerformanceScorecardMetricCycle {
    id
    performanceScorecardMetricId
    dateFrom
    dateTo
    score
    comment
    status
    createdAt
    updatedAt
    deletedAt
  }
`
