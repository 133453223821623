var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// packages/remirror__extension-text/src/text-extension.ts
import {
  extension,
  ExtensionPriority,
  ExtensionTag,
  NodeExtension
} from "@remirror/core";
var TextExtension = class extends NodeExtension {
  get name() {
    return "text";
  }
  createTags() {
    return [ExtensionTag.InlineNode];
  }
  createNodeSpec() {
    return {};
  }
};
TextExtension = __decorateClass([
  extension({
    disableExtraAttributes: true,
    defaultPriority: ExtensionPriority.Medium
  })
], TextExtension);
export {
  TextExtension
};
