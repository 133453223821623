var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// packages/remirror__extension-trailing-node/src/trailing-node-extension.ts
import { extension, PlainExtension } from "@remirror/core";
import { trailingNode } from "@remirror/pm/trailing-node";
var TrailingNodeExtension = class extends PlainExtension {
  get name() {
    return "trailingNode";
  }
  /**
   * Whenever the options are changed make sure to update the plugin with the
   * new values and trigger a state update.
   */
  onSetOptions(props) {
    const { changes } = props;
    if (changes.disableTags.changed || changes.ignoredNodes.changed || changes.nodeName.changed) {
      this.store.updateExtensionPlugins(this);
    }
  }
  /**
   * Add the trailing node plugin to the editor.
   */
  createExternalPlugins() {
    const { tags } = this.store;
    const { disableTags, nodeName } = this.options;
    const ignoredNodes = disableTags ? [...this.options.ignoredNodes] : [...this.options.ignoredNodes, ...tags.lastNodeCompatible];
    return [trailingNode({ ignoredNodes, nodeName })];
  }
};
TrailingNodeExtension = __decorateClass([
  extension({
    defaultOptions: {
      ignoredNodes: [],
      disableTags: false,
      nodeName: "paragraph"
    }
  })
], TrailingNodeExtension);
export {
  TrailingNodeExtension
};
