var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// packages/remirror__extension-sup/src/sup-extension.ts
import {
  command,
  extension,
  ExtensionTag,
  keyBinding,
  MarkExtension,
  NamedShortcut,
  toggleMark
} from "@remirror/core";
import { ExtensionSupMessages as Messages } from "@remirror/messages";
var toggleSuperscriptOptions = {
  icon: "superscript",
  label: ({ t }) => t(Messages.LABEL)
};
var SupExtension = class extends MarkExtension {
  get name() {
    return "sup";
  }
  createTags() {
    return [ExtensionTag.FormattingMark, ExtensionTag.FontStyle];
  }
  createMarkSpec(extra, override) {
    return {
      ...override,
      attrs: extra.defaults(),
      parseDOM: [
        {
          tag: "sup",
          getAttrs: extra.parse
        },
        ...override.parseDOM ?? []
      ],
      toDOM: (mark) => ["sup", extra.dom(mark), 0]
    };
  }
  toggleSuperscript() {
    return toggleMark({ type: this.type });
  }
  shortcut(props) {
    return this.toggleSuperscript()(props);
  }
};
__decorateClass([
  command(toggleSuperscriptOptions)
], SupExtension.prototype, "toggleSuperscript", 1);
__decorateClass([
  keyBinding({ shortcut: NamedShortcut.Superscript, command: "toggleSuperscript" })
], SupExtension.prototype, "shortcut", 1);
SupExtension = __decorateClass([
  extension({})
], SupExtension);
export {
  SupExtension
};
