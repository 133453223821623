import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { filter, flatMap, map, max, some } from 'lodash'
import React, { ReactNode, memo, useEffect, useMemo, useRef, useState } from 'react'
import {
  TableThAction,
  Pagination,
  PaginationModel,
  Skeleton,
  TRANSITION_DURATION,
  TableTd,
  TableTh,
  useGetWindowDimensions,
  TableState,
  TableVariant,
} from '@cotiss/common'

type RenderTableParam = {
  columns: ScrollableTableColumn[]
  isFixed?: boolean
  hasEmptyCta?: boolean
}

export type ScrollableTableRowContentParam = {
  isHighlighted: boolean
}

export type ScrollableTableRow = {
  content?: (param: ScrollableTableRowContentParam) => ReactNode
  cta?: ReactNode
  tdClassName?: string
  variant?: TableVariant
  colSpan?: number
  maxWidth?: number
  hasHover?: boolean
}

export type ScrollableTableColumn = {
  heading?: ReactNode
  thClassName?: string
  rows: ScrollableTableRow[]
  actions?: TableThAction[]
  onSort?: () => void
  colSpan?: number
  isThWrapped?: boolean
  isWrapped?: boolean
}

type Props = {
  className?: string
  state?: TableState
  variant?: TableVariant
  fixedColumns: ScrollableTableColumn[]
  fixedColumnsWidth?: number
  columns: ScrollableTableColumn[]
  emptyCta?: ReactNode
  pagination?: PaginationModel
  onPageChange?: (page: number) => void
  isLoading?: boolean
}

export const ScrollableTable = memo((props: Props) => {
  const {
    className,
    state = 'default',
    variant = 'white',
    fixedColumns,
    fixedColumnsWidth = 300,
    columns,
    emptyCta,
    pagination,
    onPageChange,
    isLoading,
  } = props
  const { windowWidth } = useGetWindowDimensions()
  const [isScrolled, setIsScrolled] = useState(false)
  const fixedTableRef = useRef<HTMLTableElement>(null)
  const scrollableTableRef = useRef<HTMLTableElement>(null)
  const [isOverflowing, setIsOverflowing] = useState(false)
  const scrollableContainerRef = useRef<HTMLDivElement>(null)
  const classes = classNames(className, 'bg-white rounded-lg')
  const [highlightedRowIndex, setHighlightedRowIndex] = useState<number | null>(null)

  // If there are no CTAs then the table will not have a row hover state.
  const hasCta = useMemo(() => some(flatMap([...fixedColumns, ...columns], 'rows'), 'cta'), [fixedColumns, columns])

  // This useEffect manages the scroll state of the table. It is responsible for determining if the table is scrolled, and if it is overflowing.
  useEffect(() => {
    if (isLoading) {
      return
    }

    // First we need to determine if there is indeed content overflowing when the component first mounts.
    setTimeout(() => {
      setIsOverflowing((scrollableContainerRef.current?.scrollWidth || 0) > (scrollableContainerRef.current?.clientWidth || 0))
    }, 0)

    const handleScroll = () => {
      const { scrollLeft = 0, scrollWidth = 0, clientWidth = 0 } = scrollableContainerRef?.current || {}
      setIsScrolled(scrollLeft > 0)
      setIsOverflowing(scrollWidth - scrollLeft !== clientWidth)
    }

    scrollableContainerRef?.current?.addEventListener('scroll', handleScroll)

    return () => {
      scrollableContainerRef?.current?.removeEventListener('scroll', handleScroll)
    }
  }, [isLoading])

  // This useEffect is responsible for keeping the heights of the fixed and scrollable tables in sync.
  useEffect(() => {
    const fixedTableRows = fixedTableRef.current?.getElementsByTagName('tr')
    const scrollableTableRows = scrollableTableRef.current?.getElementsByTagName('tr')

    // Only need to check heights if we've got two tables.
    if (!fixedTableRows?.length || !scrollableTableRows?.length) {
      return
    }

    for (let i = 0; i < fixedTableRows?.length; i++) {
      // This shouldn't happen, but make sure there is a corresponding row on the right-hand table.
      if (!scrollableTableRows[i]) {
        continue
      }

      // Remove previously-calculated values so we can calculate based on latest row data.
      fixedTableRows[i].style.removeProperty('height')
      scrollableTableRows[i].style.removeProperty('height')

      const height = max([fixedTableRows[i].offsetHeight, scrollableTableRows[i].offsetHeight])

      if (!height) {
        return
      }

      fixedTableRows[i].style.height = `${height}px`
      scrollableTableRows[i].style.height = `${height}px`
    }
  }, [fixedColumns, columns, windowWidth, isLoading])

  const getRows = ({ columns, isFixed }: RenderTableParam) => {
    const rows: ScrollableTableRow[][] = []

    if (isLoading) {
      for (let i = 0; i < 5; i++) {
        const cells: ScrollableTableRow[] = []

        for (let j = 0; j < columns.length; j++) {
          cells.push({ hasHover: false, content: () => <Skeleton className="w-34 h-2" variant="gray" /> })
        }

        rows.push(cells)
      }

      return rows
    }

    // If the table is empty, and there is a CTA, we need to render a single row where the first column renders the CTA, and the rest are empty.
    if (emptyCta && !columns[0].rows.length) {
      const cells: ScrollableTableRow[] = []

      for (let i = 0; i < columns.length; i++) {
        cells.push({ content: () => (!i && isFixed ? emptyCta : <></>) })
      }

      rows.push(cells)
    }

    for (let i = 0; i < columns[0].rows.length; i++) {
      const cells: ScrollableTableRow[] = []

      for (let j = 0; j < columns.length; j++) {
        cells.push({ variant, ...columns[j].rows[i] })
      }

      rows.push(cells)
    }

    return rows
  }

  const renderTable = ({ columns, isFixed }: RenderTableParam) => {
    const tableClasses = classNames('shrink-0 table-fixed bg-white', {
      'border-collapse border-l border-gray-200': isFixed && state === 'default',
      'border-separate border-spacing-y-2': state === 'split',
      'min-w-full': !isFixed,
      'z-1': isFixed,
    })

    return (
      <table ref={isFixed ? fixedTableRef : scrollableTableRef} className={tableClasses} style={{ width: isFixed ? fixedColumnsWidth : undefined }}>
        <thead onMouseEnter={() => setHighlightedRowIndex(null)}>
          <tr>
            {/* We need to filter out any columns with no heading. This should only happen when a `colSpan` has been passed. */}
            {map(
              filter(columns, (column) => Boolean(column.heading)),
              (column, index) => (
                <TableTh
                  key={index}
                  className={column.thClassName}
                  state={state}
                  variant={variant}
                  onSort={column.onSort}
                  actions={column.actions}
                  colSpan={column.colSpan}
                  isWrapped={column.isThWrapped}
                  isScrollableTable
                >
                  {column.heading}
                </TableTh>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {map(getRows({ columns, isFixed }), (rows, rowIndex) => (
            <tr key={rowIndex} onMouseEnter={() => setHighlightedRowIndex(rowIndex)}>
              {map(
                filter(rows, (row) => Boolean(row.content)),
                (cell, columnIndex) => {
                  const column = columns[columnIndex]
                  const isHovered = highlightedRowIndex === rowIndex
                  const isHighlighted = (cell.hasHover === undefined ? true : cell.hasHover) && isHovered && hasCta
                  // TODO: Because there are instances where we are making individual API requests for each row, we need to be careful about when we
                  // TODO: mount these components. Once we make a rule that we never to API calls within list items, we can remove this logic, and
                  // TODO: improve the behaviour.
                  const ctaClasses = classNames('ml-2', { 'absolute right-0 h-full invisible': !isHovered })

                  return (
                    <TableTd
                      key={columnIndex}
                      className={cell.tdClassName}
                      state={state}
                      variant={cell.variant}
                      colSpan={cell.colSpan}
                      isFixedColumn={isFixed}
                      isWrapped={column.isWrapped}
                      isHighlighted={isHighlighted}
                      isScrollableTable
                    >
                      {cell.cta && (
                        <div className="flex items-center justify-between">
                          {cell.content && cell.content({ isHighlighted })}
                          <div className={ctaClasses}>{cell.cta}</div>
                        </div>
                      )}
                      {!cell.cta && cell.content && cell.content({ isHighlighted })}
                    </TableTd>
                  )
                }
              )}
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  return (
    <div className={classes}>
      <div className="relative flex items-start" onMouseLeave={() => setHighlightedRowIndex(null)}>
        {renderTable({ columns: fixedColumns, isFixed: true })}
        <AnimatePresence>
          {isScrolled && (
            <motion.div
              className="absolute left-0 bg-gray-300 blur-sm w-1 h-full z-1"
              style={{ left: fixedColumnsWidth }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.8 }}
              transition={{ duration: TRANSITION_DURATION.sm }}
              exit={{ opacity: 0 }}
            />
          )}
        </AnimatePresence>
        <div className="overflow-x-auto" style={{ width: `calc(100% - ${fixedColumnsWidth}px)` }} ref={scrollableContainerRef}>
          {renderTable({ columns })}
        </div>
        <AnimatePresence>
          {isOverflowing && (
            <motion.div
              className="absolute right-0 bg-gray-300 blur-sm w-1 h-full z-1"
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.8 }}
              transition={{ duration: TRANSITION_DURATION.sm }}
              exit={{ opacity: 0 }}
            />
          )}
        </AnimatePresence>
      </div>
      {Boolean(pagination?.pageCount && pagination.pageCount > 1 && onPageChange) && (
        <div className="p-4">{pagination && onPageChange && <Pagination pagination={pagination} onPageChange={onPageChange} />}</div>
      )}
    </div>
  )
})
