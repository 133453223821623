import { isAfter, parseISO } from 'date-fns'
import React, { memo, useMemo } from 'react'
import { lowerCase, upperFirst } from 'lodash'
import { Badge, BadgeSize, BadgeState } from '@cotiss/common'
import { GqlPerformanceScorecardMetricCycleFieldsFragment } from '@gql'

type Props = {
  className?: string
  performanceScorecardMetricCycle: GqlPerformanceScorecardMetricCycleFieldsFragment
  size?: BadgeSize
  state?: BadgeState
}

export const PerformanceScorecardMetricCycleStatusBadge = memo((props: Props) => {
  const { className, performanceScorecardMetricCycle, size = 'sm', state = 'translucent' } = props

  const { label, variant } = useMemo(() => {
    const { dateTo, status } = performanceScorecardMetricCycle

    if (status === 'complete') {
      return { label: 'Completed', variant: 'success' } as const
    }

    if (isAfter(new Date(), parseISO(dateTo))) {
      return { label: 'Overdue', variant: 'danger' } as const
    }

    return { label: 'Due now', variant: 'warning' } as const
  }, [performanceScorecardMetricCycle])

  return (
    <Badge className={className} size={size} variant={variant} state={state}>
      {upperFirst(lowerCase(label))}
    </Badge>
  )
})
