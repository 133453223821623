var __accessCheck = (obj, member, msg) => {
  if (!member.has(obj))
    throw TypeError("Cannot " + msg);
};
var __privateGet = (obj, member, getter) => {
  __accessCheck(obj, member, "read from private field");
  return getter ? getter.call(obj) : member.get(obj);
};
var __privateAdd = (obj, member, value) => {
  if (member.has(obj))
    throw TypeError("Cannot add the same private member more than once");
  member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
};
var __privateSet = (obj, member, value, setter) => {
  __accessCheck(obj, member, "write to private field");
  setter ? setter.call(obj, value) : member.set(obj, value);
  return value;
};

// packages/remirror__react-core/src/hooks/use-remirror-context.ts
import { createContext, useContext, useEffect, useRef } from "react";
import {
  ErrorConstant,
  invariant,
  isFunction,
  isPlainObject
} from "@remirror/core";

// packages/remirror__react-core/src/hooks/use-force-update.ts
import { useCallback, useState } from "react";
import { object } from "@remirror/core";
function useForceUpdate() {
  const [, setState] = useState(object());
  return useCallback(() => {
    setState(object());
  }, []);
}

// packages/remirror__react-core/src/hooks/use-remirror-context.ts
var RemirrorContext = createContext(null);
function useRemirrorContext(handler) {
  const context = useContext(RemirrorContext);
  const forceUpdate = useRef(useForceUpdate());
  invariant(context, { code: ErrorConstant.REACT_PROVIDER_CONTEXT });
  const { addHandler } = context;
  useEffect(() => {
    let updateHandler = handler;
    if (!updateHandler) {
      return;
    }
    if (isPlainObject(updateHandler)) {
      const { autoUpdate } = updateHandler;
      updateHandler = autoUpdate ? () => forceUpdate.current() : void 0;
    }
    if (!isFunction(updateHandler)) {
      return;
    }
    return addHandler("updated", updateHandler);
  }, [addHandler, handler]);
  return context;
}

// packages/remirror__react-core/src/hooks/use-active.ts
function useActive(autoUpdate = true) {
  return useRemirrorContext({ autoUpdate }).active;
}

// packages/remirror__react-core/src/hooks/use-attrs.ts
function useAttrs(update = false) {
  return useRemirrorContext(update ? { autoUpdate: true } : void 0).attrs;
}

// packages/remirror__react-core/src/hooks/use-chained-commands.ts
function useChainedCommands() {
  return useRemirrorContext().chain;
}

// packages/remirror__react-core/src/hooks/use-commands.ts
function useCommands() {
  return useRemirrorContext().commands;
}

// packages/remirror__react-core/src/hooks/use-current-selection.ts
function useCurrentSelection() {
  return useRemirrorContext({ autoUpdate: true }).getState().selection;
}

// packages/remirror__react-core/src/hooks/use-doc-changed.ts
import { DocChangedExtension } from "@remirror/core";

// packages/remirror__react-core/src/hooks/use-extension-event.ts
import { useCallback as useCallback2 } from "react";

// packages/remirror__react-core/src/hooks/use-extension.ts
import { useEffect as useEffect2, useMemo } from "react";
import {
  isFunction as isFunction2
} from "@remirror/core";
function useExtension(Constructor, optionsOrCallback = void 0, dependencies) {
  const { getExtension } = useRemirrorContext();
  const extension = useMemo(() => getExtension(Constructor), [Constructor, getExtension]);
  let deps;
  if (isFunction2(optionsOrCallback)) {
    deps = dependencies ? [extension, ...dependencies] : [extension, optionsOrCallback];
  } else {
    deps = optionsOrCallback ? [extension, ...Object.values(optionsOrCallback)] : [];
  }
  useEffect2(() => {
    if (isFunction2(optionsOrCallback) || !optionsOrCallback) {
      return;
    }
    extension.setOptions(optionsOrCallback);
  }, deps);
  useEffect2(() => {
    if (!isFunction2(optionsOrCallback)) {
      return;
    }
    return optionsOrCallback({
      addHandler: extension.addHandler.bind(extension),
      addCustomHandler: extension.addCustomHandler.bind(extension),
      extension
    });
  }, deps);
  if (optionsOrCallback) {
    return;
  }
  return extension;
}

// packages/remirror__react-core/src/hooks/use-extension-event.ts
function useExtensionEvent(extension, event, memoizedHandler) {
  const callback = useCallback2(
    ({ addHandler }) => addHandler(event, memoizedHandler),
    [memoizedHandler, event]
  );
  return useExtension(extension, callback);
}
function useExtensionCustomEvent(extension, event, memoizedCustomHandler) {
  const callback = useCallback2(
    ({ addCustomHandler }) => addCustomHandler(event, memoizedCustomHandler),
    [memoizedCustomHandler, event]
  );
  return useExtension(extension, callback);
}

// packages/remirror__react-core/src/hooks/use-doc-changed.ts
function useDocChanged(handler) {
  useExtensionEvent(DocChangedExtension, "docChanged", handler);
}

// packages/remirror__react-core/src/hooks/use-editor-dom-ref.ts
import { useRef as useRef2 } from "react";

// packages/remirror__react-core/src/hooks/use-editor-view.ts
function useEditorView() {
  return useRemirrorContext().view;
}

// packages/remirror__react-core/src/hooks/use-editor-dom-ref.ts
function useEditorDomRef() {
  return useRef2(useEditorView().dom);
}

// packages/remirror__react-core/src/hooks/use-editor-state.ts
function useEditorState() {
  return useRemirrorContext({ autoUpdate: true }).getState();
}

// packages/remirror__react-core/src/hooks/use-has-extension.ts
import { useMemo as useMemo2 } from "react";
function useHasExtension(Constructor) {
  const { hasExtension } = useRemirrorContext();
  return useMemo2(() => hasExtension(Constructor), [Constructor, hasExtension]);
}

// packages/remirror__react-core/src/hooks/use-helpers.ts
function useHelpers(update = false) {
  return useRemirrorContext(update ? { autoUpdate: true } : void 0).helpers;
}

// packages/remirror__react-core/src/hooks/use-i18n.ts
import { createContextState } from "create-context-state";
import { i18n as defaultI18n } from "@remirror/i18n";
var [I18nProvider, useI18n] = createContextState(({ props }) => {
  const locale = props.locale ?? "en";
  const i18n = props.i18n ?? defaultI18n;
  const supportedLocales = props.supportedLocales ?? [locale];
  const t = (...args) => i18n._(...args);
  return { locale, i18n, supportedLocales, t };
});

// packages/remirror__react-core/src/hooks/use-manager.ts
import { useEffect as useEffect3, useRef as useRef3, useState as useState2 } from "react";

// packages/remirror__react-core/src/react-helpers.tsx
import { getLazyArray, isRemirrorManager, RemirrorManager } from "@remirror/core";
import { corePreset } from "@remirror/preset-core";
import { ReactExtension } from "@remirror/preset-react";
function createReactManager(extensions, options = {}) {
  const { core, react, ...settings } = options;
  if (isRemirrorManager(extensions)) {
    return extensions;
  }
  return RemirrorManager.create(
    () => [...getLazyArray(extensions), new ReactExtension(react), ...corePreset(core)],
    settings
  );
}

// packages/remirror__react-core/src/hooks/use-manager.ts
function useManager(extensions, options = {}) {
  const extensionsRef = useRef3(extensions);
  const optionsRef = useRef3(options);
  const [manager, setManager] = useState2(() => createReactManager(extensions, options));
  extensionsRef.current = extensions;
  optionsRef.current = options;
  useEffect3(() => {
    return manager.addHandler("destroy", () => {
      setManager(() => createReactManager(extensionsRef.current, optionsRef.current));
    });
  }, [manager]);
  return manager;
}

// packages/remirror__react-core/src/hooks/use-mark-range.ts
import { getMarkRange } from "@remirror/core";
function useMarkRange(type) {
  const { getState } = useRemirrorContext({ autoUpdate: true });
  const { $from, $to } = getState().selection;
  return getMarkRange($from, type, $to);
}

// packages/remirror__react-core/src/hooks/use-portal-container.ts
function usePortalContainer() {
  return useRemirrorContext().portalContainer;
}

// packages/remirror__react-core/src/hooks/use-react-framework.tsx
import { useEffect as useEffect5, useMemo as useMemo3, useRef as useRef5, useState as useState3 } from "react";
import { ErrorConstant as ErrorConstant3, invariant as invariant3, isArray, isNullOrUndefined } from "@remirror/core";
import { ReactExtension as ReactExtension2 } from "@remirror/preset-react";

// packages/remirror__react-core/src/react-framework.tsx
import {
  ErrorConstant as ErrorConstant2,
  Framework,
  invariant as invariant2,
  object as object2,
  STATE_OVERRIDE
} from "@remirror/core";
import { EditorView } from "@remirror/pm/view";
import { PlaceholderExtension } from "@remirror/preset-react";

// packages/remirror__react-core/src/commonjs-packages/seznam-compose-react-refs.ts
import _composeRefs from "@seznam/compose-react-refs";
var composeRefs = typeof _composeRefs === "object" && _composeRefs.__esModule && _composeRefs.default ? _composeRefs.default : _composeRefs;

// packages/remirror__react-core/src/react-framework.tsx
var _editorRef;
var ReactFramework = class extends Framework {
  constructor(props) {
    super(props);
    /**
     * Stores the Prosemirror EditorView dom element.
     */
    __privateAdd(this, _editorRef, void 0);
    /**
     * Keep track of whether the get root props has been called during the most recent render.
     */
    this.rootPropsConfig = {
      called: false,
      count: 0
    };
    /**
     * The external `getRootProps` that is used to spread props onto a desired
     * holder element for the prosemirror view.
     */
    this.getRootProps = (options) => {
      return this.internalGetRootProps(options, null);
    };
    /**
     * Creates the props that should be spread on the root element inside which
     * the prosemirror instance will be rendered.
     *
     * TODO - this is useless - REFACTOR
     */
    this.internalGetRootProps = (options, children) => {
      this.rootPropsConfig.called = true;
      const {
        refKey = "ref",
        ref,
        ...config
      } = options ?? object2();
      return {
        [refKey]: composeRefs(ref, this.onRef),
        key: this.uid,
        ...config,
        children
      };
    };
    /**
     * Stores the Prosemirror editor dom instance for this component using `refs`.
     */
    this.onRef = (element) => {
      if (!element) {
        return;
      }
      this.rootPropsConfig.count += 1;
      invariant2(this.rootPropsConfig.count <= 1, {
        code: ErrorConstant2.REACT_GET_ROOT_PROPS,
        message: `Called ${this.rootPropsConfig.count} times`
      });
      __privateSet(this, _editorRef, element);
      this.onRefLoad();
    };
    if (this.manager.view) {
      this.manager.view.setProps({
        state: this.manager.view.state,
        dispatchTransaction: this.dispatchTransaction,
        attributes: () => this.getAttributes(),
        editable: () => this.props.editable ?? true
      });
      return;
    }
    this.manager.getExtension(PlaceholderExtension).setOptions({ placeholder: this.props.placeholder ?? "" });
  }
  get name() {
    return "react";
  }
  /**
   * This is called to update props on every render so that values don't become stale.
   */
  update(props) {
    super.update(props);
    return this;
  }
  /**
   * Create the prosemirror editor view.
   */
  createView(state) {
    return new EditorView(null, {
      state,
      dispatchTransaction: this.dispatchTransaction,
      attributes: () => this.getAttributes(),
      editable: () => this.props.editable ?? true,
      plugins: []
    });
  }
  /**
   * Updates the state either by calling `onChange` when it exists or
   * directly setting the internal state via a `setState` call.
   */
  updateState({ state, ...rest }) {
    const { triggerChange = true, tr, transactions } = rest;
    if (this.props.state) {
      const { onChange } = this.props;
      invariant2(onChange, {
        code: ErrorConstant2.REACT_CONTROLLED,
        message: "You are required to provide the `onChange` handler when creating a controlled editor."
      });
      invariant2(triggerChange, {
        code: ErrorConstant2.REACT_CONTROLLED,
        message: "Controlled editors do not support `clearContent` or `setContent` where `triggerChange` is `true`. Update the `state` prop instead."
      });
      if (!this.previousStateOverride) {
        this.previousStateOverride = this.getState();
      }
      this.onChange({ state, tr, transactions });
      return;
    }
    if (!tr && !transactions) {
      state = state.apply(state.tr.setMeta(STATE_OVERRIDE, {}));
    }
    this.view.updateState(state);
    if (triggerChange && (transactions == null ? void 0 : transactions.length) !== 0) {
      this.onChange({ state, tr, transactions });
    }
    this.manager.onStateUpdate({ previousState: this.previousState, state, tr, transactions });
  }
  /**
   * Update the controlled state when the value changes and notify the extension
   * of this update.
   */
  updateControlledState(state, previousState) {
    this.previousStateOverride = previousState;
    state = state.apply(state.tr.setMeta(STATE_OVERRIDE, {}));
    this.view.updateState(state);
    this.manager.onStateUpdate({ previousState: this.previousState, state });
    this.previousStateOverride = void 0;
  }
  /**
   * Adds the prosemirror view to the dom in the position specified via the
   * component props.
   */
  addProsemirrorViewToDom(element, viewDom) {
    if (this.props.insertPosition === "start") {
      element.insertBefore(viewDom, element.firstChild);
    } else {
      element.append(viewDom);
    }
  }
  /**
   * Called once the container dom node (`this.editorRef`) has been initialized
   * after the component mounts.
   *
   * This method handles the cases where the dom is not focused.
   */
  onRefLoad() {
    invariant2(__privateGet(this, _editorRef), {
      code: ErrorConstant2.REACT_EDITOR_VIEW,
      message: "Something went wrong when initializing the text editor. Please check your setup."
    });
    const { autoFocus } = this.props;
    this.addProsemirrorViewToDom(__privateGet(this, _editorRef), this.view.dom);
    if (autoFocus) {
      this.focus(autoFocus);
    }
    this.onChange();
    this.addFocusListeners();
  }
  /**
   * Called for every update of the props and state.
   */
  onUpdate() {
    if (this.view && __privateGet(this, _editorRef)) {
      this.view.setProps({ ...this.view.props, editable: () => this.props.editable ?? true });
    }
  }
  /**
   * Get the framework output.
   */
  get frameworkOutput() {
    return {
      ...this.baseOutput,
      getRootProps: this.getRootProps,
      portalContainer: this.manager.store.portalContainer
    };
  }
  /**
   * Reset the called status of `getRootProps`.
   */
  resetRender() {
    this.rootPropsConfig.called = false;
    this.rootPropsConfig.count = 0;
  }
};
_editorRef = new WeakMap();

// packages/remirror__react-core/src/hooks/use-isomorphic-layout-effect.ts
import { useEffect as useEffect4, useLayoutEffect } from "react";
var useIsomorphicLayoutEffect = typeof document !== "undefined" ? useLayoutEffect : useEffect4;

// packages/remirror__react-core/src/hooks/use-previous.ts
import { useRef as useRef4 } from "react";
function usePrevious(value) {
  const ref = useRef4();
  useIsomorphicLayoutEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

// packages/remirror__react-core/src/hooks/use-react-framework.tsx
function useReactFramework(props) {
  const { manager, state } = props;
  const { placeholder, editable } = props;
  const firstUpdate = useRef5(true);
  if (firstUpdate.current && !isNullOrUndefined(placeholder)) {
    manager.getExtension(ReactExtension2).setOptions({ placeholder });
  }
  useEffect5(() => {
    if (placeholder == null) {
      return;
    }
    manager.getExtension(ReactExtension2).setOptions({ placeholder });
  }, [placeholder, manager]);
  const [initialEditorState] = useState3(() => {
    if (state) {
      return state;
    }
    const fallback = manager.createEmptyDoc();
    const [initialContent, initialSelection] = isArray(props.initialContent) ? props.initialContent : [props.initialContent ?? fallback];
    return manager.createState({ content: initialContent, selection: initialSelection });
  });
  const framework = useFramework({
    initialEditorState,
    getProps: () => props
  });
  useEffect5(() => {
    return () => {
      framework.destroy();
    };
  }, [framework]);
  useEffect5(() => {
    framework.onUpdate();
  }, [editable, framework]);
  useControlledEditor(framework);
  return framework.frameworkOutput;
}
function useFramework(props) {
  const propsRef = useRef5(props);
  propsRef.current = props;
  const framework = useMemo3(() => new ReactFramework(propsRef.current), []);
  framework.update(props);
  return framework;
}
function useControlledEditor(framework) {
  const { state } = framework.props;
  const isControlledRef = useRef5(!!state);
  const previousValue = usePrevious(state);
  useIsomorphicLayoutEffect(() => {
    const validUpdate = state ? isControlledRef.current === true : isControlledRef.current === false;
    invariant3(validUpdate, {
      code: ErrorConstant3.REACT_CONTROLLED,
      message: isControlledRef.current ? "You have attempted to switch from a controlled to an uncontrolled editor. Once you set up an editor as a controlled editor it must always provide a `state` prop." : "You have provided a `state` prop to an uncontrolled editor. In order to set up your editor as controlled you must provide the `state` prop from the very first render."
    });
    if (!state || state === previousValue) {
      return;
    }
    framework.updateControlledState(state, previousValue ?? void 0);
  }, [state, previousValue, framework]);
}

// packages/remirror__react-core/src/hooks/use-remirror.tsx
import { useCallback as useCallback3, useMemo as useMemo4, useState as useState4 } from "react";
function useRemirror(props = {}) {
  const { content, document: document2, selection, extensions, ...settings } = props;
  const manager = useManager(extensions ?? (() => []), settings);
  const [state, setState] = useState4(
    () => manager.createState({
      selection,
      content: content ?? manager.createEmptyDoc()
    })
  );
  const onChange = useCallback3(({ state: state2 }) => {
    setState(state2);
  }, []);
  const getContext = useCallback3(() => {
    const context = manager.output;
    return context;
  }, [manager]);
  return useMemo4(
    () => ({ state, setState, manager, onChange, getContext }),
    [getContext, manager, onChange, state]
  );
}

// packages/remirror__react-core/src/hooks/use-selected-text.ts
import { isTextSelection } from "@remirror/core";
function useSelectedText() {
  const { getState, helpers } = useRemirrorContext({ autoUpdate: true });
  const { selection, doc } = getState();
  if (!isTextSelection(selection) || selection.empty) {
    return;
  }
  const { from, to } = selection;
  return helpers.getTextBetween(from, to, doc);
}

// packages/remirror__react-core/src/hooks/use-update-reason.ts
import { useCallback as useCallback4, useState as useState5 } from "react";
import { PluginsExtension } from "@remirror/core";
var noReason = { doc: false, selection: false, storedMark: false };
function useUpdateReason() {
  const [updateReason, setUpdateReason] = useState5(noReason);
  useExtensionEvent(
    PluginsExtension,
    "applyState",
    useCallback4(({ tr }) => {
      const reason = { ...noReason };
      if (tr.docChanged) {
        reason.doc = true;
      }
      if (tr.selectionSet) {
        reason.selection = true;
      }
      if (tr.storedMarksSet) {
        reason.storedMark = true;
      }
      setUpdateReason(reason);
    }, [])
  );
  return updateReason;
}

// packages/remirror__react-core/src/on-change.tsx
import { useCallback as useCallback5 } from "react";
var OnChangeJSON = ({ onChange }) => {
  const { getJSON } = useHelpers();
  useDocChanged(
    useCallback5(
      ({ state }) => {
        const json = getJSON(state);
        onChange(json);
      },
      [onChange, getJSON]
    )
  );
  return null;
};
var OnChangeHTML = ({ onChange }) => {
  const { getHTML } = useHelpers();
  useDocChanged(
    useCallback5(
      ({ state }) => {
        const html = getHTML(state);
        onChange(html);
      },
      [onChange, getHTML]
    )
  );
  return null;
};

// packages/remirror__react-core/src/prosemirror-view.ts
import { EditorView as EditorView2 } from "@remirror/pm/view";
function createEditorView(place, props) {
  return new EditorView2(place, props);
}

// packages/remirror__react-core/src/react-remirror.tsx
import React from "react";
import { isNullOrUndefined as isNullOrUndefined2 } from "@remirror/core";
import { RemirrorPortals, usePortals } from "@remirror/extension-react-component";
import { ComponentsTheme } from "@remirror/theme";
var EditorComponent = () => {
  return /* @__PURE__ */ React.createElement("div", { className: ComponentsTheme.EDITOR_WRAPPER, ...useRemirrorContext().getRootProps() });
};
var HookComponent = (props) => {
  props.hook();
  return null;
};
function Remirror(props) {
  const {
    children,
    autoRender,
    i18n,
    locale,
    supportedLocales,
    hooks = [],
    ...frameworkProps
  } = props;
  const context = useReactFramework(frameworkProps);
  const portals = usePortals(context.portalContainer);
  const autoRenderAtStart = autoRender === "start" || autoRender === true || !children && isNullOrUndefined2(autoRender);
  const autoRenderAtEnd = autoRender === "end";
  return /* @__PURE__ */ React.createElement(I18nProvider, { i18n, locale, supportedLocales }, /* @__PURE__ */ React.createElement(RemirrorContext.Provider, { value: context }, /* @__PURE__ */ React.createElement(RemirrorPortals, { portals }), hooks.map((hook, index) => /* @__PURE__ */ React.createElement(HookComponent, { hook, key: index })), autoRenderAtStart && /* @__PURE__ */ React.createElement(EditorComponent, null), children, autoRenderAtEnd && /* @__PURE__ */ React.createElement(EditorComponent, null)));
}
export {
  EditorComponent,
  I18nProvider,
  OnChangeHTML,
  OnChangeJSON,
  Remirror,
  RemirrorContext,
  createEditorView,
  createReactManager,
  useActive,
  useAttrs,
  useChainedCommands,
  useCommands,
  useCurrentSelection,
  useDocChanged,
  useEditorDomRef,
  useEditorState,
  useEditorView,
  useExtension,
  useExtensionCustomEvent,
  useExtensionEvent,
  useForceUpdate,
  useHasExtension,
  useHelpers,
  useI18n,
  useManager,
  useMarkRange,
  usePortalContainer,
  useRemirror,
  useRemirrorContext,
  useSelectedText,
  useUpdateReason
};
