import { makeVar, useReactiveVar } from '@apollo/client'
import { queryPerformanceScorecardMetricCycleList, queryPerformanceScorecardMetricCycleView } from '@cotiss/performance'
import {
  GqlPerformanceScorecardMetricCycleFieldsFragment,
  GqlPerformanceScorecardMetricCycleListInput,
  GqlPerformanceScorecardMetricCycleViewInput,
} from '@gql'

const performanceScorecardMetricCycleVar = makeVar<GqlPerformanceScorecardMetricCycleFieldsFragment | null>(null)
const performanceScorecardMetricCyclesVar = makeVar<GqlPerformanceScorecardMetricCycleFieldsFragment[]>([])

export const usePerformanceScorecardMetricCycle = () => {
  const performanceScorecardMetricCycles = useReactiveVar(performanceScorecardMetricCyclesVar)

  return {
    performanceScorecardMetricCycles,
    setPerformanceScorecardMetricCycles: performanceScorecardMetricCyclesVar,
    queryPerformanceScorecardMetricCycleList: async (input: GqlPerformanceScorecardMetricCycleListInput) => {
      const { items, pagination } = await queryPerformanceScorecardMetricCycleList(input)

      performanceScorecardMetricCyclesVar(items)

      return { items, pagination }
    },
    queryPerformanceScorecardMetricCycleView: async (input: GqlPerformanceScorecardMetricCycleViewInput) => {
      const performanceScorecardMetricCycle = await queryPerformanceScorecardMetricCycleView(input)

      performanceScorecardMetricCycleVar(performanceScorecardMetricCycle)

      return performanceScorecardMetricCycle
    },
  }
}

export const clearReactivePerformanceScorecardMetricCycle = async () => {
  performanceScorecardMetricCycleVar(null)
  performanceScorecardMetricCyclesVar([])
}
