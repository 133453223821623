// packages/remirror__react-components/src/button-groups/baseline-button-group.tsx
import React37 from "react";

// packages/remirror__react-components/src/buttons/center-align-button.tsx
import React4, { useCallback as useCallback2 } from "react";
import { useCommands, useCurrentSelection } from "@remirror/react-core";

// packages/remirror__react-components/src/buttons/command-button.tsx
import { Box, ToggleButton, Tooltip } from "@mui/material";
import React3, { useCallback } from "react";
import { isString as isString3 } from "@remirror/core";

// packages/remirror__react-components/src/use-command-option-values.ts
import { useMemo } from "react";
import { useHelpers, useI18n } from "@remirror/react-core";

// packages/remirror__react-components/src/react-component-utils.ts
import {
  capitalize,
  getShortcutSymbols,
  includes,
  isArray,
  isEqual,
  isFunction,
  isString
} from "@remirror/core";
function getCommandOptionValue(value, commandProps) {
  return isFunction(value) ? value(commandProps) : value;
}
function isStringArray(array) {
  return isString(array[0]);
}
function getUiShortcutString(uiShortcut, attrs) {
  var _a;
  if (isString(uiShortcut)) {
    return uiShortcut;
  }
  if (!isArray(uiShortcut)) {
    return uiShortcut.shortcut;
  }
  if (isStringArray(uiShortcut)) {
    return uiShortcut[0] ?? "";
  }
  return ((_a = uiShortcut.find((shortcut) => isEqual(shortcut.attrs, attrs)) ?? uiShortcut[0]) == null ? void 0 : _a.shortcut) ?? "";
}
var CASINGS = {
  title: (value) => capitalize(value),
  upper: (value) => value.toLocaleUpperCase(),
  lower: (value) => value.toLocaleLowerCase()
};
function getShortcutString(shortcut, options) {
  const {
    casing = "title",
    namedAsSymbol = false,
    modifierAsSymbol = true,
    separator = " ",
    t
  } = options;
  const symbols = getShortcutSymbols(shortcut);
  const stringSymbols = [];
  const transform = CASINGS[casing];
  for (const sym of symbols) {
    if (sym.type === "char") {
      stringSymbols.push(transform(sym.key));
      continue;
    }
    if (sym.type === "named") {
      const value2 = namedAsSymbol === true || isArray(namedAsSymbol) && includes(namedAsSymbol, sym.key) ? sym.symbol ?? t(sym.i18n) : t(sym.i18n);
      stringSymbols.push(transform(value2));
      continue;
    }
    const value = modifierAsSymbol === true || isArray(modifierAsSymbol) && includes(modifierAsSymbol, sym.key) ? sym.symbol : t(sym.i18n);
    stringSymbols.push(transform(value));
  }
  return stringSymbols.join(separator);
}

// packages/remirror__react-components/src/use-command-option-values.ts
var useCommandOptionValues = ({
  commandName,
  active,
  enabled,
  attrs
}) => {
  const { t } = useI18n();
  const { getCommandOptions } = useHelpers();
  const options = getCommandOptions(commandName);
  const { description, label, icon, shortcut } = options || {};
  const commandProps = useMemo(() => {
    return { active, attrs, enabled, t };
  }, [active, attrs, enabled, t]);
  const shortcutString = useMemo(() => {
    if (!shortcut) {
      return;
    }
    return getShortcutString(getUiShortcutString(shortcut, attrs ?? {}), { t, separator: "" });
  }, [shortcut, attrs, t]);
  return useMemo(() => {
    return {
      description: getCommandOptionValue(description, commandProps),
      label: getCommandOptionValue(label, commandProps),
      icon: getCommandOptionValue(icon, commandProps),
      shortcut: shortcutString
    };
  }, [commandProps, description, label, icon, shortcutString]);
};

// packages/remirror__react-components/src/buttons/command-button-icon.tsx
import { Badge } from "@mui/material";
import React2 from "react";
import { isPlainObject, isString as isString2 } from "@remirror/core";

// packages/remirror__react-components/src/icons/icons-base.tsx
import React, { createElement } from "react";
import * as Icons from "@remirror/icons";

// packages/remirror__react-components/src/icons/icons-context.tsx
import { createContext } from "react";
var DefaultContext = {
  color: void 0,
  size: void 0,
  className: void 0,
  style: void 0,
  attr: void 0
};
var IconContext = createContext(DefaultContext);
var IconProvider = IconContext.Provider;

// packages/remirror__react-components/src/icons/icons-base.tsx
function Tree2Element(tree) {
  return tree.map(
    (node, index) => createElement(node.tag, { key: index, ...node.attr }, Tree2Element(node.child ?? []))
  );
}
function GenIcon(tree, viewBox = "0 0 24 24") {
  return (props) => /* @__PURE__ */ React.createElement(IconBase, { viewBox, ...props }, Tree2Element(tree ?? []));
}
var Icon = (props) => {
  const { name } = props;
  return /* @__PURE__ */ React.createElement(IconBase, { ...props }, Tree2Element(Icons[name]));
};
var IconBase = (props) => {
  const renderSvg = (context) => {
    const computedSize = props.size ?? context.size ?? "1em";
    let className;
    if (context.className) {
      className = context.className;
    }
    if (props.className) {
      className = (className ? `${className} ` : "") + props.className;
    }
    const { title, ...svgProps } = props;
    return /* @__PURE__ */ React.createElement(
      "svg",
      {
        stroke: "currentColor",
        fill: "currentColor",
        strokeWidth: "0",
        ...context.attr,
        ...svgProps,
        className,
        style: { color: props.color ?? context.color, ...context.style, ...props.style },
        height: computedSize,
        width: computedSize,
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 24 24"
      },
      title && /* @__PURE__ */ React.createElement("title", null, title),
      props.children
    );
  };
  return /* @__PURE__ */ React.createElement(IconContext.Consumer, null, renderSvg);
};

// packages/remirror__react-components/src/buttons/command-button-icon.tsx
var isCommandUiIcon = (val) => {
  if (!isPlainObject(val)) {
    return false;
  }
  return !!val.name;
};
var CommandButtonIcon = ({ icon }) => {
  if (isString2(icon)) {
    return /* @__PURE__ */ React2.createElement(Icon, { name: icon, size: "1rem" });
  }
  return icon;
};
var CommandButtonBadge = ({ icon, children }) => {
  if (!isCommandUiIcon(icon)) {
    return /* @__PURE__ */ React2.createElement(React2.Fragment, null, children);
  }
  const { sub, sup } = icon;
  const value = sub ?? sup;
  const isBottom = sub !== void 0;
  if (value === void 0) {
    return /* @__PURE__ */ React2.createElement(React2.Fragment, null, children);
  }
  return /* @__PURE__ */ React2.createElement(
    Badge,
    {
      anchorOrigin: {
        vertical: isBottom ? "bottom" : "top",
        horizontal: "right"
      },
      badgeContent: value,
      sx: {
        "& > .MuiBadge-badge": {
          bgcolor: "background.paper",
          color: "text.secondary",
          minWidth: 12,
          height: 12,
          margin: "2px 0",
          padding: "1px"
        }
      }
    },
    children
  );
};

// packages/remirror__react-components/src/buttons/command-button.tsx
var CommandButton = ({
  commandName,
  active = false,
  enabled,
  attrs,
  onSelect,
  onChange,
  icon,
  displayShortcut = true,
  "aria-label": ariaLabel,
  label,
  ...rest
}) => {
  const handleChange = useCallback(
    (e, value) => {
      onSelect();
      onChange == null ? void 0 : onChange(e, value);
    },
    [onSelect, onChange]
  );
  const handleMouseDown = useCallback((e) => {
    e.preventDefault();
  }, []);
  const commandOptions = useCommandOptionValues({ commandName, active, enabled, attrs });
  let fallbackIcon = null;
  if (commandOptions.icon) {
    fallbackIcon = isString3(commandOptions.icon) ? commandOptions.icon : commandOptions.icon.name;
  }
  const labelText = ariaLabel ?? commandOptions.label ?? "";
  const tooltipText = label ?? labelText;
  const shortcutText = displayShortcut && commandOptions.shortcut ? ` (${commandOptions.shortcut})` : "";
  return /* @__PURE__ */ React3.createElement(Tooltip, { title: `${tooltipText}${shortcutText}` }, /* @__PURE__ */ React3.createElement(Box, { component: "span", sx: { "&:not(:first-of-type)": { marginLeft: "-1px" } } }, /* @__PURE__ */ React3.createElement(
    ToggleButton,
    {
      "aria-label": labelText,
      selected: active,
      disabled: !enabled,
      onMouseDown: handleMouseDown,
      color: "primary",
      size: "small",
      sx: {
        padding: "6px 12px",
        "&.Mui-selected": {
          backgroundColor: "primary.main",
          color: "primary.contrastText"
        },
        "&.Mui-selected:hover": {
          backgroundColor: "primary.dark",
          color: "primary.contrastText"
        },
        "&:not(:first-of-type)": {
          borderLeft: "1px solid transparent",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0
        },
        "&:not(:last-of-type)": {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0
        }
      },
      ...rest,
      value: commandName,
      onChange: handleChange
    },
    /* @__PURE__ */ React3.createElement(CommandButtonBadge, { icon: commandOptions.icon }, /* @__PURE__ */ React3.createElement(CommandButtonIcon, { icon: icon ?? fallbackIcon }))
  )));
};

// packages/remirror__react-components/src/buttons/center-align-button.tsx
var CenterAlignButton = (props) => {
  var _a;
  const { centerAlign } = useCommands();
  useCurrentSelection();
  const handleSelect = useCallback2(() => {
    if (centerAlign.enabled()) {
      centerAlign();
    }
  }, [centerAlign]);
  const active = (_a = centerAlign.active) == null ? void 0 : _a.call(centerAlign);
  const enabled = centerAlign.enabled();
  return /* @__PURE__ */ React4.createElement(
    CommandButton,
    {
      ...props,
      commandName: "centerAlign",
      active,
      enabled,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/copy-button.tsx
import React5, { useCallback as useCallback3 } from "react";
import { useCommands as useCommands2, useCurrentSelection as useCurrentSelection2 } from "@remirror/react-core";
var CopyButton = (props) => {
  const { copy } = useCommands2();
  useCurrentSelection2();
  const handleSelect = useCallback3(() => {
    if (copy.enabled()) {
      copy();
    }
  }, [copy]);
  const enabled = copy.enabled();
  return /* @__PURE__ */ React5.createElement(
    CommandButton,
    {
      ...props,
      commandName: "copy",
      active: false,
      enabled,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/create-table-button.tsx
import React6, { useCallback as useCallback4 } from "react";
import { useActive, useCommands as useCommands3 } from "@remirror/react-core";
var CreateTableButton = (props) => {
  const { createTable } = useCommands3();
  const handleSelect = useCallback4(() => {
    if (createTable.enabled()) {
      createTable();
    }
  }, [createTable]);
  const active = useActive().table();
  const enabled = createTable.enabled();
  return /* @__PURE__ */ React6.createElement(
    CommandButton,
    {
      ...props,
      commandName: "createTable",
      active,
      enabled,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/cut-button.tsx
import React7, { useCallback as useCallback5 } from "react";
import { useCommands as useCommands4, useCurrentSelection as useCurrentSelection3 } from "@remirror/react-core";
var CutButton = (props) => {
  const { cut } = useCommands4();
  useCurrentSelection3();
  const handleSelect = useCallback5(() => {
    if (cut.enabled()) {
      cut();
    }
  }, [cut]);
  const enabled = cut.enabled();
  return /* @__PURE__ */ React7.createElement(
    CommandButton,
    {
      ...props,
      commandName: "cut",
      active: false,
      enabled,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/decrease-font-size-button.tsx
import React8, { useCallback as useCallback6 } from "react";
import { useCommands as useCommands5 } from "@remirror/react-core";
var DecreaseFontSizeButton = (props) => {
  const { decreaseFontSize } = useCommands5();
  const handleSelect = useCallback6(() => {
    if (decreaseFontSize.enabled()) {
      decreaseFontSize();
    }
  }, [decreaseFontSize]);
  const enabled = decreaseFontSize.enabled();
  return /* @__PURE__ */ React8.createElement(
    CommandButton,
    {
      ...props,
      commandName: "decreaseFontSize",
      enabled,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/decrease-indent-button.tsx
import React9, { useCallback as useCallback7 } from "react";
import { useCommands as useCommands6 } from "@remirror/react-core";
var DecreaseIndentButton = (props) => {
  const { decreaseIndent } = useCommands6();
  const handleSelect = useCallback7(() => {
    if (decreaseIndent.enabled()) {
      decreaseIndent();
    }
  }, [decreaseIndent]);
  const enabled = decreaseIndent.enabled();
  return /* @__PURE__ */ React9.createElement(
    CommandButton,
    {
      ...props,
      commandName: "decreaseIndent",
      enabled,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/dropdown-button.tsx
import { IconButton, Menu, Tooltip as Tooltip2 } from "@mui/material";
import React10, { useCallback as useCallback8, useRef, useState } from "react";
import { isString as isString4, uniqueId } from "@remirror/core";
var ButtonIcon = ({ icon }) => {
  if (isString4(icon)) {
    return /* @__PURE__ */ React10.createElement(Icon, { name: icon, size: "1rem" });
  }
  return icon;
};
var DropdownButton = ({
  label,
  "aria-label": ariaLabel,
  icon,
  children,
  onClose,
  ...rest
}) => {
  const id = useRef(uniqueId());
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMouseDown = useCallback8((e) => {
    e.preventDefault();
  }, []);
  const handleClick = useCallback8((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback8(
    (e, reason) => {
      setAnchorEl(null);
      onClose == null ? void 0 : onClose(e, reason);
    },
    [onClose]
  );
  return /* @__PURE__ */ React10.createElement(React10.Fragment, null, /* @__PURE__ */ React10.createElement(Tooltip2, { title: label ?? ariaLabel }, /* @__PURE__ */ React10.createElement(
    IconButton,
    {
      "aria-label": ariaLabel,
      "aria-controls": open ? id.current : void 0,
      "aria-haspopup": true,
      "aria-expanded": open ? "true" : void 0,
      onMouseDown: handleMouseDown,
      onClick: handleClick,
      size: "small",
      sx: (theme) => ({
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: `${theme.shape.borderRadius}px`,
        padding: "6px 12px",
        "&:not(:first-of-type)": {
          marginLeft: "-1px",
          borderLeft: "1px solid transparent",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0
        },
        "&:not(:last-of-type)": {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0
        }
      })
    },
    icon && /* @__PURE__ */ React10.createElement(ButtonIcon, { icon }),
    /* @__PURE__ */ React10.createElement(Icon, { name: "arrowDownSFill", size: "1rem" })
  )), /* @__PURE__ */ React10.createElement(Menu, { ...rest, id: id.current, anchorEl, open, onClose: handleClose }, children));
};

// packages/remirror__react-components/src/buttons/increase-font-size-button.tsx
import React11, { useCallback as useCallback9 } from "react";
import { useCommands as useCommands7 } from "@remirror/react-core";
var IncreaseFontSizeButton = (props) => {
  const { increaseFontSize } = useCommands7();
  const handleSelect = useCallback9(() => {
    if (increaseFontSize.enabled()) {
      increaseFontSize();
    }
  }, [increaseFontSize]);
  const enabled = increaseFontSize.enabled();
  return /* @__PURE__ */ React11.createElement(
    CommandButton,
    {
      ...props,
      commandName: "increaseFontSize",
      enabled,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/increase-indent-button.tsx
import React12, { useCallback as useCallback10 } from "react";
import { useCommands as useCommands8 } from "@remirror/react-core";
var IncreaseIndentButton = (props) => {
  const { increaseIndent } = useCommands8();
  const handleSelect = useCallback10(() => {
    if (increaseIndent.enabled()) {
      increaseIndent();
    }
  }, [increaseIndent]);
  const enabled = increaseIndent.enabled();
  return /* @__PURE__ */ React12.createElement(
    CommandButton,
    {
      ...props,
      commandName: "increaseIndent",
      enabled,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/insert-horizontal-rule-button.tsx
import React13, { useCallback as useCallback11 } from "react";
import { useCommands as useCommands9, useCurrentSelection as useCurrentSelection4 } from "@remirror/react-core";
var InsertHorizontalRuleButton = (props) => {
  const { insertHorizontalRule } = useCommands9();
  useCurrentSelection4();
  const handleSelect = useCallback11(() => {
    if (insertHorizontalRule.enabled()) {
      insertHorizontalRule();
    }
  }, [insertHorizontalRule]);
  const enabled = insertHorizontalRule.enabled();
  return /* @__PURE__ */ React13.createElement(
    CommandButton,
    {
      ...props,
      commandName: "insertHorizontalRule",
      enabled,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/justify-align-button.tsx
import React14, { useCallback as useCallback12 } from "react";
import { useCommands as useCommands10, useCurrentSelection as useCurrentSelection5 } from "@remirror/react-core";
var JustifyAlignButton = (props) => {
  var _a;
  const { justifyAlign } = useCommands10();
  useCurrentSelection5();
  const handleSelect = useCallback12(() => {
    if (justifyAlign.enabled()) {
      justifyAlign();
    }
  }, [justifyAlign]);
  const active = (_a = justifyAlign.active) == null ? void 0 : _a.call(justifyAlign);
  const enabled = justifyAlign.enabled();
  return /* @__PURE__ */ React14.createElement(
    CommandButton,
    {
      ...props,
      commandName: "justifyAlign",
      active,
      enabled,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/left-align-button.tsx
import React15, { useCallback as useCallback13 } from "react";
import { useCommands as useCommands11, useCurrentSelection as useCurrentSelection6 } from "@remirror/react-core";
var LeftAlignButton = (props) => {
  var _a;
  const { leftAlign } = useCommands11();
  useCurrentSelection6();
  const handleSelect = useCallback13(() => {
    if (leftAlign.enabled()) {
      leftAlign();
    }
  }, [leftAlign]);
  const active = (_a = leftAlign.active) == null ? void 0 : _a.call(leftAlign);
  const enabled = leftAlign.enabled();
  return /* @__PURE__ */ React15.createElement(
    CommandButton,
    {
      ...props,
      commandName: "leftAlign",
      active,
      enabled,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/paste-button.tsx
import React16, { useCallback as useCallback14 } from "react";
import { useCommands as useCommands12, useEditorState } from "@remirror/react-core";
var PasteButton = (props) => {
  const { paste } = useCommands12();
  useEditorState();
  const handleSelect = useCallback14(() => {
    if (paste.enabled()) {
      paste();
    }
  }, [paste]);
  const enabled = paste.enabled();
  return /* @__PURE__ */ React16.createElement(
    CommandButton,
    {
      ...props,
      commandName: "paste",
      active: false,
      enabled,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/redo-button.tsx
import React17, { useCallback as useCallback15 } from "react";
import { useCommands as useCommands13, useHelpers as useHelpers2 } from "@remirror/react-core";
var RedoButton = (props) => {
  const { redo } = useCommands13();
  const { redoDepth } = useHelpers2(true);
  const handleSelect = useCallback15(() => {
    if (redo.enabled()) {
      redo();
    }
  }, [redo]);
  const enabled = redoDepth() > 0;
  return /* @__PURE__ */ React17.createElement(
    CommandButton,
    {
      ...props,
      commandName: "redo",
      active: false,
      enabled,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/right-align-button.tsx
import React18, { useCallback as useCallback16 } from "react";
import { useCommands as useCommands14, useCurrentSelection as useCurrentSelection7 } from "@remirror/react-core";
var RightAlignButton = (props) => {
  var _a;
  const { rightAlign } = useCommands14();
  useCurrentSelection7();
  const handleSelect = useCallback16(() => {
    if (rightAlign.enabled()) {
      rightAlign();
    }
  }, [rightAlign]);
  const active = (_a = rightAlign.active) == null ? void 0 : _a.call(rightAlign);
  const enabled = rightAlign.enabled();
  return /* @__PURE__ */ React18.createElement(
    CommandButton,
    {
      ...props,
      commandName: "rightAlign",
      active,
      enabled,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/toggle-blockquote-button.tsx
import React19, { useCallback as useCallback17 } from "react";
import { useActive as useActive2, useCommands as useCommands15 } from "@remirror/react-core";
var ToggleBlockquoteButton = (props) => {
  const { toggleBlockquote } = useCommands15();
  const handleSelect = useCallback17(() => {
    if (toggleBlockquote.enabled()) {
      toggleBlockquote();
    }
  }, [toggleBlockquote]);
  const active = useActive2().blockquote();
  const enabled = toggleBlockquote.enabled();
  return /* @__PURE__ */ React19.createElement(
    CommandButton,
    {
      ...props,
      commandName: "toggleBlockquote",
      active,
      enabled,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/toggle-bold-button.tsx
import React20, { useCallback as useCallback18 } from "react";
import { useActive as useActive3, useCommands as useCommands16 } from "@remirror/react-core";
var ToggleBoldButton = (props) => {
  const { toggleBold } = useCommands16();
  const handleSelect = useCallback18(() => {
    if (toggleBold.enabled()) {
      toggleBold();
    }
  }, [toggleBold]);
  const active = useActive3().bold();
  const enabled = toggleBold.enabled();
  return /* @__PURE__ */ React20.createElement(
    CommandButton,
    {
      ...props,
      commandName: "toggleBold",
      active,
      enabled,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/toggle-bullet-list-button.tsx
import React21, { useCallback as useCallback19 } from "react";
import { useActive as useActive4, useCommands as useCommands17 } from "@remirror/react-core";
var ToggleBulletListButton = (props) => {
  const { toggleBulletList } = useCommands17();
  const handleSelect = useCallback19(() => {
    if (toggleBulletList.enabled()) {
      toggleBulletList();
    }
  }, [toggleBulletList]);
  const active = useActive4().bulletList();
  const enabled = toggleBulletList.enabled();
  return /* @__PURE__ */ React21.createElement(
    CommandButton,
    {
      ...props,
      commandName: "toggleBulletList",
      active,
      enabled,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/toggle-callout-button.tsx
import React22, { useCallback as useCallback20 } from "react";
import { useActive as useActive5, useCommands as useCommands18 } from "@remirror/react-core";
var ToggleCalloutButton = ({ attrs = {}, ...rest }) => {
  const { toggleCallout } = useCommands18();
  const handleSelect = useCallback20(() => {
    if (toggleCallout.enabled(attrs)) {
      toggleCallout(attrs);
    }
  }, [toggleCallout, attrs]);
  const active = useActive5().callout(attrs);
  const enabled = toggleCallout.enabled(attrs);
  return /* @__PURE__ */ React22.createElement(
    CommandButton,
    {
      ...rest,
      commandName: "toggleCallout",
      active,
      enabled,
      attrs,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/toggle-code-block-button.tsx
import React23, { useCallback as useCallback21 } from "react";
import { useActive as useActive6, useCommands as useCommands19 } from "@remirror/react-core";
var ToggleCodeBlockButton = ({ attrs = {}, ...rest }) => {
  const { toggleCodeBlock } = useCommands19();
  const handleSelect = useCallback21(() => {
    if (toggleCodeBlock.enabled(attrs)) {
      toggleCodeBlock(attrs);
    }
  }, [toggleCodeBlock, attrs]);
  const active = useActive6().codeBlock();
  const enabled = toggleCodeBlock.enabled(attrs);
  return /* @__PURE__ */ React23.createElement(
    CommandButton,
    {
      ...rest,
      commandName: "toggleCodeBlock",
      active,
      enabled,
      attrs,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/toggle-code-button.tsx
import React24, { useCallback as useCallback22 } from "react";
import { useActive as useActive7, useCommands as useCommands20 } from "@remirror/react-core";
var ToggleCodeButton = (props) => {
  const { toggleCode } = useCommands20();
  const handleSelect = useCallback22(() => {
    if (toggleCode.enabled()) {
      toggleCode();
    }
  }, [toggleCode]);
  const active = useActive7().code();
  const enabled = toggleCode.enabled();
  return /* @__PURE__ */ React24.createElement(
    CommandButton,
    {
      ...props,
      commandName: "toggleCode",
      active,
      enabled,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/toggle-columns-button.tsx
import React25, { useCallback as useCallback23 } from "react";
import { useActive as useActive8, useCommands as useCommands21 } from "@remirror/react-core";
var ToggleColumnsButton = ({ attrs = {}, ...rest }) => {
  const { toggleColumns } = useCommands21();
  const handleSelect = useCallback23(() => {
    if (toggleColumns.enabled(attrs)) {
      toggleColumns(attrs);
    }
  }, [toggleColumns, attrs]);
  const active = useActive8().columns(attrs);
  const enabled = toggleColumns.enabled(attrs);
  return /* @__PURE__ */ React25.createElement(
    CommandButton,
    {
      ...rest,
      commandName: "toggleColumns",
      active,
      enabled,
      attrs,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/toggle-heading-button.tsx
import React26, { useCallback as useCallback24 } from "react";
import { useActive as useActive9, useCommands as useCommands22 } from "@remirror/react-core";
var ToggleHeadingButton = ({ attrs, ...rest }) => {
  const { toggleHeading } = useCommands22();
  const handleSelect = useCallback24(() => {
    if (toggleHeading.enabled(attrs)) {
      toggleHeading(attrs);
    }
  }, [toggleHeading, attrs]);
  const active = useActive9().heading(attrs);
  const enabled = toggleHeading.enabled(attrs);
  return /* @__PURE__ */ React26.createElement(
    CommandButton,
    {
      ...rest,
      commandName: "toggleHeading",
      active,
      enabled,
      attrs,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/toggle-italic-button.tsx
import React27, { useCallback as useCallback25 } from "react";
import { useActive as useActive10, useCommands as useCommands23 } from "@remirror/react-core";
var ToggleItalicButton = (props) => {
  const { toggleItalic } = useCommands23();
  const handleSelect = useCallback25(() => {
    if (toggleItalic.enabled()) {
      toggleItalic();
    }
  }, [toggleItalic]);
  const active = useActive10().italic();
  const enabled = toggleItalic.enabled();
  return /* @__PURE__ */ React27.createElement(
    CommandButton,
    {
      ...props,
      commandName: "toggleItalic",
      active,
      enabled,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/toggle-ordered-list-button.tsx
import React28, { useCallback as useCallback26 } from "react";
import { useActive as useActive11, useCommands as useCommands24 } from "@remirror/react-core";
var ToggleOrderedListButton = (props) => {
  const { toggleOrderedList } = useCommands24();
  const handleSelect = useCallback26(() => {
    if (toggleOrderedList.enabled()) {
      toggleOrderedList();
    }
  }, [toggleOrderedList]);
  const active = useActive11().orderedList();
  const enabled = toggleOrderedList.enabled();
  return /* @__PURE__ */ React28.createElement(
    CommandButton,
    {
      ...props,
      commandName: "toggleOrderedList",
      active,
      enabled,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/toggle-strike-button.tsx
import React29, { useCallback as useCallback27 } from "react";
import { useActive as useActive12, useCommands as useCommands25 } from "@remirror/react-core";
var ToggleStrikeButton = (props) => {
  const { toggleStrike } = useCommands25();
  const handleSelect = useCallback27(() => {
    if (toggleStrike.enabled()) {
      toggleStrike();
    }
  }, [toggleStrike]);
  const active = useActive12().strike();
  const enabled = toggleStrike.enabled();
  return /* @__PURE__ */ React29.createElement(
    CommandButton,
    {
      ...props,
      commandName: "toggleStrike",
      active,
      enabled,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/toggle-subscript-button.tsx
import React30, { useCallback as useCallback28 } from "react";
import { useActive as useActive13, useCommands as useCommands26 } from "@remirror/react-core";
var ToggleSubscriptButton = (props) => {
  const { toggleSubscript } = useCommands26();
  const handleSelect = useCallback28(() => {
    if (toggleSubscript.enabled()) {
      toggleSubscript();
    }
  }, [toggleSubscript]);
  const active = useActive13().sub();
  const enabled = toggleSubscript.enabled();
  return /* @__PURE__ */ React30.createElement(
    CommandButton,
    {
      ...props,
      commandName: "toggleSubscript",
      active,
      enabled,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/toggle-superscript-button.tsx
import React31, { useCallback as useCallback29 } from "react";
import { useActive as useActive14, useCommands as useCommands27 } from "@remirror/react-core";
var ToggleSuperscriptButton = (props) => {
  const { toggleSuperscript } = useCommands27();
  const handleSelect = useCallback29(() => {
    if (toggleSuperscript.enabled()) {
      toggleSuperscript();
    }
  }, [toggleSuperscript]);
  const active = useActive14().sup();
  const enabled = toggleSuperscript.enabled();
  return /* @__PURE__ */ React31.createElement(
    CommandButton,
    {
      ...props,
      commandName: "toggleSuperscript",
      active,
      enabled,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/toggle-task-list-button.tsx
import React32, { useCallback as useCallback30 } from "react";
import { useActive as useActive15, useCommands as useCommands28 } from "@remirror/react-core";
var ToggleTaskListButton = (props) => {
  const { toggleTaskList } = useCommands28();
  const handleSelect = useCallback30(() => {
    if (toggleTaskList.enabled()) {
      toggleTaskList();
    }
  }, [toggleTaskList]);
  const active = useActive15().taskList();
  const enabled = toggleTaskList.enabled();
  return /* @__PURE__ */ React32.createElement(
    CommandButton,
    {
      ...props,
      commandName: "toggleTaskList",
      active,
      enabled,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/toggle-underline-button.tsx
import React33, { useCallback as useCallback31 } from "react";
import { useActive as useActive16, useCommands as useCommands29 } from "@remirror/react-core";
var ToggleUnderlineButton = (props) => {
  const { toggleUnderline } = useCommands29();
  const handleSelect = useCallback31(() => {
    if (toggleUnderline.enabled()) {
      toggleUnderline();
    }
  }, [toggleUnderline]);
  const active = useActive16().underline();
  const enabled = toggleUnderline.enabled();
  return /* @__PURE__ */ React33.createElement(
    CommandButton,
    {
      ...props,
      commandName: "toggleUnderline",
      active,
      enabled,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/toggle-whitespace-button.tsx
import React34, { useCallback as useCallback32 } from "react";
import { useCommands as useCommands30, useHelpers as useHelpers3 } from "@remirror/react-core";
var ToggleWhitespaceButton = (props) => {
  const { toggleWhitespace } = useCommands30();
  const { isWhitespaceVisible } = useHelpers3(true);
  const handleSelect = useCallback32(() => {
    if (toggleWhitespace.enabled()) {
      toggleWhitespace();
    }
  }, [toggleWhitespace]);
  const active = isWhitespaceVisible();
  const enabled = toggleWhitespace.enabled();
  return /* @__PURE__ */ React34.createElement(
    CommandButton,
    {
      ...props,
      commandName: "toggleWhitespace",
      active,
      enabled,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/buttons/undo-button.tsx
import React35, { useCallback as useCallback33 } from "react";
import { useCommands as useCommands31, useHelpers as useHelpers4 } from "@remirror/react-core";
var UndoButton = (props) => {
  const { undo } = useCommands31();
  const { undoDepth } = useHelpers4(true);
  const handleSelect = useCallback33(() => {
    if (undo.enabled()) {
      undo();
    }
  }, [undo]);
  const enabled = undoDepth() > 0;
  return /* @__PURE__ */ React35.createElement(
    CommandButton,
    {
      ...props,
      commandName: "undo",
      active: false,
      enabled,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/button-groups/command-button-group.tsx
import { Box as Box2 } from "@mui/material";
import React36 from "react";
var CommandButtonGroup = (props) => {
  return /* @__PURE__ */ React36.createElement(
    Box2,
    {
      sx: {
        display: "flex",
        alignItems: "center",
        width: "fit-content",
        bgcolor: "background.paper",
        color: "text.secondary"
      },
      ...props
    }
  );
};

// packages/remirror__react-components/src/button-groups/baseline-button-group.tsx
var BaselineButtonGroup = ({ children }) => {
  return /* @__PURE__ */ React37.createElement(CommandButtonGroup, null, /* @__PURE__ */ React37.createElement(ToggleSubscriptButton, null), /* @__PURE__ */ React37.createElement(ToggleSuperscriptButton, null), children);
};

// packages/remirror__react-components/src/button-groups/basic-formatting-button-group.tsx
import React38 from "react";
var BasicFormattingButtonGroup = ({ children }) => {
  return /* @__PURE__ */ React38.createElement(CommandButtonGroup, null, /* @__PURE__ */ React38.createElement(ToggleBoldButton, null), /* @__PURE__ */ React38.createElement(ToggleItalicButton, null), /* @__PURE__ */ React38.createElement(ToggleUnderlineButton, null), children);
};

// packages/remirror__react-components/src/button-groups/callout-type-button-group.tsx
import React39 from "react";
var INFO_CALLOUT = { type: "info" };
var WARNING_CALLOUT = { type: "warning" };
var ERROR_CALLOUT = { type: "error" };
var SUCCESS_CALLOUT = { type: "success" };
var CalloutTypeButtonGroup = ({ children }) => {
  return /* @__PURE__ */ React39.createElement(CommandButtonGroup, null, /* @__PURE__ */ React39.createElement(ToggleCalloutButton, { attrs: INFO_CALLOUT }), /* @__PURE__ */ React39.createElement(ToggleCalloutButton, { attrs: WARNING_CALLOUT }), /* @__PURE__ */ React39.createElement(ToggleCalloutButton, { attrs: ERROR_CALLOUT }), /* @__PURE__ */ React39.createElement(ToggleCalloutButton, { attrs: SUCCESS_CALLOUT }), children);
};

// packages/remirror__react-components/src/button-groups/data-transfer-button-group.tsx
import React40 from "react";
var DataTransferButtonGroup = ({ children }) => {
  return /* @__PURE__ */ React40.createElement(CommandButtonGroup, null, /* @__PURE__ */ React40.createElement(CopyButton, null), /* @__PURE__ */ React40.createElement(CutButton, null), /* @__PURE__ */ React40.createElement(PasteButton, null), children);
};

// packages/remirror__react-components/src/button-groups/formatting-button-group.tsx
import React41 from "react";
var FormattingButtonGroup = ({ children }) => {
  return /* @__PURE__ */ React41.createElement(CommandButtonGroup, null, /* @__PURE__ */ React41.createElement(ToggleBoldButton, null), /* @__PURE__ */ React41.createElement(ToggleItalicButton, null), /* @__PURE__ */ React41.createElement(ToggleUnderlineButton, null), /* @__PURE__ */ React41.createElement(ToggleStrikeButton, null), /* @__PURE__ */ React41.createElement(ToggleCodeButton, null), children);
};

// packages/remirror__react-components/src/button-groups/heading-level-button-group.tsx
import React45 from "react";

// packages/remirror__react-components/src/menus/command-menu-item.tsx
import { ListItemIcon, ListItemText, MenuItem, Typography } from "@mui/material";
import React42, { useCallback as useCallback34 } from "react";
import { isString as isString5 } from "@remirror/core";
var MenuItemIcon = ({ icon }) => {
  if (icon) {
    return /* @__PURE__ */ React42.createElement(ListItemIcon, null, isString5(icon) ? /* @__PURE__ */ React42.createElement(Icon, { name: icon, size: "1rem" }) : /* @__PURE__ */ React42.createElement(React42.Fragment, null, icon));
  }
  return null;
};
var CommandMenuItem = ({
  commandName,
  active = false,
  enabled,
  attrs,
  onSelect,
  onClick,
  icon,
  displayShortcut = true,
  label,
  description,
  displayDescription = true,
  ...rest
}) => {
  const handleClick = useCallback34(
    (e) => {
      onSelect();
      onClick == null ? void 0 : onClick(e);
    },
    [onSelect, onClick]
  );
  const handleMouseDown = useCallback34((e) => {
    e.preventDefault();
  }, []);
  const commandOptions = useCommandOptionValues({ commandName, active, enabled, attrs });
  let fallbackIcon = null;
  if (commandOptions.icon) {
    fallbackIcon = isString5(commandOptions.icon) ? commandOptions.icon : commandOptions.icon.name;
  }
  const primary = label ?? commandOptions.label ?? "";
  const secondary = displayDescription && (description ?? commandOptions.description);
  return /* @__PURE__ */ React42.createElement(
    MenuItem,
    {
      selected: active,
      disabled: !enabled,
      onMouseDown: handleMouseDown,
      ...rest,
      onClick: handleClick
    },
    icon !== null && /* @__PURE__ */ React42.createElement(MenuItemIcon, { icon: icon ?? fallbackIcon }),
    /* @__PURE__ */ React42.createElement(ListItemText, { primary, secondary }),
    displayShortcut && commandOptions.shortcut && /* @__PURE__ */ React42.createElement(Typography, { variant: "body2", color: "text.secondary", sx: { ml: 2 } }, commandOptions.shortcut)
  );
};

// packages/remirror__react-components/src/menus/toggle-callout-menu-item.tsx
import React43, { useCallback as useCallback35 } from "react";
import { useActive as useActive17, useCommands as useCommands32 } from "@remirror/react-core";
var ToggleCalloutMenuItem = ({ attrs, ...rest }) => {
  const { toggleCallout } = useCommands32();
  const handleSelect = useCallback35(() => {
    if (toggleCallout.enabled(attrs)) {
      toggleCallout(attrs);
    }
  }, [toggleCallout, attrs]);
  const active = useActive17().callout(attrs);
  const enabled = toggleCallout.enabled(attrs);
  return /* @__PURE__ */ React43.createElement(
    CommandMenuItem,
    {
      ...rest,
      commandName: "toggleCallout",
      active,
      enabled,
      attrs,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/menus/toggle-heading-menu-item.tsx
import React44, { useCallback as useCallback36 } from "react";
import { useActive as useActive18, useCommands as useCommands33 } from "@remirror/react-core";
var ToggleHeadingMenuItem = ({ attrs, ...rest }) => {
  const { toggleHeading } = useCommands33();
  const handleSelect = useCallback36(() => {
    if (toggleHeading.enabled(attrs)) {
      toggleHeading(attrs);
    }
  }, [toggleHeading, attrs]);
  const active = useActive18().heading(attrs);
  const enabled = toggleHeading.enabled(attrs);
  return /* @__PURE__ */ React44.createElement(
    CommandMenuItem,
    {
      ...rest,
      commandName: "toggleHeading",
      active,
      enabled,
      attrs,
      onSelect: handleSelect
    }
  );
};

// packages/remirror__react-components/src/button-groups/heading-level-button-group.tsx
var LEVEL_1 = { level: 1 };
var LEVEL_2 = { level: 2 };
var LEVEL_3 = { level: 3 };
var LEVEL_4 = { level: 4 };
var LEVEL_5 = { level: 5 };
var LEVEL_6 = { level: 6 };
var HeadingLevelButtonGroup = ({
  showAll = false,
  children
}) => {
  return /* @__PURE__ */ React45.createElement(CommandButtonGroup, null, /* @__PURE__ */ React45.createElement(ToggleHeadingButton, { attrs: LEVEL_1 }), /* @__PURE__ */ React45.createElement(ToggleHeadingButton, { attrs: LEVEL_2 }), !showAll ? /* @__PURE__ */ React45.createElement(ToggleHeadingButton, { attrs: LEVEL_3 }) : /* @__PURE__ */ React45.createElement(DropdownButton, { "aria-label": "More heading options" }, /* @__PURE__ */ React45.createElement(ToggleHeadingMenuItem, { attrs: LEVEL_3 }), /* @__PURE__ */ React45.createElement(ToggleHeadingMenuItem, { attrs: LEVEL_4 }), /* @__PURE__ */ React45.createElement(ToggleHeadingMenuItem, { attrs: LEVEL_5 }), /* @__PURE__ */ React45.createElement(ToggleHeadingMenuItem, { attrs: LEVEL_6 })), children);
};

// packages/remirror__react-components/src/button-groups/history-button-group.tsx
import React46 from "react";
var HistoryButtonGroup = ({ children }) => {
  return /* @__PURE__ */ React46.createElement(CommandButtonGroup, null, /* @__PURE__ */ React46.createElement(UndoButton, null), /* @__PURE__ */ React46.createElement(RedoButton, null), children);
};

// packages/remirror__react-components/src/button-groups/indentation-button-group.tsx
import React47 from "react";
var IndentationButtonGroup = ({ children }) => {
  return /* @__PURE__ */ React47.createElement(CommandButtonGroup, null, /* @__PURE__ */ React47.createElement(DecreaseIndentButton, null), /* @__PURE__ */ React47.createElement(IncreaseIndentButton, null), children);
};

// packages/remirror__react-components/src/button-groups/list-button-group.tsx
import React49 from "react";
import { TaskListExtension } from "@remirror/extension-list";

// packages/remirror__react-components/src/if-extension-present.tsx
import React48 from "react";
import { useHasExtension } from "@remirror/react-core";
var IfExtensionPresent = ({ children, extension }) => {
  const hasExtension = useHasExtension(extension);
  return hasExtension ? /* @__PURE__ */ React48.createElement(React48.Fragment, null, children) : null;
};

// packages/remirror__react-components/src/button-groups/list-button-group.tsx
var ListButtonGroup = ({ children }) => {
  return /* @__PURE__ */ React49.createElement(CommandButtonGroup, null, /* @__PURE__ */ React49.createElement(ToggleBulletListButton, null), /* @__PURE__ */ React49.createElement(ToggleOrderedListButton, null), /* @__PURE__ */ React49.createElement(IfExtensionPresent, { extension: TaskListExtension }, /* @__PURE__ */ React49.createElement(ToggleTaskListButton, null)), children);
};

// packages/remirror__react-components/src/button-groups/text-alignment-button-group.tsx
import React50 from "react";
var TextAlignmentButtonGroup = ({
  showAll = false,
  children
}) => {
  return /* @__PURE__ */ React50.createElement(CommandButtonGroup, null, /* @__PURE__ */ React50.createElement(LeftAlignButton, null), /* @__PURE__ */ React50.createElement(CenterAlignButton, null), /* @__PURE__ */ React50.createElement(RightAlignButton, null), showAll && /* @__PURE__ */ React50.createElement(JustifyAlignButton, null), children);
};

// packages/remirror__react-components/src/find-replace/find-replace-component.tsx
import { Box as Box3 } from "@mui/material";
import React56 from "react";

// packages/remirror__react-components/src/find-replace/find-controller.tsx
import { IconButton as IconButton2 } from "@mui/material";
import React52 from "react";

// packages/remirror__react-components/src/find-replace/letter-icon.tsx
import React51 from "react";
var MdiFormatLetterCase = (props) => {
  return /* @__PURE__ */ React51.createElement("svg", { width: "1em", height: "1em", viewBox: "0 0 24 24", ...props }, /* @__PURE__ */ React51.createElement(
    "path",
    {
      fill: "currentColor",
      d: "M20.06 18a3.99 3.99 0 0 1-.2-.89c-.67.7-1.48 1.05-2.41 1.05c-.83 0-1.52-.24-2.05-.71c-.53-.45-.8-1.06-.8-1.79c0-.88.33-1.56 1-2.05c.67-.49 1.61-.73 2.83-.73h1.4v-.64c0-.49-.15-.88-.45-1.17c-.3-.29-.75-.43-1.33-.43c-.52 0-.95.12-1.3.36c-.35.25-.52.54-.52.89h-1.46c0-.43.15-.84.45-1.24c.28-.4.71-.71 1.22-.94c.51-.21 1.06-.35 1.69-.35c.98 0 1.74.24 2.29.73s.84 1.16.86 2.02V16c0 .8.1 1.42.3 1.88V18h-1.52m-2.4-1.12c.45 0 .88-.11 1.29-.32c.4-.21.7-.49.88-.83v-1.57H18.7c-1.77 0-2.66.47-2.66 1.41c0 .43.15.73.46.96c.3.23.68.35 1.16.35m-12.2-3.17h4.07L7.5 8.29l-2.04 5.42M6.64 6h1.72l4.71 12h-1.93l-.97-2.57H4.82L3.86 18H1.93L6.64 6Z"
    }
  ));
};

// packages/remirror__react-components/src/find-replace/find-controller.tsx
var FindController = ({ findPrev, findNext, stopFind, caseSensitive, toggleCaseSensitive, onDismiss }) => {
  return /* @__PURE__ */ React52.createElement(React52.Fragment, null, /* @__PURE__ */ React52.createElement(
    IconButton2,
    {
      onClick: findPrev,
      size: "small",
      title: "Next Match (Enter)",
      "aria-label": "Next Match (Enter)"
    },
    /* @__PURE__ */ React52.createElement(Icon, { name: "arrowLeftSFill" })
  ), /* @__PURE__ */ React52.createElement(
    IconButton2,
    {
      onClick: findNext,
      size: "small",
      title: "Previous Match (Shift+Enter)",
      "aria-label": "Previous Match (Shift+Enter)"
    },
    /* @__PURE__ */ React52.createElement(Icon, { name: "arrowRightSFill" })
  ), /* @__PURE__ */ React52.createElement(
    IconButton2,
    {
      onClick: toggleCaseSensitive,
      size: "small",
      color: caseSensitive ? "primary" : "default",
      title: "Match Case",
      "aria-label": "Match Case"
    },
    /* @__PURE__ */ React52.createElement(MdiFormatLetterCase, null)
  ), /* @__PURE__ */ React52.createElement(
    IconButton2,
    {
      onClick: () => {
        stopFind();
        onDismiss == null ? void 0 : onDismiss();
      },
      size: "small"
    },
    /* @__PURE__ */ React52.createElement(Icon, { name: "closeFill" })
  ));
};

// packages/remirror__react-components/src/find-replace/find-input.tsx
import { InputAdornment, OutlinedInput } from "@mui/material";
import React53 from "react";
var FindInput = ({ query, setQuery, total, activeIndex }) => {
  const counterLabel = `${total && activeIndex != null ? activeIndex + 1 : 0} of ${total}`;
  return /* @__PURE__ */ React53.createElement(
    OutlinedInput,
    {
      fullWidth: true,
      margin: "none",
      placeholder: "Find",
      value: query,
      onChange: (event) => setQuery(event.target.value),
      sx: {
        "& input": {
          paddingTop: "4px",
          paddingBottom: "4px"
        }
      },
      size: "small",
      inputProps: { "aria-label": "Find" },
      endAdornment: /* @__PURE__ */ React53.createElement(InputAdornment, { position: "end" }, counterLabel)
    }
  );
};

// packages/remirror__react-components/src/find-replace/replace-controller.tsx
import { Button, ButtonGroup } from "@mui/material";
import React54 from "react";
var ReplaceController = ({ replace, replaceAll }) => {
  return /* @__PURE__ */ React54.createElement(ButtonGroup, { variant: "outlined", size: "small" }, /* @__PURE__ */ React54.createElement(Button, { "aria-label": "Relace", sx: { textTransform: "none" }, onClick: replace }, "Replace"), /* @__PURE__ */ React54.createElement(Button, { "aria-label": "Relace all", sx: { textTransform: "none" }, onClick: replaceAll }, "All"));
};

// packages/remirror__react-components/src/find-replace/replace-input.tsx
import { OutlinedInput as OutlinedInput2 } from "@mui/material";
import React55 from "react";
var ReplaceInput = ({ replacement, setReplacement }) => {
  return /* @__PURE__ */ React55.createElement(
    OutlinedInput2,
    {
      fullWidth: true,
      margin: "none",
      placeholder: "Replace",
      value: replacement,
      onChange: (event) => setReplacement(event.target.value),
      sx: {
        "& input": {
          paddingTop: "4px",
          paddingBottom: "4px"
        }
      },
      size: "small",
      inputProps: { "aria-label": "Replace" }
    }
  );
};

// packages/remirror__react-components/src/find-replace/use-find-replace.tsx
import { useCallback as useCallback37, useEffect, useState as useState2 } from "react";
import { useCommands as useCommands34, useHelpers as useHelpers5 } from "@remirror/react-core";
function initialState() {
  return {
    query: "",
    replacement: "",
    activeIndex: null,
    total: 0,
    caseSensitive: false
  };
}
function useFindReplace() {
  const helpers = useHelpers5();
  const commands = useCommands34();
  const [state, setState] = useState2(initialState);
  const find = useCallback37(
    (indexDiff = 0) => {
      setState((state2) => {
        const { query, caseSensitive, activeIndex } = state2;
        const result = helpers.findRanges({
          query,
          caseSensitive,
          activeIndex: activeIndex == null ? 0 : activeIndex + indexDiff
        });
        return { ...state2, total: result.ranges.length, activeIndex: result.activeIndex ?? 0 };
      });
    },
    [helpers]
  );
  const findNext = useCallback37(() => find(1), [find]);
  const findPrev = useCallback37(() => find(-1), [find]);
  const stopFind = useCallback37(() => {
    setState(initialState());
    commands.stopFind();
  }, [commands]);
  const replace = useCallback37(() => {
    const { query, replacement, caseSensitive, activeIndex } = state;
    commands.findAndReplace({ query, replacement, caseSensitive, index: activeIndex ?? void 0 });
    const isQuerySubsetOfReplacement = caseSensitive ? replacement.includes(query) : replacement.toLowerCase().includes(query.toLowerCase());
    if (isQuerySubsetOfReplacement) {
      findNext();
    } else {
      find();
    }
  }, [state, commands, findNext, find]);
  const replaceAll = useCallback37(() => {
    const { query, replacement, caseSensitive } = state;
    commands.findAndReplaceAll({ query, replacement, caseSensitive });
    find();
  }, [commands, state, find]);
  const toggleCaseSensitive = useCallback37(() => {
    setState((state2) => ({ ...state2, caseSensitive: !state2.caseSensitive }));
  }, []);
  const setQuery = useCallback37((query) => {
    setState((state2) => ({ ...state2, query }));
  }, []);
  const setReplacement = useCallback37((replacement) => {
    setState((state2) => ({ ...state2, replacement }));
  }, []);
  useEffect(() => {
    find();
  }, [find, commands, state.query, state.caseSensitive]);
  return {
    ...state,
    findNext,
    findPrev,
    stopFind,
    replace,
    replaceAll,
    toggleCaseSensitive,
    setQuery,
    setReplacement
  };
}

// packages/remirror__react-components/src/find-replace/find-replace-component.tsx
var FindReplaceComponent = ({ onDismiss }) => {
  const {
    query,
    setQuery,
    activeIndex,
    total,
    caseSensitive,
    replacement,
    setReplacement,
    toggleCaseSensitive,
    findNext,
    findPrev,
    stopFind,
    replace,
    replaceAll
  } = useFindReplace();
  return /* @__PURE__ */ React56.createElement(
    Box3,
    {
      sx: {
        display: "grid",
        gridTemplateColumns: "1fr max-content",
        gridTemplateRows: "1fr 1fr",
        rowGap: 1,
        columnGap: 1,
        alignItems: "center"
      }
    },
    /* @__PURE__ */ React56.createElement(Box3, null, /* @__PURE__ */ React56.createElement(FindInput, { query, setQuery, total, activeIndex })),
    /* @__PURE__ */ React56.createElement(Box3, { sx: { justifySelf: "end" } }, /* @__PURE__ */ React56.createElement(
      FindController,
      {
        findPrev,
        findNext,
        toggleCaseSensitive,
        caseSensitive,
        stopFind,
        onDismiss
      }
    )),
    /* @__PURE__ */ React56.createElement(Box3, null, /* @__PURE__ */ React56.createElement(ReplaceInput, { replacement, setReplacement })),
    /* @__PURE__ */ React56.createElement(Box3, { sx: { justifySelf: "end" } }, /* @__PURE__ */ React56.createElement(ReplaceController, { replace, replaceAll }))
  );
};

// packages/remirror__react-components/src/floating-menu.tsx
import React57, { useMemo as useMemo3 } from "react";
import { createPortal } from "react-dom";
import { cx } from "@remirror/core";
import { getPositioner } from "@remirror/extension-positioner";
import { useHelpers as useHelpers6 } from "@remirror/react-core";
import { useEditorFocus, usePositioner } from "@remirror/react-hooks";
import { ComponentsTheme, ExtensionPositionerTheme } from "@remirror/theme";

// packages/remirror__react-components/src/commonjs-packages/seznam-compose-react-refs.ts
import _composeRefs from "@seznam/compose-react-refs";
var composeRefs = typeof _composeRefs === "object" && _composeRefs.__esModule && _composeRefs.default ? _composeRefs.default : _composeRefs;

// packages/remirror__react-components/src/use-isomorphic-layout-effect.ts
import { useEffect as useEffect2, useLayoutEffect } from "react";
var useIsomorphicLayoutEffect = typeof document !== "undefined" ? useLayoutEffect : useEffect2;

// packages/remirror__react-components/src/use-popper.ts
import { createPopper } from "@popperjs/core";
import {
  useCallback as useCallback38,
  useEffect as useEffect3,
  useMemo as useMemo2,
  useRef as useRef2,
  useState as useState3
} from "react";
import { environment, isEqual as isEqual2, isSafari } from "@remirror/core";
var isSafariBrowser = environment.isBrowser && isSafari();
function applyStyles(styles) {
  return (prevStyles) => {
    if (styles && !isEqual2(prevStyles, styles)) {
      return styles;
    }
    return prevStyles;
  };
}
function usePopper(props = {}) {
  const {
    gutter = 12,
    placement: sealedPlacement = "bottom",
    flip = true,
    offset: sealedOffset,
    preventOverflow = true,
    fixed = false,
    visible = false
  } = props;
  const popper = useRef2(null);
  const referenceRef = useRef2();
  const popperRef = useRef2();
  const arrowRef = useRef2();
  const [originalPlacement, place] = useState3(sealedPlacement);
  const [placement, setPlacement] = useState3(sealedPlacement);
  const [offset] = useState3(sealedOffset || [0, gutter]);
  const [popoverStyles, setPopoverStyles] = useState3({
    position: fixed ? "fixed" : "absolute",
    left: "-9999999px",
    top: "-9999999px"
  });
  const [arrowStyles, setArrowStyles] = useState3({});
  const update = useCallback38(() => {
    if (popper.current) {
      popper.current.forceUpdate();
      return true;
    }
    return false;
  }, []);
  const updateState = useCallback38((state) => {
    if (state.placement) {
      setPlacement(state.placement);
    }
    if (state.styles) {
      setPopoverStyles(applyStyles(state.styles.popper));
      if (arrowRef.current) {
        setArrowStyles(applyStyles(state.styles.arrow));
      }
    }
  }, []);
  useIsomorphicLayoutEffect(() => {
    if (referenceRef.current && popperRef.current) {
      popper.current = createPopper(referenceRef.current, popperRef.current, {
        // https://popper.js.org/docs/v2/constructors/#options
        placement: originalPlacement,
        strategy: fixed ? "fixed" : "absolute",
        // Safari needs styles to be applied in the first render, otherwise
        // hovering over the popover when it gets visible for the first time
        // will change its dimensions unexpectedly.
        onFirstUpdate: isSafariBrowser ? updateState : void 0,
        modifiers: [
          {
            // https://popper.js.org/docs/v2/modifiers/event-listeners/
            name: "eventListeners",
            enabled: visible
          },
          {
            // https://popper.js.org/docs/v2/modifiers/apply-styles/
            name: "applyStyles",
            enabled: false
          },
          {
            // https://popper.js.org/docs/v2/modifiers/flip/
            name: "flip",
            enabled: flip,
            options: { padding: 8 }
          },
          {
            // https://popper.js.org/docs/v2/modifiers/offset/
            name: "offset",
            options: { offset }
          },
          {
            // https://popper.js.org/docs/v2/modifiers/prevent-overflow/
            name: "preventOverflow",
            enabled: preventOverflow,
            options: {
              tetherOffset: () => {
                var _a;
                return ((_a = arrowRef.current) == null ? void 0 : _a.clientWidth) || 0;
              }
            }
          },
          {
            // https://popper.js.org/docs/v2/modifiers/arrow/
            name: "arrow",
            enabled: !!arrowRef.current,
            options: { element: arrowRef.current }
          },
          {
            // https://popper.js.org/docs/v2/modifiers/#custom-modifiers
            name: "updateState",
            phase: "write",
            requires: ["computeStyles"],
            enabled: visible,
            fn: ({ state }) => updateState(state)
          }
        ]
      });
    }
    return () => {
      if (popper.current) {
        popper.current.destroy();
        popper.current = null;
      }
    };
  }, [originalPlacement, fixed, visible, flip, offset, preventOverflow, updateState]);
  useEffect3(() => {
    if (!visible) {
      return;
    }
    const id = window.requestAnimationFrame(() => {
      var _a;
      (_a = popper.current) == null ? void 0 : _a.forceUpdate();
    });
    return () => {
      window.cancelAnimationFrame(id);
    };
  }, [visible]);
  return useMemo2(
    () => ({
      referenceRef,
      popperRef,
      arrowRef,
      popoverStyles,
      arrowStyles,
      update,
      originalPlacement,
      placement,
      place
    }),
    [arrowStyles, originalPlacement, placement, popoverStyles, update]
  );
}

// packages/remirror__react-components/src/floating-menu.tsx
function useMemoizedPosition(props) {
  const { height, left, top, width } = props;
  return useMemo3(() => ({ height, left, top, width }), [height, left, top, width]);
}
var FloatingWrapper = (props) => {
  const {
    containerClass,
    placement = "right-end",
    positioner,
    children,
    blurOnInactive = false,
    ignoredElements = [],
    enabled = true,
    floatingLabel,
    hideWhenInvisible = true,
    renderOutsideEditor = false
  } = props;
  const [isFocused] = useEditorFocus({ blurOnInactive, ignoredElements });
  const {
    ref,
    active,
    height,
    x: left,
    y: top,
    width,
    visible
  } = usePositioner(() => {
    const active2 = isFocused && enabled;
    const refinedPositioner = getPositioner(positioner);
    return refinedPositioner.active(active2);
  }, [isFocused, enabled, renderOutsideEditor]);
  const shouldShow = (hideWhenInvisible ? visible : true) && active;
  const position = useMemoizedPosition({ height, left, top, width });
  const { popperRef, referenceRef, popoverStyles, update } = usePopper({
    placement,
    visible
  });
  let floatingElement = /* @__PURE__ */ React57.createElement(
    "div",
    {
      "aria-label": floatingLabel,
      ref: popperRef,
      style: popoverStyles,
      className: cx(ComponentsTheme.FLOATING_POPOVER, containerClass)
    },
    shouldShow && children
  );
  if (!renderOutsideEditor) {
    floatingElement = /* @__PURE__ */ React57.createElement(PositionerPortal, null, floatingElement);
  }
  useIsomorphicLayoutEffect(() => {
    update();
  }, [shouldShow, update, height, left, top, width]);
  return /* @__PURE__ */ React57.createElement(React57.Fragment, null, /* @__PURE__ */ React57.createElement(PositionerPortal, null, /* @__PURE__ */ React57.createElement(
    "span",
    {
      className: ExtensionPositionerTheme.POSITIONER,
      style: {
        top: position.top,
        left: position.left,
        width: position.width,
        height: position.height
      },
      ref: composeRefs(ref, referenceRef)
    }
  )), floatingElement);
};
var PositionerPortal = (props) => {
  const container = useHelpers6().getPositionerWidget();
  return createPortal(/* @__PURE__ */ React57.createElement(React57.Fragment, null, props.children), container);
};

// packages/remirror__react-components/src/popups/emoji-popup-component.tsx
import React58 from "react";
import { cx as cx2 } from "@remirror/core";
import { useEmoji } from "@remirror/react-hooks";
import { ExtensionEmojiTheme } from "@remirror/theme";
var EmojiFromCdn = (props) => {
  return /* @__PURE__ */ React58.createElement(
    "img",
    {
      role: "presentation",
      className: ExtensionEmojiTheme.EMOJI_IMAGE,
      "aria-label": props.annotation,
      alt: props.annotation,
      src: props.url
    }
  );
};
var emptyList = [];
var EmojiPopupComponent = () => {
  const { state, getMenuProps, getItemProps, indexIsHovered, indexIsSelected } = useEmoji();
  const enabled = !!state;
  if (state == null ? void 0 : state.exit) {
    return null;
  }
  return /* @__PURE__ */ React58.createElement(FloatingWrapper, { positioner: "cursor", enabled, placement: "auto-end", renderOutsideEditor: true }, /* @__PURE__ */ React58.createElement("div", { ...getMenuProps(), className: cx2(ExtensionEmojiTheme.EMOJI_POPUP_WRAPPER) }, enabled && ((state == null ? void 0 : state.list) ?? emptyList).map((emoji, index) => {
    var _a;
    const isHighlighted = indexIsSelected(index);
    const isHovered = indexIsHovered(index);
    const shortcode = ((_a = emoji.shortcodes) == null ? void 0 : _a[0]) ?? emoji.annotation;
    return /* @__PURE__ */ React58.createElement(
      "div",
      {
        key: emoji.emoji,
        className: cx2(
          ExtensionEmojiTheme.EMOJI_POPUP_ITEM,
          isHighlighted && ExtensionEmojiTheme.EMOJI_POPUP_HIGHLIGHT,
          isHovered && ExtensionEmojiTheme.EMOJI_POPUP_HOVERED
        ),
        ...getItemProps({
          item: emoji,
          index
        })
      },
      /* @__PURE__ */ React58.createElement("span", { className: ExtensionEmojiTheme.EMOJI_POPUP_CHAR }, /* @__PURE__ */ React58.createElement(EmojiFromCdn, { ...emoji })),
      /* @__PURE__ */ React58.createElement("span", { className: ExtensionEmojiTheme.EMOJI_POPUP_NAME }, ":", shortcode, ":")
    );
  })));
};

// packages/remirror__react-components/src/popups/mention-atom-popup-component.tsx
import React59, { useEffect as useEffect4 } from "react";
import { cx as cx3, isEmptyArray } from "@remirror/core";
import { ReactComponentMessages as Messages } from "@remirror/messages";
import { useCommands as useCommands35, useI18n as useI18n2 } from "@remirror/react-core";
import {
  useMentionAtom
} from "@remirror/react-hooks";
import { ExtensionMentionAtomTheme as Theme } from "@remirror/theme";
function useMentionAtomChangeHandler(props) {
  const { onChange, state } = props;
  useEffect4(() => {
    onChange(state);
  }, [state, onChange]);
}
function MentionAtomPopupComponent(props) {
  const { focus } = useCommands35();
  const {
    onChange,
    ItemComponent = DefaultItemComponent,
    ZeroItemsComponent = DefaultZeroItemsComponent,
    ...hookProps
  } = props;
  const { state, getMenuProps, getItemProps, indexIsHovered, indexIsSelected } = useMentionAtom(hookProps);
  useMentionAtomChangeHandler({ state, onChange });
  return /* @__PURE__ */ React59.createElement(FloatingWrapper, { positioner: "cursor", enabled: !!state, placement: "auto-end", renderOutsideEditor: true }, /* @__PURE__ */ React59.createElement("div", { ...getMenuProps(), className: cx3(Theme.MENTION_ATOM_POPUP_WRAPPER) }, !!state && isEmptyArray(hookProps.items) ? /* @__PURE__ */ React59.createElement(ZeroItemsComponent, null) : hookProps.items.map((item, index) => {
    const isHighlighted = indexIsSelected(index);
    const isHovered = indexIsHovered(index);
    return /* @__PURE__ */ React59.createElement(
      "div",
      {
        key: item.id,
        className: cx3(
          Theme.MENTION_ATOM_POPUP_ITEM,
          isHighlighted && Theme.MENTION_ATOM_POPUP_HIGHLIGHT,
          isHovered && Theme.MENTION_ATOM_POPUP_HOVERED
        ),
        ...getItemProps({
          onClick: () => {
            state == null ? void 0 : state.command(item);
            focus();
          },
          item,
          index
        })
      },
      /* @__PURE__ */ React59.createElement(ItemComponent, { item, state })
    );
  })));
}
function DefaultItemComponent(props) {
  return /* @__PURE__ */ React59.createElement("span", { className: Theme.MENTION_ATOM_POPUP_NAME }, props.item.label);
}
var DefaultZeroItemsComponent = () => {
  const { t } = useI18n2();
  return /* @__PURE__ */ React59.createElement("span", { className: Theme.MENTION_ATOM_ZERO_ITEMS }, t(Messages.NO_ITEMS_AVAILABLE));
};

// packages/remirror__react-components/src/providers/theme-provider.tsx
import { createTheme, ThemeProvider as MuiThemeProvider } from "@mui/material";
import React60, {
  createContext as createContext2,
  useContext,
  useMemo as useMemo4
} from "react";
import { cx as cx4, deepMerge } from "@remirror/core";
import {
  createThemeVariables,
  defaultRemirrorTheme,
  THEME
} from "@remirror/theme";
var ThemeContext = createContext2({});
function useTheme(props = {}) {
  const parent = useContext(ThemeContext);
  const theme = useMemo4(() => deepMerge(parent, props.theme ?? {}), [parent, props.theme]);
  const style = useMemo4(() => createThemeVariables(theme).styles, [theme]);
  const className = cx4(THEME, props.className);
  return useMemo4(() => ({ style, className, theme }), [style, className, theme]);
}
var ThemeProvider = (props) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const { children, as: Component = "div" } = props;
  const { theme, style, className } = useTheme({ theme: props.theme ?? defaultRemirrorTheme });
  const muiTheme = createTheme({
    palette: {
      primary: {
        main: ((_a = theme.color) == null ? void 0 : _a.primary) ?? defaultRemirrorTheme.color.primary,
        dark: ((_c = (_b = theme.color) == null ? void 0 : _b.hover) == null ? void 0 : _c.primary) ?? defaultRemirrorTheme.color.hover.primary,
        contrastText: ((_d = theme.color) == null ? void 0 : _d.primaryText) ?? defaultRemirrorTheme.color.primaryText
      },
      secondary: {
        main: ((_e = theme.color) == null ? void 0 : _e.secondary) ?? defaultRemirrorTheme.color.secondary,
        dark: ((_g = (_f = theme.color) == null ? void 0 : _f.hover) == null ? void 0 : _g.secondary) ?? defaultRemirrorTheme.color.hover.secondary,
        contrastText: ((_h = theme.color) == null ? void 0 : _h.secondaryText) ?? defaultRemirrorTheme.color.secondaryText
      }
    }
  });
  return /* @__PURE__ */ React60.createElement(MuiThemeProvider, { theme: muiTheme }, /* @__PURE__ */ React60.createElement(ThemeContext.Provider, { value: theme }, /* @__PURE__ */ React60.createElement(Component, { style, className }, children)));
};

// packages/remirror__react-components/src/toolbar/base-toolbar.tsx
import { Stack } from "@mui/material";
import React61 from "react";
var Toolbar = (props) => {
  return /* @__PURE__ */ React61.createElement(
    Stack,
    {
      direction: "row",
      spacing: 1,
      sx: { backgroundColor: "background.paper", overflowX: "auto" },
      ...props
    }
  );
};

// packages/remirror__react-components/src/toolbar/floating-toolbar.tsx
import { Popper } from "@mui/material";
import React62, { useCallback as useCallback39, useMemo as useMemo5, useState as useState4 } from "react";
import { getPositioner as getPositioner2 } from "@remirror/extension-positioner";
import { usePositioner as usePositioner2 } from "@remirror/react-hooks";
var DEFAULT_MODIFIERS = [
  {
    name: "offset",
    options: {
      offset: [0, 8]
    }
  }
];
var FloatingToolbar = ({
  positioner = "selection",
  children,
  ...rest
}) => {
  const { ref, x, y, width, height, active } = usePositioner2(() => {
    return getPositioner2(positioner);
  }, [positioner]);
  const [anchorEl, setAnchorEl] = useState4(null);
  const inlineStyle = useMemo5(
    () => ({
      position: "absolute",
      pointerEvents: "none",
      left: x,
      top: y,
      width,
      height
    }),
    [x, y, width, height]
  );
  const combinedSelectionRefs = useCallback39(
    (elem) => {
      setAnchorEl(elem);
      ref == null ? void 0 : ref(elem);
    },
    [ref]
  );
  return /* @__PURE__ */ React62.createElement(React62.Fragment, null, /* @__PURE__ */ React62.createElement("div", { ref: combinedSelectionRefs, style: inlineStyle }), /* @__PURE__ */ React62.createElement(
    Popper,
    {
      placement: "top",
      modifiers: DEFAULT_MODIFIERS,
      ...rest,
      open: active,
      anchorEl
    },
    /* @__PURE__ */ React62.createElement(Toolbar, null, children ? /* @__PURE__ */ React62.createElement(React62.Fragment, null, children) : /* @__PURE__ */ React62.createElement(FormattingButtonGroup, null))
  ));
};

// packages/remirror__react-components/src/toolbar/markdown-toolbar.tsx
import React64 from "react";

// packages/remirror__react-components/src/toolbar/vertical-divider.tsx
import { Divider } from "@mui/material";
import React63 from "react";
var VerticalDivider = (props) => {
  return /* @__PURE__ */ React63.createElement(Divider, { flexItem: true, sx: { mx: 0.5 }, ...props, orientation: "vertical" });
};

// packages/remirror__react-components/src/toolbar/markdown-toolbar.tsx
var MarkdownToolbar = () => {
  return /* @__PURE__ */ React64.createElement(Toolbar, null, /* @__PURE__ */ React64.createElement(CommandButtonGroup, null, /* @__PURE__ */ React64.createElement(ToggleBoldButton, null), /* @__PURE__ */ React64.createElement(ToggleItalicButton, null), /* @__PURE__ */ React64.createElement(ToggleStrikeButton, null), /* @__PURE__ */ React64.createElement(ToggleCodeButton, null)), /* @__PURE__ */ React64.createElement(VerticalDivider, null), /* @__PURE__ */ React64.createElement(HeadingLevelButtonGroup, { showAll: true }), /* @__PURE__ */ React64.createElement(VerticalDivider, null), /* @__PURE__ */ React64.createElement(CommandButtonGroup, null, /* @__PURE__ */ React64.createElement(ToggleBlockquoteButton, null), /* @__PURE__ */ React64.createElement(ToggleCodeBlockButton, null)), /* @__PURE__ */ React64.createElement(VerticalDivider, null), /* @__PURE__ */ React64.createElement(HistoryButtonGroup, null));
};

// packages/remirror__react-components/src/toolbar/wysiwyg-toolbar.tsx
import React65 from "react";
var WysiwygToolbar = () => {
  return /* @__PURE__ */ React65.createElement(Toolbar, null, /* @__PURE__ */ React65.createElement(HistoryButtonGroup, null), /* @__PURE__ */ React65.createElement(VerticalDivider, null), /* @__PURE__ */ React65.createElement(DataTransferButtonGroup, null), /* @__PURE__ */ React65.createElement(VerticalDivider, null), /* @__PURE__ */ React65.createElement(HeadingLevelButtonGroup, null), /* @__PURE__ */ React65.createElement(VerticalDivider, null), /* @__PURE__ */ React65.createElement(BasicFormattingButtonGroup, null), /* @__PURE__ */ React65.createElement(VerticalDivider, null), /* @__PURE__ */ React65.createElement(ListButtonGroup, null, /* @__PURE__ */ React65.createElement(CreateTableButton, null)));
};
export {
  BaselineButtonGroup,
  BasicFormattingButtonGroup,
  CalloutTypeButtonGroup,
  CenterAlignButton,
  CommandButton,
  CommandButtonGroup,
  CommandMenuItem,
  CopyButton,
  CreateTableButton,
  CutButton,
  DataTransferButtonGroup,
  DecreaseFontSizeButton,
  DecreaseIndentButton,
  DropdownButton,
  EmojiPopupComponent,
  FindReplaceComponent,
  FloatingToolbar,
  FloatingWrapper,
  FormattingButtonGroup,
  GenIcon,
  HeadingLevelButtonGroup,
  HistoryButtonGroup,
  Icon,
  IconBase,
  IncreaseFontSizeButton,
  IncreaseIndentButton,
  IndentationButtonGroup,
  InsertHorizontalRuleButton,
  JustifyAlignButton,
  LeftAlignButton,
  ListButtonGroup,
  MarkdownToolbar,
  MentionAtomPopupComponent,
  PasteButton,
  PositionerPortal,
  RedoButton,
  RightAlignButton,
  TextAlignmentButtonGroup,
  ThemeProvider,
  ToggleBlockquoteButton,
  ToggleBoldButton,
  ToggleBulletListButton,
  ToggleCalloutButton,
  ToggleCalloutMenuItem,
  ToggleCodeBlockButton,
  ToggleCodeButton,
  ToggleColumnsButton,
  ToggleHeadingButton,
  ToggleHeadingMenuItem,
  ToggleItalicButton,
  ToggleOrderedListButton,
  ToggleStrikeButton,
  ToggleSubscriptButton,
  ToggleSuperscriptButton,
  ToggleTaskListButton,
  ToggleUnderlineButton,
  ToggleWhitespaceButton,
  Toolbar,
  UndoButton,
  VerticalDivider,
  WysiwygToolbar,
  useTheme
};
