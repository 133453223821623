var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key2, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key2) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key2, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key2, result);
  return result;
};
var __accessCheck = (obj, member, msg) => {
  if (!member.has(obj))
    throw TypeError("Cannot " + msg);
};
var __privateGet = (obj, member, getter) => {
  __accessCheck(obj, member, "read from private field");
  return getter ? getter.call(obj) : member.get(obj);
};
var __privateAdd = (obj, member, value) => {
  if (member.has(obj))
    throw TypeError("Cannot add the same private member more than once");
  member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
};
var __privateSet = (obj, member, value, setter) => {
  __accessCheck(obj, member, "write to private field");
  setter ? setter.call(obj, value) : member.set(obj, value);
  return value;
};

// packages/remirror__core/src/builtins/attributes-extension.ts
import { cx, object as object3 } from "@remirror/core-helpers";

// packages/remirror__core/src/extension/extension.ts
import {
  __INTERNAL_REMIRROR_IDENTIFIER_KEY__ as __INTERNAL_REMIRROR_IDENTIFIER_KEY__2,
  ErrorConstant as ErrorConstant3,
  ExtensionPriority as ExtensionPriority2,
  RemirrorIdentifier as RemirrorIdentifier2
} from "@remirror/core-constants";
import { assertGet, freeze as freeze2, invariant as invariant3, pascalCase, uniqueBy } from "@remirror/core-helpers";
import { isIdentifierOfType, isRemirrorType } from "@remirror/core-utils";

// packages/remirror__core/src/extension/base-class.ts
import {
  ErrorConstant as ErrorConstant2,
  ExtensionPriority
} from "@remirror/core-constants";
import {
  deepMerge,
  invariant as invariant2,
  isEmptyArray,
  isFunction,
  keys as keys2,
  noop,
  object as object2,
  omit,
  sort
} from "@remirror/core-helpers";
import { environment } from "@remirror/core-utils";

// packages/remirror__core/src/helpers.ts
import { ErrorConstant } from "@remirror/core-constants";
import { freeze, invariant, keys, object } from "@remirror/core-helpers";
function defaultEquals(valueA, valueB) {
  return valueA === valueB;
}
function getChangedOptions(props) {
  const { previousOptions, update, equals = defaultEquals } = props;
  const next = freeze({ ...previousOptions, ...update });
  const changes = object();
  const optionKeys = keys(previousOptions);
  for (const key2 of optionKeys) {
    const previousValue = previousOptions[key2];
    const value = next[key2];
    if (equals(previousValue, value)) {
      changes[key2] = { changed: false };
      continue;
    }
    changes[key2] = { changed: true, previousValue, value };
  }
  const pickChanged = (keys3) => {
    const picked = object();
    for (const key2 of keys3) {
      const item = changes[key2];
      if (item == null ? void 0 : item.changed) {
        picked[key2] = item.value;
      }
    }
    return picked;
  };
  return { changes: freeze(changes), options: next, pickChanged };
}
var codeLabelMap = {
  [ErrorConstant.DUPLICATE_HELPER_NAMES]: "helper method",
  [ErrorConstant.DUPLICATE_COMMAND_NAMES]: "command method"
};
function throwIfNameNotUnique(props) {
  const { name, set, code } = props;
  const label = codeLabelMap[code];
  invariant(!set.has(name), {
    code,
    message: `There is a naming conflict for the name: ${name} used in this '${label}'. Please rename or remove from the editor to avoid runtime errors.`
  });
  set.add(name);
}

// packages/remirror__core/src/extension/base-class.ts
var IGNORE = "__IGNORE__";
var GENERAL_OPTIONS = "__ALL__";
var BaseClass = class {
  constructor(defaultOptions2, ...[options]) {
    /**
     * This is not for external use. It is purely here for TypeScript inference of
     * the generic `Options` type parameter.
     *
     * @internal
     */
    this["~O"] = {};
    this._mappedHandlers = object2();
    this.populateMappedHandlers();
    this._options = this._initialOptions = deepMerge(
      defaultOptions2,
      this.constructor.defaultOptions,
      options ?? object2(),
      this.createDefaultHandlerOptions()
    );
    this._dynamicKeys = this.getDynamicKeys();
    this.init();
  }
  /**
   * The options for this extension.
   *
   * @remarks
   *
   * Options are composed of Static, Dynamic, Handlers and ObjectHandlers.
   *
   * - `Static` - set at instantiation by the constructor.
   * - `Dynamic` - optionally set at instantiation by the constructor and also
   *   set during the runtime.
   * - `Handlers` - can only be set during the runtime.
   * - `ObjectHandlers` - Can only be set during the runtime of the extension.
   */
  get options() {
    return this._options;
  }
  /**
   * Get the dynamic keys for this extension.
   */
  get dynamicKeys() {
    return this._dynamicKeys;
  }
  /**
   * The options that this instance was created with, merged with all the
   * default options.
   */
  get initialOptions() {
    return this._initialOptions;
  }
  /**
   * This method is called by the extension constructor. It is not strictly a
   * lifecycle method since at this point the manager has not yet been
   * instantiated.
   *
   * @remarks
   *
   * It should be used instead of overriding the constructor which is strongly
   * advised against.
   *
   * There are some limitations when using this method.
   *
   * - Accessing `this.store` will throw an error since the manager hasn't been
   *   created and it hasn't yet been attached to the extensions.
   * - `this.type` in `NodeExtension` and `MarkExtension` will also throw an
   *   error since the schema hasn't been created yet.
   *
   * You should use this to setup any instance properties with the options
   * provided to the extension.
   */
  init() {
  }
  /**
   * Get the dynamic keys for this extension.
   */
  getDynamicKeys() {
    const dynamicKeys = [];
    const { customHandlerKeys, handlerKeys, staticKeys } = this.constructor;
    for (const key2 of keys2(this._options)) {
      if (staticKeys.includes(key2) || handlerKeys.includes(key2) || customHandlerKeys.includes(key2)) {
        continue;
      }
      dynamicKeys.push(key2);
    }
    return dynamicKeys;
  }
  /**
   * Throw an error if non dynamic keys are updated.
   */
  ensureAllKeysAreDynamic(update) {
    if (environment.isProduction) {
      return;
    }
    const invalid = [];
    for (const key2 of keys2(update)) {
      if (this._dynamicKeys.includes(key2)) {
        continue;
      }
      invalid.push(key2);
    }
    invariant2(isEmptyArray(invalid), {
      code: ErrorConstant2.INVALID_SET_EXTENSION_OPTIONS,
      message: `Invalid properties passed into the 'setOptions()' method: ${JSON.stringify(
        invalid
      )}.`
    });
  }
  /**
   * Update the properties with the provided partial value when changed.
   */
  setOptions(update) {
    var _a;
    const previousOptions = this.getDynamicOptions();
    this.ensureAllKeysAreDynamic(update);
    const { changes, options, pickChanged } = getChangedOptions({
      previousOptions,
      update
    });
    this.updateDynamicOptions(options);
    (_a = this.onSetOptions) == null ? void 0 : _a.call(this, {
      reason: "set",
      changes,
      options,
      pickChanged,
      initialOptions: this._initialOptions
    });
  }
  /**
   * Reset the extension properties to their default values.
   *
   * @nonVirtual
   */
  resetOptions() {
    var _a;
    const previousOptions = this.getDynamicOptions();
    const { changes, options, pickChanged } = getChangedOptions({
      previousOptions,
      update: this._initialOptions
    });
    this.updateDynamicOptions(options);
    (_a = this.onSetOptions) == null ? void 0 : _a.call(this, {
      reason: "reset",
      options,
      changes,
      pickChanged,
      initialOptions: this._initialOptions
    });
  }
  /**
   * Update the private options.
   */
  getDynamicOptions() {
    return omit(this._options, [
      ...this.constructor.customHandlerKeys,
      ...this.constructor.handlerKeys
    ]);
  }
  /**
   * Update the dynamic options.
   */
  updateDynamicOptions(options) {
    this._options = { ...this._options, ...options };
  }
  /**
   * Set up the mapped handlers object with default values (an empty array);
   */
  populateMappedHandlers() {
    for (const key2 of this.constructor.handlerKeys) {
      this._mappedHandlers[key2] = [];
    }
  }
  /**
   * This is currently fudged together, I'm not sure it will work.
   */
  createDefaultHandlerOptions() {
    const methods = object2();
    for (const key2 of this.constructor.handlerKeys) {
      methods[key2] = (...args) => {
        var _a;
        const { handlerKeyOptions } = this.constructor;
        const reducer = (_a = handlerKeyOptions[key2]) == null ? void 0 : _a.reducer;
        let returnValue = reducer == null ? void 0 : reducer.getDefault(...args);
        for (const [, handler] of this._mappedHandlers[key2]) {
          const value = handler(...args);
          returnValue = reducer ? reducer.accumulator(returnValue, value, ...args) : value;
          if (shouldReturnEarly(handlerKeyOptions, returnValue, key2)) {
            return returnValue;
          }
        }
        return returnValue;
      };
    }
    return methods;
  }
  /**
   * Add a handler to the event handlers so that it is called along with all the
   * other handler methods.
   *
   * This is helpful for integrating react hooks which can be used in multiple
   * places. The original problem with fixed properties is that you can only
   * assign to a method once and it overwrites any other methods. This pattern
   * for adding handlers allows for multiple usages of the same handler in the
   * most relevant part of the code.
   *
   * More to come on this pattern.
   *
   * @nonVirtual
   */
  addHandler(key2, method, priority = ExtensionPriority.Default) {
    this._mappedHandlers[key2].push([priority, method]);
    this.sortHandlers(key2);
    return () => this._mappedHandlers[key2] = this._mappedHandlers[key2].filter(
      ([, handler]) => handler !== method
    );
  }
  /**
   * Determines if handlers exist for the given key.
   *
   * Checking the existence of a handler property directly gives wrong results.
   * `this.options.onHandlerName` is always truthy because it is a reference to
   * the wrapper function that calls each handler.
   *
   * ```ts
   *
   * // GOOD ✅
   * if (!this.hasHandlers('onHandlerName')) {
   *   return;
   * }
   *
   * // BAD ❌
   * if (!this.options.onHandlerName) {
   *   return;
   * }
   * ```
   *
   * @param key The handler to test
   */
  hasHandlers(key2) {
    return (this._mappedHandlers[key2] ?? []).length > 0;
  }
  sortHandlers(key2) {
    this._mappedHandlers[key2] = sort(
      this._mappedHandlers[key2],
      // Sort from highest binding to the lowest.
      ([a], [z]) => z - a
    );
  }
  /**
   * A method that can be used to add a custom handler. It is up to the
   * extension creator to manage the handlers and dispose methods.
   */
  addCustomHandler(key2, value) {
    var _a;
    return ((_a = this.onAddCustomHandler) == null ? void 0 : _a.call(this, { [key2]: value })) ?? noop;
  }
};
/**
 * The default options for this extension.
 *
 * TODO see if this can be cast to something other than any and allow
 * composition.
 */
BaseClass.defaultOptions = {};
/**
 * The static keys for this class.
 */
BaseClass.staticKeys = [];
/**
 * The event handler keys.
 */
BaseClass.handlerKeys = [];
/**
 * Customize the way the handler should behave.
 */
BaseClass.handlerKeyOptions = {};
/**
 * The custom keys.
 */
BaseClass.customHandlerKeys = [];
function shouldReturnEarly(handlerKeyOptions, returnValue, handlerKey) {
  const { [GENERAL_OPTIONS]: generalOptions } = handlerKeyOptions;
  const handlerOptions = handlerKeyOptions[handlerKey];
  if (!generalOptions && !handlerOptions) {
    return false;
  }
  if (handlerOptions && // Only proceed if the value should not be ignored.
  handlerOptions.earlyReturnValue !== IGNORE && (isFunction(handlerOptions.earlyReturnValue) ? handlerOptions.earlyReturnValue(returnValue) === true : returnValue === handlerOptions.earlyReturnValue)) {
    return true;
  }
  if (generalOptions && // Only proceed if they are not ignored.
  generalOptions.earlyReturnValue !== IGNORE && // Check whether the `earlyReturnValue` is a predicate check.
  (isFunction(generalOptions.earlyReturnValue) ? (
    // If it is a predicate and when called with the current
    // `returnValue` the value is `true` then we should return
    // early.
    generalOptions.earlyReturnValue(returnValue) === true
  ) : (
    // Check the actual return value.
    returnValue === generalOptions.earlyReturnValue
  ))) {
    return true;
  }
  return false;
}

// packages/remirror__core/src/extension/extension.ts
var Extension = class extends BaseClass {
  constructor(...args) {
    super(defaultOptions, ...args);
    /**
     * Not for usage. This is purely for types to make it easier to infer
     * available sub extension types.
     *
     * @internal
     */
    this["~E"] = {};
    this._extensions = uniqueBy(
      this.createExtensions(),
      // Ensure that all the provided extensions are unique.
      (extension2) => extension2.constructor
    );
    this.extensionMap = /* @__PURE__ */ new Map();
    for (const extension2 of this._extensions) {
      this.extensionMap.set(extension2.constructor, extension2);
    }
  }
  /**
   * The priority level for this instance of the extension. A higher value
   * corresponds to a higher priority extension
   */
  get priority() {
    return this.priorityOverride ?? this.options.priority ?? this.constructor.defaultPriority;
  }
  /**
   * The name that the constructor should have, which doesn't get mangled in
   * production.
   */
  get constructorName() {
    return `${pascalCase(this.name)}Extension`;
  }
  /**
   * The store is a shared object that's internal to each extension. It includes
   * often used items like the `view` and `schema` that are added by the
   * extension manager and also the lifecycle extension methods.
   *
   * **NOTE** - The store is not available until the manager has been created
   * and received the extension. As a result trying to access the store during
   * `init` and `constructor` will result in a runtime error.
   *
   * Some properties of the store are available at different phases. You should
   * check the inline documentation to know when a certain property is useable
   * in your extension.
   */
  get store() {
    invariant3(this._store, {
      code: ErrorConstant3.MANAGER_PHASE_ERROR,
      message: `An error occurred while attempting to access the 'extension.store' when the Manager has not yet set created the lifecycle methods.`
    });
    return freeze2(this._store, { requireKeys: true });
  }
  /**
   * The list of extensions added to the editor by this `Preset`.
   */
  get extensions() {
    return this._extensions;
  }
  /**
   * When there are duplicate extensions used within the editor the extension
   * manager will call this method and make sure all extension holders are using
   * the same instance of the `ExtensionConstructor`.
   *
   * @internal
   */
  replaceChildExtension(constructor, extension2) {
    if (!this.extensionMap.has(constructor)) {
      return;
    }
    this.extensionMap.set(constructor, extension2);
    this._extensions = this.extensions.map(
      (currentExtension) => extension2.constructor === constructor ? extension2 : currentExtension
    );
  }
  /**
   * Create the extensions which will be consumed by the preset. Override this
   * if you would like to make your extension a parent to other (holder)
   * extensions which don't make sense existing outside of the context of this
   * extension.
   *
   * @remarks
   *
   * Since this method is called in the constructor it should always be created
   * as an instance method and not a property. Properties aren't available for
   * the call to the parent class.
   *
   * ```ts
   * class HolderExtension extends PlainExtension {
   *   get name() {
   *     return 'holder'
   *   }
   *
   *   // GOOD ✅
   *   createExtensions() {
   *     return [];
   *   }
   *
   *   // BAD ❌
   *   createExtensions = () => {
   *     return [];
   *   }
   * }
   * ```
   */
  createExtensions() {
    return [];
  }
  /**
   * Get an extension from this holder extension by providing the desired
   * `Constructor`.
   *
   * @param Constructor - the extension constructor to find in the editor.
   *
   * @remarks
   *
   * This method will throw an error if the constructor doesn't exist within the
   * extension created by this extension.
   *
   * It can be used to forward options and attach handlers to the children
   * extensions. It is the spiritual replacement of the `Preset` extension.
   *
   * ```ts
   * import { PlainExtension, OnSetOptionsProps } from 'remirror';
   *
   * interface ParentOptions { weight?: string }
   *
   * class ParentExtension extends PlainExtension<ParentOptions> {
   *   get name() {
   *     return 'parent' as const;
   *   }
   *
   *   createExtensions() {
   *     return [new BoldExtension()]
   *   }
   *
   *   onSetOptions(options: OnSetOptionsProps<ParentOptions>): void {
   *     if (options.changes.weight.changed) {
   *       // Update the value of the provided extension.
   *       this.getExtension(BoldExtension).setOption({ weight: options.changes.weight.value });
   *     }
   *   }
   * }
   * ```
   */
  getExtension(Constructor) {
    const extension2 = this.extensionMap.get(Constructor);
    invariant3(extension2, {
      code: ErrorConstant3.INVALID_GET_EXTENSION,
      message: `'${Constructor.name}' does not exist within the preset: '${this.name}'`
    });
    return extension2;
  }
  /**
   * Check if the type of this extension's constructor matches the type of the
   * provided constructor.
   */
  isOfType(Constructor) {
    return this.constructor === Constructor;
  }
  /**
   * Pass a reference to the globally shared `ExtensionStore` for this
   * extension.
   *
   * @remarks
   *
   * The extension store allows extensions to access important variables without
   * complicating their creator methods.
   *
   * ```ts
   * import { PlainExtension } from 'remirror';
   *
   * class Awesome extends PlainExtension {
   *   customMethod() {
   *     if (this.store.view.hasFocus()) {
   *       log('dance dance dance');
   *     }
   *   }
   * }
   * ```
   *
   * This should only be called by the `RemirrorManager`.
   *
   * @internal
   * @nonVirtual
   */
  setStore(store) {
    if (this._store) {
      return;
    }
    this._store = store;
  }
  /**
   * Clone an extension.
   */
  clone(...args) {
    return new this.constructor(...args);
  }
  /**
   * Set the priority override for this extension. This is used in the
   * `RemirrorManager` in order to override the priority of an extension.
   *
   * If you set the first parameter to `undefined` it will remove the priority
   * override.
   *
   * @internal
   */
  setPriority(priority) {
    this.priorityOverride = priority;
  }
};
/**
 * The default priority for this family of extensions.
 */
Extension.defaultPriority = ExtensionPriority2.Default;
var PlainExtension = class extends Extension {
  /** @internal */
  static get [__INTERNAL_REMIRROR_IDENTIFIER_KEY__2]() {
    return RemirrorIdentifier2.PlainExtensionConstructor;
  }
  /** @internal */
  get [__INTERNAL_REMIRROR_IDENTIFIER_KEY__2]() {
    return RemirrorIdentifier2.PlainExtension;
  }
};
var MarkExtension = class extends Extension {
  /** @internal */
  static get [__INTERNAL_REMIRROR_IDENTIFIER_KEY__2]() {
    return RemirrorIdentifier2.MarkExtensionConstructor;
  }
  /** @internal */
  get [__INTERNAL_REMIRROR_IDENTIFIER_KEY__2]() {
    return RemirrorIdentifier2.MarkExtension;
  }
  /**
   * Provides access to the mark type from the schema.
   *
   * @remarks
   *
   * The type is available as soon as the schema is created by the
   * `SchemaExtension` which has the priority `Highest`. It should be safe to
   * access in any of the lifecycle methods.
   */
  get type() {
    return assertGet(this.store.schema.marks, this.name);
  }
  constructor(...args) {
    super(...args);
  }
};
/**
 * Whether to disable extra attributes for this extension.
 */
MarkExtension.disableExtraAttributes = false;
var NodeExtension = class extends Extension {
  static get [__INTERNAL_REMIRROR_IDENTIFIER_KEY__2]() {
    return RemirrorIdentifier2.NodeExtensionConstructor;
  }
  /** @internal */
  get [__INTERNAL_REMIRROR_IDENTIFIER_KEY__2]() {
    return RemirrorIdentifier2.NodeExtension;
  }
  /**
   * Provides access to the node type from the schema.
   */
  get type() {
    return assertGet(this.store.schema.nodes, this.name);
  }
  constructor(...args) {
    super(...args);
  }
};
/**
 * Whether to disable extra attributes for this extension.
 */
NodeExtension.disableExtraAttributes = false;
var defaultOptions = {
  priority: void 0,
  extraAttributes: {},
  disableExtraAttributes: false,
  exclude: {}
};
function mutateDefaultExtensionOptions(mutatorMethod) {
  mutatorMethod(defaultOptions);
}
function isExtension(value) {
  return isRemirrorType(value) && isIdentifierOfType(value, [
    RemirrorIdentifier2.PlainExtension,
    RemirrorIdentifier2.MarkExtension,
    RemirrorIdentifier2.NodeExtension
  ]);
}
function isExtensionConstructor(value) {
  return isRemirrorType(value) && isIdentifierOfType(value, [
    RemirrorIdentifier2.PlainExtensionConstructor,
    RemirrorIdentifier2.MarkExtensionConstructor,
    RemirrorIdentifier2.NodeExtensionConstructor
  ]);
}
function isPlainExtension(value) {
  return isRemirrorType(value) && isIdentifierOfType(value, RemirrorIdentifier2.PlainExtension);
}
function isNodeExtension(value) {
  return isRemirrorType(value) && isIdentifierOfType(value, RemirrorIdentifier2.NodeExtension);
}
function isMarkExtension(value) {
  return isRemirrorType(value) && isIdentifierOfType(value, RemirrorIdentifier2.MarkExtension);
}

// packages/remirror__core/src/extension/extension-decorator.ts
import { Cast } from "@remirror/core-helpers";
function extension(options) {
  return (ReadonlyConstructor) => {
    const {
      defaultOptions: defaultOptions2,
      customHandlerKeys,
      handlerKeys,
      staticKeys,
      defaultPriority,
      handlerKeyOptions,
      ...rest
    } = options;
    const Constructor = Cast(ReadonlyConstructor);
    if (defaultOptions2) {
      Constructor.defaultOptions = defaultOptions2;
    }
    if (defaultPriority) {
      Constructor.defaultPriority = defaultPriority;
    }
    if (handlerKeyOptions) {
      Constructor.handlerKeyOptions = handlerKeyOptions;
    }
    Constructor.staticKeys = staticKeys ?? [];
    Constructor.handlerKeys = handlerKeys ?? [];
    Constructor.customHandlerKeys = customHandlerKeys ?? [];
    for (const [key2, value] of Object.entries(rest)) {
      if (Constructor[key2]) {
        continue;
      }
      Constructor[key2] = value;
    }
    return Cast(Constructor);
  };
}
var extensionDecorator = extension;

// packages/remirror__core/src/builtins/attributes-extension.ts
var AttributesExtension = class extends PlainExtension {
  constructor() {
    super(...arguments);
    this.attributeList = [];
    this.attributeObject = object3();
    this.updateAttributes = (triggerUpdate = true) => {
      this.transformAttributes();
      if (triggerUpdate) {
        this.store.commands.forceUpdate("attributes");
      }
    };
  }
  get name() {
    return "attributes";
  }
  /**
   * Create the attributes object on initialization.
   *
   * @internal
   */
  onCreate() {
    this.transformAttributes();
    this.store.setExtensionStore("updateAttributes", this.updateAttributes);
  }
  transformAttributes() {
    var _a, _b, _c;
    this.attributeObject = object3();
    if ((_a = this.store.managerSettings.exclude) == null ? void 0 : _a.attributes) {
      this.store.setStoreKey("attributes", this.attributeObject);
      return;
    }
    this.attributeList = [];
    for (const extension2 of this.store.extensions) {
      if ((_b = extension2.options.exclude) == null ? void 0 : _b.attributes) {
        continue;
      }
      const createdAttributes = (_c = extension2.createAttributes) == null ? void 0 : _c.call(extension2);
      const attributes = {
        ...createdAttributes,
        class: cx(...extension2.classNames ?? [], createdAttributes == null ? void 0 : createdAttributes.class)
      };
      this.attributeList.unshift(attributes);
    }
    for (const attributes of this.attributeList) {
      this.attributeObject = {
        ...this.attributeObject,
        ...attributes,
        class: cx(this.attributeObject.class, attributes.class)
      };
    }
    this.store.setStoreKey("attributes", this.attributeObject);
  }
};

// packages/remirror__core/src/builtins/builtin-decorators.ts
function helper(options = {}) {
  return (target, propertyKey, _descriptor) => {
    (target.decoratedHelpers ?? (target.decoratedHelpers = {}))[propertyKey] = options;
  };
}
function command(options = {}) {
  return (target, propertyKey, _descriptor) => {
    (target.decoratedCommands ?? (target.decoratedCommands = {}))[propertyKey] = options;
  };
}
function keyBinding(options) {
  return (target, propertyKey, _descriptor) => {
    (target.decoratedKeybindings ?? (target.decoratedKeybindings = {}))[propertyKey] = options;
  };
}

// packages/remirror__core/src/builtins/builtin-preset.ts
import { pick } from "@remirror/core-helpers";

// packages/remirror__core/src/builtins/commands-extension.ts
import { ErrorConstant as ErrorConstant5, ExtensionPriority as ExtensionPriority3, NamedShortcut } from "@remirror/core-constants";
import {
  entries as entries2,
  invariant as invariant5,
  isEmptyArray as isEmptyArray2,
  isEmptyObject,
  isString as isString2,
  object as object4,
  uniqueArray
} from "@remirror/core-helpers";
import {
  environment as environment2,
  getMarkRange,
  getTextSelection as getTextSelection2,
  htmlToProsemirrorNode,
  isEmptyBlockNode,
  isProsemirrorFragment,
  isProsemirrorNode,
  isTextSelection,
  removeMark,
  replaceText,
  setBlockType,
  toggleBlockItem,
  toggleWrap,
  wrapIn
} from "@remirror/core-utils";
import { CoreMessages as Messages } from "@remirror/messages";
import { Mark } from "@remirror/pm/model";
import { TextSelection } from "@remirror/pm/state";

// packages/remirror__core/src/commands.ts
import { ErrorConstant as ErrorConstant4 } from "@remirror/core-constants";
import {
  assertGet as assertGet2,
  entries,
  invariant as invariant4,
  isFunction as isFunction2,
  isPromise,
  isString
} from "@remirror/core-helpers";
import { convertCommand, getCursor, getTextSelection, isMarkActive } from "@remirror/core-utils";
import { toggleMark as originalToggleMark } from "@remirror/pm/commands";
function isDelayedValue(value) {
  return isFunction2(value) || isPromise(value);
}
function delayedCommand({
  immediate,
  promise,
  onDone,
  onFail
}) {
  return (props) => {
    const { view } = props;
    if ((immediate == null ? void 0 : immediate(props)) === false) {
      return false;
    }
    if (!view) {
      return true;
    }
    const deferred = isFunction2(promise) ? promise() : promise;
    deferred.then((value) => {
      onDone({ state: view.state, tr: view.state.tr, dispatch: view.dispatch, view, value });
    }).catch(() => {
      onFail == null ? void 0 : onFail({ state: view.state, tr: view.state.tr, dispatch: view.dispatch, view });
    });
    return true;
  };
}
var DelayedCommand = class {
  constructor(promiseCreator) {
    this.promiseCreator = promiseCreator;
    this.failureHandlers = [];
    this.successHandlers = [];
    this.validateHandlers = [];
    /**
     * Generate the `remirror` command.
     */
    this.generateCommand = () => {
      return (props) => {
        let isValid = true;
        const { view, tr, dispatch } = props;
        if (!view) {
          return false;
        }
        for (const handler of this.validateHandlers) {
          if (!handler({ ...props, dispatch: () => {
          } })) {
            isValid = false;
            break;
          }
        }
        if (!dispatch || !isValid) {
          return isValid;
        }
        const deferred = this.promiseCreator(props);
        deferred.then((value) => {
          this.runHandlers(this.successHandlers, {
            value,
            state: view.state,
            tr: view.state.tr,
            dispatch: view.dispatch,
            view
          });
        }).catch((error) => {
          this.runHandlers(this.failureHandlers, {
            error,
            state: view.state,
            tr: view.state.tr,
            dispatch: view.dispatch,
            view
          });
        });
        dispatch(tr);
        return true;
      };
    };
  }
  /**
   * The commands that will immediately be run and used to evaluate whether to
   * proceed.
   */
  validate(handler, method = "push") {
    this.validateHandlers[method](handler);
    return this;
  }
  /**
   * Add a success callback to the handler.
   */
  success(handler, method = "push") {
    this.successHandlers[method](handler);
    return this;
  }
  /**
   * Add a failure callback to the handler.
   */
  failure(handler, method = "push") {
    this.failureHandlers[method](handler);
    return this;
  }
  runHandlers(handlers, param) {
    var _a;
    for (const handler of handlers) {
      if (!handler({ ...param, dispatch: () => {
      } })) {
        break;
      }
    }
    (_a = param.dispatch) == null ? void 0 : _a.call(param, param.tr);
  }
};
function toggleMark(props) {
  const { type, attrs, range, selection } = props;
  return (props2) => {
    const { dispatch, tr, state } = props2;
    const markType = isString(type) ? state.schema.marks[type] : type;
    invariant4(markType, {
      code: ErrorConstant4.SCHEMA,
      message: `Mark type: ${type} does not exist on the current schema.`
    });
    if (range || selection) {
      const { from, to } = getTextSelection(selection ?? range ?? tr.selection, tr.doc);
      isMarkActive({ trState: tr, type, ...range }) ? dispatch == null ? void 0 : dispatch(tr.removeMark(from, to, markType)) : dispatch == null ? void 0 : dispatch(tr.addMark(from, to, markType.create(attrs)));
      return true;
    }
    return convertCommand(originalToggleMark(markType, attrs))(props2);
  };
}
function markApplies(type, doc, ranges) {
  for (const { $from, $to } of ranges) {
    let markIsAllowed = $from.depth === 0 ? doc.type.allowsMarkType(type) : false;
    doc.nodesBetween($from.pos, $to.pos, (node) => {
      if (markIsAllowed) {
        return false;
      }
      markIsAllowed = node.inlineContent && node.type.allowsMarkType(type);
      return;
    });
    if (markIsAllowed) {
      return true;
    }
  }
  return false;
}
function applyMark(type, attrs, selectionPoint) {
  return ({ tr, dispatch, state }) => {
    const selection = getTextSelection(selectionPoint ?? tr.selection, tr.doc);
    const $cursor = getCursor(selection);
    const markType = isString(type) ? state.schema.marks[type] : type;
    invariant4(markType, {
      code: ErrorConstant4.SCHEMA,
      message: `Mark type: ${type} does not exist on the current schema.`
    });
    if (selection.empty && !$cursor || !markApplies(markType, tr.doc, selection.ranges)) {
      return false;
    }
    if (!dispatch) {
      return true;
    }
    if ($cursor) {
      tr.removeStoredMark(markType);
      if (attrs) {
        tr.addStoredMark(markType.create(attrs));
      }
      dispatch(tr);
      return true;
    }
    let containsMark = false;
    for (const { $from, $to } of selection.ranges) {
      if (containsMark) {
        break;
      }
      containsMark = tr.doc.rangeHasMark($from.pos, $to.pos, markType);
    }
    for (const { $from, $to } of selection.ranges) {
      if (containsMark) {
        tr.removeMark($from.pos, $to.pos, markType);
      }
      if (attrs) {
        tr.addMark($from.pos, $to.pos, markType.create(attrs));
      }
    }
    dispatch(tr);
    return true;
  };
}
function insertText(text, options = {}) {
  return ({ tr, dispatch, state }) => {
    const schema = state.schema;
    const selection = tr.selection;
    const { from = selection.from, to = from ?? selection.to, marks = {} } = options;
    if (!dispatch) {
      return true;
    }
    tr.insertText(text, from, to);
    const end = assertGet2(tr.steps, tr.steps.length - 1).getMap().map(to);
    for (const [markName, attributes] of entries(marks)) {
      tr.addMark(from, end, assertGet2(schema.marks, markName).create(attributes));
    }
    dispatch(tr);
    return true;
  };
}

// packages/remirror__core/src/builtins/commands-extension.ts
var CommandsExtension = class extends PlainExtension {
  constructor() {
    super(...arguments);
    /**
     * Track the decorated command data.
     */
    this.decorated = /* @__PURE__ */ new Map();
    /**
     * A helper for forcing through updates in the view layer. The view layer can
     * check for the meta data of the transaction with
     * `manager.store.getForcedUpdate(tr)`. If that has a value then it should use
     * the unique symbol to update the key.
     */
    this.forceUpdateTransaction = (tr, ...keys3) => {
      const { forcedUpdates } = this.getCommandMeta(tr);
      this.setCommandMeta(tr, { forcedUpdates: uniqueArray([...forcedUpdates, ...keys3]) });
      return tr;
    };
  }
  get name() {
    return "commands";
  }
  /**
   * The current transaction which allows for making commands chainable.
   *
   * It is shared by all the commands helpers and can even be used in the
   * [[`KeymapExtension`]].
   *
   * @internal
   */
  get transaction() {
    const state = this.store.getState();
    if (!this._transaction) {
      this._transaction = state.tr;
    }
    const isValid = this._transaction.before.eq(state.doc);
    const hasSteps = !isEmptyArray2(this._transaction.steps);
    if (!isValid) {
      const tr = state.tr;
      if (hasSteps) {
        for (const step of this._transaction.steps) {
          tr.step(step);
        }
      }
      this._transaction = tr;
    }
    return this._transaction;
  }
  onCreate() {
    this.store.setStoreKey("getForcedUpdates", this.getForcedUpdates.bind(this));
  }
  /**
   * Attach commands once the view is attached.
   */
  onView(view) {
    var _a;
    const { extensions, helpers } = this.store;
    const commands = object4();
    const names = /* @__PURE__ */ new Set();
    let allDecoratedCommands = object4();
    const chain = (tr) => {
      var _a2;
      const customChain = object4();
      const getTr = () => tr ?? this.transaction;
      let commandChain = [];
      const getChain = () => commandChain;
      for (const [name, command2] of Object.entries(commands)) {
        if ((_a2 = allDecoratedCommands[name]) == null ? void 0 : _a2.disableChaining) {
          continue;
        }
        customChain[name] = this.chainedFactory({
          chain: customChain,
          command: command2.original,
          getTr,
          getChain
        });
      }
      const dispatch = (transaction) => {
        invariant5(transaction === getTr(), {
          message: "Chaining currently only supports `CommandFunction` methods which do not use the `state.tr` property. Instead you should use the provided `tr` property."
        });
      };
      customChain.run = (options = {}) => {
        const commands2 = commandChain;
        commandChain = [];
        for (const cmd of commands2) {
          if (!cmd(dispatch) && options.exitEarly) {
            return;
          }
        }
        view.dispatch(getTr());
      };
      customChain.tr = () => {
        const commands2 = commandChain;
        commandChain = [];
        for (const cmd of commands2) {
          cmd(dispatch);
        }
        return getTr();
      };
      customChain.enabled = () => {
        for (const cmd of commandChain) {
          if (!cmd()) {
            return false;
          }
        }
        return true;
      };
      return customChain;
    };
    for (const extension2 of extensions) {
      const extensionCommands = ((_a = extension2.createCommands) == null ? void 0 : _a.call(extension2)) ?? {};
      const decoratedCommands = extension2.decoratedCommands ?? {};
      const active = {};
      allDecoratedCommands = { ...allDecoratedCommands, decoratedCommands };
      for (const [commandName, options] of Object.entries(decoratedCommands)) {
        const shortcut = isString2(options.shortcut) && options.shortcut.startsWith("_|") ? { shortcut: helpers.getNamedShortcut(options.shortcut, extension2.options) } : void 0;
        this.updateDecorated(commandName, { ...options, name: extension2.name, ...shortcut });
        extensionCommands[commandName] = extension2[commandName].bind(extension2);
        if (options.active) {
          active[commandName] = () => {
            var _a2;
            return ((_a2 = options.active) == null ? void 0 : _a2.call(options, extension2.options, this.store)) ?? false;
          };
        }
      }
      if (isEmptyObject(extensionCommands)) {
        continue;
      }
      this.addCommands({ active, names, commands, extensionCommands });
    }
    const chainProperty = chain();
    for (const [key2, command2] of Object.entries(chainProperty)) {
      chain[key2] = command2;
    }
    this.store.setStoreKey("commands", commands);
    this.store.setStoreKey("chain", chain);
    this.store.setExtensionStore("commands", commands);
    this.store.setExtensionStore("chain", chain);
  }
  /**
   * Update the cached transaction whenever the state is updated.
   */
  onStateUpdate({ state }) {
    this._transaction = state.tr;
  }
  /**
   * Create a plugin that solely exists to track forced updates via the
   * generated plugin key.
   */
  createPlugin() {
    return {};
  }
  customDispatch(command2) {
    return command2;
  }
  insertText(text, options = {}) {
    if (isString2(text)) {
      return insertText(text, options);
    }
    return this.store.createPlaceholderCommand({
      promise: text,
      placeholder: { type: "inline" },
      onSuccess: (value, range, props) => {
        return this.insertText(value, { ...options, ...range })(props);
      }
    }).generateCommand();
  }
  selectText(selection, options = {}) {
    return ({ tr, dispatch }) => {
      const textSelection = getTextSelection2(selection, tr.doc);
      const selectionUnchanged = tr.selection.anchor === textSelection.anchor && tr.selection.head === textSelection.head;
      if (selectionUnchanged && !options.forceUpdate) {
        return false;
      }
      dispatch == null ? void 0 : dispatch(tr.setSelection(textSelection));
      return true;
    };
  }
  selectMark(type) {
    return (props) => {
      const { tr } = props;
      const range = getMarkRange(tr.selection.$from, type);
      if (!range) {
        return false;
      }
      return this.store.commands.selectText.original({ from: range.from, to: range.to })(props);
    };
  }
  delete(range) {
    return ({ tr, dispatch }) => {
      const { from, to } = range ?? tr.selection;
      dispatch == null ? void 0 : dispatch(tr.delete(from, to));
      return true;
    };
  }
  emptyUpdate(action) {
    return ({ tr, dispatch }) => {
      if (dispatch) {
        action == null ? void 0 : action();
        dispatch(tr);
      }
      return true;
    };
  }
  forceUpdate(...keys3) {
    return ({ tr, dispatch }) => {
      dispatch == null ? void 0 : dispatch(this.forceUpdateTransaction(tr, ...keys3));
      return true;
    };
  }
  updateNodeAttributes(pos, attrs) {
    return ({ tr, dispatch }) => {
      dispatch == null ? void 0 : dispatch(tr.setNodeMarkup(pos, void 0, attrs));
      return true;
    };
  }
  setContent(content, selection) {
    return (props) => {
      const { tr, dispatch } = props;
      const state = this.store.manager.createState({ content, selection });
      if (!state) {
        return false;
      }
      dispatch == null ? void 0 : dispatch(tr.replaceRangeWith(0, tr.doc.nodeSize - 2, state.doc));
      return true;
    };
  }
  resetContent() {
    return (props) => {
      const { tr, dispatch } = props;
      const doc = this.store.manager.createEmptyDoc();
      if (doc) {
        return this.setContent(doc)(props);
      }
      dispatch == null ? void 0 : dispatch(tr.delete(0, tr.doc.nodeSize));
      return true;
    };
  }
  emptySelection() {
    return ({ tr, dispatch }) => {
      if (tr.selection.empty) {
        return false;
      }
      dispatch == null ? void 0 : dispatch(tr.setSelection(TextSelection.near(tr.selection.$anchor)));
      return true;
    };
  }
  insertNewLine() {
    return ({ dispatch, tr }) => {
      if (!isTextSelection(tr.selection)) {
        return false;
      }
      dispatch == null ? void 0 : dispatch(tr.insertText("\n"));
      return true;
    };
  }
  insertNode(node, options = {}) {
    return ({ dispatch, tr, state }) => {
      var _a;
      const { attrs, range, selection, replaceEmptyParentBlock = false } = options;
      const { from, to, $from } = getTextSelection2(selection ?? range ?? tr.selection, tr.doc);
      if (isProsemirrorNode(node) || isProsemirrorFragment(node)) {
        const pos = $from.before($from.depth);
        dispatch == null ? void 0 : dispatch(
          replaceEmptyParentBlock && from === to && isEmptyBlockNode($from.parent) ? tr.replaceWith(pos, pos + $from.parent.nodeSize, node) : tr.replaceWith(from, to, node)
        );
        return true;
      }
      const nodeType = isString2(node) ? state.schema.nodes[node] : node;
      invariant5(nodeType, {
        code: ErrorConstant5.SCHEMA,
        message: `The requested node type ${node} does not exist in the schema.`
      });
      const marks = (_a = options.marks) == null ? void 0 : _a.map((mark) => {
        if (mark instanceof Mark) {
          return mark;
        }
        const markType = isString2(mark) ? state.schema.marks[mark] : mark;
        invariant5(markType, {
          code: ErrorConstant5.SCHEMA,
          message: `The requested mark type ${mark} does not exist in the schema.`
        });
        return markType.create();
      });
      const content = nodeType.createAndFill(
        attrs,
        isString2(options.content) ? state.schema.text(options.content) : options.content,
        marks
      );
      if (!content) {
        return false;
      }
      const isReplacement = from !== to;
      dispatch == null ? void 0 : dispatch(isReplacement ? tr.replaceRangeWith(from, to, content) : tr.insert(from, content));
      return true;
    };
  }
  focus(position) {
    return (props) => {
      const { dispatch, tr } = props;
      const { view } = this.store;
      if (position === false) {
        return false;
      }
      if (view.hasFocus() && (position === void 0 || position === true)) {
        return false;
      }
      if (position === void 0 || position === true) {
        const { from = 0, to = from } = tr.selection;
        position = { from, to };
      }
      if (dispatch) {
        this.delayedFocus();
      }
      return this.selectText(position)(props);
    };
  }
  blur(position) {
    return (props) => {
      const { view } = this.store;
      if (!view.hasFocus()) {
        return false;
      }
      requestAnimationFrame(() => {
        view.dom.blur();
      });
      return position ? this.selectText(position)(props) : true;
    };
  }
  setBlockNodeType(nodeType, attrs, selection, preserveAttrs = true) {
    return setBlockType(nodeType, attrs, selection, preserveAttrs);
  }
  toggleWrappingNode(nodeType, attrs, selection) {
    return toggleWrap(nodeType, attrs, selection);
  }
  toggleBlockNodeItem(toggleProps) {
    return toggleBlockItem(toggleProps);
  }
  wrapInNode(nodeType, attrs, range) {
    return wrapIn(nodeType, attrs, range);
  }
  applyMark(markType, attrs, selection) {
    return applyMark(markType, attrs, selection);
  }
  toggleMark(props) {
    return toggleMark(props);
  }
  removeMark(props) {
    return removeMark(props);
  }
  setMeta(name, value) {
    return ({ tr }) => {
      tr.setMeta(name, value);
      return true;
    };
  }
  selectAll() {
    return this.selectText("all");
  }
  copy() {
    return (props) => {
      if (props.tr.selection.empty) {
        return false;
      }
      if (props.dispatch) {
        document.execCommand("copy");
      }
      return true;
    };
  }
  paste() {
    return this.store.createPlaceholderCommand({
      // TODO https://caniuse.com/?search=clipboard.read - once browser support is sufficient.
      promise: async () => {
        var _a;
        if ((_a = navigator.clipboard) == null ? void 0 : _a.readText) {
          return await navigator.clipboard.readText();
        }
        return "";
      },
      placeholder: { type: "inline" },
      onSuccess: (value, selection, props) => {
        return this.insertNode(
          htmlToProsemirrorNode({ content: value, schema: props.state.schema }),
          { selection }
        )(props);
      }
    }).generateCommand();
  }
  cut() {
    return (props) => {
      if (props.tr.selection.empty) {
        return false;
      }
      if (props.dispatch) {
        document.execCommand("cut");
      }
      return true;
    };
  }
  replaceText(props) {
    return replaceText(props);
  }
  getAllCommandOptions() {
    const uiCommands = {};
    for (const [name, options] of this.decorated) {
      if (isEmptyObject(options)) {
        continue;
      }
      uiCommands[name] = options;
    }
    return uiCommands;
  }
  getCommandOptions(name) {
    return this.decorated.get(name);
  }
  getCommandProp() {
    return {
      tr: this.transaction,
      dispatch: this.store.view.dispatch,
      state: this.store.view.state,
      view: this.store.view
    };
  }
  /**
   * Update the command options via a shallow merge of the provided options. If
   * no options are provided the entry is deleted.
   *
   * @internal
   */
  updateDecorated(name, options) {
    if (!options) {
      this.decorated.delete(name);
      return;
    }
    const decoratorOptions = this.decorated.get(name) ?? { name: "" };
    this.decorated.set(name, { ...decoratorOptions, ...options });
  }
  /**
   * Needed on iOS since `requestAnimationFrame` doesn't breaks the focus
   * implementation.
   */
  handleIosFocus() {
    if (!environment2.isIos) {
      return;
    }
    this.store.view.dom.focus();
  }
  /**
   * Focus the editor after a slight delay.
   */
  delayedFocus() {
    this.handleIosFocus();
    requestAnimationFrame(() => {
      this.store.view.focus();
      this.store.view.dispatch(this.transaction.scrollIntoView());
    });
  }
  /**
   * Check for a forced update in the transaction. This pulls the meta data
   * from the transaction and if it is true then it was a forced update.
   *
   * ```ts
   * import { CommandsExtension } from 'remirror/extensions';
   *
   * const commandsExtension = manager.getExtension(CommandsExtension);
   * log(commandsExtension.getForcedUpdates(tr))
   * ```
   *
   * This can be used for updating:
   *
   * - `nodeViews`
   * - `editable` status of the editor
   * - `attributes` - for the top level node
   *
   * @internal
   */
  getForcedUpdates(tr) {
    return this.getCommandMeta(tr).forcedUpdates;
  }
  /**
   * Get the command metadata.
   */
  getCommandMeta(tr) {
    const meta = tr.getMeta(this.pluginKey) ?? {};
    return { ...DEFAULT_COMMAND_META, ...meta };
  }
  setCommandMeta(tr, update) {
    const meta = this.getCommandMeta(tr);
    tr.setMeta(this.pluginKey, { ...meta, ...update });
  }
  /**
   * Add the commands from the provided `commands` property to the `chained`,
   * `original` and `unchained` objects.
   */
  addCommands(props) {
    const { extensionCommands, commands, names, active } = props;
    for (const [name, command2] of entries2(extensionCommands)) {
      throwIfNameNotUnique({ name, set: names, code: ErrorConstant5.DUPLICATE_COMMAND_NAMES });
      invariant5(!forbiddenNames.has(name), {
        code: ErrorConstant5.DUPLICATE_COMMAND_NAMES,
        message: "The command name you chose is forbidden."
      });
      commands[name] = this.createUnchainedCommand(command2, active[name]);
    }
  }
  /**
   * Create an unchained command method.
   */
  unchainedFactory(props) {
    return (...args) => {
      const { shouldDispatch = true, command: command2 } = props;
      const { view } = this.store;
      const { state } = view;
      let dispatch;
      if (shouldDispatch) {
        dispatch = view.dispatch;
      }
      return command2(...args)({ state, dispatch, view, tr: state.tr });
    };
  }
  /**
   * Create the unchained command.
   */
  createUnchainedCommand(command2, active) {
    const unchainedCommand = this.unchainedFactory({ command: command2 });
    unchainedCommand.enabled = this.unchainedFactory({ command: command2, shouldDispatch: false });
    unchainedCommand.isEnabled = unchainedCommand.enabled;
    unchainedCommand.original = command2;
    unchainedCommand.active = active;
    return unchainedCommand;
  }
  /**
   * Create a chained command method.
   */
  chainedFactory(props) {
    return (...args) => {
      const { chain: chained, command: command2, getTr, getChain } = props;
      const lazyChain = getChain();
      const { view } = this.store;
      const { state } = view;
      lazyChain.push((dispatch) => {
        return command2(...args)({ state, dispatch, view, tr: getTr() });
      });
      return chained;
    };
  }
};
__decorateClass([
  command()
], CommandsExtension.prototype, "customDispatch", 1);
__decorateClass([
  command()
], CommandsExtension.prototype, "insertText", 1);
__decorateClass([
  command()
], CommandsExtension.prototype, "selectText", 1);
__decorateClass([
  command()
], CommandsExtension.prototype, "selectMark", 1);
__decorateClass([
  command()
], CommandsExtension.prototype, "delete", 1);
__decorateClass([
  command()
], CommandsExtension.prototype, "emptyUpdate", 1);
__decorateClass([
  command()
], CommandsExtension.prototype, "forceUpdate", 1);
__decorateClass([
  command()
], CommandsExtension.prototype, "updateNodeAttributes", 1);
__decorateClass([
  command()
], CommandsExtension.prototype, "setContent", 1);
__decorateClass([
  command()
], CommandsExtension.prototype, "resetContent", 1);
__decorateClass([
  command()
], CommandsExtension.prototype, "emptySelection", 1);
__decorateClass([
  command()
], CommandsExtension.prototype, "insertNewLine", 1);
__decorateClass([
  command()
], CommandsExtension.prototype, "insertNode", 1);
__decorateClass([
  command()
], CommandsExtension.prototype, "focus", 1);
__decorateClass([
  command()
], CommandsExtension.prototype, "blur", 1);
__decorateClass([
  command()
], CommandsExtension.prototype, "setBlockNodeType", 1);
__decorateClass([
  command()
], CommandsExtension.prototype, "toggleWrappingNode", 1);
__decorateClass([
  command()
], CommandsExtension.prototype, "toggleBlockNodeItem", 1);
__decorateClass([
  command()
], CommandsExtension.prototype, "wrapInNode", 1);
__decorateClass([
  command()
], CommandsExtension.prototype, "applyMark", 1);
__decorateClass([
  command()
], CommandsExtension.prototype, "toggleMark", 1);
__decorateClass([
  command()
], CommandsExtension.prototype, "removeMark", 1);
__decorateClass([
  command()
], CommandsExtension.prototype, "setMeta", 1);
__decorateClass([
  command({
    description: ({ t }) => t(Messages.SELECT_ALL_DESCRIPTION),
    label: ({ t }) => t(Messages.SELECT_ALL_LABEL),
    shortcut: NamedShortcut.SelectAll
  })
], CommandsExtension.prototype, "selectAll", 1);
__decorateClass([
  command({
    description: ({ t }) => t(Messages.COPY_DESCRIPTION),
    label: ({ t }) => t(Messages.COPY_LABEL),
    shortcut: NamedShortcut.Copy,
    icon: "fileCopyLine"
  })
], CommandsExtension.prototype, "copy", 1);
__decorateClass([
  command({
    description: ({ t }) => t(Messages.PASTE_DESCRIPTION),
    label: ({ t }) => t(Messages.PASTE_LABEL),
    shortcut: NamedShortcut.Paste,
    icon: "clipboardLine"
  })
], CommandsExtension.prototype, "paste", 1);
__decorateClass([
  command({
    description: ({ t }) => t(Messages.CUT_DESCRIPTION),
    label: ({ t }) => t(Messages.CUT_LABEL),
    shortcut: NamedShortcut.Cut,
    icon: "scissorsFill"
  })
], CommandsExtension.prototype, "cut", 1);
__decorateClass([
  command()
], CommandsExtension.prototype, "replaceText", 1);
__decorateClass([
  helper()
], CommandsExtension.prototype, "getAllCommandOptions", 1);
__decorateClass([
  helper()
], CommandsExtension.prototype, "getCommandOptions", 1);
__decorateClass([
  helper()
], CommandsExtension.prototype, "getCommandProp", 1);
CommandsExtension = __decorateClass([
  extension({
    defaultPriority: ExtensionPriority3.Highest,
    defaultOptions: { trackerClassName: "remirror-tracker-position", trackerNodeName: "span" },
    staticKeys: ["trackerClassName", "trackerNodeName"]
  })
], CommandsExtension);
var DEFAULT_COMMAND_META = {
  forcedUpdates: []
};
var forbiddenNames = /* @__PURE__ */ new Set(["run", "chain", "original", "raw", "enabled", "tr"]);

// packages/remirror__core/src/builtins/decorations-extension.ts
import { ExtensionPriority as ExtensionPriority4 } from "@remirror/core-constants";
import { isNumber, isString as isString3, uniqueArray as uniqueArray2, uniqueId } from "@remirror/core-helpers";
import { findNodeAtPosition, isNodeSelection } from "@remirror/core-utils";
import { Decoration, DecorationSet } from "@remirror/pm/view";
var DecorationsExtension = class extends PlainExtension {
  constructor() {
    super(...arguments);
    /**
     * The placeholder decorations.
     */
    this.placeholders = DecorationSet.empty;
    /**
     * A map of the html elements to their decorations.
     */
    this.placeholderWidgets = /* @__PURE__ */ new Map();
    /**
     * Handles delayed commands which rely on the
     */
    this.createPlaceholderCommand = (props) => {
      const id = uniqueId();
      const { promise, placeholder, onFailure, onSuccess } = props;
      return new DelayedCommand(promise).validate((props2) => {
        return this.addPlaceholder(id, placeholder)(props2);
      }).success((props2) => {
        const { state, tr, dispatch, view, value } = props2;
        const range = this.store.helpers.findPlaceholder(id);
        if (!range) {
          const error = new Error("The placeholder has been removed");
          return (onFailure == null ? void 0 : onFailure({ error, state, tr, dispatch, view })) ?? false;
        }
        this.removePlaceholder(id)({ state, tr, view, dispatch: () => {
        } });
        return onSuccess(value, range, { state, tr, dispatch, view });
      }).failure((props2) => {
        this.removePlaceholder(id)({ ...props2, dispatch: () => {
        } });
        return (onFailure == null ? void 0 : onFailure(props2)) ?? false;
      });
    };
  }
  get name() {
    return "decorations";
  }
  onCreate() {
    this.store.setExtensionStore("createPlaceholderCommand", this.createPlaceholderCommand);
  }
  /**
   * Create the extension plugin for inserting decorations into the editor.
   */
  createPlugin() {
    return {
      state: {
        init: () => {
        },
        apply: (tr) => {
          var _a, _b, _c, _d, _e, _f;
          const { added, clearTrackers, removed, updated } = this.getMeta(tr);
          if (clearTrackers) {
            this.placeholders = DecorationSet.empty;
            for (const [, widget] of this.placeholderWidgets) {
              (_b = (_a = widget.spec).onDestroy) == null ? void 0 : _b.call(_a, this.store.view, widget.spec.element);
            }
            this.placeholderWidgets.clear();
            return;
          }
          this.placeholders = this.placeholders.map(tr.mapping, tr.doc, {
            onRemove: (spec) => {
              var _a2, _b2;
              const widget = this.placeholderWidgets.get(spec.id);
              if (widget) {
                (_b2 = (_a2 = widget.spec).onDestroy) == null ? void 0 : _b2.call(_a2, this.store.view, widget.spec.element);
              }
            }
          });
          for (const [, widget] of this.placeholderWidgets) {
            (_d = (_c = widget.spec).onUpdate) == null ? void 0 : _d.call(
              _c,
              this.store.view,
              widget.from,
              widget.spec.element,
              widget.spec.data
            );
          }
          for (const placeholder of added) {
            if (placeholder.type === "inline") {
              this.addInlinePlaceholder(placeholder, tr);
              continue;
            }
            if (placeholder.type === "node") {
              this.addNodePlaceholder(placeholder, tr);
              continue;
            }
            if (placeholder.type === "widget") {
              this.addWidgetPlaceholder(placeholder, tr);
              continue;
            }
          }
          for (const { id, data } of updated) {
            const widget = this.placeholderWidgets.get(id);
            if (!widget) {
              continue;
            }
            const updatedWidget = Decoration.widget(widget.from, widget.spec.element, {
              ...widget.spec,
              data
            });
            this.placeholders = this.placeholders.remove([widget]).add(tr.doc, [updatedWidget]);
            this.placeholderWidgets.set(id, updatedWidget);
          }
          for (const id of removed) {
            const found = this.placeholders.find(
              void 0,
              void 0,
              (spec) => spec.id === id && spec.__type === __type
            );
            const widget = this.placeholderWidgets.get(id);
            if (widget) {
              (_f = (_e = widget.spec).onDestroy) == null ? void 0 : _f.call(_e, this.store.view, widget.spec.element);
            }
            this.placeholders = this.placeholders.remove(found);
            this.placeholderWidgets.delete(id);
          }
        }
      },
      props: {
        decorations: (state) => {
          let decorationSet = this.options.decorations(state);
          decorationSet = decorationSet.add(state.doc, this.placeholders.find());
          for (const extension2 of this.store.extensions) {
            if (!extension2.createDecorations) {
              continue;
            }
            const decorations = extension2.createDecorations(state).find();
            decorationSet = decorationSet.add(state.doc, decorations);
          }
          return decorationSet;
        },
        handleDOMEvents: {
          // Dispatch a transaction for focus/blur events so that the editor state
          // can be refreshed.
          //
          // https://discuss.prosemirror.net/t/handling-focus-in-plugins/1981/2
          blur: (view) => {
            if (this.options.persistentSelectionClass) {
              view.dispatch(view.state.tr.setMeta(persistentSelectionFocusKey, false));
            }
            return false;
          },
          focus: (view) => {
            if (this.options.persistentSelectionClass) {
              view.dispatch(view.state.tr.setMeta(persistentSelectionFocusKey, true));
            }
            return false;
          }
        }
      }
    };
  }
  updateDecorations() {
    return ({ tr, dispatch }) => (dispatch == null ? void 0 : dispatch(tr), true);
  }
  addPlaceholder(id, placeholder, deleteSelection) {
    return ({ dispatch, tr }) => {
      return this.addPlaceholderTransaction(id, placeholder, tr, !dispatch) ? (dispatch == null ? void 0 : dispatch(deleteSelection ? tr.deleteSelection() : tr), true) : false;
    };
  }
  updatePlaceholder(id, data) {
    return ({ dispatch, tr }) => {
      return this.updatePlaceholderTransaction({ id, data, tr, checkOnly: !dispatch }) ? (dispatch == null ? void 0 : dispatch(tr), true) : false;
    };
  }
  removePlaceholder(id) {
    return ({ dispatch, tr }) => {
      return this.removePlaceholderTransaction({ id, tr, checkOnly: !dispatch }) ? (dispatch == null ? void 0 : dispatch(tr), true) : false;
    };
  }
  clearPlaceholders() {
    return ({ tr, dispatch }) => {
      return this.clearPlaceholdersTransaction({ tr, checkOnly: !dispatch }) ? (dispatch == null ? void 0 : dispatch(tr), true) : false;
    };
  }
  findPlaceholder(id) {
    return this.findAllPlaceholders().get(id);
  }
  findAllPlaceholders() {
    const trackers = /* @__PURE__ */ new Map();
    const found = this.placeholders.find(void 0, void 0, (spec) => spec.__type === __type);
    for (const decoration of found) {
      trackers.set(decoration.spec.id, { from: decoration.from, to: decoration.to });
    }
    return trackers;
  }
  /**
   * Add some decorations based on the provided settings.
   */
  createDecorations(state) {
    var _a, _b, _c;
    const { persistentSelectionClass } = this.options;
    if (!persistentSelectionClass || ((_a = this.store.view) == null ? void 0 : _a.hasFocus()) || ((_c = (_b = this.store.helpers).isInteracting) == null ? void 0 : _c.call(_b))) {
      return DecorationSet.empty;
    }
    return generatePersistentSelectionDecorations(state, DecorationSet.empty, {
      class: isString3(persistentSelectionClass) ? persistentSelectionClass : "selection"
    });
  }
  /**
   * This stores all tracked positions in the editor and maps them via the
   * transaction updates.
   */
  onApplyState() {
  }
  /**
   * Add a widget placeholder and track it as a widget placeholder.
   */
  addWidgetPlaceholder(placeholder, tr) {
    const { pos, createElement, onDestroy, onUpdate, className, nodeName, id, type } = placeholder;
    const element = (createElement == null ? void 0 : createElement(this.store.view, pos)) ?? document.createElement(nodeName);
    element.classList.add(className);
    const decoration = Decoration.widget(pos, element, {
      id,
      __type,
      type,
      element,
      onDestroy,
      onUpdate
    });
    this.placeholderWidgets.set(id, decoration);
    this.placeholders = this.placeholders.add(tr.doc, [decoration]);
  }
  /**
   * Add an inline placeholder.
   */
  addInlinePlaceholder(placeholder, tr) {
    const {
      from = tr.selection.from,
      to = tr.selection.to,
      className,
      nodeName,
      id,
      type
    } = placeholder;
    let decoration;
    if (from === to) {
      const element = document.createElement(nodeName);
      element.classList.add(className);
      decoration = Decoration.widget(from, element, {
        id,
        type,
        __type,
        widget: element
      });
    } else {
      decoration = Decoration.inline(
        from,
        to,
        { nodeName, class: className },
        {
          id,
          __type
        }
      );
    }
    this.placeholders = this.placeholders.add(tr.doc, [decoration]);
  }
  /**
   * Add a placeholder for nodes.
   */
  addNodePlaceholder(placeholder, tr) {
    const { pos, className, nodeName, id } = placeholder;
    const $pos = isNumber(pos) ? tr.doc.resolve(pos) : tr.selection.$from;
    const found = isNumber(pos) ? $pos.nodeAfter ? { pos, end: $pos.nodeAfter.nodeSize } : void 0 : findNodeAtPosition($pos);
    if (!found) {
      return;
    }
    const decoration = Decoration.node(
      found.pos,
      found.end,
      { nodeName, class: className },
      { id, __type }
    );
    this.placeholders = this.placeholders.add(tr.doc, [decoration]);
  }
  /**
   * Add the node and class name to the placeholder object.
   */
  withRequiredBase(id, placeholder) {
    const { placeholderNodeName, placeholderClassName } = this.options;
    const { nodeName = placeholderNodeName, className, ...rest } = placeholder;
    const classes = (className ? [placeholderClassName, className] : [placeholderClassName]).join(
      " "
    );
    return { nodeName, className: classes, ...rest, id };
  }
  /**
   * Get the command metadata.
   */
  getMeta(tr) {
    const meta = tr.getMeta(this.pluginKey) ?? {};
    return { ...DEFAULT_PLACEHOLDER_META, ...meta };
  }
  /**
   * Set the metadata for the command.
   */
  setMeta(tr, update) {
    const meta = this.getMeta(tr);
    tr.setMeta(this.pluginKey, { ...meta, ...update });
  }
  /**
   * Add a placeholder decoration with the specified params to the transaction
   * and return the transaction.
   *
   * It is up to you to dispatch the transaction or you can just use the
   * commands.
   */
  addPlaceholderTransaction(id, placeholder, tr, checkOnly = false) {
    const existingPosition = this.findPlaceholder(id);
    if (existingPosition) {
      return false;
    }
    if (checkOnly) {
      return true;
    }
    const { added } = this.getMeta(tr);
    this.setMeta(tr, {
      added: [...added, this.withRequiredBase(id, placeholder)]
    });
    return true;
  }
  /**
   * Update the data stored by a placeholder.
   *
   * This replaces the whole data value.
   */
  updatePlaceholderTransaction(props) {
    const { id, tr, checkOnly = false, data } = props;
    const existingPosition = this.findPlaceholder(id);
    if (!existingPosition) {
      return false;
    }
    if (checkOnly) {
      return true;
    }
    const { updated } = this.getMeta(tr);
    this.setMeta(tr, { updated: uniqueArray2([...updated, { id, data }]) });
    return true;
  }
  /**
   * Discards a previously defined tracker once not needed.
   *
   * This should be used to cleanup once the position is no longer needed.
   */
  removePlaceholderTransaction(props) {
    const { id, tr, checkOnly = false } = props;
    const existingPosition = this.findPlaceholder(id);
    if (!existingPosition) {
      return false;
    }
    if (checkOnly) {
      return true;
    }
    const { removed } = this.getMeta(tr);
    this.setMeta(tr, { removed: uniqueArray2([...removed, id]) });
    return true;
  }
  /**
   * This helper returns a transaction that clears all position trackers when
   * any exist.
   *
   * Otherwise it returns undefined.
   */
  clearPlaceholdersTransaction(props) {
    const { tr, checkOnly = false } = props;
    const positionTrackerState = this.getPluginState();
    if (positionTrackerState === DecorationSet.empty) {
      return false;
    }
    if (checkOnly) {
      return true;
    }
    this.setMeta(tr, { clearTrackers: true });
    return true;
  }
};
__decorateClass([
  command()
], DecorationsExtension.prototype, "updateDecorations", 1);
__decorateClass([
  command()
], DecorationsExtension.prototype, "addPlaceholder", 1);
__decorateClass([
  command()
], DecorationsExtension.prototype, "updatePlaceholder", 1);
__decorateClass([
  command()
], DecorationsExtension.prototype, "removePlaceholder", 1);
__decorateClass([
  command()
], DecorationsExtension.prototype, "clearPlaceholders", 1);
__decorateClass([
  helper()
], DecorationsExtension.prototype, "findPlaceholder", 1);
__decorateClass([
  helper()
], DecorationsExtension.prototype, "findAllPlaceholders", 1);
DecorationsExtension = __decorateClass([
  extension({
    defaultOptions: {
      persistentSelectionClass: void 0,
      placeholderClassName: "placeholder",
      placeholderNodeName: "span"
    },
    staticKeys: ["placeholderClassName", "placeholderNodeName"],
    handlerKeys: ["decorations"],
    handlerKeyOptions: {
      decorations: {
        reducer: {
          accumulator: (accumulated, latestValue, state) => {
            return accumulated.add(state.doc, latestValue.find());
          },
          getDefault: () => DecorationSet.empty
        }
      }
    },
    defaultPriority: ExtensionPriority4.Low
  })
], DecorationsExtension);
var DEFAULT_PLACEHOLDER_META = {
  added: [],
  updated: [],
  clearTrackers: false,
  removed: []
};
var __type = "placeholderDecoration";
var persistentSelectionFocusKey = "persistentSelectionFocus";
function generatePersistentSelectionDecorations(state, decorationSet, attrs) {
  const { selection, doc } = state;
  if (selection.empty) {
    return decorationSet;
  }
  const { from, to } = selection;
  const decoration = isNodeSelection(selection) ? Decoration.node(from, to, attrs) : Decoration.inline(from, to, attrs);
  return decorationSet.add(doc, [decoration]);
}

// packages/remirror__core/src/builtins/doc-changed-extension.ts
import { ExtensionPriority as ExtensionPriority5 } from "@remirror/core-constants";
var DocChangedExtension = class extends PlainExtension {
  get name() {
    return "docChanged";
  }
  onStateUpdate(props) {
    const { firstUpdate, transactions, tr } = props;
    if (firstUpdate) {
      return;
    }
    if ((transactions ?? [tr]).some((tr2) => tr2 == null ? void 0 : tr2.docChanged)) {
      this.options.docChanged(props);
    }
  }
};
DocChangedExtension = __decorateClass([
  extension({
    handlerKeys: ["docChanged"],
    handlerKeyOptions: {
      docChanged: { earlyReturnValue: false }
      // Execute all handlers, even if one returns false
    },
    defaultPriority: ExtensionPriority5.Lowest
  })
], DocChangedExtension);

// packages/remirror__core/src/builtins/helpers-extension.ts
import { ErrorConstant as ErrorConstant6, NULL_CHARACTER } from "@remirror/core-constants";
import { entries as entries3, isEmptyObject as isEmptyObject2, object as object5 } from "@remirror/core-helpers";
import {
  containsAttributes,
  getActiveNode,
  getMarkRange as getMarkRange2,
  htmlToProsemirrorNode as htmlToProsemirrorNode2,
  isMarkActive as isMarkActive2,
  isNodeActive,
  isSelectionEmpty,
  prosemirrorNodeToHtml
} from "@remirror/core-utils";
var HelpersExtension = class extends PlainExtension {
  get name() {
    return "helpers";
  }
  /**
   * Add the `html` and `text` string handlers to the editor.
   */
  onCreate() {
    var _a;
    this.store.setStringHandler("text", this.textToProsemirrorNode.bind(this));
    this.store.setStringHandler("html", htmlToProsemirrorNode2);
    const helpers = object5();
    const active = object5();
    const attrs = object5();
    const names = /* @__PURE__ */ new Set();
    for (const extension2 of this.store.extensions) {
      if (isNodeExtension(extension2)) {
        active[extension2.name] = (attrs2) => {
          return isNodeActive({ state: this.store.getState(), type: extension2.type, attrs: attrs2 });
        };
        attrs[extension2.name] = (attrs2) => {
          var _a2;
          return (_a2 = getActiveNode({ state: this.store.getState(), type: extension2.type, attrs: attrs2 })) == null ? void 0 : _a2.node.attrs;
        };
      }
      if (isMarkExtension(extension2)) {
        active[extension2.name] = (attrs2) => {
          return isMarkActive2({ trState: this.store.getState(), type: extension2.type, attrs: attrs2 });
        };
        attrs[extension2.name] = (attrs2) => {
          const markRange = getMarkRange2(this.store.getState().selection.$from, extension2.type);
          if (!markRange || !attrs2) {
            return markRange == null ? void 0 : markRange.mark.attrs;
          }
          if (containsAttributes(markRange.mark, attrs2)) {
            return markRange.mark.attrs;
          }
          return;
        };
      }
      const extensionHelpers = ((_a = extension2.createHelpers) == null ? void 0 : _a.call(extension2)) ?? {};
      for (const helperName of Object.keys(extension2.decoratedHelpers ?? {})) {
        extensionHelpers[helperName] = extension2[helperName].bind(extension2);
      }
      if (isEmptyObject2(extensionHelpers)) {
        continue;
      }
      for (const [name, helper2] of entries3(extensionHelpers)) {
        throwIfNameNotUnique({ name, set: names, code: ErrorConstant6.DUPLICATE_HELPER_NAMES });
        helpers[name] = helper2;
      }
    }
    this.store.setStoreKey("attrs", attrs);
    this.store.setStoreKey("active", active);
    this.store.setStoreKey("helpers", helpers);
    this.store.setExtensionStore("attrs", attrs);
    this.store.setExtensionStore("active", active);
    this.store.setExtensionStore("helpers", helpers);
  }
  isSelectionEmpty(state = this.store.getState()) {
    return isSelectionEmpty(state);
  }
  isViewEditable(state = this.store.getState()) {
    var _a, _b;
    return ((_b = (_a = this.store.view.props).editable) == null ? void 0 : _b.call(_a, state)) ?? false;
  }
  getStateJSON(state = this.store.getState()) {
    return state.toJSON();
  }
  getJSON(state = this.store.getState()) {
    return state.doc.toJSON();
  }
  getRemirrorJSON(state = this.store.getState()) {
    return this.getJSON(state);
  }
  insertHtml(html, options) {
    return (props) => {
      const { state } = props;
      const fragment = htmlToProsemirrorNode2({
        content: html,
        schema: state.schema,
        fragment: true
      });
      return this.store.commands.insertNode.original(fragment, options)(props);
    };
  }
  getText({
    lineBreakDivider = "\n\n",
    state = this.store.getState()
  } = {}) {
    return state.doc.textBetween(0, state.doc.content.size, lineBreakDivider, NULL_CHARACTER);
  }
  getTextBetween(from, to, doc = this.store.getState().doc) {
    return doc.textBetween(from, to, "\n\n", NULL_CHARACTER);
  }
  getHTML(state = this.store.getState()) {
    return prosemirrorNodeToHtml(state.doc, this.store.document);
  }
  textToProsemirrorNode(options) {
    const content = `<pre>${options.content}</pre>`;
    return this.store.stringHandlers.html({ ...options, content });
  }
};
__decorateClass([
  helper()
], HelpersExtension.prototype, "isSelectionEmpty", 1);
__decorateClass([
  helper()
], HelpersExtension.prototype, "isViewEditable", 1);
__decorateClass([
  helper()
], HelpersExtension.prototype, "getStateJSON", 1);
__decorateClass([
  helper()
], HelpersExtension.prototype, "getJSON", 1);
__decorateClass([
  helper()
], HelpersExtension.prototype, "getRemirrorJSON", 1);
__decorateClass([
  command()
], HelpersExtension.prototype, "insertHtml", 1);
__decorateClass([
  helper()
], HelpersExtension.prototype, "getText", 1);
__decorateClass([
  helper()
], HelpersExtension.prototype, "getTextBetween", 1);
__decorateClass([
  helper()
], HelpersExtension.prototype, "getHTML", 1);
HelpersExtension = __decorateClass([
  extension({})
], HelpersExtension);

// packages/remirror__core/src/builtins/input-rules-extension.ts
import { ExtensionPriority as ExtensionPriority6, ExtensionTag } from "@remirror/core-constants";
import { inputRules } from "@remirror/pm/inputrules";
var InputRulesExtension = class extends PlainExtension {
  get name() {
    return "inputRules";
  }
  /**
   * Add the extension store method for rebuilding all input rules.
   */
  onCreate() {
    this.store.setExtensionStore("rebuildInputRules", this.rebuildInputRules.bind(this));
  }
  /**
   * Add the `inputRules` plugin to the editor.
   */
  createExternalPlugins() {
    return [this.generateInputRulesPlugin()];
  }
  generateInputRulesPlugin() {
    var _a, _b;
    const rules = [];
    const invalidMarks = this.store.markTags[ExtensionTag.ExcludeInputRules];
    for (const extension2 of this.store.extensions) {
      if (
        // managerSettings excluded this from running
        ((_a = this.store.managerSettings.exclude) == null ? void 0 : _a.inputRules) || // Method doesn't exist
        !extension2.createInputRules || // Extension settings exclude it
        ((_b = extension2.options.exclude) == null ? void 0 : _b.inputRules)
      ) {
        continue;
      }
      for (const rule of extension2.createInputRules()) {
        rule.shouldSkip = this.options.shouldSkipInputRule;
        rule.invalidMarks = invalidMarks;
        rules.push(rule);
      }
    }
    return inputRules({ rules });
  }
  /**
   * The method for rebuilding all the input rules.
   *
   * 1. Rebuild inputRules.
   * 2. Replace the old input rules plugin.
   * 3. Update the plugins used in the state (triggers an editor update).
   */
  rebuildInputRules() {
    this.store.updateExtensionPlugins(this);
  }
};
InputRulesExtension = __decorateClass([
  extension({
    defaultPriority: ExtensionPriority6.Default,
    handlerKeys: ["shouldSkipInputRule"],
    // Return when the value `true` is encountered.
    handlerKeyOptions: { shouldSkipInputRule: { earlyReturnValue: true } }
  })
], InputRulesExtension);

// packages/remirror__core/src/builtins/keymap-extension.ts
import { ExtensionPriority as ExtensionPriority7, ExtensionTag as ExtensionTag2, NamedShortcut as NamedShortcut2 } from "@remirror/core-constants";
import {
  entries as entries4,
  includes,
  isArray,
  isEmptyArray as isEmptyArray3,
  isFunction as isFunction3,
  isString as isString4,
  isUndefined,
  object as object6,
  sort as sort2,
  values
} from "@remirror/core-helpers";
import {
  chainKeyBindingCommands,
  convertCommand as convertCommand2,
  environment as environment3,
  findParentNodeOfType,
  isDefaultBlockNode,
  isEmptyBlockNode as isEmptyBlockNode2,
  isEndOfTextBlock,
  isStartOfDoc,
  isStartOfTextBlock,
  mergeProsemirrorKeyBindings
} from "@remirror/core-utils";
import {
  baseKeymap,
  chainCommands as pmChainCommands,
  selectParentNode
} from "@remirror/pm/commands";
import { undoInputRule } from "@remirror/pm/inputrules";
import { keydownHandler } from "@remirror/pm/keymap";
import { Plugin } from "@remirror/pm/state";
var KeymapExtension = class extends PlainExtension {
  constructor() {
    super(...arguments);
    /**
     * The custom keybindings added by the handlers. In react these can be added
     * via `hooks`.
     */
    this.extraKeyBindings = [];
    /**
     * Track the backward exits from a mark to allow double tapping the left arrow
     * to move to the previous block node.
     */
    this.backwardMarkExitTracker = /* @__PURE__ */ new Map();
    /**
     * The underlying keydown handler.
     */
    this.keydownHandler = null;
    /**
     * @internalremarks
     *
     * Think about the case where bindings are disposed of and then added in a
     * different position in the `extraKeyBindings` array. This is especially
     * pertinent when using hooks.
     */
    this.onAddCustomHandler = ({ keymap }) => {
      var _a, _b;
      if (!keymap) {
        return;
      }
      this.extraKeyBindings = [...this.extraKeyBindings, keymap];
      (_b = (_a = this.store).rebuildKeymap) == null ? void 0 : _b.call(_a);
      return () => {
        var _a2, _b2;
        this.extraKeyBindings = this.extraKeyBindings.filter((binding) => binding !== keymap);
        (_b2 = (_a2 = this.store).rebuildKeymap) == null ? void 0 : _b2.call(_a2);
      };
    };
    /**
     * The method for rebuilding all the extension keymaps.
     *
     * 1. Rebuild keymaps.
     * 2. Replace `this.keydownHandler` with the new keydown handler.
     */
    this.rebuildKeymap = () => {
      this.setupKeydownHandler();
    };
  }
  get name() {
    return "keymap";
  }
  /**
   * Get the shortcut map.
   */
  get shortcutMap() {
    const { shortcuts } = this.options;
    return isString4(shortcuts) ? keyboardShortcuts[shortcuts] : shortcuts;
  }
  /**
   * This adds the `createKeymap` method functionality to all extensions.
   */
  onCreate() {
    this.store.setExtensionStore("rebuildKeymap", this.rebuildKeymap);
  }
  /** Add the created keymap to the available plugins. */
  createExternalPlugins() {
    var _a;
    if (
      // The user doesn't want any keymaps in the editor so don't add the keymap
      // handler.
      (_a = this.store.managerSettings.exclude) == null ? void 0 : _a.keymap
    ) {
      return [];
    }
    this.setupKeydownHandler();
    return [
      new Plugin({
        props: {
          handleKeyDown: (view, event) => {
            var _a2;
            return (_a2 = this.keydownHandler) == null ? void 0 : _a2.call(this, view, event);
          }
        }
      })
    ];
  }
  setupKeydownHandler() {
    const bindings = this.generateKeymapBindings();
    this.keydownHandler = keydownHandler(bindings);
  }
  /**
   * Updates the stored keymap bindings on this extension.
   */
  generateKeymapBindings() {
    var _a;
    const extensionKeymaps = [];
    const shortcutMap = this.shortcutMap;
    const commandsExtension = this.store.getExtension(CommandsExtension);
    const extractNamesFactory = (extension2) => (shortcut) => extractShortcutNames({
      shortcut,
      map: shortcutMap,
      store: this.store,
      options: extension2.options
    });
    for (const extension2 of this.store.extensions) {
      const decoratedKeybindings = extension2.decoratedKeybindings ?? {};
      if (
        // The extension was configured to ignore the keymap.
        (_a = extension2.options.exclude) == null ? void 0 : _a.keymap
      ) {
        continue;
      }
      if (
        // The extension doesn't have the `createKeymap` method.
        extension2.createKeymap
      ) {
        extensionKeymaps.push(
          updateNamedKeys(extension2.createKeymap(extractNamesFactory(extension2)), shortcutMap)
        );
      }
      for (const [name, options] of entries4(decoratedKeybindings)) {
        if (options.isActive && !options.isActive(extension2.options, this.store)) {
          continue;
        }
        const keyBinding2 = extension2[name].bind(extension2);
        const shortcutNames = extractShortcutNames({
          shortcut: options.shortcut,
          map: shortcutMap,
          options: extension2.options,
          store: this.store
        });
        const priority = isFunction3(options.priority) ? options.priority(extension2.options, this.store) : options.priority ?? ExtensionPriority7.Low;
        const bindingObject = object6();
        for (const shortcut of shortcutNames) {
          bindingObject[shortcut] = keyBinding2;
        }
        extensionKeymaps.push([priority, bindingObject]);
        if (options.command) {
          commandsExtension.updateDecorated(options.command, { shortcut: shortcutNames });
        }
      }
    }
    const sortedKeymaps = this.sortKeymaps([...this.extraKeyBindings, ...extensionKeymaps]);
    const mappedCommands = mergeProsemirrorKeyBindings(sortedKeymaps);
    return mappedCommands;
  }
  arrowRightShortcut(props) {
    const excludedMarks = this.store.markTags[ExtensionTag2.PreventExits];
    const excludedNodes = this.store.nodeTags[ExtensionTag2.PreventExits];
    return this.exitMarkForwards(excludedMarks, excludedNodes)(props);
  }
  arrowLeftShortcut(props) {
    const excludedMarks = this.store.markTags[ExtensionTag2.PreventExits];
    const excludedNodes = this.store.nodeTags[ExtensionTag2.PreventExits];
    return chainKeyBindingCommands(
      this.exitNodeBackwards(excludedNodes),
      this.exitMarkBackwards(excludedMarks, excludedNodes)
    )(props);
  }
  backspace(props) {
    const excludedMarks = this.store.markTags[ExtensionTag2.PreventExits];
    const excludedNodes = this.store.nodeTags[ExtensionTag2.PreventExits];
    return chainKeyBindingCommands(
      this.exitNodeBackwards(excludedNodes, true),
      this.exitMarkBackwards(excludedMarks, excludedNodes, true)
    )(props);
  }
  /**
   * Create the base keymap and give it a low priority so that all other keymaps
   * override it.
   */
  createKeymap() {
    const { selectParentNodeOnEscape, undoInputRuleOnBackspace, excludeBaseKeymap } = this.options;
    const baseKeyBindings = object6();
    if (!excludeBaseKeymap) {
      for (const [key2, value] of entries4(baseKeymap)) {
        baseKeyBindings[key2] = convertCommand2(value);
      }
    }
    if (undoInputRuleOnBackspace && baseKeymap.Backspace) {
      baseKeyBindings.Backspace = convertCommand2(
        pmChainCommands(undoInputRule, baseKeymap.Backspace)
      );
    }
    if (selectParentNodeOnEscape) {
      baseKeyBindings.Escape = convertCommand2(selectParentNode);
    }
    return [ExtensionPriority7.Low, baseKeyBindings];
  }
  getNamedShortcut(shortcut, options = {}) {
    if (!shortcut.startsWith("_|")) {
      return [shortcut];
    }
    return extractShortcutNames({
      shortcut,
      map: this.shortcutMap,
      store: this.store,
      options
    });
  }
  /**
   * Handle changes in the dynamic properties.
   */
  onSetOptions(props) {
    var _a, _b;
    const { changes } = props;
    if (changes.excludeBaseKeymap.changed || changes.selectParentNodeOnEscape.changed || changes.undoInputRuleOnBackspace.changed) {
      (_b = (_a = this.store).rebuildKeymap) == null ? void 0 : _b.call(_a);
    }
  }
  sortKeymaps(bindings) {
    return sort2(
      bindings.map(
        (binding) => (
          // Make all bindings prioritized a default priority of
          // `ExtensionPriority.Default`
          isArray(binding) ? binding : [ExtensionPriority7.Default, binding]
        )
      ),
      // Sort from highest binding to the lowest.
      (a, z) => z[0] - a[0]
      // Extract the bindings from the prioritized tuple.
    ).map((binding) => binding[1]);
  }
  /**
   * Exits the mark forwards when at the end of a block node.
   */
  exitMarkForwards(excludedMarks, excludedNodes) {
    return (props) => {
      const { tr, dispatch } = props;
      if (!isEndOfTextBlock(tr.selection)) {
        return false;
      }
      const isInsideExcludedNode = findParentNodeOfType({
        selection: tr.selection,
        types: excludedNodes
      });
      if (isInsideExcludedNode) {
        return false;
      }
      const $pos = tr.selection.$from;
      const marksToRemove = $pos.marks().filter((mark) => !excludedMarks.includes(mark.type.name));
      if (isEmptyArray3(marksToRemove)) {
        return false;
      }
      if (!dispatch) {
        return true;
      }
      for (const mark of marksToRemove) {
        tr.removeStoredMark(mark);
      }
      dispatch(tr.insertText(" ", tr.selection.from));
      return true;
    };
  }
  exitNodeBackwards(excludedNodes, startOfDoc = false) {
    return (props) => {
      const { tr } = props;
      const checker = startOfDoc ? isStartOfDoc : isStartOfTextBlock;
      if (!checker(tr.selection)) {
        return false;
      }
      const node = tr.selection.$anchor.node();
      if (!isEmptyBlockNode2(node) || isDefaultBlockNode(node) || excludedNodes.includes(node.type.name)) {
        return false;
      }
      return this.store.commands.toggleBlockNodeItem.original({ type: node.type })(props);
    };
  }
  /**
   * Exit a mark when at the beginning of a block node.
   */
  exitMarkBackwards(excludedMarks, excludedNodes, startOfDoc = false) {
    return (props) => {
      const { tr, dispatch } = props;
      const checker = startOfDoc ? isStartOfDoc : isStartOfTextBlock;
      if (!checker(tr.selection) || this.backwardMarkExitTracker.has(tr.selection.anchor)) {
        this.backwardMarkExitTracker.clear();
        return false;
      }
      const isInsideExcludedNode = findParentNodeOfType({
        selection: tr.selection,
        types: excludedNodes
      });
      if (isInsideExcludedNode) {
        return false;
      }
      const marksToRemove = [...tr.storedMarks ?? [], ...tr.selection.$from.marks()].filter(
        (mark) => !excludedMarks.includes(mark.type.name)
      );
      if (isEmptyArray3(marksToRemove)) {
        return false;
      }
      if (!dispatch) {
        return true;
      }
      for (const mark of marksToRemove) {
        tr.removeStoredMark(mark);
      }
      this.backwardMarkExitTracker.set(tr.selection.anchor, true);
      dispatch(tr);
      return true;
    };
  }
};
__decorateClass([
  keyBinding({
    shortcut: "ArrowRight",
    isActive: (options) => options.exitMarksOnArrowPress
  })
], KeymapExtension.prototype, "arrowRightShortcut", 1);
__decorateClass([
  keyBinding({
    shortcut: "ArrowLeft",
    isActive: (options) => options.exitMarksOnArrowPress
  })
], KeymapExtension.prototype, "arrowLeftShortcut", 1);
__decorateClass([
  keyBinding({
    shortcut: "Backspace",
    isActive: (options) => options.exitMarksOnArrowPress
  })
], KeymapExtension.prototype, "backspace", 1);
__decorateClass([
  helper()
], KeymapExtension.prototype, "getNamedShortcut", 1);
KeymapExtension = __decorateClass([
  extension({
    defaultPriority: ExtensionPriority7.Low,
    defaultOptions: {
      shortcuts: "default",
      undoInputRuleOnBackspace: true,
      selectParentNodeOnEscape: false,
      excludeBaseKeymap: false,
      exitMarksOnArrowPress: true
    },
    customHandlerKeys: ["keymap"]
  })
], KeymapExtension);
function isNamedShortcut(value) {
  return includes(values(NamedShortcut2), value);
}
function extractShortcutNames({
  shortcut,
  map,
  options,
  store
}) {
  if (isString4(shortcut)) {
    return [normalizeShortcutName(shortcut, map)];
  }
  if (isArray(shortcut)) {
    return shortcut.map((value) => normalizeShortcutName(value, map));
  }
  shortcut = shortcut(options, store);
  return extractShortcutNames({ shortcut, map, options, store });
}
function normalizeShortcutName(value, shortcutMap) {
  return isNamedShortcut(value) ? shortcutMap[value] : value;
}
function updateNamedKeys(prioritizedBindings, shortcutMap) {
  const updatedBindings = {};
  let previousBindings;
  let priority;
  if (isArray(prioritizedBindings)) {
    [priority, previousBindings] = prioritizedBindings;
  } else {
    previousBindings = prioritizedBindings;
  }
  for (const [shortcutName, commandFunction] of entries4(previousBindings)) {
    updatedBindings[normalizeShortcutName(shortcutName, shortcutMap)] = commandFunction;
  }
  return isUndefined(priority) ? updatedBindings : [priority, updatedBindings];
}
var DEFAULT_SHORTCUTS = {
  [NamedShortcut2.Copy]: "Mod-c",
  [NamedShortcut2.Cut]: "Mod-x",
  [NamedShortcut2.Paste]: "Mod-v",
  [NamedShortcut2.PastePlain]: "Mod-Shift-v",
  [NamedShortcut2.SelectAll]: "Mod-a",
  [NamedShortcut2.Undo]: "Mod-z",
  [NamedShortcut2.Redo]: environment3.isMac ? "Shift-Mod-z" : "Mod-y",
  [NamedShortcut2.Bold]: "Mod-b",
  [NamedShortcut2.Italic]: "Mod-i",
  [NamedShortcut2.Underline]: "Mod-u",
  [NamedShortcut2.Strike]: "Mod-d",
  [NamedShortcut2.Code]: "Mod-`",
  [NamedShortcut2.Paragraph]: "Mod-Shift-0",
  [NamedShortcut2.H1]: "Mod-Shift-1",
  [NamedShortcut2.H2]: "Mod-Shift-2",
  [NamedShortcut2.H3]: "Mod-Shift-3",
  [NamedShortcut2.H4]: "Mod-Shift-4",
  [NamedShortcut2.H5]: "Mod-Shift-5",
  [NamedShortcut2.H6]: "Mod-Shift-6",
  [NamedShortcut2.TaskList]: "Mod-Shift-7",
  [NamedShortcut2.BulletList]: "Mod-Shift-8",
  [NamedShortcut2.OrderedList]: "Mod-Shift-9",
  [NamedShortcut2.Quote]: "Mod->",
  [NamedShortcut2.Divider]: "Mod-Shift-|",
  [NamedShortcut2.Codeblock]: "Mod-Shift-~",
  [NamedShortcut2.ClearFormatting]: "Mod-Shift-C",
  [NamedShortcut2.Superscript]: "Mod-.",
  [NamedShortcut2.Subscript]: "Mod-,",
  [NamedShortcut2.LeftAlignment]: "Mod-Shift-L",
  [NamedShortcut2.CenterAlignment]: "Mod-Shift-E",
  [NamedShortcut2.RightAlignment]: "Mod-Shift-R",
  [NamedShortcut2.JustifyAlignment]: "Mod-Shift-J",
  [NamedShortcut2.InsertLink]: "Mod-k",
  [NamedShortcut2.Find]: "Mod-f",
  [NamedShortcut2.FindBackwards]: "Mod-Shift-f",
  [NamedShortcut2.FindReplace]: "Mod-Shift-H",
  [NamedShortcut2.AddFootnote]: "Mod-Alt-f",
  [NamedShortcut2.AddComment]: "Mod-Alt-m",
  [NamedShortcut2.ContextMenu]: "Mod-Shift-\\",
  [NamedShortcut2.IncreaseFontSize]: "Mod-Shift-.",
  [NamedShortcut2.DecreaseFontSize]: "Mod-Shift-,",
  [NamedShortcut2.IncreaseIndent]: "Tab",
  [NamedShortcut2.DecreaseIndent]: "Shift-Tab",
  [NamedShortcut2.Shortcuts]: "Mod-/",
  [NamedShortcut2.Format]: environment3.isMac ? "Alt-Shift-f" : "Shift-Ctrl-f"
};
var GOOGLE_DOC_SHORTCUTS = {
  ...DEFAULT_SHORTCUTS,
  [NamedShortcut2.Strike]: "Mod-Shift-S",
  [NamedShortcut2.Code]: "Mod-Shift-M",
  [NamedShortcut2.Paragraph]: "Mod-Alt-0",
  [NamedShortcut2.H1]: "Mod-Alt-1",
  [NamedShortcut2.H2]: "Mod-Alt-2",
  [NamedShortcut2.H3]: "Mod-Alt-3",
  [NamedShortcut2.H4]: "Mod-Alt-4",
  [NamedShortcut2.H5]: "Mod-Alt-5",
  [NamedShortcut2.H6]: "Mod-Alt-6",
  [NamedShortcut2.OrderedList]: "Mod-Alt-7",
  [NamedShortcut2.BulletList]: "Mod-Alt-8",
  [NamedShortcut2.Quote]: "Mod-Alt-9",
  [NamedShortcut2.ClearFormatting]: "Mod-\\",
  [NamedShortcut2.IncreaseIndent]: "Mod-[",
  [NamedShortcut2.DecreaseIndent]: "Mod-]"
};
var keyboardShortcuts = {
  default: DEFAULT_SHORTCUTS,
  googleDoc: GOOGLE_DOC_SHORTCUTS
};

// packages/remirror__core/src/builtins/node-views-extension.ts
import { isFunction as isFunction4, object as object7 } from "@remirror/core-helpers";
var NodeViewsExtension = class extends PlainExtension {
  get name() {
    return "nodeViews";
  }
  createPlugin() {
    const nodeViewList = [];
    const nodeViews = object7();
    for (const extension2 of this.store.extensions) {
      if (!extension2.createNodeViews) {
        continue;
      }
      const nodeView = extension2.createNodeViews();
      nodeViewList.unshift(isFunction4(nodeView) ? { [extension2.name]: nodeView } : nodeView);
    }
    nodeViewList.unshift(this.store.managerSettings.nodeViews ?? {});
    for (const nodeView of nodeViewList) {
      Object.assign(nodeViews, nodeView);
    }
    return {
      props: { nodeViews }
    };
  }
};

// packages/remirror__core/src/builtins/paste-rules-extension.ts
import { isArray as isArray2 } from "@remirror/core-helpers";
import { pasteRules } from "@remirror/pm/paste-rules";
var PasteRulesExtension = class extends PlainExtension {
  get name() {
    return "pasteRules";
  }
  createExternalPlugins() {
    return [this.generatePasteRulesPlugin()];
  }
  generatePasteRulesPlugin() {
    var _a, _b;
    const extensionPasteRules = [];
    for (const extension2 of this.store.extensions) {
      if (
        // managerSettings excluded this from running
        ((_a = this.store.managerSettings.exclude) == null ? void 0 : _a.pasteRules) || // Method doesn't exist
        !extension2.createPasteRules || // Extension settings exclude it
        ((_b = extension2.options.exclude) == null ? void 0 : _b.pasteRules)
      ) {
        continue;
      }
      const value = extension2.createPasteRules();
      const rules = isArray2(value) ? value : [value];
      extensionPasteRules.push(...rules);
    }
    return pasteRules(extensionPasteRules);
  }
};

// packages/remirror__core/src/builtins/plugins-extension.ts
import { ErrorConstant as ErrorConstant7, ExtensionPriority as ExtensionPriority8, ManagerPhase } from "@remirror/core-constants";
import { assertGet as assertGet3, invariant as invariant6, isEmptyArray as isEmptyArray4, object as object8 } from "@remirror/core-helpers";
import { Plugin as Plugin2, PluginKey } from "@remirror/pm/state";
var PluginsExtension = class extends PlainExtension {
  constructor() {
    super(...arguments);
    /**
     * All plugins created by other extension as well.
     */
    this.plugins = [];
    /**
     * The plugins added via the manager (for reference only).
     */
    this.managerPlugins = [];
    /**
     * Called when the state is is being applied after an update.
     */
    this.applyStateHandlers = [];
    /**
     * Called when the state is first initialized.
     */
    this.initStateHandlers = [];
    /**
     * Handlers for the `onAppendTransaction` lifecycle method.
     */
    this.appendTransactionHandlers = [];
    /**
     * Store the plugin keys.
     */
    this.pluginKeys = object8();
    /**
     * Store state getters for the extension.
     */
    this.stateGetters = /* @__PURE__ */ new Map();
    this.getPluginStateCreator = (key2) => (state) => {
      return key2.getState(state ?? this.store.getState());
    };
    // Method for retrieving the plugin state by the extension name.
    this.getStateByName = (identifier) => {
      const stateGetter = this.stateGetters.get(identifier);
      invariant6(stateGetter, { message: "No plugin exists for the requested extension name." });
      return stateGetter();
    };
  }
  get name() {
    return "plugins";
  }
  /**
   * This extension is responsible for adding state to the editor.
   */
  onCreate() {
    const { setStoreKey, setExtensionStore, managerSettings, extensions } = this.store;
    this.updateExtensionStore();
    const { plugins = [] } = managerSettings;
    this.updatePlugins(plugins, this.managerPlugins);
    for (const extension2 of extensions) {
      if (extension2.onApplyState) {
        this.applyStateHandlers.push(extension2.onApplyState.bind(extension2));
      }
      if (extension2.onInitState) {
        this.initStateHandlers.push(extension2.onInitState.bind(extension2));
      }
      if (extension2.onAppendTransaction) {
        this.appendTransactionHandlers.push(extension2.onAppendTransaction.bind(extension2));
      }
      this.extractExtensionPlugins(extension2);
    }
    this.managerPlugins = plugins;
    this.store.setStoreKey("plugins", this.plugins);
    setStoreKey("pluginKeys", this.pluginKeys);
    setStoreKey("getPluginState", this.getStateByName);
    setExtensionStore("getPluginState", this.getStateByName);
  }
  /**
   * Create a plugin which adds the [[`onInitState`]] and [[`onApplyState`]]
   * lifecycle methods.
   */
  createPlugin() {
    return {
      appendTransaction: (transactions, previousState, state) => {
        const tr = state.tr;
        const props = { previousState, tr, transactions, state };
        for (const handler of this.appendTransactionHandlers) {
          handler(props);
        }
        this.options.appendTransaction(props);
        return tr.docChanged || tr.steps.length > 0 || tr.selectionSet || tr.storedMarksSet ? tr : void 0;
      },
      state: {
        init: (_, state) => {
          for (const handler of this.initStateHandlers) {
            handler(state);
          }
        },
        apply: (tr, _, previousState, state) => {
          const props = { previousState, state, tr };
          for (const handler of this.applyStateHandlers) {
            handler(props);
          }
          this.options.applyState(props);
        }
      }
    };
  }
  /**
   * Get all the plugins from the extension.
   */
  extractExtensionPlugins(extension2) {
    var _a, _b;
    const isNotPluginCreator = !extension2.createPlugin && !extension2.createExternalPlugins;
    if (isNotPluginCreator || // the manager settings don't exclude plugins
    ((_a = this.store.managerSettings.exclude) == null ? void 0 : _a.plugins) || // The extension settings don't exclude plugins
    ((_b = extension2.options.exclude) == null ? void 0 : _b.plugins)) {
      return;
    }
    if (extension2.createPlugin) {
      const key2 = new PluginKey(extension2.name);
      this.pluginKeys[extension2.name] = key2;
      const getter = this.getPluginStateCreator(key2);
      extension2.pluginKey = key2;
      extension2.getPluginState = getter;
      this.stateGetters.set(extension2.name, getter);
      this.stateGetters.set(extension2.constructor, getter);
      const pluginSpec = {
        ...extension2.createPlugin(),
        key: key2
      };
      const plugin = new Plugin2(pluginSpec);
      this.updatePlugins([plugin], extension2.plugin ? [extension2.plugin] : void 0);
      extension2.plugin = plugin;
    }
    if (extension2.createExternalPlugins) {
      const externalPlugins = extension2.createExternalPlugins();
      this.updatePlugins(externalPlugins, extension2.externalPlugins);
      extension2.externalPlugins = externalPlugins;
    }
  }
  /**
   * Add or replace a plugin.
   */
  updatePlugins(plugins, previous) {
    if (!previous || isEmptyArray4(previous)) {
      this.plugins = [...this.plugins, ...plugins];
      return;
    }
    if (plugins.length !== previous.length) {
      this.plugins = [...this.plugins.filter((plugin) => !previous.includes(plugin)), ...plugins];
      return;
    }
    const pluginMap = /* @__PURE__ */ new Map();
    for (const [index, plugin] of plugins.entries()) {
      pluginMap.set(assertGet3(previous, index), plugin);
    }
    this.plugins = this.plugins.map((plugin) => {
      return previous.includes(plugin) ? pluginMap.get(plugin) : plugin;
    });
  }
  /**
   * Add the plugin specific properties and methods to the manager and extension
   * store.
   */
  updateExtensionStore() {
    const { setExtensionStore } = this.store;
    setExtensionStore("updatePlugins", this.updatePlugins.bind(this));
    setExtensionStore("dispatchPluginUpdate", this.dispatchPluginUpdate.bind(this));
    setExtensionStore("updateExtensionPlugins", this.updateExtensionPlugins.bind(this));
  }
  /**
   * Reruns the `createPlugin` and `createExternalPlugins` methods of the
   * provided extension.
   *
   * ```ts
   * // From within an extension
   * this.store.updateExtensionPlugins(this);
   * ```
   */
  updateExtensionPlugins(value) {
    const extension2 = isExtension(value) ? value : isExtensionConstructor(value) ? this.store.manager.getExtension(value) : this.store.extensions.find((extension3) => extension3.name === value);
    invariant6(extension2, {
      code: ErrorConstant7.INVALID_MANAGER_EXTENSION,
      message: `The extension ${value} does not exist within the editor.`
    });
    this.extractExtensionPlugins(extension2);
    this.store.setStoreKey("plugins", this.plugins);
    this.dispatchPluginUpdate();
  }
  /**
   * Applies the store plugins to the state. If any have changed then it will be
   * updated.
   */
  dispatchPluginUpdate() {
    invariant6(this.store.phase >= ManagerPhase.EditorView, {
      code: ErrorConstant7.MANAGER_PHASE_ERROR,
      message: "`dispatchPluginUpdate` should only be called after the view has been added to the manager."
    });
    const { view, updateState } = this.store;
    const newState = view.state.reconfigure({ plugins: this.plugins });
    updateState(newState);
  }
};
PluginsExtension = __decorateClass([
  extension({
    defaultPriority: ExtensionPriority8.Highest,
    handlerKeys: ["applyState", "appendTransaction"]
  })
], PluginsExtension);

// packages/remirror__core/src/builtins/schema-extension.ts
import {
  ErrorConstant as ErrorConstant8,
  ExtensionPriority as ExtensionPriority9,
  ExtensionTag as ExtensionTag3
} from "@remirror/core-constants";
import {
  assertGet as assertGet4,
  entries as entries5,
  invariant as invariant7,
  isArray as isArray3,
  isFunction as isFunction5,
  isNullOrUndefined,
  isPlainObject,
  isString as isString5,
  object as object9,
  toString
} from "@remirror/core-helpers";
import {
  getDefaultBlockNode,
  getMarkRange as getMarkRange3,
  isElementDomNode,
  isProsemirrorMark,
  isProsemirrorNode as isProsemirrorNode2
} from "@remirror/core-utils";
import { Schema } from "@remirror/pm/model";
import { ignoreUpdateForSuggest } from "@remirror/pm/suggest";
var SchemaExtension = class extends PlainExtension {
  constructor() {
    super(...arguments);
    /**
     * The dynamic attributes for each node and mark extension.
     *
     * The structure will look like the following.
     *
     * ```ts
     * {
     *   paragraph: { id: () => uid(), hash: (node) => hash(node) },
     *   bold: { random: () => Math.random(), created: () => Date.now() },
     * };
     * ```
     *
     * This object is used by the created plugin to listen for changes to the doc,
     * and check for new nodes and marks which haven't yet applied the dynamic
     * attribute and add the attribute.
     */
    this.dynamicAttributes = {
      marks: object9(),
      nodes: object9()
    };
  }
  get name() {
    return "schema";
  }
  /**
   * This method is responsible for creating, configuring and adding the
   * `schema` to the editor. `Schema` is a special type in ProseMirror editors
   * and with `remirror` it's all just handled for you.
   */
  onCreate() {
    const { managerSettings, tags, markNames, nodeNames, extensions } = this.store;
    const { defaultBlockNode, disableExtraAttributes, nodeOverride, markOverride } = managerSettings;
    const isValidDefaultBlockNode = (name) => !!(name && tags[ExtensionTag3.Block].includes(name));
    if (managerSettings.schema) {
      const { nodes: nodes2, marks: marks2 } = getSpecFromSchema(managerSettings.schema);
      this.addSchema(managerSettings.schema, nodes2, marks2);
      return;
    }
    const nodes = isValidDefaultBlockNode(defaultBlockNode) ? {
      doc: object9(),
      // Ensure that this is the highest positioned block node by adding it
      // to the object early. Later on it will be overwritten but maintain
      // it's position.
      [defaultBlockNode]: object9()
    } : object9();
    const marks = object9();
    const namedExtraAttributes = getNamedSchemaAttributes({
      settings: managerSettings,
      gatheredSchemaAttributes: this.gatherExtraAttributes(extensions),
      nodeNames,
      markNames,
      tags
    });
    for (const extension2 of extensions) {
      namedExtraAttributes[extension2.name] = {
        ...namedExtraAttributes[extension2.name],
        ...extension2.options.extraAttributes
      };
      const ignoreExtraAttributes = disableExtraAttributes === true || extension2.options.disableExtraAttributes === true || extension2.constructor.disableExtraAttributes === true;
      if (isNodeExtension(extension2)) {
        const { spec, dynamic } = createSpec({
          createExtensionSpec: (extra, override) => extension2.createNodeSpec(extra, override),
          extraAttributes: assertGet4(namedExtraAttributes, extension2.name),
          // Todo add support for setting overrides via the manager.
          override: { ...nodeOverride, ...extension2.options.nodeOverride },
          ignoreExtraAttributes,
          name: extension2.constructorName,
          tags: extension2.tags
        });
        extension2.spec = spec;
        nodes[extension2.name] = spec;
        if (Object.keys(dynamic).length > 0) {
          this.dynamicAttributes.nodes[extension2.name] = dynamic;
        }
      }
      if (isMarkExtension(extension2)) {
        const { spec, dynamic } = createSpec({
          createExtensionSpec: (extra, override) => extension2.createMarkSpec(extra, override),
          extraAttributes: assertGet4(namedExtraAttributes, extension2.name),
          // Todo add support for setting overrides via the manager.
          override: { ...markOverride, ...extension2.options.markOverride },
          ignoreExtraAttributes,
          name: extension2.constructorName,
          tags: extension2.tags ?? []
        });
        extension2.spec = spec;
        marks[extension2.name] = spec;
        if (Object.keys(dynamic).length > 0) {
          this.dynamicAttributes.marks[extension2.name] = dynamic;
        }
      }
    }
    const schema = new Schema({ nodes, marks, topNode: "doc" });
    this.addSchema(
      schema,
      nodes,
      marks
    );
  }
  /**
   * This creates the plugin that is used to automatically create the dynamic
   * attributes defined in the extra attributes object.
   */
  createPlugin() {
    return {
      appendTransaction: (transactions, _, nextState) => {
        const { tr } = nextState;
        const documentHasChanged = transactions.some((tr2) => tr2.docChanged);
        if (!documentHasChanged) {
          return null;
        }
        if (Object.keys(this.dynamicAttributes.nodes).length === 0 && Object.keys(this.dynamicAttributes.marks).length === 0) {
          return null;
        }
        tr.doc.descendants((child, pos) => {
          this.checkAndUpdateDynamicNodes(child, pos, tr);
          this.checkAndUpdateDynamicMarks(child, pos, tr);
          return true;
        });
        return tr.steps.length > 0 ? tr : null;
      }
    };
  }
  /**
   * Add the schema and nodes to the manager and extension store.
   */
  addSchema(schema, nodes, marks) {
    this.store.setStoreKey("nodes", nodes);
    this.store.setStoreKey("marks", marks);
    this.store.setStoreKey("schema", schema);
    this.store.setExtensionStore("schema", schema);
    this.store.setStoreKey("defaultBlockNode", getDefaultBlockNode(schema).name);
    for (const type of Object.values(schema.nodes)) {
      if (type.name === "doc") {
        continue;
      }
      if (type.isBlock || type.isTextblock) {
        break;
      }
    }
  }
  /**
   * Check the dynamic nodes to see if the provided node:
   *
   * - a) is dynamic and therefore can be updated.
   * - b) has just been created and does not yet have a value for the dynamic
   *   node.
   *
   * @param node - the node
   * @param pos - the node's position
   * @param tr - the mutable ProseMirror transaction which is applied to create
   * the next editor state
   */
  checkAndUpdateDynamicNodes(node, pos, tr) {
    for (const [name, dynamic] of entries5(this.dynamicAttributes.nodes)) {
      if (node.type.name !== name) {
        continue;
      }
      for (const [attributeName, attributeCreator] of entries5(dynamic)) {
        if (!isNullOrUndefined(node.attrs[attributeName])) {
          continue;
        }
        const attrs = { ...node.attrs, [attributeName]: attributeCreator(node) };
        tr.setNodeMarkup(pos, void 0, attrs);
        ignoreUpdateForSuggest(tr);
      }
    }
  }
  /**
   * Loop through the dynamic marks to see if the provided node:
   *
   * - a) is wrapped by a matching mark.
   * - b) has just been added and doesn't yet have the dynamic attribute
   *   applied.
   *
   * @param node - the node
   * @param pos - the node's position
   * @param tr - the mutable ProseMirror transaction which is applied to create
   * the next editor state.
   */
  checkAndUpdateDynamicMarks(node, pos, tr) {
    for (const [name, dynamic] of entries5(this.dynamicAttributes.marks)) {
      const type = assertGet4(this.store.schema.marks, name);
      const mark = node.marks.find((mark2) => mark2.type.name === name);
      if (!mark) {
        continue;
      }
      for (const [attributeName, attributeCreator] of entries5(dynamic)) {
        if (!isNullOrUndefined(mark.attrs[attributeName])) {
          continue;
        }
        const range = getMarkRange3(tr.doc.resolve(pos), type);
        if (!range) {
          continue;
        }
        const { from, to } = range;
        const newMark = type.create({
          ...mark.attrs,
          [attributeName]: attributeCreator(mark)
        });
        tr.removeMark(from, to, type).addMark(from, to, newMark);
        ignoreUpdateForSuggest(tr);
      }
    }
  }
  /**
   * Gather all the extra attributes that have been added by extensions.
   */
  gatherExtraAttributes(extensions) {
    const extraSchemaAttributes = [];
    for (const extension2 of extensions) {
      if (!extension2.createSchemaAttributes) {
        continue;
      }
      extraSchemaAttributes.push(...extension2.createSchemaAttributes());
    }
    return extraSchemaAttributes;
  }
};
SchemaExtension = __decorateClass([
  extension({ defaultPriority: ExtensionPriority9.Highest })
], SchemaExtension);
function getNamedSchemaAttributes(props) {
  const { settings, gatheredSchemaAttributes, nodeNames, markNames, tags } = props;
  const extraAttributes = object9();
  if (settings.disableExtraAttributes) {
    return extraAttributes;
  }
  const extraSchemaAttributes = [
    ...gatheredSchemaAttributes,
    ...settings.extraAttributes ?? []
  ];
  for (const attributeGroup of extraSchemaAttributes ?? []) {
    const identifiers = getIdentifiers({
      identifiers: attributeGroup.identifiers,
      nodeNames,
      markNames,
      tags
    });
    for (const identifier of identifiers) {
      const currentValue = extraAttributes[identifier] ?? {};
      extraAttributes[identifier] = { ...currentValue, ...attributeGroup.attributes };
    }
  }
  return extraAttributes;
}
function isIdentifiersObject(value) {
  return isPlainObject(value) && isArray3(value.tags);
}
function getIdentifiers(props) {
  const { identifiers, nodeNames, markNames, tags } = props;
  if (identifiers === "nodes") {
    return nodeNames;
  }
  if (identifiers === "marks") {
    return markNames;
  }
  if (identifiers === "all") {
    return [...nodeNames, ...markNames];
  }
  if (isArray3(identifiers)) {
    return identifiers;
  }
  invariant7(isIdentifiersObject(identifiers), {
    code: ErrorConstant8.EXTENSION_EXTRA_ATTRIBUTES,
    message: `Invalid value passed as an identifier when creating \`extraAttributes\`.`
  });
  const {
    tags: extensionTags = [],
    names: extensionNames = [],
    behavior = "any",
    excludeNames,
    excludeTags,
    type
  } = identifiers;
  const names = /* @__PURE__ */ new Set();
  const acceptableNames = type === "mark" ? markNames : type === "node" ? nodeNames : [...markNames, ...nodeNames];
  const isNameValid = (name) => acceptableNames.includes(name) && !(excludeNames == null ? void 0 : excludeNames.includes(name));
  for (const name of extensionNames) {
    if (isNameValid(name)) {
      names.add(name);
    }
  }
  const taggedNamesMap = /* @__PURE__ */ new Map();
  for (const tag of extensionTags) {
    if (excludeTags == null ? void 0 : excludeTags.includes(tag)) {
      continue;
    }
    for (const name of tags[tag]) {
      if (!isNameValid(name)) {
        continue;
      }
      if (behavior === "any") {
        names.add(name);
        continue;
      }
      const tagSet = taggedNamesMap.get(name) ?? /* @__PURE__ */ new Set();
      tagSet.add(tag);
      taggedNamesMap.set(name, tagSet);
    }
  }
  for (const [name, tagSet] of taggedNamesMap) {
    if (tagSet.size === extensionTags.length) {
      names.add(name);
    }
  }
  return [...names];
}
function createSpec(props) {
  var _a;
  const { createExtensionSpec, extraAttributes, ignoreExtraAttributes, name, tags, override } = props;
  const dynamic = object9();
  function addDynamic(attributeName, creator) {
    dynamic[attributeName] = creator;
  }
  let defaultsCalled = false;
  function onDefaultsCalled() {
    defaultsCalled = true;
  }
  const defaults = createDefaults(
    extraAttributes,
    ignoreExtraAttributes,
    onDefaultsCalled,
    addDynamic
  );
  const parse = createParseDOM(extraAttributes, ignoreExtraAttributes);
  const dom = createToDOM(extraAttributes, ignoreExtraAttributes);
  const spec = createExtensionSpec({ defaults, parse, dom }, override);
  invariant7(ignoreExtraAttributes || defaultsCalled, {
    code: ErrorConstant8.EXTENSION_SPEC,
    message: `When creating a node specification you must call the 'defaults', and parse, and 'dom' methods. To avoid this error you can set the static property 'disableExtraAttributes' of '${name}' to 'true'.`
  });
  spec.group = [...((_a = spec.group) == null ? void 0 : _a.split(" ")) ?? [], ...tags].join(" ") || void 0;
  return { spec, dynamic };
}
function getExtraAttributesObject(value) {
  if (isString5(value) || isFunction5(value)) {
    return { default: value };
  }
  invariant7(value, {
    message: `${toString(value)} is not supported`,
    code: ErrorConstant8.EXTENSION_EXTRA_ATTRIBUTES
  });
  return value;
}
function createDefaults(extraAttributes, shouldIgnore, onCalled, addDynamicCreator) {
  return () => {
    onCalled();
    const attributes = object9();
    if (shouldIgnore) {
      return attributes;
    }
    for (const [name, config] of entries5(extraAttributes)) {
      const attributesObject = getExtraAttributesObject(config);
      let defaultValue = attributesObject.default;
      if (isFunction5(defaultValue)) {
        addDynamicCreator(name, defaultValue);
        defaultValue = null;
      }
      attributes[name] = defaultValue === void 0 ? {} : { default: defaultValue };
    }
    return attributes;
  };
}
function createParseDOM(extraAttributes, shouldIgnore) {
  return (domNode) => {
    const attributes = object9();
    if (shouldIgnore) {
      return attributes;
    }
    for (const [name, config] of entries5(extraAttributes)) {
      const { parseDOM, ...other } = getExtraAttributesObject(config);
      if (!isElementDomNode(domNode)) {
        continue;
      }
      if (isNullOrUndefined(parseDOM)) {
        attributes[name] = domNode.getAttribute(name) ?? other.default;
        continue;
      }
      if (isFunction5(parseDOM)) {
        attributes[name] = parseDOM(domNode) ?? other.default;
        continue;
      }
      attributes[name] = domNode.getAttribute(parseDOM) ?? other.default;
    }
    return attributes;
  };
}
function createToDOM(extraAttributes, shouldIgnore) {
  return (item) => {
    const domAttributes = object9();
    if (shouldIgnore) {
      return domAttributes;
    }
    function updateDomAttributes(value, name) {
      if (!value) {
        return;
      }
      if (isString5(value)) {
        domAttributes[name] = value;
        return;
      }
      if (isArray3(value)) {
        const [attr, val] = value;
        domAttributes[attr] = val ?? item.attrs[name];
        return;
      }
      for (const [attr, val] of entries5(value)) {
        domAttributes[attr] = val;
      }
    }
    for (const [name, config] of entries5(extraAttributes)) {
      const { toDOM, parseDOM } = getExtraAttributesObject(config);
      if (isNullOrUndefined(toDOM)) {
        const key2 = isString5(parseDOM) ? parseDOM : name;
        domAttributes[key2] = item.attrs[name];
        continue;
      }
      if (isFunction5(toDOM)) {
        updateDomAttributes(toDOM(item.attrs, getNodeMarkOptions(item)), name);
        continue;
      }
      updateDomAttributes(toDOM, name);
    }
    return domAttributes;
  };
}
function getNodeMarkOptions(item) {
  if (isProsemirrorNode2(item)) {
    return { node: item };
  }
  if (isProsemirrorMark(item)) {
    return { mark: item };
  }
  return {};
}
function getSpecFromSchema(schema) {
  const nodes = object9();
  const marks = object9();
  for (const [name, type] of Object.entries(schema.nodes)) {
    nodes[name] = type.spec;
  }
  for (const [name, type] of Object.entries(schema.marks)) {
    marks[name] = type.spec;
  }
  return { nodes, marks };
}

// packages/remirror__core/src/builtins/suggest-extension.ts
import { includes as includes2, isArray as isArray4 } from "@remirror/core-helpers";
import {
  addSuggester,
  getSuggestPluginState,
  removeSuggester,
  suggest
} from "@remirror/pm/suggest";
var SuggestExtension = class extends PlainExtension {
  constructor() {
    super(...arguments);
    /**
     * Allow additional `Suggesters` to be added to the editor. This can be used
     * by `React` to create hooks.
     */
    this.onAddCustomHandler = ({ suggester }) => {
      var _a;
      if (!suggester || ((_a = this.store.managerSettings.exclude) == null ? void 0 : _a.suggesters)) {
        return;
      }
      return addSuggester(this.store.getState(), suggester);
    };
  }
  get name() {
    return "suggest";
  }
  /**
   * Create the `addSuggester` method and `removeSuggester` methods to the
   * extension store.
   *
   * This can be used by extensions to conditionally add suggestion support.
   */
  onCreate() {
    this.store.setExtensionStore(
      "addSuggester",
      (suggester) => addSuggester(this.store.getState(), suggester)
    );
    this.store.setExtensionStore(
      "removeSuggester",
      (suggester) => removeSuggester(this.store.getState(), suggester)
    );
  }
  /**
   * Add the `prosemirror-suggest` plugin to the editor.
   */
  createExternalPlugins() {
    var _a, _b;
    const suggesters = [];
    for (const extension2 of this.store.extensions) {
      if ((_a = this.store.managerSettings.exclude) == null ? void 0 : _a.suggesters) {
        break;
      }
      if (
        // Method doesn't exist
        !extension2.createSuggesters || // Extension settings exclude it from running
        ((_b = extension2.options.exclude) == null ? void 0 : _b.suggesters)
      ) {
        continue;
      }
      const suggester = extension2.createSuggesters();
      const suggesterList = isArray4(suggester) ? suggester : [suggester];
      suggesters.push(...suggesterList);
    }
    return [suggest(...suggesters)];
  }
  getSuggestState(state) {
    return getSuggestPluginState(state ?? this.store.getState());
  }
  getSuggestMethods() {
    const {
      addIgnored,
      clearIgnored,
      removeIgnored,
      ignoreNextExit,
      setMarkRemoved,
      findMatchAtPosition,
      findNextTextSelection,
      setLastChangeFromAppend
    } = this.getSuggestState();
    return {
      addIgnored,
      clearIgnored,
      removeIgnored,
      ignoreNextExit,
      setMarkRemoved,
      findMatchAtPosition,
      findNextTextSelection,
      setLastChangeFromAppend
    };
  }
  isSuggesterActive(name) {
    var _a;
    return includes2(isArray4(name) ? name : [name], (_a = this.getSuggestState().match) == null ? void 0 : _a.suggester.name);
  }
};
__decorateClass([
  helper()
], SuggestExtension.prototype, "getSuggestState", 1);
__decorateClass([
  helper()
], SuggestExtension.prototype, "getSuggestMethods", 1);
__decorateClass([
  helper()
], SuggestExtension.prototype, "isSuggesterActive", 1);
SuggestExtension = __decorateClass([
  extension({ customHandlerKeys: ["suggester"] })
], SuggestExtension);

// packages/remirror__core/src/builtins/tags-extension.ts
import {
  ErrorConstant as ErrorConstant9,
  ExtensionPriority as ExtensionPriority10,
  ExtensionTag as ExtensionTag4
} from "@remirror/core-constants";
import { includes as includes3, invariant as invariant8, object as object10, values as values2 } from "@remirror/core-helpers";
var TagsExtension = class extends PlainExtension {
  constructor() {
    super(...arguments);
    /**
     * Track the tags which have been applied to the extensions in this editor.
     */
    this.allTags = object10();
    /**
     * The tags for plain extensions.
     */
    this.plainTags = object10();
    /**
     * The tags for mark extensions.
     */
    this.markTags = object10();
    /**
     * The tags for node extensions.
     */
    this.nodeTags = object10();
  }
  get name() {
    return "tags";
  }
  /**
   * Create the tags which are used to identify extension with particular
   * behavioral traits.
   */
  onCreate() {
    this.resetTags();
    for (const extension2 of this.store.extensions) {
      this.updateTagForExtension(extension2);
    }
    this.store.setStoreKey("tags", this.allTags);
    this.store.setExtensionStore("tags", this.allTags);
    this.store.setStoreKey("plainTags", this.plainTags);
    this.store.setExtensionStore("plainTags", this.plainTags);
    this.store.setStoreKey("markTags", this.markTags);
    this.store.setExtensionStore("markTags", this.markTags);
    this.store.setStoreKey("nodeTags", this.nodeTags);
    this.store.setExtensionStore("nodeTags", this.nodeTags);
  }
  /**
   * Reset the tags to the empty object with empty arrays.
   */
  resetTags() {
    const allTags = object10();
    const plainTags = object10();
    const markTags = object10();
    const nodeTags = object10();
    for (const tagName of values2(ExtensionTag4)) {
      allTags[tagName] = [];
      plainTags[tagName] = [];
      markTags[tagName] = [];
      nodeTags[tagName] = [];
    }
    this.allTags = allTags;
    this.plainTags = plainTags;
    this.markTags = markTags;
    this.nodeTags = nodeTags;
  }
  /**
   * Update the tags object for each extension.
   */
  updateTagForExtension(extension2) {
    var _a, _b;
    const allTags = /* @__PURE__ */ new Set([
      // TODO remove `extension.tags` once all tags have been moved over to `createTags`
      ...extension2.tags ?? [],
      ...((_a = extension2.createTags) == null ? void 0 : _a.call(extension2)) ?? [],
      ...extension2.options.extraTags ?? [],
      ...((_b = this.store.managerSettings.extraTags) == null ? void 0 : _b[extension2.name]) ?? []
    ]);
    for (const tag of allTags) {
      invariant8(isExtensionTag(tag), {
        code: ErrorConstant9.EXTENSION,
        message: `The tag provided by the extension: ${extension2.constructorName} is not supported by the editor. To add custom tags you can use the 'mutateTag' method.`
      });
      this.allTags[tag].push(extension2.name);
      if (isPlainExtension(extension2)) {
        this.plainTags[tag].push(extension2.name);
      }
      if (isMarkExtension(extension2)) {
        this.markTags[tag].push(extension2.name);
      }
      if (isNodeExtension(extension2)) {
        this.nodeTags[tag].push(extension2.name);
      }
    }
    extension2.tags = [...allTags];
  }
};
TagsExtension = __decorateClass([
  extension({ defaultPriority: ExtensionPriority10.Highest })
], TagsExtension);
function isExtensionTag(value) {
  return includes3(values2(ExtensionTag4), value);
}

// packages/remirror__core/src/builtins/upload-extension/file-placeholder-plugin.ts
import { Plugin as Plugin3, PluginKey as PluginKey2 } from "@remirror/pm/state";
import { Decoration as Decoration2, DecorationSet as DecorationSet2 } from "@remirror/pm/view";
var key = new PluginKey2("remirrorFilePlaceholderPlugin");
function createUploadPlaceholderPlugin() {
  const plugin = new Plugin3({
    key,
    state: {
      init() {
        return { set: DecorationSet2.empty, payloads: /* @__PURE__ */ new Map() };
      },
      apply(tr, { set, payloads }) {
        set = set.map(tr.mapping, tr.doc);
        const action = tr.getMeta(plugin);
        if (action) {
          if (action.type === 0 /* ADD_PLACEHOLDER */) {
            const widget = document.createElement("placeholder");
            const deco = Decoration2.widget(action.pos, widget, { id: action.id });
            set = set.add(tr.doc, [deco]);
            payloads.set(action.id, action.payload);
          } else if (action.type === 1 /* REMOVE_PLACEHOLDER */) {
            set = set.remove(set.find(void 0, void 0, (spec) => spec.id === action.id));
            payloads.delete(action.id);
          }
        }
        return { set, payloads };
      }
    },
    props: {
      decorations(state) {
        var _a;
        return ((_a = plugin.getState(state)) == null ? void 0 : _a.set) ?? null;
      }
    }
  });
  return plugin;
}
function findUploadPlaceholderPos(state, id) {
  var _a, _b;
  const set = (_a = key.getState(state)) == null ? void 0 : _a.set;
  if (set) {
    const decorations = set.find(void 0, void 0, (spec) => spec.id === id);
    const pos = (_b = decorations == null ? void 0 : decorations[0]) == null ? void 0 : _b.from;
    if (pos != null) {
      return pos;
    }
  }
  let foundPos;
  state.doc.descendants((node, pos) => {
    if (node.attrs.id === id) {
      foundPos = pos;
    }
    return foundPos === void 0;
  });
  return foundPos;
}
function findUploadPlaceholderPayload(state, id) {
  var _a;
  const payloads = (_a = key.getState(state)) == null ? void 0 : _a.payloads;
  if (!payloads) {
    return void 0;
  }
  return payloads.get(id);
}
function hasUploadingFile(state) {
  var _a, _b;
  const placeholderCount = ((_b = (_a = key.getState(state)) == null ? void 0 : _a.payloads) == null ? void 0 : _b.size) ?? 0;
  return placeholderCount > 0;
}
function setUploadPlaceholderAction(tr, action) {
  return tr.setMeta(key, action);
}

// packages/remirror__core/src/builtins/upload-extension/file-upload.ts
import { isNumber as isNumber2, uniqueId as uniqueId2 } from "@remirror/core-helpers";

// packages/remirror__core/src/builtins/upload-extension/upload-context.ts
import { createNanoEvents } from "nanoevents";
function createUploadContext() {
  const values3 = {};
  const emitter = createNanoEvents();
  const get = (key2) => {
    return values3[key2];
  };
  const set = (key2, value) => {
    values3[key2] = value;
    emitter.emit("set", values3);
  };
  const addListener = (listener) => {
    return emitter.on("set", listener);
  };
  return { set, get, addListener };
}

// packages/remirror__core/src/builtins/upload-extension/file-upload.ts
function uploadFile({
  file,
  pos,
  view,
  fileType,
  uploadHandler
}) {
  const id = uniqueId2("file-placeholder-");
  const context = createUploadContext();
  const fileUploader = createFilePlaceholder({
    id,
    context,
    file,
    pos,
    view,
    fileType,
    uploadHandler
  });
  fileUploader == null ? void 0 : fileUploader.upload(context).then((attrs) => onFileLoaded({ id, fileType, view, attrs })).catch((error) => onFileLoaded({ id, fileType, view, attrs: { error: error.message } }));
}
function insertFilePoint(doc, pos, nodeType) {
  const $pos = doc.resolve(pos);
  if ($pos.parent.canReplaceWith($pos.index(), $pos.index(), nodeType)) {
    return pos;
  }
  if ($pos.parentOffset === 0) {
    for (let d = $pos.depth - 1; d >= 0; d--) {
      const index = $pos.index(d);
      if ($pos.node(d).canReplaceWith(index, index, nodeType)) {
        return $pos.before(d + 1);
      }
      if (index > 0) {
        return null;
      }
    }
  }
  for (let d = $pos.depth - 1; d >= 0; d--) {
    const index = $pos.indexAfter(d);
    if ($pos.node(d).canReplaceWith(index, index, nodeType)) {
      return $pos.after(d + 1);
    }
    if (index < $pos.node(d).childCount) {
      return null;
    }
  }
  return null;
}
function createFilePlaceholder({
  id,
  context,
  file,
  pos,
  view,
  fileType,
  uploadHandler
}) {
  const tr = view.state.tr;
  const insertPos = insertFilePoint(tr.doc, isNumber2(pos) ? pos : tr.selection.from, fileType);
  if (!isNumber2(insertPos)) {
    return;
  }
  const fileUploader = uploadHandler();
  const attrs = { ...fileUploader.insert(file), id };
  tr.insert(insertPos, fileType.createChecked(attrs));
  const payload = { context, fileUploader };
  setUploadPlaceholderAction(tr, { type: 0 /* ADD_PLACEHOLDER */, id, pos: insertPos, payload });
  view.dispatch(tr);
  return fileUploader;
}
function onFileLoaded({
  id,
  attrs,
  fileType,
  view
}) {
  const placeholderPos = findUploadPlaceholderPos(view.state, id);
  if (placeholderPos == null) {
    return;
  }
  const $pos = view.state.doc.resolve(placeholderPos);
  const fileNode = $pos.nodeAfter;
  if (!fileNode || fileNode.type !== fileType || fileNode.attrs.id !== id) {
    const tr2 = view.state.tr;
    setUploadPlaceholderAction(tr2, { type: 1 /* REMOVE_PLACEHOLDER */, id });
    view.dispatch(tr2);
    return;
  }
  const tr = view.state.tr;
  setUploadPlaceholderAction(tr, { type: 1 /* REMOVE_PLACEHOLDER */, id });
  const fileAttrs = { ...fileNode.attrs, ...attrs, id: null };
  tr.setNodeMarkup(placeholderPos, void 0, fileAttrs);
  view.dispatch(tr);
}

// packages/remirror__core/src/builtins/upload-extension/upload-extension.ts
var UploadExtension = class extends PlainExtension {
  get name() {
    return "upload";
  }
  /**
   * Create the extension plugin for inserting decorations into the editor.
   */
  createExternalPlugins() {
    return [createUploadPlaceholderPlugin()];
  }
};

// packages/remirror__core/src/builtins/builtin-preset.ts
function builtinPreset(options = {}) {
  const defaultOptions2 = {
    exitMarksOnArrowPress: KeymapExtension.defaultOptions.exitMarksOnArrowPress,
    excludeBaseKeymap: KeymapExtension.defaultOptions.excludeBaseKeymap,
    selectParentNodeOnEscape: KeymapExtension.defaultOptions.selectParentNodeOnEscape,
    undoInputRuleOnBackspace: KeymapExtension.defaultOptions.undoInputRuleOnBackspace,
    persistentSelectionClass: DecorationsExtension.defaultOptions.persistentSelectionClass
  };
  options = { ...defaultOptions2, ...options };
  const keymapOptions = pick(options, [
    "excludeBaseKeymap",
    "selectParentNodeOnEscape",
    "undoInputRuleOnBackspace"
  ]);
  const decorationsOptions = pick(options, ["persistentSelectionClass"]);
  return [
    // The order of these extension is important. First come first served.
    new TagsExtension(),
    new SchemaExtension(),
    new AttributesExtension(),
    new PluginsExtension(),
    new InputRulesExtension(),
    new PasteRulesExtension(),
    new NodeViewsExtension(),
    new SuggestExtension(),
    new CommandsExtension(),
    new HelpersExtension(),
    new KeymapExtension(keymapOptions),
    new DocChangedExtension(),
    new UploadExtension(),
    new DecorationsExtension(decorationsOptions)
  ];
}

// packages/remirror__core/src/builtins/meta-extension.ts
import { ExtensionPriority as ExtensionPriority11 } from "@remirror/core-constants";
import { environment as environment4 } from "@remirror/core-utils";
var MetaExtension = class extends PlainExtension {
  get name() {
    return "meta";
  }
  onCreate() {
    this.store.setStoreKey("getCommandMeta", this.getCommandMeta.bind(this));
    if (!this.options.capture) {
      return;
    }
    for (const extension2 of this.store.extensions) {
      this.captureCommands(extension2);
      this.captureKeybindings(extension2);
    }
  }
  /**
   * This is here to provide a
   */
  createPlugin() {
    return {};
  }
  /**
   * Intercept command names and attributes.
   */
  captureCommands(extension2) {
    const decoratedCommands = extension2.decoratedCommands ?? {};
    const createCommands = extension2.createCommands;
    for (const name of Object.keys(decoratedCommands)) {
      const command2 = extension2[name];
      extension2[name] = (...args) => (props) => {
        var _a;
        const value = command2(...args)(props);
        if (props.dispatch && value) {
          this.setCommandMeta(props.tr, {
            type: "command",
            chain: props.dispatch !== ((_a = props.view) == null ? void 0 : _a.dispatch),
            name,
            extension: extension2.name,
            decorated: true
          });
        }
        return value;
      };
    }
    if (createCommands) {
      extension2.createCommands = () => {
        const commandsObject = createCommands();
        for (const [name, command2] of Object.entries(commandsObject)) {
          commandsObject[name] = (...args) => (props) => {
            var _a;
            const value = command2(...args)(props);
            if (props.dispatch && value) {
              this.setCommandMeta(props.tr, {
                type: "command",
                chain: props.dispatch !== ((_a = props.view) == null ? void 0 : _a.dispatch),
                name,
                extension: extension2.name,
                decorated: false
              });
            }
            return value;
          };
        }
        return commandsObject;
      };
    }
  }
  /**
   * Intercept command name and attributes.
   */
  captureKeybindings(_) {
  }
  /**
   * Get the command metadata.
   */
  getCommandMeta(tr) {
    return tr.getMeta(this.pluginKey) ?? [];
  }
  setCommandMeta(tr, update) {
    const meta = this.getCommandMeta(tr);
    tr.setMeta(this.pluginKey, [...meta, update]);
  }
};
MetaExtension = __decorateClass([
  extension({
    defaultOptions: {
      capture: environment4.isDevelopment
    },
    staticKeys: ["capture"],
    defaultPriority: ExtensionPriority11.Highest
  })
], MetaExtension);

// packages/remirror__core/src/framework/framework.ts
import { createNanoEvents as createNanoEvents2 } from "nanoevents";
import { ErrorConstant as ErrorConstant10 } from "@remirror/core-constants";
import {
  cx as cx2,
  invariant as invariant9,
  isEmptyArray as isEmptyArray5,
  isFunction as isFunction6,
  isNumber as isNumber3,
  object as object11,
  omitUndefined,
  pick as pick2,
  uniqueArray as uniqueArray3,
  uniqueId as uniqueId3
} from "@remirror/core-helpers";
var _uid, _getProps, _previousState, _firstRender, _events, _addHandler, _initialEditorState;
var Framework = class {
  constructor(options) {
    /**
     * A unique ID for the editor which can also be used as a key in frameworks
     * that need it.
     */
    __privateAdd(this, _uid, uniqueId3());
    /**
     * A method which enables retrieving the props from the editor.
     */
    __privateAdd(this, _getProps, void 0);
    /**
     * The private reference to the previous state.
     */
    __privateAdd(this, _previousState, void 0);
    /**
     * True when this is the first render.
     */
    __privateAdd(this, _firstRender, true);
    /**
     * The event listener which allows consumers to subscribe to the different
     * events taking place in the editor. Events currently supported are:
     *
     * - `destroy`
     * - `focus`
     * - `blur`
     * - `updated`
     */
    __privateAdd(this, _events, createNanoEvents2());
    /**
     * The handler which is bound to the events listener object.
     */
    __privateAdd(this, _addHandler, void 0);
    __privateAdd(this, _initialEditorState, void 0);
    /**
     * Retrieve the editor state.
     */
    this.getState = () => this.view.state ?? this.initialEditorState;
    /**
     * Retrieve the previous editor state.
     */
    this.getPreviousState = () => this.previousState;
    /**
     * Part of the Prosemirror API and is called whenever there is state change in
     * the editor.
     *
     * @internalremarks
     * How does it work when transactions are dispatched one after the other.
     */
    this.dispatchTransaction = (tr) => {
      var _a, _b;
      invariant9(!this.manager.destroyed, {
        code: ErrorConstant10.MANAGER_PHASE_ERROR,
        message: "A transaction was dispatched to a manager that has already been destroyed. Please check your set up, or open an issue."
      });
      tr = ((_b = (_a = this.props).onDispatchTransaction) == null ? void 0 : _b.call(_a, tr, this.getState())) ?? tr;
      const previousState = this.getState();
      const { state, transactions } = previousState.applyTransaction(tr);
      __privateSet(this, _previousState, previousState);
      this.updateState({ state, tr, transactions });
      const forcedUpdates = this.manager.store.getForcedUpdates(tr);
      if (!isEmptyArray5(forcedUpdates)) {
        this.updateViewProps(...forcedUpdates);
      }
    };
    /**
     * Use this method in the `onUpdate` event to run all change handlers.
     */
    this.onChange = (props = object11()) => {
      var _a, _b;
      const onChangeProps = this.eventListenerProps(props);
      if (__privateGet(this, _firstRender)) {
        __privateSet(this, _firstRender, false);
      }
      (_b = (_a = this.props).onChange) == null ? void 0 : _b.call(_a, onChangeProps);
    };
    /**
     * Listener for editor 'blur' events
     */
    this.onBlur = (event) => {
      var _a, _b;
      const props = this.eventListenerProps();
      (_b = (_a = this.props).onBlur) == null ? void 0 : _b.call(_a, props, event);
      __privateGet(this, _events).emit("blur", props, event);
    };
    /**
     * Listener for editor 'focus' events
     */
    this.onFocus = (event) => {
      var _a, _b;
      const props = this.eventListenerProps();
      (_b = (_a = this.props).onFocus) == null ? void 0 : _b.call(_a, props, event);
      __privateGet(this, _events).emit("focus", props, event);
    };
    /**
     * Sets the content of the editor. This bypasses the update function.
     *
     * @param content
     * @param triggerChange
     */
    this.setContent = (content, { triggerChange = false } = {}) => {
      const { doc } = this.manager.createState({ content });
      const previousState = this.getState();
      const { state } = this.getState().applyTransaction(
        previousState.tr.replaceRangeWith(0, previousState.doc.nodeSize - 2, doc)
      );
      if (triggerChange) {
        return this.updateState({ state, triggerChange });
      }
      this.view.updateState(state);
    };
    /**
     * Clear the content of the editor (reset to the default empty node).
     *
     * @param triggerChange - whether to notify the onChange handler that the
     * content has been reset
     */
    this.clearContent = ({ triggerChange = false } = {}) => {
      this.setContent(this.manager.createEmptyDoc(), { triggerChange });
    };
    this.createStateFromContent = (content, selection) => {
      return this.manager.createState({ content, selection });
    };
    /**
     * Focus the editor.
     */
    this.focus = (position) => {
      this.manager.store.commands.focus(position);
    };
    /**
     * Blur the editor.
     */
    this.blur = (position) => {
      this.manager.store.commands.blur(position);
    };
    const { getProps, initialEditorState, element } = options;
    __privateSet(this, _getProps, getProps);
    __privateSet(this, _initialEditorState, initialEditorState);
    this.manager.attachFramework(this, this.updateListener.bind(this));
    if (this.manager.view) {
      return;
    }
    const view = this.createView(initialEditorState, element);
    this.manager.addView(view);
  }
  /**
   * The event listener which allows consumers to subscribe to the different
   * events taking place in the editor. Events currently supported are:
   *
   * - `destroy`
   * - `focus`
   * - `blur`
   * - `updated`
   */
  get addHandler() {
    return __privateGet(this, _addHandler) ?? __privateSet(this, _addHandler, __privateGet(this, _events).on.bind(__privateGet(this, _events)));
  }
  /**
   * The updatable view props.
   */
  get updatableViewProps() {
    return {
      attributes: () => this.getAttributes(),
      editable: () => this.props.editable ?? true
    };
  }
  /**
   * True when this is the first render of the editor.
   */
  get firstRender() {
    return __privateGet(this, _firstRender);
  }
  /**
   * The props passed in when creating or updating the `Framework` instance.
   */
  get props() {
    return __privateGet(this, _getProps).call(this);
  }
  /**
   * Returns the previous editor state. On the first render it defaults to
   * returning the current state. For the first render the previous state and
   * current state will always be equal.
   */
  get previousState() {
    return this.previousStateOverride ?? __privateGet(this, _previousState) ?? this.initialEditorState;
  }
  /**
   * The instance of the [[`RemirrorManager`]].
   */
  get manager() {
    return this.props.manager;
  }
  /**
   * The ProseMirror [[`EditorView`]].
   */
  get view() {
    return this.manager.view;
  }
  /**
   * A unique id for the editor. Can be used to differentiate between editors.
   *
   * Please note that this ID is only locally unique, it should not be used as a
   * database key.
   */
  get uid() {
    return __privateGet(this, _uid);
  }
  /**
   * The initial editor state from when the editor was first created.
   */
  get initialEditorState() {
    return __privateGet(this, _initialEditorState);
  }
  /**
   * Setup the manager event listeners which are disposed of when the manager is
   * destroyed.
   */
  updateListener(props) {
    const { state, tr } = props;
    return __privateGet(this, _events).emit("updated", this.eventListenerProps({ state, tr }));
  }
  /**
   * Update the constructor props passed in. Useful for frameworks like react
   * where props are constantly changing and when using hooks function closures
   * can become stale.
   *
   * You can call the update method with the new `props` to update the internal
   * state of this instance.
   */
  update(options) {
    const { getProps } = options;
    __privateSet(this, _getProps, getProps);
    return this;
  }
  /**
   * Update the view props.
   */
  updateViewProps(...keys3) {
    const props = pick2(this.updatableViewProps, keys3);
    this.view.setProps({ ...this.view.props, ...props });
  }
  getAttributes(ssr) {
    var _a;
    const { attributes, autoFocus, classNames = [], label, editable } = this.props;
    const managerAttributes = (_a = this.manager.store) == null ? void 0 : _a.attributes;
    const propAttributes = isFunction6(attributes) ? attributes(this.eventListenerProps()) : attributes;
    let focus = {};
    if (autoFocus || isNumber3(autoFocus)) {
      focus = ssr ? { autoFocus: true } : { autofocus: "true" };
    }
    const uniqueClasses = uniqueArray3(
      cx2(ssr && "Prosemirror", "remirror-editor", managerAttributes == null ? void 0 : managerAttributes.class, ...classNames).split(
        " "
      )
    ).join(" ");
    const defaultAttributes = {
      role: "textbox",
      ...focus,
      "aria-multiline": "true",
      ...!(editable ?? true) ? { "aria-readonly": "true" } : {},
      "aria-label": label ?? "",
      ...managerAttributes,
      class: uniqueClasses
    };
    return omitUndefined({ ...defaultAttributes, ...propAttributes });
  }
  /**
   * Adds `onBlur` and `onFocus` listeners.
   *
   * When extending this class make sure to call this method once
   * `ProsemirrorView` has been added to the dom.
   */
  addFocusListeners() {
    this.view.dom.addEventListener("blur", this.onBlur);
    this.view.dom.addEventListener("focus", this.onFocus);
  }
  /**
   * Remove `onBlur` and `onFocus` listeners.
   *
   * When extending this class in your framework, make sure to call this just
   * before the view is destroyed.
   */
  removeFocusListeners() {
    this.view.dom.removeEventListener("blur", this.onBlur);
    this.view.dom.removeEventListener("focus", this.onFocus);
  }
  /**
   * Called when the component unmounts and is responsible for cleanup.
   *
   * @remarks
   *
   * - Removes listeners for the editor `blur` and `focus` events
   */
  destroy() {
    __privateGet(this, _events).emit("destroy");
    if (this.view) {
      this.removeFocusListeners();
    }
  }
  /**
   * Creates the props passed into all event listener handlers. e.g.
   * `onChange`
   */
  eventListenerProps(props = object11()) {
    const { state, tr, transactions } = props;
    return {
      tr,
      transactions,
      internalUpdate: !tr,
      view: this.view,
      firstRender: __privateGet(this, _firstRender),
      state: state ?? this.getState(),
      createStateFromContent: this.createStateFromContent,
      previousState: this.previousState,
      helpers: this.manager.store.helpers
    };
  }
  /**
   * Methods and properties which are made available to all consumers of the
   * `Framework` class.
   */
  get baseOutput() {
    return {
      manager: this.manager,
      ...this.manager.store,
      addHandler: this.addHandler,
      // Commands
      focus: this.focus,
      blur: this.blur,
      // Properties
      uid: __privateGet(this, _uid),
      view: this.view,
      // Getter Methods
      getState: this.getState,
      getPreviousState: this.getPreviousState,
      getExtension: this.manager.getExtension.bind(this.manager),
      hasExtension: this.manager.hasExtension.bind(this.manager),
      // Setter Methods
      clearContent: this.clearContent,
      setContent: this.setContent
    };
  }
};
_uid = new WeakMap();
_getProps = new WeakMap();
_previousState = new WeakMap();
_firstRender = new WeakMap();
_events = new WeakMap();
_addHandler = new WeakMap();
_initialEditorState = new WeakMap();

// packages/remirror__core/src/manager/remirror-manager.ts
import { createNanoEvents as createNanoEvents3 } from "nanoevents";
import {
  __INTERNAL_REMIRROR_IDENTIFIER_KEY__ as __INTERNAL_REMIRROR_IDENTIFIER_KEY__3,
  ErrorConstant as ErrorConstant12,
  ManagerPhase as ManagerPhase2,
  RemirrorIdentifier as RemirrorIdentifier3
} from "@remirror/core-constants";
import {
  freeze as freeze3,
  getLazyArray,
  includes as includes4,
  invariant as invariant11,
  isNullOrUndefined as isNullOrUndefined2,
  isString as isString6,
  object as object12
} from "@remirror/core-helpers";
import {
  createDocumentNode,
  getDocument,
  getTextSelection as getTextSelection3,
  isIdentifierOfType as isIdentifierOfType2,
  isRemirrorType as isRemirrorType2
} from "@remirror/core-utils";
import { EditorState as EditorState3 } from "@remirror/pm/state";

// packages/remirror__core/src/manager/remirror-manager-helpers.ts
import warning from "tiny-warning";
import { ErrorConstant as ErrorConstant11 } from "@remirror/core-constants";
import { invariant as invariant10, isEmptyArray as isEmptyArray6, sort as sort3 } from "@remirror/core-helpers";
function transformExtensions(initialExtensions, settings) {
  const extensions = [];
  const extensionMap = /* @__PURE__ */ new WeakMap();
  const parentExtensions = [];
  const duplicateMap = /* @__PURE__ */ new WeakMap();
  let gatheredExtensions = [];
  const gatherRawExtensionConfig = { duplicateMap, parentExtensions, gatheredExtensions, settings };
  for (const extension2 of initialExtensions) {
    gatherRawExtensions(gatherRawExtensionConfig, { extension: extension2 });
  }
  gatheredExtensions = sort3(gatheredExtensions, (a, z) => z.priority - a.priority);
  const found = /* @__PURE__ */ new WeakSet();
  const names = /* @__PURE__ */ new Set();
  for (const extension2 of gatheredExtensions) {
    const key2 = extension2.constructor;
    const name = extension2.name;
    const duplicates = duplicateMap.get(key2);
    invariant10(duplicates, {
      message: `No entries were found for the ExtensionConstructor ${extension2.name}`,
      code: ErrorConstant11.INTERNAL
    });
    if (found.has(key2) || names.has(name)) {
      continue;
    }
    found.add(key2);
    names.add(name);
    extensions.push(extension2);
    extensionMap.set(key2, extension2);
    duplicates.forEach((parent) => parent == null ? void 0 : parent.replaceChildExtension(key2, extension2));
  }
  const missing = [];
  for (const extension2 of extensions) {
    findMissingExtensions({ extension: extension2, found, missing });
  }
  invariant10(isEmptyArray6(missing), {
    code: ErrorConstant11.MISSING_REQUIRED_EXTENSION,
    message: missing.map(
      ({ Constructor, extension: extension2 }) => `The extension '${extension2.name}' requires '${Constructor.name} in order to run correctly.`
    ).join("\n")
  });
  return { extensions, extensionMap };
}
function gatherRawExtensions(config, props) {
  var _a;
  const { gatheredExtensions, duplicateMap, parentExtensions, settings } = config;
  const { extension: extension2, parentExtension } = props;
  let { names = [] } = props;
  invariant10(isExtension(extension2), {
    code: ErrorConstant11.INVALID_MANAGER_EXTENSION,
    message: `An invalid extension: ${extension2} was provided to the [[\`RemirrorManager\`]].`
  });
  const childExtensions = extension2.extensions;
  extension2.setPriority((_a = settings.priority) == null ? void 0 : _a[extension2.name]);
  gatheredExtensions.push(extension2);
  updateExtensionDuplicates({ duplicateMap, extension: extension2, parentExtension });
  if (childExtensions.length === 0) {
    return;
  }
  if (names.includes(extension2.name)) {
    warning(
      false,
      `Circular dependency encountered when loading extensions: ${names.join(" > ")} > ${extension2.name}`
    );
    return;
  }
  names = [...names, extension2.name];
  parentExtensions.push(extension2);
  for (const child of childExtensions) {
    gatherRawExtensions(config, { names, extension: child, parentExtension: extension2 });
  }
}
function findMissingExtensions(props) {
  const { extension: extension2, found, missing } = props;
  if (!extension2.requiredExtensions) {
    return;
  }
  for (const Constructor of extension2.requiredExtensions ?? []) {
    if (found.has(Constructor)) {
      continue;
    }
    missing.push({ Constructor, extension: extension2 });
  }
}
function updateExtensionDuplicates(props) {
  const { duplicateMap, extension: extension2, parentExtension } = props;
  const key2 = extension2.constructor;
  const duplicate = duplicateMap.get(key2);
  const parentToAdd = parentExtension ? [parentExtension] : [];
  duplicateMap.set(key2, duplicate ? [...duplicate, ...parentToAdd] : parentToAdd);
}
function extractLifecycleMethods(props) {
  var _a, _b, _c, _d;
  const { extension: extension2, nodeNames, markNames, plainNames, store, handlers } = props;
  extension2.setStore(store);
  const createHandler = (_a = extension2.onCreate) == null ? void 0 : _a.bind(extension2);
  const viewHandler = (_b = extension2.onView) == null ? void 0 : _b.bind(extension2);
  const stateUpdateHandler = (_c = extension2.onStateUpdate) == null ? void 0 : _c.bind(extension2);
  const destroyHandler = (_d = extension2.onDestroy) == null ? void 0 : _d.bind(extension2);
  if (createHandler) {
    handlers.create.push(createHandler);
  }
  if (viewHandler) {
    handlers.view.push(viewHandler);
  }
  if (stateUpdateHandler) {
    handlers.update.push(stateUpdateHandler);
  }
  if (destroyHandler) {
    handlers.destroy.push(destroyHandler);
  }
  if (isMarkExtension(extension2)) {
    markNames.push(extension2.name);
  }
  if (isNodeExtension(extension2) && extension2.name !== "doc") {
    nodeNames.push(extension2.name);
  }
  if (isPlainExtension(extension2)) {
    plainNames.push(extension2.name);
  }
}

// packages/remirror__core/src/manager/remirror-manager.ts
var _extensionStore, _stringHandlers, _store, _extensions, _extensionMap, _phase, _settings, _firstStateUpdate, _handlers, _disposers, _events2, _framework, _disposeFramework;
var _RemirrorManager = class {
  /**
   * Creates the extension manager which is used to simplify the management of
   * the prosemirror editor.
   *
   * This is set to private to encourage using `RemirrorManager.create`
   * instead of the `new` keyword.
   */
  constructor(initialExtension, settings = {}) {
    /**
     * Utility getter for storing the base method props which is available to
     * all extensions.
     */
    __privateAdd(this, _extensionStore, void 0);
    /**
     * The named string handlers
     */
    __privateAdd(this, _stringHandlers, object12());
    /**
     * The extension manager store.
     */
    __privateAdd(this, _store, object12());
    /**
     * All the extensions being used within this editor.
     */
    __privateAdd(this, _extensions, void 0);
    /**
     * The map of extension constructor to their extension counterparts. This is
     * what makes the `getExtension` method possible.
     */
    __privateAdd(this, _extensionMap, void 0);
    /**
     * The stage the manager is currently running.
     */
    __privateAdd(this, _phase, ManagerPhase2.None);
    /**
     * The settings used to create the manager.
     */
    __privateAdd(this, _settings, void 0);
    /**
     * When true this identifies this as the first state update since the view was
     * added to the editor.
     */
    __privateAdd(this, _firstStateUpdate, true);
    /**
     * Store the handlers that will be run when for each event method.
     */
    __privateAdd(this, _handlers, {
      create: [],
      view: [],
      update: [],
      destroy: []
    });
    /**
     * The disposers for the editor.
     */
    __privateAdd(this, _disposers, []);
    /**
     * The event listener which allows consumers to subscribe to the different
     * events without using props.
     */
    __privateAdd(this, _events2, createNanoEvents3());
    /**
     * The active framework for this manager if it exists.
     */
    __privateAdd(this, _framework, void 0);
    /**
     * A method for disposing the state update event listeners on the active
     * framework.
     */
    __privateAdd(this, _disposeFramework, void 0);
    /**
     * A state getter method which is passed into the params.
     */
    this.getState = () => {
      var _a;
      if (__privateGet(this, _phase) >= ManagerPhase2.EditorView) {
        return this.view.state;
      }
      invariant11(__privateGet(this, _framework), {
        code: ErrorConstant12.MANAGER_PHASE_ERROR,
        message: "`getState` can only be called after the `Framework` or the `EditorView` has been added to the manager`. Check your plugins to make sure that the decorations callback uses the state argument."
      });
      return (_a = __privateGet(this, _framework)) == null ? void 0 : _a.initialEditorState;
    };
    /**
     * Update the state of the view and trigger the `onStateUpdate` lifecycle
     * method as well.
     */
    this.updateState = (state) => {
      const previousState = this.getState();
      this.view.updateState(state);
      this.onStateUpdate({ previousState, state });
    };
    const { extensions, extensionMap } = transformExtensions(initialExtension, settings);
    __privateSet(this, _settings, settings);
    __privateSet(this, _extensions, freeze3(extensions));
    __privateSet(this, _extensionMap, extensionMap);
    __privateSet(this, _extensionStore, this.createExtensionStore());
    __privateSet(this, _phase, ManagerPhase2.Create);
    this.setupLifecycleHandlers();
    for (const handler of __privateGet(this, _handlers).create) {
      const disposer = handler();
      if (disposer) {
        __privateGet(this, _disposers).push(disposer);
      }
    }
  }
  /**
   * Create the manager for your `Remirror` editor.
   */
  static create(extensions, settings = {}) {
    return new _RemirrorManager(
      [...getLazyArray(extensions), ...builtinPreset(settings.builtin)],
      settings
    );
  }
  /**
   * Identifies this as a `Manager`.
   *
   * @internal
   */
  get [__INTERNAL_REMIRROR_IDENTIFIER_KEY__3]() {
    return RemirrorIdentifier3.Manager;
  }
  /**
   * Returns `true` if the manager has been destroyed.
   */
  get destroyed() {
    return __privateGet(this, _phase) === ManagerPhase2.Destroy;
  }
  /**
   * `true` when the view has been added to the UI layer and the editor is
   * running.
   */
  get mounted() {
    return __privateGet(this, _phase) >= ManagerPhase2.EditorView && __privateGet(this, _phase) < ManagerPhase2.Destroy;
  }
  /**
   * Retrieve the framework output.
   *
   * This be undefined if the manager hasn't been provided to a framework yet
   * the manager.
   *
   * With synchronous frameworks this means that it should only be accessed
   * after the manager has been applied to the editor creation function.
   *
   * For frameworks like React it is only available when the manager is provided
   * to the `Remirror` component and after the very first render. This means it
   * is available within the `onRef` callback.
   *
   * ```tsx
   * import React, { useEffect } from 'react';
   * import { useRemirror, Remirror } from '@remirror/react';
   *
   * const Editor = () => {
   *   const { manager } = useRemirror();
   *
   *   const callback = () => {
   *     return manager.output; // ✅ This is fine.
   *   }
   *
   *   useEffect(() => {
   *     log(manager.output); // ✅  This is also fine.
   *   }, []);
   *
   *   log(manager.output); // ❌ This will be undefined on the first render.
   *
   *   return <Remirror manager={manager} />
   * }
   * ```
   */
  get output() {
    var _a;
    return (_a = __privateGet(this, _framework)) == null ? void 0 : _a.frameworkOutput;
  }
  /**
   * Returns true when a framework is attached to the manager.
   *
   * This can be used to check if it is safe to call `manager.output`.
   */
  get frameworkAttached() {
    return !!__privateGet(this, _framework);
  }
  /**
   * The extensions stored by this manager
   */
  get extensions() {
    return __privateGet(this, _extensions);
  }
  /**
   * The registered string handlers provided by the extensions.
   *
   * By default this includes `html` and `plainText`
   */
  get stringHandlers() {
    return __privateGet(this, _stringHandlers);
  }
  /**
   * Get the extension manager store which is accessible at initialization.
   */
  get store() {
    return freeze3(__privateGet(this, _store));
  }
  /**
   * Provides access to the extension store.
   */
  get extensionStore() {
    return freeze3(__privateGet(this, _extensionStore));
  }
  /**
   * Shorthand access to the active transaction from the manager. This is the
   * shared transaction available to all commands and should be used when you
   * need to make your commands chainable.
   *
   * If working with react and setting up your editor as a controlled component
   * then this is the preferred way to run custom commands, otherwise your
   * commands will end up being non-chainable and be overwritten by anything
   * that comes after.
   */
  get tr() {
    return this.getExtension(CommandsExtension).transaction;
  }
  /**
   * Returns the stored nodes
   */
  get nodes() {
    return __privateGet(this, _store).nodes;
  }
  /**
   * Returns the store marks.
   */
  get marks() {
    return __privateGet(this, _store).marks;
  }
  /**
   * A shorthand method for retrieving the schema for this extension manager
   * from the data.
   */
  get schema() {
    return __privateGet(this, _store).schema;
  }
  /**
   * A shorthand getter for retrieving the tags from the extension manager.
   */
  get extensionTags() {
    return __privateGet(this, _store).tags;
  }
  /**
   * A shorthand way of retrieving the editor view.
   */
  get view() {
    return __privateGet(this, _store).view;
  }
  /**
   * Retrieve the settings used when creating the manager.
   */
  get settings() {
    return __privateGet(this, _settings);
  }
  /**
   * The document to use for rendering and outputting HTML.
   */
  get document() {
    return __privateGet(this, _settings).document ?? getDocument();
  }
  /**
   * Loops through all extensions to set up the lifecycle handlers.
   */
  setupLifecycleHandlers() {
    const store = __privateGet(this, _extensionStore);
    const handlers = __privateGet(this, _handlers);
    const nodeNames = [];
    const markNames = [];
    const plainNames = [];
    store.nodeNames = nodeNames;
    store.markNames = markNames;
    store.plainNames = plainNames;
    for (const extension2 of __privateGet(this, _extensions)) {
      extractLifecycleMethods({ extension: extension2, nodeNames, markNames, plainNames, handlers, store });
    }
  }
  /**
   * Set the string handler to use for a given name.
   *
   * This allows users to set the string handler
   */
  setStringHandler(name, handler) {
    __privateGet(this, _stringHandlers)[name] = handler;
  }
  /**
   * Set the manager value for the provided key. This is used by extensions to
   * add data to the manager.
   */
  setStoreKey(key2, value) {
    __privateGet(this, _store)[key2] = value;
  }
  /**
   * Get the manager value for the provided key. This is used by extensions to
   * get data from the manager.
   */
  getStoreKey(key2) {
    const value = __privateGet(this, _store)[key2];
    invariant11(!isNullOrUndefined2(value), {
      code: ErrorConstant12.MANAGER_PHASE_ERROR,
      message: "`getStoreKey` should not be called before the values are available."
    });
    return value;
  }
  /**
   * A method to set values in the extension store which is made available to
   * extension.
   *
   * **NOTE** This method should only be used in the `onCreate` extension method
   * or it will throw an error.
   */
  setExtensionStore(key2, value) {
    invariant11(__privateGet(this, _phase) <= ManagerPhase2.EditorView, {
      code: ErrorConstant12.MANAGER_PHASE_ERROR,
      message: "`setExtensionStore` should only be called during the `onCreate` lifecycle hook. Make sure to only call it within the returned methods."
    });
    __privateGet(this, _extensionStore)[key2] = value;
  }
  /**
   * Create the initial store.
   */
  createExtensionStore() {
    const store = object12();
    const enumerable = true;
    let currentState;
    let previousState;
    Object.defineProperties(store, {
      extensions: { get: () => __privateGet(this, _extensions), enumerable },
      phase: { get: () => __privateGet(this, _phase), enumerable },
      view: { get: () => this.view, enumerable },
      managerSettings: { get: () => freeze3(__privateGet(this, _settings)), enumerable },
      getState: { value: this.getState, enumerable },
      updateState: { value: this.updateState, enumerable },
      isMounted: { value: () => this.mounted, enumerable },
      getExtension: { value: this.getExtension.bind(this), enumerable },
      manager: { get: () => this, enumerable },
      document: { get: () => this.document, enumerable },
      stringHandlers: { get: () => __privateGet(this, _stringHandlers), enumerable },
      currentState: {
        get: () => currentState ?? (currentState = this.getState()),
        set: (state) => {
          currentState = state;
        },
        enumerable
      },
      previousState: {
        get: () => previousState,
        set: (state) => {
          previousState = state;
        },
        enumerable
      }
    });
    store.getStoreKey = this.getStoreKey.bind(this);
    store.setStoreKey = this.setStoreKey.bind(this);
    store.setExtensionStore = this.setExtensionStore.bind(this);
    store.setStringHandler = this.setStringHandler.bind(this);
    return store;
  }
  /**
   * Stores the editor view on the manager
   *
   * @param view - the editor view
   */
  addView(view) {
    if (__privateGet(this, _phase) >= ManagerPhase2.EditorView) {
      return this;
    }
    __privateSet(this, _firstStateUpdate, true);
    __privateSet(this, _phase, ManagerPhase2.EditorView);
    __privateGet(this, _store).view = view;
    for (const handler of __privateGet(this, _handlers).view) {
      const disposer = handler(view);
      if (disposer) {
        __privateGet(this, _disposers).push(disposer);
      }
    }
    return this;
  }
  /**
   * Attach a framework to the manager.
   */
  attachFramework(framework, updateHandler) {
    var _a;
    if (__privateGet(this, _framework) === framework) {
      return;
    }
    if (__privateGet(this, _framework)) {
      __privateGet(this, _framework).destroy();
      (_a = __privateGet(this, _disposeFramework)) == null ? void 0 : _a.call(this);
    }
    __privateSet(this, _framework, framework);
    __privateSet(this, _disposeFramework, this.addHandler("stateUpdate", updateHandler));
  }
  /* Public Methods */
  /**
   * Create an empty document for the editor based on the current schema.
   *
   * This automatically looks at the supported content for the doc and the
   * available nodes which fulfil that content in order to create a document
   * with only the minimal required content.
   *
   * This can be used in conjunction with the create state to reset the current
   * value of the editor.
   */
  createEmptyDoc() {
    var _a;
    const doc = (_a = this.schema.nodes.doc) == null ? void 0 : _a.createAndFill();
    invariant11(doc, {
      code: ErrorConstant12.INVALID_CONTENT,
      message: `An empty node could not be created due to an invalid schema.`
    });
    return doc;
  }
  /**
   * Create the editor state from content passed to this extension manager.
   */
  createState(props = {}) {
    const { onError, defaultSelection = "end" } = this.settings;
    const {
      content = this.createEmptyDoc(),
      selection = defaultSelection,
      stringHandler = this.settings.stringHandler
    } = props;
    const { schema, plugins } = this.store;
    const doc = createDocumentNode({
      stringHandler: isString6(stringHandler) ? this.stringHandlers[stringHandler] : stringHandler,
      document: this.document,
      content,
      onError,
      schema,
      selection
    });
    return EditorState3.create({
      schema,
      doc,
      plugins,
      selection: getTextSelection3(selection, doc)
    });
  }
  /**
   * Add a handler to the manager.
   *
   * Currently the only event that can be listened to is the `destroy` event.
   */
  addHandler(event, cb) {
    return __privateGet(this, _events2).on(event, cb);
  }
  /**
   * This method should be called by the view layer every time the state is
   * updated.
   *
   * An example usage of this is within the collaboration extension.
   */
  onStateUpdate(props) {
    const firstUpdate = __privateGet(this, _firstStateUpdate);
    __privateGet(this, _extensionStore).currentState = props.state;
    __privateGet(this, _extensionStore).previousState = props.previousState;
    if (firstUpdate) {
      __privateSet(this, _phase, ManagerPhase2.Runtime);
      __privateSet(this, _firstStateUpdate, false);
    }
    const propsWithUpdate = { ...props, firstUpdate };
    for (const handler of __privateGet(this, _handlers).update) {
      handler(propsWithUpdate);
    }
    __privateGet(this, _events2).emit("stateUpdate", propsWithUpdate);
  }
  /**
   * Get the extension instance matching the provided constructor from the
   * manager.
   *
   * This will throw an error if non existent.
   */
  getExtension(Constructor) {
    const extension2 = __privateGet(this, _extensionMap).get(Constructor);
    invariant11(extension2, {
      code: ErrorConstant12.INVALID_MANAGER_EXTENSION,
      message: `'${Constructor.name}' doesn't exist within this manager. Make sure it is properly added before attempting to use it.`
    });
    return extension2;
  }
  /**
   * Determines in an extension is present by providing the desired
   * `Constructor`.
   *
   * This method can be used as a safer alternative to getExtension which
   * will throw an error if the constructor doesn't exist within the
   * extension created by this extension.
   */
  hasExtension(Constructor) {
    const extension2 = __privateGet(this, _extensionMap).get(Constructor);
    return !!extension2;
  }
  /**
   * Make a clone of the manager.
   *
   * @internalremarks What about the state stored in the extensions and presets,
   * does this need to be recreated as well?
   */
  clone() {
    const extensions = __privateGet(this, _extensions).map((e) => e.clone(e.options));
    const manager = _RemirrorManager.create(() => extensions, __privateGet(this, _settings));
    __privateGet(this, _events2).emit("clone", manager);
    return manager;
  }
  /**
   * Recreate the manager with new settings and extensions
   */
  recreate(extensions = [], settings = {}) {
    const currentExtensions = __privateGet(this, _extensions).map((e) => e.clone(e.initialOptions));
    const manager = _RemirrorManager.create(() => [...currentExtensions, ...extensions], settings);
    __privateGet(this, _events2).emit("recreate", manager);
    return manager;
  }
  /**
   * This method should be called to destroy the manager and remove the view.
   */
  destroy() {
    var _a, _b, _c, _d, _e, _f;
    __privateSet(this, _phase, ManagerPhase2.Destroy);
    for (const plugin of ((_a = this.view) == null ? void 0 : _a.state.plugins) ?? []) {
      (_c = (_b = plugin.getState(this.view.state)) == null ? void 0 : _b.destroy) == null ? void 0 : _c.call(_b);
    }
    (_d = __privateGet(this, _framework)) == null ? void 0 : _d.destroy();
    (_e = __privateGet(this, _disposeFramework)) == null ? void 0 : _e.call(this);
    for (const dispose of __privateGet(this, _disposers)) {
      dispose();
    }
    for (const onDestroy of __privateGet(this, _handlers).destroy) {
      onDestroy();
    }
    (_f = this.view) == null ? void 0 : _f.destroy();
    __privateGet(this, _events2).emit("destroy");
  }
  /**
   * Check whether the manager includes the names or constructors provided for
   * the preset and extensions.
   *
   * Returns true if all are included, returns false otherwise.
   */
  includes(mustIncludeList) {
    const names = [];
    const extensionsAndPresets = [];
    for (const item of __privateGet(this, _extensions)) {
      names.push(item.name, item.constructorName);
      extensionsAndPresets.push(item.constructor);
    }
    return mustIncludeList.every(
      (item) => isString6(item) ? includes4(names, item) : includes4(extensionsAndPresets, item)
    );
  }
};
var RemirrorManager = _RemirrorManager;
_extensionStore = new WeakMap();
_stringHandlers = new WeakMap();
_store = new WeakMap();
_extensions = new WeakMap();
_extensionMap = new WeakMap();
_phase = new WeakMap();
_settings = new WeakMap();
_firstStateUpdate = new WeakMap();
_handlers = new WeakMap();
_disposers = new WeakMap();
_events2 = new WeakMap();
_framework = new WeakMap();
_disposeFramework = new WeakMap();
function isRemirrorManager(value, mustIncludeList) {
  if (!isRemirrorType2(value) || !isIdentifierOfType2(value, RemirrorIdentifier3.Manager)) {
    return false;
  }
  if (!mustIncludeList) {
    return true;
  }
  return value.includes(mustIncludeList);
}

// packages/remirror__core/src/index.ts
export * from "@remirror/core-constants";
export * from "@remirror/core-helpers";
export * from "@remirror/core-types";
export * from "@remirror/core-utils";
export {
  AttributesExtension,
  CommandsExtension,
  DEFAULT_SHORTCUTS,
  DecorationsExtension,
  DelayedCommand,
  DocChangedExtension,
  Framework,
  GOOGLE_DOC_SHORTCUTS,
  HelpersExtension,
  InputRulesExtension,
  KeymapExtension,
  MarkExtension,
  MetaExtension,
  NodeExtension,
  NodeViewsExtension,
  PasteRulesExtension,
  PlainExtension,
  PluginsExtension,
  RemirrorManager,
  SchemaExtension,
  SuggestExtension,
  TagsExtension,
  UploadExtension,
  builtinPreset,
  command,
  delayedCommand,
  extension,
  extensionDecorator,
  findUploadPlaceholderPayload,
  findUploadPlaceholderPos,
  hasUploadingFile,
  helper,
  insertText,
  isDelayedValue,
  isExtension,
  isExtensionConstructor,
  isExtensionTag,
  isMarkExtension,
  isNodeExtension,
  isPlainExtension,
  isRemirrorManager,
  keyBinding,
  keyboardShortcuts,
  mutateDefaultExtensionOptions,
  setUploadPlaceholderAction,
  toggleMark,
  uploadFile
};
