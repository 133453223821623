var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// packages/remirror__extension-blockquote/src/blockquote-extension.ts
import {
  command,
  ExtensionTag,
  keyBinding,
  NodeExtension,
  toggleWrap
} from "@remirror/core";
import { ExtensionBlockquoteMessages as Messages } from "@remirror/messages";
import { wrappingInputRule } from "@remirror/pm/inputrules";
var BlockquoteExtension = class extends NodeExtension {
  get name() {
    return "blockquote";
  }
  createTags() {
    return [ExtensionTag.Block, ExtensionTag.FormattingNode];
  }
  createNodeSpec(extra, override) {
    return {
      content: "block+",
      defining: true,
      draggable: false,
      ...override,
      attrs: extra.defaults(),
      parseDOM: [
        { tag: "blockquote", getAttrs: extra.parse, priority: 100 },
        ...override.parseDOM ?? []
      ],
      toDOM: (node) => ["blockquote", extra.dom(node), 0]
    };
  }
  toggleBlockquote() {
    return toggleWrap(this.type);
  }
  shortcut(props) {
    return this.toggleBlockquote()(props);
  }
  createInputRules() {
    return [wrappingInputRule(/^\s*>\s$/, this.type)];
  }
  createPasteRules() {
    return {
      type: "node",
      nodeType: this.type,
      regexp: /^\s*>\s$/,
      startOfTextBlock: true
    };
  }
};
__decorateClass([
  command({
    icon: "doubleQuotesL",
    description: ({ t }) => t(Messages.DESCRIPTION),
    label: ({ t }) => t(Messages.LABEL)
  })
], BlockquoteExtension.prototype, "toggleBlockquote", 1);
__decorateClass([
  keyBinding({ shortcut: "Ctrl->", command: "toggleBlockquote" })
], BlockquoteExtension.prototype, "shortcut", 1);
export {
  BlockquoteExtension
};
