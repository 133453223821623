var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// packages/remirror__extension-mention-atom/src/mention-atom-extension.ts
import {
  command,
  ErrorConstant,
  extension,
  ExtensionTag,
  invariant,
  isElementDomNode,
  isString,
  kebabCase,
  NodeExtension,
  omitExtraAttributes,
  pick,
  replaceText
} from "@remirror/core";
import {
  DEFAULT_SUGGESTER
} from "@remirror/pm/suggest";
import { ExtensionMentionAtomTheme as Theme } from "@remirror/theme";
var MentionAtomExtension = class extends NodeExtension {
  get name() {
    return "mentionAtom";
  }
  createTags() {
    return [ExtensionTag.InlineNode, ExtensionTag.Behavior];
  }
  createNodeSpec(extra, override) {
    const dataAttributeId = "data-mention-atom-id";
    const dataAttributeName = "data-mention-atom-name";
    return {
      inline: true,
      marks: "",
      selectable: this.options.selectable,
      draggable: this.options.draggable,
      atom: true,
      ...override,
      attrs: {
        ...extra.defaults(),
        id: {},
        label: {},
        name: {}
      },
      parseDOM: [
        ...this.options.matchers.map((matcher) => ({
          tag: `${matcher.mentionTag ?? this.options.mentionTag}[${dataAttributeId}]`,
          getAttrs: (node) => {
            if (!isElementDomNode(node)) {
              return false;
            }
            const id = node.getAttribute(dataAttributeId);
            const name = node.getAttribute(dataAttributeName);
            const label = node.textContent;
            return { ...extra.parse(node), id, label, name };
          }
        })),
        ...override.parseDOM ?? []
      ],
      toDOM: (node) => {
        const { label, id, name } = omitExtraAttributes(
          node.attrs,
          extra
        );
        const matcher = this.options.matchers.find((matcher2) => matcher2.name === name);
        const mentionClassName = matcher ? matcher.mentionClassName ?? DEFAULT_MATCHER.mentionClassName : DEFAULT_MATCHER.mentionClassName;
        const attrs = {
          ...extra.dom(node),
          class: name ? `${mentionClassName} ${mentionClassName}-${kebabCase(name)}` : mentionClassName,
          [dataAttributeId]: id,
          [dataAttributeName]: name
        };
        return [(matcher == null ? void 0 : matcher.mentionTag) ?? this.options.mentionTag, attrs, label];
      }
    };
  }
  createMentionAtom(details, attrs) {
    const { name, range } = details;
    const validNameExists = this.options.matchers.some((matcher) => name === matcher.name);
    invariant(validNameExists, {
      code: ErrorConstant.EXTENSION,
      message: `Invalid name '${name}' provided when creating a mention. Please ensure you only use names that were configured on the matchers when creating the \`MentionAtomExtension\`.`
    });
    const { appendText, ...rest } = attrs;
    return replaceText({
      type: this.type,
      appendText: getAppendText(appendText, this.options.appendText),
      attrs: { name, ...rest },
      range
    });
  }
  /**
   * Track click events passed through to the editor.
   */
  createEventHandlers() {
    return {
      click: (event, clickState) => {
        if (!clickState.direct) {
          return;
        }
        const nodeWithPosition = clickState.getNode(this.type);
        if (!nodeWithPosition) {
          return;
        }
        return this.options.onClick(event, nodeWithPosition);
      }
    };
  }
  createSuggesters() {
    const options = pick(this.options, [
      "invalidMarks",
      "invalidNodes",
      "isValidPosition",
      "validMarks",
      "validNodes",
      "suggestTag",
      "disableDecorations",
      "appendText"
    ]);
    return this.options.matchers.map((matcher) => {
      return {
        ...DEFAULT_MATCHER,
        ...options,
        ...matcher,
        onChange: (props) => {
          const { name, range } = props;
          const { createMentionAtom } = this.store.commands;
          function command2(attrs) {
            createMentionAtom({ name, range }, attrs);
          }
          this.options.onChange(props, command2);
        }
      };
    });
  }
};
__decorateClass([
  command()
], MentionAtomExtension.prototype, "createMentionAtom", 1);
MentionAtomExtension = __decorateClass([
  extension({
    defaultOptions: {
      selectable: true,
      draggable: false,
      mentionTag: "span",
      matchers: [],
      appendText: " ",
      suggestTag: "span",
      disableDecorations: false,
      invalidMarks: [],
      invalidNodes: [],
      isValidPosition: () => true,
      validMarks: null,
      validNodes: null
    },
    handlerKeyOptions: { onClick: { earlyReturnValue: true } },
    handlerKeys: ["onChange", "onClick"],
    staticKeys: ["selectable", "draggable", "mentionTag", "matchers"]
  })
], MentionAtomExtension);
var DEFAULT_MATCHER = {
  ...pick(DEFAULT_SUGGESTER, [
    "startOfLine",
    "supportedCharacters",
    "validPrefixCharacters",
    "invalidPrefixCharacters"
  ]),
  appendText: "",
  matchOffset: 1,
  suggestClassName: Theme.SUGGEST_ATOM,
  mentionClassName: Theme.MENTION_ATOM
};
function getAppendText(preferred, fallback) {
  if (isString(preferred)) {
    return preferred;
  }
  if (isString(fallback)) {
    return fallback;
  }
  return DEFAULT_MATCHER.appendText;
}
export {
  MentionAtomExtension
};
