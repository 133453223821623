// packages/remirror__preset-wysiwyg/src/wysiwyg-preset.ts
import { BidiExtension } from "@remirror/extension-bidi";
import { BlockquoteExtension } from "@remirror/extension-blockquote";
import { BoldExtension } from "@remirror/extension-bold";
import { CodeExtension } from "@remirror/extension-code";
import { CodeBlockExtension } from "@remirror/extension-code-block";
import { DropCursorExtension } from "@remirror/extension-drop-cursor";
import { IframeExtension } from "@remirror/extension-embed";
import { GapCursorExtension } from "@remirror/extension-gap-cursor";
import { HardBreakExtension } from "@remirror/extension-hard-break";
import { HeadingExtension } from "@remirror/extension-heading";
import { HorizontalRuleExtension } from "@remirror/extension-horizontal-rule";
import { ImageExtension } from "@remirror/extension-image";
import { ItalicExtension } from "@remirror/extension-italic";
import { LinkExtension } from "@remirror/extension-link";
import {
  BulletListExtension,
  OrderedListExtension,
  TaskListExtension
} from "@remirror/extension-list";
import { SearchExtension } from "@remirror/extension-search";
import { ShortcutsExtension } from "@remirror/extension-shortcuts";
import { StrikeExtension } from "@remirror/extension-strike";
import { TrailingNodeExtension } from "@remirror/extension-trailing-node";
import { UnderlineExtension } from "@remirror/extension-underline";
var DEFAULT_OPTIONS = {
  ...BidiExtension.defaultOptions,
  ...BoldExtension.defaultOptions,
  ...CodeBlockExtension.defaultOptions,
  ...DropCursorExtension.defaultOptions,
  ...SearchExtension.defaultOptions,
  ...TrailingNodeExtension.defaultOptions,
  ...HeadingExtension.defaultOptions
};
function wysiwygPreset(options = {}) {
  options = { ...DEFAULT_OPTIONS, ...options };
  const gapCursorExtension = new GapCursorExtension();
  const hardBreakExtension = new HardBreakExtension();
  const horizontalRuleExtension = new HorizontalRuleExtension();
  const imageExtension = new ImageExtension();
  const italicExtension = new ItalicExtension();
  const strikeExtension = new StrikeExtension();
  const underlineExtension = new UnderlineExtension();
  const blockquoteExtension = new BlockquoteExtension();
  const codeExtension = new CodeExtension();
  const iframeExtension = new IframeExtension();
  const bulletListExtension = new BulletListExtension();
  const orderedListExtension = new OrderedListExtension();
  const taskListExtension = new TaskListExtension({});
  const shortcutsExtension = new ShortcutsExtension();
  const { selectTextOnClick } = options;
  const linkExtension = new LinkExtension({ autoLink: true, selectTextOnClick });
  const { autoUpdate, defaultDirection, excludeNodes } = options;
  const bidiExtension = new BidiExtension({ autoUpdate, defaultDirection, excludeNodes });
  const { weight } = options;
  const boldExtension = new BoldExtension({ weight });
  const { defaultLanguage, formatter, toggleName, syntaxTheme, supportedLanguages } = options;
  const codeBlockExtension = new CodeBlockExtension({
    defaultLanguage,
    formatter,
    toggleName,
    syntaxTheme,
    supportedLanguages
  });
  const { color, width } = options;
  const dropCursorExtension = new DropCursorExtension({
    color,
    width
  });
  const { defaultLevel, levels } = options;
  const headingExtension = new HeadingExtension({ defaultLevel, levels });
  const { alwaysSearch } = options;
  const searchExtension = new SearchExtension({
    alwaysSearch
  });
  const { disableTags, ignoredNodes, nodeName } = options;
  const trailingNodeExtension = new TrailingNodeExtension({
    disableTags,
    ignoredNodes,
    nodeName
  });
  return [
    // Plain
    bidiExtension,
    dropCursorExtension,
    gapCursorExtension,
    searchExtension,
    shortcutsExtension,
    trailingNodeExtension,
    // Nodes
    hardBreakExtension,
    imageExtension,
    horizontalRuleExtension,
    blockquoteExtension,
    codeBlockExtension,
    headingExtension,
    iframeExtension,
    bulletListExtension,
    orderedListExtension,
    taskListExtension,
    // Marks
    boldExtension,
    codeExtension,
    strikeExtension,
    italicExtension,
    linkExtension,
    underlineExtension
  ];
}
export {
  wysiwygPreset
};
