var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// packages/remirror__extension-text-color/src/text-color-extension.ts
import { toHex } from "color2k";
import {
  command,
  extension,
  ExtensionPriority,
  ExtensionTag,
  helper,
  isElementDomNode,
  isString,
  joinStyles,
  MarkExtension,
  omitExtraAttributes
} from "@remirror/core";

// packages/remirror__extension-text-color/src/text-color-utils.ts
import { range } from "@remirror/core";
import { ExtensionTextColorMessages as Messages } from "@remirror/messages";
import { getThemeVar } from "@remirror/theme";
var setTextColorOptions = {
  icon: "fontColor",
  description: ({ t }) => t(Messages.SET_COLOR_DESCRIPTION),
  label: ({ t }) => t(Messages.SET_COLOR_LABEL)
};
var hueRange = range([10]);
function createHuePalette(props) {
  const { t, name, labelDescriptor, hueDescriptor } = props;
  const label = t(labelDescriptor);
  const hues = hueRange.map((hue) => ({
    label: t(hueDescriptor, { hue }),
    color: getThemeVar("hue", name, hue)
  }));
  return { label, hues };
}
function palette(t) {
  const black = { label: t(Messages.BLACK), color: "#000" };
  const white = { label: t(Messages.WHITE), color: "#fff" };
  const transparent = { label: t(Messages.TRANSPARENT), color: "transparent" };
  const hues = {
    gray: createHuePalette({
      t,
      name: "gray",
      labelDescriptor: Messages.GRAY,
      hueDescriptor: Messages.GRAY_HUE
    }),
    blue: createHuePalette({
      t,
      name: "blue",
      labelDescriptor: Messages.BLUE,
      hueDescriptor: Messages.BLUE_HUE
    }),
    cyan: createHuePalette({
      t,
      name: "cyan",
      labelDescriptor: Messages.CYAN,
      hueDescriptor: Messages.CYAN_HUE
    }),
    grape: createHuePalette({
      t,
      name: "grape",
      labelDescriptor: Messages.GRAPE,
      hueDescriptor: Messages.GRAPE_HUE
    }),
    green: createHuePalette({
      t,
      name: "green",
      labelDescriptor: Messages.GREEN,
      hueDescriptor: Messages.GREEN_HUE
    }),
    indigo: createHuePalette({
      t,
      name: "indigo",
      labelDescriptor: Messages.INDIGO,
      hueDescriptor: Messages.INDIGO_HUE
    }),
    lime: createHuePalette({
      t,
      name: "lime",
      labelDescriptor: Messages.LIME,
      hueDescriptor: Messages.LIME_HUE
    }),
    orange: createHuePalette({
      t,
      name: "orange",
      labelDescriptor: Messages.ORANGE,
      hueDescriptor: Messages.ORANGE_HUE
    }),
    pink: createHuePalette({
      t,
      name: "pink",
      labelDescriptor: Messages.PINK,
      hueDescriptor: Messages.PINK_HUE
    }),
    red: createHuePalette({
      t,
      name: "red",
      labelDescriptor: Messages.RED,
      hueDescriptor: Messages.RED_HUE
    }),
    teal: createHuePalette({
      t,
      name: "teal",
      labelDescriptor: Messages.TEAL,
      hueDescriptor: Messages.TEAL_HUE
    }),
    violet: createHuePalette({
      t,
      name: "violet",
      labelDescriptor: Messages.VIOLET,
      hueDescriptor: Messages.VIOLET_HUE
    }),
    yellow: createHuePalette({
      t,
      name: "yellow",
      labelDescriptor: Messages.YELLOW,
      hueDescriptor: Messages.YELLOW_HUE
    })
  };
  return { black, hues, transparent, white };
}
var CSS_VAR_REGEX = /^var\((--[A-Za-z-]+)\);?/;
var TEXT_COLOR_ATTRIBUTE = "data-text-color-mark";

// packages/remirror__extension-text-color/src/text-color-extension.ts
var TextColorExtension = class extends MarkExtension {
  get name() {
    return "textColor";
  }
  createTags() {
    return [ExtensionTag.FormattingMark, ExtensionTag.FontStyle];
  }
  createMarkSpec(extra, override) {
    return {
      ...override,
      attrs: {
        ...extra.defaults(),
        color: { default: this.options.defaultColor }
      },
      parseDOM: [
        {
          tag: `span[${TEXT_COLOR_ATTRIBUTE}]`,
          getAttrs: (dom) => {
            if (!isElementDomNode(dom)) {
              return null;
            }
            const color = dom.getAttribute(TEXT_COLOR_ATTRIBUTE);
            if (!color) {
              return null;
            }
            return { ...extra.parse(dom), color };
          }
        },
        {
          // Get the color from the css style property. This is useful for pasted content.
          style: "color",
          priority: ExtensionPriority.Low,
          getAttrs: (color) => {
            if (!isString(color)) {
              return null;
            }
            return { color };
          }
        },
        ...override.parseDOM ?? []
      ],
      toDOM: (mark) => {
        const { color, ...other } = omitExtraAttributes(mark.attrs, extra);
        const extraAttrs = extra.dom(mark);
        let style = extraAttrs.style;
        if (color) {
          style = joinStyles({ color }, style);
        }
        return ["span", { ...other, ...extraAttrs, style, [TEXT_COLOR_ATTRIBUTE]: color }, 0];
      }
    };
  }
  setTextColor(color, options) {
    return this.store.commands.applyMark.original(this.type, { color }, options == null ? void 0 : options.selection);
  }
  removeTextColor(options) {
    return this.store.commands.removeMark.original({ type: this.type, ...options, expand: true });
  }
  getHexColor(color) {
    const match = color.match(CSS_VAR_REGEX);
    const cssVar = match == null ? void 0 : match[1];
    if (cssVar) {
      color = getComputedStyle(this.store.view.dom).getPropertyValue(cssVar);
    }
    return toHex(color);
  }
};
__decorateClass([
  command(setTextColorOptions)
], TextColorExtension.prototype, "setTextColor", 1);
__decorateClass([
  command()
], TextColorExtension.prototype, "removeTextColor", 1);
__decorateClass([
  helper()
], TextColorExtension.prototype, "getHexColor", 1);
TextColorExtension = __decorateClass([
  extension({
    defaultOptions: {
      defaultColor: "",
      palette
    },
    staticKeys: ["defaultColor"]
  })
], TextColorExtension);
export {
  CSS_VAR_REGEX,
  TEXT_COLOR_ATTRIBUTE,
  TextColorExtension,
  palette,
  setTextColorOptions
};
